import Http from '../../Globals/Http';
import {requestOderType} from "../../Globals/Constants";

const get		= async (parentId, type)	=> await Http.get('parentpsychologyjournals', {conditions:[{k:'parentId',v:parentId},{k:'type',v:type}], orders:[{k:'date', isd:requestOderType.DESC}]});

const add		= async (data)				=> await Http.post('parentpsychologyjournals', {data});
const remove 	= async (id) 			=> await Http.remove('parentpsychologyjournals', {conditions:[{k:'id',v:id}]});

export {
	get,
	add,
	remove
}