import {Component} from 'react';
import Template from '../../Components/Template';
import {Helpers, LS} from '../../Globals/index';

import './styles.scss';
import { Childs, Parents, Programs, Teachers } from '../../Models';
import Loader from '../../Components/Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { PropTypes } from 'prop-types';

class SearchScreen extends Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		const nameParam = urlParams.get('search');

        

		this.state = {
			user:null,
            areaId:null,
			loading:true,
            search:nameParam
		};
	}

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));

        try {
            await Teachers.getAll(areaId, 1).then((res) => this.setState({ teachers: res.data }));
            await Programs.getAll().then((res) => this.setState({ programs: res.data }));
            await Parents.getAll(areaId).then((res) => this.setState({ parents: res.data }));
            await Childs.getAll(areaId).then((res) => this.setState({ pupils: res.data }));
		
            this.setState({
                user,
                areaId,
                loading:false
            });
        } catch(err) {
            console.error(err);
        }
	}
    
    updateQueryInURL = (query) => {
        const { history, location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('search', query);
        history.push({ search: queryParams.toString() });
    };

	searchBy = (event) => {
        this.setState({ search: event.target.value });
        this.updateQueryInURL(event.target.value);
        //history.push({ search: queryParams.toString() });
    };

    render() {
        const { teachers, parents, pupils, search, loading } = this.state;

        let t = teachers;
        let p = parents;
        let c = pupils;

        t = t?.filter(i => `${i.lastName} ${i.firstName} ${i.middleName}`.toLowerCase().includes(search?.toLowerCase()));
        p = p?.filter(i => `${i.lastName} ${i.firstName} ${i.middleName}`.toLowerCase().includes(search?.toLowerCase()));
        c = c?.filter(i => `${i.lastName} ${i.firstName} ${i.middleName}`.toLowerCase().includes(search?.toLowerCase()));

		return <Template 
			page={'search'} 
			title={'Поиск'}
        >
            {loading ? <Loader /> :
            <div className="search-container">
                <input type="text" onChange={this.searchBy} value={search} placeholder={'Поиск'} />
                <span>Воспитанники</span>
                <div className="search-block">
                    {
                        c?.length ? c.map((item, i) => (
                            <div key={i}><Link to={`/pupil/${item.id}`}>{item.lastName} {item.firstName} {item.middleName}</Link></div>
                        )) : 'Не найдено воспитанников по условию'
                    }
                </div>
                <span>Родители</span>
                <div className="search-block">
                    {
                        p?.length ? p.map((item, i) => (
                            <div key={i}><Link to={`/parent/${item.id}`}>{item.lastName} {item.firstName} {item.middleName}</Link></div>
                        )) : 'Не найдено родителей по условию'
                    }
                </div>
                <span>Сотрудники</span>
                <div className="search-block">
                    {
                        t?.length ? t.map((item, i) => (
                            <div key={i}><Link to={`/teacher/${item.id}`}>{item.lastName} {item.firstName} {item.middleName}</Link></div>
                        )) : 'Не найдено сотрудников по условию'
                    }
                </div>
            </div>
            }
		</Template>
    }
}


SearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default SearchScreen;