import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Users} from '../../Models';

// helpers
import {Utils,Helpers,Errors} from '../../Globals/index';

// globals
import {comonStatusName} from '../../Globals/Constants';

// styles
import './styles.css';

import PropTypes from 'prop-types';

class UserScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const u = await Users.get(this.state.id);
			if (u.data && u.data.length) this.setState({data:u.data[0],notfound:false});
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name,
			position:data.position,
			info:data.info,
			username:data.username,
			status:data.status
		};
		if (id === 0) d.password = data.password;
		else {
			if (!Utils.empty(data.password)) d.password = data.password;
		}
		if (id === 0) {
			const res = await Users.add(d).catch(() => this.error());
			if (res) Errors.add();
			else return false;
		} else {
			await Users.update(id, d).catch(() => this.error());
			Errors.update();
		}
		this.props.history.push('/users');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'settings'}
					title={this.state.id === 0 ? 'Добавление пользователя' : 'Редактирование пользователя'}
					controlPanel={
						<div className="form-control-panel">
							<button form="user-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/users'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/users'} /> :
						<form id="user-form" className="form" onSubmit={this.save}>
							<div className="form-row">
								<label htmlFor="name">Имя</label>
								<input type="text" name="name" id="name" placeholder="Имя, Ф.И.О." required className="double" onChange={this.formHandle} value={this.state.data.name} />
							</div>
							<div className="form-row-oneline">
								<div className="form-row-oneline">
									<div className="form-row">
										<label htmlFor="username">Имя пользователя</label>
										<input type="text" name="username" id="username" placeholder="Имя пользователя" required onChange={this.formHandle} value={this.state.data.username} />
									</div>
									<div className="form-row">
										<label htmlFor="password">Пароль</label>
										{this.state.id === 0 ? 
												<input type="password" name="password" id="password" placeholder="Пароль" required onChange={this.formHandle} value={this.state.data.password} />
											:
												<input type="password" name="password" id="password" placeholder="Пароль" onChange={this.formHandle} value={this.state.data.password} />
										}
									</div>
								</div>
							</div>
							<div className="form-row">
								<label htmlFor="position">Позиция</label>
								<input type="text" name="position" id="position" placeholder="Позиция" required className="double" onChange={this.formHandle} value={this.state.data.position} />
							</div>
							<div className="form-row">
								<label htmlFor="info">Доп. описание</label>
								<textarea name="info" id="info" placeholder="Доп. описание" onChange={this.formHandle}>{this.state.data.info}</textarea>
							</div>
							<div className="form-row">
								<label htmlFor="status">Статус</label>
								<select name="status" id="status" onChange={this.formHandle} required defaultValue={this.state.data.status}>
									<option></option>
									{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
								</select>
							</div>
						</form>
			}
			<Alert />
		</Template>
	}
}
UserScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default UserScreen;