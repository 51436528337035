import { Component } from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import { Events } from '../../Models';

// globals
import { eventMessageName } from '../../Globals/Constants';

// helpers
import { Helpers } from '../../Globals/index';

// styles
import './styles.css';

//vars
const menu = [
  {
    name: 'Дети',
    link: 'correction-childs',
    desc: 'Анамнез, диагностика, индивидуальная работа, динамика',
  },
  {
    name: 'Групповая работа мальчиков',
    link: 'correction-group',
    desc: 'Мероприятия согласно программе',
  },
  {
    name: 'Групповая работа девочек',
    link: 'correction-group-girls',
    desc: 'Мероприятия согласно программе',
  },
  {
    name: 'Расписание',
    link: 'correction-timetable',
    desc: 'Еженедельное расписание мероприятий',
  },
  {
    name: 'Правила',
    link: 'correction-rules',
    desc: 'Знакомство с правилами центра',
  },
  {
    name: 'Экспорт',
    link: 'correctionexport',
    desc: 'Экспорт по выбранным параметрам',
  },
];

class PsychologyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }
  componentDidMount = async () => {
    const user = Helpers.authCheck();
    this.setState({ user, loading: false });

    // Add events in log
    Events.add(
      user.id,
      eventMessageName.viewCorrectionPage,
      null,
      null,
      null,
      2
    );
  };
  render() {
    return (
      <Template page={'departments'} title={'Коррекционная работа'}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="psychology-list">
            {menu.map((v, i) => (
              <Link to={`/${v.link}`} className="item" key={i}>
                <div className="item-title">{v.name}</div>
                <div className="item-description">{v.desc}</div>
              </Link>
            ))}
          </div>
        )}
      </Template>
    );
  }
}

export default PsychologyScreen;
