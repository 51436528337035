import {Component} from 'react';
import Template from '../../Components/Template';
import Alert, { alertShow } from '../../Components/Alert';
import {Helpers,LS} from '../../Globals/index';
import './styles.scss';
import { taskChildTypeNames, taskTypeNames, typeUserNames, typeUsers } from '../../Globals/Constants';
import { Childs, Parents, TaskUsers, Tasks, Teachers } from '../../Models';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../Components/Loader';
import { PropTypes } from 'prop-types';

class TasksScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true,
			id:parseInt(this.props.match.params.id||0),
			categoryId:0,
			selectedParents: [],
			selectedPupils: [],
			timeWasEdit: true,
			teachers: [],
			pupils: [],
			parents: [],
			updatedTasks: [],
			taskusers: [],
			tasks: []
		};
	}
	
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		await Teachers.getAll(areaId, 1).then((res) => this.setState({teachers: res.data}));
		await Childs.getAll(areaId).then((res) => this.setState({pupils: res.data}));
		await Parents.getAll(areaId).then((res) => this.setState({parents: res.data}));
		
		await Tasks.get(this.state.id).then(async (tasks) => {
			if (tasks && tasks.data) {
				await TaskUsers.getAll().then((users) => {
					if (users && users.data && users.data.length) {
						const updatedTasks = tasks.data.sort((a, b) => new Date(b.dateCreate) - new Date(a.dateCreate)).map((task) => {
							const curator = this.state.teachers.filter((t) => t.id === task.curatorId)[0];
							return { ...task, users: users.data.filter((user) => user.taskId === task.id), curator, daysout: this.checkDate(this.getCurrentDateTime(), task.dateEnd) };
						})
						this.setState({taskusers: users.data, updatedTasks});
					}
				});
				this.setState({tasks: tasks.data});
			}
		});
		this.setState({user,areaId,loading:false});
	}
	
    autoResizeTextArea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

	handleMultiSelectChangePupils = (selectedItems) => this.setSelectedPupils(selectedItems);

	handleMultiSelectChangeParents = (selectedItems) => this.setSelectedParents(selectedItems);

	setSelectedPupils = (items) => this.setState({ selectedPupils: items.map(id => { return this.state.pupils.filter(p => p.id === id)[0] }) });

	setSelectedParents = (items) => this.setState({ selectedParents: items.map(id => { return this.state.parents.filter(p => p.id === id)[0] }) });

	getCurrentDateTime = () => Math.floor(new Date().getTime() / 1000);

	combineDateTime = (date, time) => date && time ? Math.floor(new Date(`${date} ${time}`).getTime() / 1000) : null;

	handleTeacherTaskChange = (event, task) => {
		const selectedTeacherId = parseInt(event.target.value);
		const updatedTasks = this.state.updatedTasks.map((t) => {
			if (t.id === task.id) {
				const curator = this.state.teachers.filter((t) => t.id === selectedTeacherId)[0];
				Tasks.update(task.id, { curatorId: selectedTeacherId, dateModify: Math.floor(Date.now() / 1000) });
				return { ...t, curatorId: selectedTeacherId, curator }
			}
			return t;
		});

		this.setState({ updatedTasks });
	}

	getDayWord = (number) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return ['день', 'дня', 'дней'][(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
	};

	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

	checkDate = (start, end) => {
		const differenceInSeconds = end - start;
		const remainingDays = Math.ceil(differenceInSeconds / 86400);
		return remainingDays;
	};

	startTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 1) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 2, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 2 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача запущена');
		return true;
	}

	stopTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.comment === '' || !task.comment) {
			alertShow('Чтобы завершить задачу, необходимо заполнить результат', 1);
			return false;
		}

		if (task.status !== 2) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 3, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 3 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача завершена');
		return true;
	}

	hideTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно скрыть эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 3) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 4, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 4 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача скрыта');
		return true;
	}

	isWasEdit = (task) => {
		return task.id;
	}

	handleTaskName = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, name: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleTaskInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, info: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleResultInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, comment: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	updateTask = async (task) => {
		const { comment, name, info } = task;
		Tasks.update(task.id, {
			comment, name, info, dateModify: Math.floor(Date.now() / 1000)
		});
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, wasedit: false };
			}
			return t;
		});
		this.setState({ updatedTasks });
		alertShow('Задача была обновлена');
	}

	setFilter = (type) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				type
			}
		}));
	}

	sortBy = (event) => {
		const sortOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				sort: sortOption
			}
		}));
	};

	searchBy = (event) => {
		const filterOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				filter: filterOption
			}
		}));
	};

	dateToString = (date) => {
		return date.toISOString();
	};

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				search: query
			}
		}));
	};

	render() {
		const { updatedTasks } = this.state;

		return <>
			<Template
				page={'psychology-tasks'}
				title={'Редактировать задачу'}
				controlPanel={
					<div className="control-panel">
						<Link to={'/psychology-tasks'}><button>Все задачи</button></Link>
					</div>
				}
				backLink={'/'}>
				{this.state.loading ? <Loader /> : (<>
					<div className="tasks-container">
						<div className="tasks-list">
							{updatedTasks?.length ? updatedTasks.map((task, i) => (<div key={i}>
								<div className="tasks-item">
									<div>
										<div className="tasks-block">
											<span>{taskTypeNames[task.type]}</span>
											<span>{taskChildTypeNames[task.view]}</span>
										</div>
									</div>
									<div>
										<div className="tasks-block">
											<span>Дата начала</span>
											<span>{this.dateToLocale(task.dateStart)}</span>
										</div>
									</div>
									<div>
										<div className="tasks-block">
											<span>{task.curator?.lastName} {task.curator?.firstName} {task.curator?.middleName}</span>
											<span>{task.curator?.position}</span>
											<select className="little-select" value={task.curator?.id} onChange={(e) => this.handleTeacherTaskChange(e, task)}>
												<option value="">Изменить ответственного</option>
												{this.state.teachers.map((teacher) => (
													<option key={teacher.id} value={teacher.id}>{`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`}</option>
												))}
											</select>
										</div>
									</div>
									<div>
										{task.status === 1 ? (
											<div className="tasks-block task-button-start">
												<div className="btn-start" onClick={() => this.startTask(task.id)}><i className="bi bi-play"></i></div>
												<span>Начать</span>
											</div>
										) : task.status === 2 ? (
											<div className="tasks-block task-button-end">
												<div className="btn-end" onClick={() => this.stopTask(task.id)}><i className="bi bi-stop"></i></div>
												<span>Завершить</span>
											</div>
										) : task.status === 3 ? (
											<div className="tasks-block task-button-hide">
												<div className="btn-hide" onClick={() => this.hideTask(task.id)}><i className="bi bi-x"></i></div>
												<span>Задача завершена</span>
											</div>
										) : <div className="tasks-block">В архиве</div>}
										{task.wasedit && <div className="tasks-block button-save"><span onClick={() => this.updateTask(task)}>Применить</span></div>}
									</div>
								</div>
								<div className="tasks-item">
									<div>
										<div className="tasks-block">
											<label>Задача</label>
											<textarea onInput={this.autoResizeTextArea} onChange={(e) => this.handleTaskName(e, task)} value={task.name}>{task.name}</textarea>
										</div>
										<div className="tasks-block">
											<label>Описание</label>
											<textarea onInput={this.autoResizeTextArea} onChange={(e) => this.handleTaskInfo(e, task)} value={task.info}>{task.info}</textarea>
										</div>
									</div>
									<div>
										<div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || task.status === 4 || task.status === 3 || task.status === 1 ? 'days-good' : task.daysout <= 3 ? 'days-warning' : task.daysout <= 1 ? 'days-error' : 'days-normal'}`}>
											<span>Крайний срок ({task.daysout < 0 ? 0 : task.daysout} {this.getDayWord(task.daysout) || 'дней'})</span>
											<span>{this.dateToLocale(task.dateEnd)}</span>
										</div>
										<div className="tasks-block" style={{ maxHeight: "400px", overflow: "auto" }}>
											<span>Участники</span>
											<span style={{ marginTop:"10px" }}>{typeUserNames[typeUsers.PUPIL]}:</span>
											<ul className="tasks-peoples">
												{task.users?.filter(user => user.userType === typeUsers.PUPIL).map((u, i) => <li key={i}><Link to={`/pupil/${u.userId}`}><i className="bi bi-person"></i>{u.userName}</Link></li>)}
											</ul>
											<span style={{ marginTop:"10px" }}>{typeUserNames[typeUsers.PARENT]}:</span>
											<ul className="tasks-peoples">
												{task.users?.filter(user => user.userType === typeUsers.PARENT).map((u, i) => <li key={i}><Link to={`/parent/${u.userId}`}><i className="bi bi-person"></i>{u.userName}</Link></li>)}
											</ul>
										</div>
									</div>
									<div>
										<div className="tasks-block">
											<label>Результат</label>
											<textarea disabled={Number(task.status) < 2} onInput={this.autoResizeTextArea} onChange={(e) => this.handleResultInfo(e, task)} value={task.comment} className="tasks-result">{task.comment}</textarea>
										</div>
									</div>
								</div>
							</div>)) : <div>Нет задач</div>}
						</div>
					</div>
				</>)}
			<Alert />
		</Template></>
	}
}

TasksScreen.propTypes = {
    match: PropTypes.object.isRequired,
};
export default TasksScreen;