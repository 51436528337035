import { PropTypes } from 'prop-types';
import './styles.scss';
import { useState, useEffect } from 'react';
import { TimeSheet } from '../../Models';
import Loader from '../../Components/Loader';
import { Helpers } from '../../Globals';

const TimeSheetScreen = ({ teachers, closeTimeSheet }) => {
    const user = Helpers.authCheck();

    const getWeekdayIndex = (day) => {
        return new Date(selectedYear, selectedMonth, day).getDay();
    };

    const getAbbreviatedWeekdays = () => ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [abbreviatedWeekdays, setAbbreviatedWeekdays] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const handleTimeDelete = async (teacher, day) => {
        await TimeSheet.remove(teacher.id, selectedYear, selectedMonth, day).then(() => getData());
    }

    const handleTimeStartChange = (teacher, day, event) => {
        const dateFrom = createTimeStamp(selectedYear, selectedMonth, day, event.target.value);

        const check = data.find((item) => item.userId === teacher.id && item.day === day);

        if (check) {
            const newData = data.map((item) => {
                if (item.userId === teacher.id && item.day === day) {
                    return { ...item, dateFrom };
                }
                return item;
            });
            setData(newData);
        } else {
            const newData = data;
            newData.push({ userId: teacher.id, day, dateFrom, year: selectedYear, month: selectedMonth });

            setData(newData);
        }
    };

    const handleTimeEndChange = (teacher, day, event) => {
        const dateTo = createTimeStamp(selectedYear, selectedMonth, day, event.target.value);

        const check = data.find((item) => item.userId === teacher.id && item.day === day);

        if (check) {
            const newData = data.map((item) => {
                if (item.userId === teacher.id && item.day === day) {
                    return { ...item, dateTo };
                }
                return item;
            });

            setData(newData);
        } else {
            const newData = data;
            newData.push({ userId: teacher.id, day, dateTo, year: selectedYear, month: selectedMonth });

            setData(newData);
        }
    };

    const handleSave = (teacher, day) => {
        const time = data.find(((item) => teacher.id === item.userId && day === item.day));

        if (!time?.userId) {
            TimeSheet.add({ userId: teacher.id, day, dateTo: createTimeStamp(selectedYear, selectedMonth, day, '18:00'), dateFrom: createTimeStamp(selectedYear, selectedMonth, day, '09:00'), year: selectedYear, month: selectedMonth }).then(() => getData());
            return;
        }

        if (!time?.dateTo) {
            time.dateTo = createTimeStamp(selectedYear, selectedMonth, day, '18:00');
        }

        if (!time?.dateFrom) {
            time.dateFrom = createTimeStamp(selectedYear, selectedMonth, day, '09:00');
        }

        if(time?.id > 0) {
            TimeSheet.update(time.id, time).then(() => getData());
        } else {
            TimeSheet.add(time).then(() => getData());
        }
    }

    useEffect(() => {
        const newDays = Array.from({ length: new Date(selectedYear, selectedMonth + 1, 0).getDate() }, (_, i) => i + 1);
        setDaysInMonth(newDays);
        setAbbreviatedWeekdays(newDays.map(day => getAbbreviatedWeekdays()[getWeekdayIndex(day)]))
    }, [selectedMonth, selectedYear]);

    const getData = async () => {
        setIsLoading(true);
        await TimeSheet.getByDate(selectedYear, selectedMonth).then((res) => {
            if(res?.data) {
                const newData = res?.data;
                setData(newData);
                setIsLoading(false);
            }
        });
    };

    useEffect(async () => {
        try {
            getData();
        } catch(err) {
            console.error(err);
        }
    }, [selectedMonth, selectedYear]);

    const handleMonthChange = (e) => setSelectedMonth(Number(e.target.value));
    const handleYearChange = (e) => setSelectedYear(Number(e.target.value));

    const [inputVisible, setInputVisible] = useState({ day: 0, row: 0 });
  
    const toggleInputVisibility = (day, row) => {
        setInputVisible(day === inputVisible.day && row === inputVisible.row ? { day: 0, row: 0 } : { day, row });
    };

    const createTimeStamp = (year, month, day, time) => {
        const [hours, minutes] = time.split(':');
        const timestamp = new Date(year, month, day, hours, minutes);
        const timestampInMilliseconds = timestamp.getTime();
        const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);

        return timestampInSeconds;
    }

    const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    const getTimeFromTimestamp = (timestamp) => {
        const timestampInMilliseconds = timestamp * 1000;
        const date = new Date(timestampInMilliseconds);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        if (formattedTime?.includes('NaN')) return null;

        return formattedTime || null;
    }

    const getTime = (teacherId, day) => {
        const timestampFrom = data?.find((item) => item.userId === teacherId && item.day === day)?.dateFrom;
        const timestampTo = data?.find((item) => item.userId === teacherId && item.day === day)?.dateTo;

        return { from: getTimeFromTimestamp(timestampFrom), to: getTimeFromTimestamp(timestampTo) };
    }

    return (
        <div className="timesheet-container">
            <table>
                <thead>
                    <tr>
                        <th>
                            Месяц
                        </th>
                        <th className="date-picker" colSpan={daysInMonth.length - 2}>
                            <label htmlFor="month">Месяц:</label>
                            <select
                                id="month"
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                {monthNames?.map((m, i) => <option key={i} value={i}>{m}</option>)}
                            </select>

                            <label htmlFor="year">Год:</label>
                            <input
                                type="number"
                                id="year"
                                min="1900"
                                max="2100"
                                value={selectedYear}
                                onChange={handleYearChange}
                            />
                        </th>
                        <th></th>
                        <th><i onClick={() => closeTimeSheet()} className="bi bi-x-circle close-button"></i></th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th>Сотрудник</th>
                        {daysInMonth?.map((day, index) => (
                            <th key={day}>
                                {day}
                                <br />
                                {abbreviatedWeekdays[index]}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {teachers?.map((teacher, rowIndex) => {
                        const currentTeacher = teacher.id === user.id;
                        return (
                            <tr key={rowIndex} className={`timesheet-tr${currentTeacher ? ' current-teacher' : ''}`}>
                                <td className={currentTeacher && 'current-teacher'}>{teacher.lastName} {teacher.firstName} {teacher.middleName}</td>
                                {daysInMonth?.map((day, dayIndex) => {
                                    
                                    const isWeekend = [0, 6].includes(getWeekdayIndex(day)); // 0 - Воскресенье, 6 - Суббота
                                    const cellClassName = isWeekend ? 'weekend-cell' : '';
                                    const time = getTime(teacher.id, day);
                                    
                                    return (
                                        <td key={dayIndex} className={cellClassName} onClick={() => toggleInputVisibility(day, rowIndex)}>
                                            {inputVisible?.day === day && inputVisible?.row === rowIndex ? (
                                                <input
                                                    name={`time_1_${day}`}
                                                    type="time"
                                                    value={time.from}
                                                    defaultValue={'09:00'}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(event) => handleTimeStartChange(teacher, day, event)}
                                                />
                                            ) : <label>{time.from || '-'}</label>}

                                            {inputVisible?.day === day && inputVisible?.row === rowIndex ? <span><i className="bi bi-trash" onClick={() => handleTimeDelete(teacher, day)}></i><i onClick={() => handleSave(teacher, day)} className="bi bi-check-circle"></i></span> : <div>-</div>}
                                            {inputVisible?.day === day && inputVisible?.row === rowIndex ? (
                                                <input
                                                    name={`time_2_${day}`}
                                                    type="time"
                                                    value={time.to}
                                                    defaultValue={'18:00'}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(event) => handleTimeEndChange(teacher, day, event)}
                                                />
                                            ) : <label>{time.to || '-'}</label>}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {isLoading && <div className="timesheet-loader"><Loader /></div>}
        </div>
    );
}

export default TimeSheetScreen;

TimeSheetScreen.propTypes = {
    teachers: PropTypes.array,
    closeTimeSheet: PropTypes.func
}