import {Component} from 'react';


// components
import Template from '../../Components/Template';
import Alert, { alertShow } from '../../Components/Alert';

// models
import {Teachers, Childs, Tasks, ChildCorrectionPhotoJournals} from '../../Models';

// helpers
import {Helpers, LS} from '../../Globals/index';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import './styles.scss'
import moment from 'moment/moment';
import { taskChildTypeNames, taskTypeNames } from '../../Globals/Constants';

class AlertsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			tab:2,
			teachers:[],
			childs:[],
			tasks:[],
			readStatus:0,
			loading:true,
			loadingMessage:true,
			endDate: new Date().setDate(new Date().getDate() + 7),
			startDate:	new Date().setDate(new Date().getDate() - 3),
			openedtasks: [],
			childsNeedPhotos: [],
			isExpanded: false
		};
	}
	
    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    };

	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    };

	toggleList = () => {
		this.setState(prevState => ({
			isExpanded: !prevState.isExpanded,
		}));
	};

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getAll(areaId);
		const teachers = await Teachers.getAll(areaId, 1);
		const tasks = await Tasks.getAll();
		const photos = await ChildCorrectionPhotoJournals.getNeedLoadPhoto();
		

		const childIds = [];

		photos?.data?.forEach(photo => {
			const childId = photo.childId;

			if (!childIds.includes(childId)) {
				childIds.push(childId);
			}
		});

		const childsNeedPhotos = childs.data.filter(child => !childIds.includes(child.id));
		
		this.setState({
			tasks: tasks?.data?.filter(task => task?.curatorId === user.id),
			childs: childs.data,
			teachers: teachers.data,
			user,
			childsNeedPhotos
		});
	}
	
	handleInfo = (e) => {
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				info: e.target.value
			}
		}));
	};

	handleName = (e) => {
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				name: e.target.value
			}
		}));
	};

	getDayWord = (number) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return ['день', 'дня', 'дней'][(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
	};

	checkDate = (start, end) => {
		const differenceInSeconds = end - start;
		const remainingDays = Math.ceil(differenceInSeconds / 86400);
		return remainingDays;
	};

	startTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 1) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 2, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 2 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача запущена');
		return true;
	}

	stopTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.comment === '' || !task.comment) {
			alertShow('Чтобы завершить задачу, необходимо заполнить результат', 1);
			return false;
		}

		if (task.status !== 2) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 3, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 3 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача завершена');
		return true;
	}

	hideTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно скрыть эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 3) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 4, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 4 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача скрыта');
		return true;
	}

	isWasEdit = (task) => {
		return task.id;
	}

	handleTaskName = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, name: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleTaskInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, info: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleResultInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, comment: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	updateTask = async (task) => {
		const { comment, name, info } = task;
		Tasks.update(task.id, {
			comment, name, info, dateModify: Math.floor(Date.now() / 1000)
		});
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, wasedit: false };
			}
			return t;
		});
		this.setState({ updatedTasks });
		alertShow('Задача была обновлена');
	}

	setFilter = (type) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				type
			}
		}));
	}

	setSeconFilter = (secondtype) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				secondtype: prevState.filter.secondtype === secondtype ? null : secondtype
			}
		}));
	}

	sortBy = (event) => {
		const sortOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				sort: sortOption
			}
		}));
	};

	searchBy = (event) => {
		const filterOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				filter: filterOption
			}
		}));
	};

	dateToString = (date) => {
		return date.toISOString();
	};

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				search: query
			}
		}));
	};

	toggleTask = (taskId) => {
		let newTasks = [...this.state.openedtasks];
		if (this.isOpenedTask(taskId)) {
			newTasks = newTasks.filter((t) => t !== taskId);
		} else {
			newTasks.push(taskId);
		}

		this.setState({ openedtasks: newTasks });
	}

	isOpenedTask = (taskId) => this.state.openedtasks?.includes(taskId);
	render() {
		const { childsNeedPhotos } = this.state;
		const showToggle = childsNeedPhotos?.length > 10;

		return <Template page='alerts' title={'События'}>
			<div className='alerts'>
				<div className="filter_data">
					<span>Фильтровать</span>
					<DatePicker
						selected={this.state.startDate}
						onChange={this.handleStartDateChange}
						placeholderText="Дата с:"
						dateFormat="dd.MM.yyyy"
						locale={ru}
					/>
					<DatePicker
						selected={this.state.endDate}
						onChange={this.handleEndDateChange}
						placeholderText="Дата по:"
						dateFormat="dd.MM.yyyy"
						locale={ru}
					/>
				</div>
				<div className="divider"></div>
				<h3>Дни рождения:</h3>
				<h4>Сотрудники:</h4>
				{
					this.state.teachers?.filter((item) => {
						if(!this.state.startDate || !this.state.endDate) {
							return false;
						}

						if(!item.birthDate) {
							return false;
						}

						const itemDate = moment(new Date((item.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear()));

						return itemDate >= moment(this.state.startDate) && itemDate <= moment(this.state.endDate);
					}).sort((a, b) => moment(new Date((b.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear())) - moment(new Date((a.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear()))).map((item, i) => {
						const birthDate = moment(new Date(item.birthDate * 1000).setFullYear(new Date().getFullYear()));

						const today = moment();
						const daysUntilBirthday = birthDate.diff(today, 'days');

						let style = "default";

						if(daysUntilBirthday >= -2 && daysUntilBirthday < 3) {
							style = "hb";
						}

						if(daysUntilBirthday >= 3 && daysUntilBirthday <= 5) {
							style = "soon";
						}

						return (
							<a href={`/teacher/${item.id}`} key={i}><div className="alert_item">
								<span>{this.dateToLocale(item.birthDate)}</span>
								<span>{item?.firstName} {item?.lastName} {item?.middleName}</span>
								<span className={style}>
									{
										daysUntilBirthday >= 0
										? <>Дней до Дня Рождения: <b>{daysUntilBirthday === 0 ? 'СЕГОДНЯ' : daysUntilBirthday}</b></>
										: <>Со Дня Рождения прошло: <b>{daysUntilBirthday === 0 ? 'СЕГОДНЯ' : -daysUntilBirthday}</b> дней</>
									}
								</span>
							</div></a>
						);
					})
				}
				<div className="divider"></div>
				<h4>Воспитанники:</h4>
				{
					this.state.childs?.filter((item) => {
						if(!this.state.startDate || !this.state.endDate) {
							return false;
						}

						if(!item.birthDate) {
							return false;
						}

						const itemDate = moment(new Date((item.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear()));

						return itemDate >= moment(this.state.startDate) && itemDate <= moment(this.state.endDate);
					}).sort((a, b) => moment(new Date((b.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear())) - moment(new Date((a.birthDate) * 1000 - 86400 * 1000).setFullYear(new Date().getFullYear()))).map((item, i) => {
						const birthDate = moment(new Date(item.birthDate * 1000).setFullYear(new Date().getFullYear()));

						const today = moment();
						const daysUntilBirthday = birthDate.diff(today, 'days');

						let style = "default";

						if(daysUntilBirthday >= -2 && daysUntilBirthday < 3) {
							style = "hb";
						}

						if(daysUntilBirthday >= 3 && daysUntilBirthday <= 5) {
							style = "soon";
						}

						return (
							<a href={`/pupil/${item.id}`} key={i}><div className="alert_item">
								<span>{this.dateToLocale(item.birthDate)}</span>
								<span>{item?.firstName} {item?.lastName} {item?.middleName}</span>
								<span className={style}>
									{
										daysUntilBirthday >= 0
										? <>Дней до Дня Рождения: <b>{daysUntilBirthday === 0 ? 'СЕГОДНЯ' : daysUntilBirthday}</b></>
										: <>Со Дня Рождения прошло: <b>{daysUntilBirthday === 0 ? 'СЕГОДНЯ' : -daysUntilBirthday}</b> дней</>
									}
								</span>
							</div></a>
						);
					})
				}
				<div className="divider"></div>
				<h3>Задачи:</h3>
				<h4>{this.state.user?.position === 'Суперадмин' ? this.state.user?.name : `${this.state.user?.firstName} ${this.state.user?.lastName} ${this.state.user?.middleName} - ${this.state.user?.position || 'Сотрудник'}`}:</h4>
				
				<div className="tasks-list">
				{
					this.state.tasks.filter((task) => task.status !== 4).sort((a, b) => moment(b.dateEnd) - moment(a.dateEnd)).map((task, i) => {
						const endDate = new Date(task.dateEnd * 1000);
						const today = new Date();
						const timeDiff = endDate - today;
						const daysOut = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

						if (daysOut > 5) {
							return;
						}

						return (
							<a href={`tasks/${task.id}`} key={i}><div className="tasks-item">
								<div>
									<div className="tasks-block">
										<span>{taskTypeNames[task.type]}</span>
										<span>{taskChildTypeNames[task.view]}</span>
									</div>
								</div>
								{!this.isOpenedTask(task.id) ? <div>
									<div className="tasks-block" style={{ height: '60px' }}>
										<textarea disabled onInput={this.autoResizeTextArea} style={{ height: "20px", minHeight: '40px'  }} onChange={(e) => this.handleTaskName(e, task)} value={task.name}>{task.name}</textarea>
									</div>
								</div> : null}
								<div>
									<div className="tasks-block">
										<span>Дата начала</span>
										<span>{this.dateToLocale(task.dateStart)}</span>
									</div>
								</div>
								{!this.isOpenedTask(task.id) ? <div>
									<div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || daysOut >= 5 ? 'days-good' : daysOut < 5 && daysOut >= 2 ? 'days-warning' : daysOut <= 1 ? 'days-error' : 'days-normal'}`}>
										<span>Крайний срок ({daysOut < 0 ? 0 : daysOut} {this.getDayWord(daysOut) || 'дней'})</span>
										<span>{this.dateToLocale(task.dateEnd)}</span>
									</div>
								</div> : null}
							</div></a>
						)
					})
				}
				</div>
				<div className="divider"></div>
				<h3>Фотографии не загружались более 3-х месяцев ({ childsNeedPhotos?.length }):</h3>
				<div className="needphotos">
				{
					childsNeedPhotos?.slice(0, this.state.isExpanded ? undefined : 10).map((child) => (
						<span key={child.id} onClick={() => window.location.href = `/correction-photo-dynamic/${child.id}?link=add-document`}>
							{child?.firstName} {child?.lastName} {child?.middleName}
						</span>
						))}

				{
					showToggle && (
						<span onClick={this.toggleList} style={{ backgroundColor: '#FFF' }}>
							{this.state.isExpanded ? 'Скрыть' : 'Показать все'}
						</span>
					)
				}
				</div>
			</div>
			<Alert />
		</Template>
	}
}

export default AlertsScreen;