import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Areas} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import { comonStatusName } from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class AreaScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const areas = await Areas.get(this.state.id);
			if (areas.data && areas.data.length) this.setState({data:areas.data[0],notfound:false});
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name,
			address:data.address,
			status:data.status
		};
		if (id === 0) {
			const res = await Areas.add(d).catch(() => this.error());
			if (res) Errors.add();
			else return false;
		} else {
			await Areas.update(id, d).catch(() => this.error());
			Errors.update();
		}
		this.props.history.push('/areas');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'settings'}
					title={this.state.id === 0 ? 'Добавление филиала' : 'Редактирование филиала'}
					controlPanel={
						<div className="form-control-panel">
							<button form="area-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/areas'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/areas'} /> :
						<form id="area-form" className="form" onSubmit={this.save}>
							<div className="form-row">
								<label htmlFor="name">Название филиала</label>
								<input type="text" name="name" id="name" placeholder="Название филиала" required className="double" autoFocus={true} onChange={this.formHandle} value={this.state.data.name} />
							</div>
							<div className="form-row">
								<label htmlFor="name">Адрес филиала</label>
								<input type="text" name="name" id="name" placeholder="Адрес филиала" required className="double" onChange={this.formHandle} value={this.state.data.address} />
							</div>
							<div className="form-row">
								<label htmlFor="status">Статус</label>
								<select name="status" id="status" onChange={this.formHandle} required defaultValue={this.state.data.status}>
									<option></option>
									{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
								</select>
							</div>
						</form>
			}
			<Alert />
		</Template>
	}
}

AreaScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default AreaScreen;