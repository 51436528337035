import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap-icons/font/bootstrap-icons.css";

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);

reportWebVitals();