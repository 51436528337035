import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (id)			=> await Http.get('settings', {conditions:[{k:'id',v:id}]});
const getAll	= async ()				=> await Http.get('settings', {orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('settings', {data});
const update	= async (id, data)		=> await Http.put('settings', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update
}