import Http from '../../Globals/Http';
import {requestOderType} from "../../Globals/Constants";

const get		= async (childId)		=> await Http.get('correctionfunctions', {conditions:[{k:'childId',v:childId}], orders:[{k:'dateCreate', isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('correctionfunctions', {data});
const update	= async (childId, data)	=> await Http.put('correctionfunctions', {data,conditions:[{k:'childId',v:childId}]});

export {
	get,
	add,
	update
}