import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { ChildCorrectionJournals, ChildCorrectionPhotoJournals, ChildDocuments, DocumentTypes } from '../../../Models';
import { API, journalType } from '../../../Globals/Constants';
import Alert, { alertShow }  from '../../../Components/Alert';
import DocumentViewer from '../../../Components/DocumentViewer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Confirm from '../../../Components/Confirm';
import { LS } from '../../../Globals';

const PupilPhoto = ({ pupil }) => {

    const [isDocumentView, setDocumentView] = useState(false);
    const [doc, setDoc] = useState(null);
    const [docType, setDocType] = useState(null);
    const [documents, setDocuments] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [documentTypes, setDocumentTypes] = useState([]);
    const [journal, setJournal] = useState([]);
    const user = LS.get('user');

    useEffect(async () => {
        const childdocuments = await ChildDocuments.get(pupil.id);
        setDocuments(childdocuments.data);
        const documenttypes = await DocumentTypes.getAll();
        setDocumentTypes(documenttypes.data);
    }, [pupil.id]);

    const documentView = async (document) => {
		const doctype = document.link.replace(/.*\./gi, '');
		const doc = `${API.documents}/?id=${pupil.id}&t=childs&ft=${document.fileType}&fn=${document.link}`;

        setDoc(doc);
        setDocType(doctype);
        setDocumentView(true);
	}
	const documentViewClose = () => setDocumentView(false);

	const childDocumentsGet = async () => {
		const childdocuments = await ChildDocuments.get(pupil.id);
		setDocuments(childdocuments.data || []);
	}

    useEffect(async () => {
        childDocumentsGet();
    }, [pupil]);

    useEffect(() => {
        const fetchPhotos = async () => {
            const res = await ChildCorrectionJournals.get(pupil.id, journalType.PHOTODYNAMIC);
            if (res.data) {
                const photoPromises = res.data.map(async (item) => {
                    const photoResponse = await ChildCorrectionPhotoJournals.get(item.id, journalType.PHOTODYNAMIC);
                    console.log(item.id);
                    return photoResponse.data;
                });
                const photoData = await Promise.all(photoPromises);

                const updatedJournal = res.data.map((item, index) => ({ ...item, photos: photoData[index] || [] }));
                setJournal(updatedJournal);
            }
        };
        fetchPhotos();
    }, [pupil]);
    
    const dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

    const deleteJournal = (id) => {
        const find = journal.find(item => item.id === id);
        ChildCorrectionJournals.remove(find.id);
        setJournal(journal.filter(item => item.id !== id));
        alertShow('Запись удалена');
    }

    const deletePhoto = (id) => {
        ChildDocuments.remove(id).then(() => {
            alertShow('Фото удалено');
            childDocumentsGet();
        });
    }

    const [isOpenDelete, setIsOpenDelete] = useState(0);
    const [isOpenPhotoDelete, setIsOpenPhotoDelete] = useState(0);

    return (
        <div className="pupil_info">
            {isOpenDelete > 0 && (
                <Confirm
                    message={'Вы уверены, что хотите удалить запись с фото?'}
                    action={() => {
                        deleteJournal(isOpenDelete);
                        setIsOpenDelete(0);
                    }}
                    close={() => { setIsOpenDelete(0) }}
                />
            )}
            {isOpenPhotoDelete > 0 && (
                <Confirm
                    message={'Вы уверены, что хотите удалить это фото?'}
                    action={() => {
                        deletePhoto(isOpenPhotoDelete);
                        setIsOpenPhotoDelete(0);
                    }}
                    close={() => { setIsOpenPhotoDelete(0) }}
                />
            )}
            <div className="photos_side">
                <div><Link to={`/correction-photo-dynamic/${pupil.id}?link=add-document`}><button className="default_button">Добавить Фото</button></Link></div>
                {journal.map((item, i) => (
                    <div key={i} className="photo_note">
                        <div className="photo_header">
                            {user?.position === 'Суперадмин' && <div>{dateToLocale(item.date)}<span onClick={() => setIsOpenDelete(item.id)} className="button-delete">Удалить</span></div>}
                            <div>Описание: <span className="photo_comment">{item.info}</span></div>
                            <div>Задача: <span className="photo_comment">{item.task}</span></div>
                            <div>Ответственный: <span className="photo_comment">{item.userName}</span></div>
                        </div>
                        <div className="photo_block">
                            {item.photos.map((photo) => documents.filter(doc => doc.id === Number(photo.photo)).map((doc, i) => (
                                <div key={i} className="photo_img">
                                    {user?.position === 'Суперадмин' && <i onClick={() => setIsOpenPhotoDelete(doc.id)} className="bi bi-trash"></i>}
                                    <img key={i} onClick={() => documentView(doc)} src={`${API.assets}/documents/childs/${pupil.id}/${doc.link}`}></img>
                                </div>
                            )))}
                        </div>
                    </div>
                ))}
            </div>
            <Alert />
            {isDocumentView && <DocumentViewer doc={doc} doctype={docType} close={documentViewClose} />}
        </div>
    );
}


PupilPhoto.propTypes = {
    pupil: PropTypes.object.isRequired,
};

export default PupilPhoto;