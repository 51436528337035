// plugins
import FileViewer from 'react-file-viewer';

// styles
import './styles.css';
import PropTypes from 'prop-types';
const DocumentViewer = (props) => {
	return ( 
		<div className="imageviewr-container">
			<div className="imageviewr-image">
				<FileViewer fileType={props.doctype} filePath={props.doc} />
			</div>
			<i className="bi bi-x-circle-fill imageviewr-close" onClick={props.close}></i>
		</div>
	);
};
DocumentViewer.propTypes = {
	close: PropTypes.object.isRequired,
	doc: PropTypes.object.isRequired,
	doctype: PropTypes.object.isRequired
};
export default DocumentViewer;