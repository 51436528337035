
import { PropTypes } from 'prop-types';
const ParentalTasks = ({ parent }) => {
    return (
        <div>
            {console.log(parent)}
        </div>
    );
}

ParentalTasks.propTypes = {
    parent: PropTypes.object.isRequired,
};

export default ParentalTasks;