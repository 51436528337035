import Http from '../../Globals/Http';
import {comonStatus,requestConditionType,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('chatusers', {conditions:[{k:'id',v:id}]});
const getByUserId       = async (userId)    => await Http.get('chatusers', {conditions:[{k:'userId',v:userId},{k:'chatId',v:0,op:requestConditionType.MORE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getByChatId       = async (chatId)    => await Http.get('chatusers', {conditions:[{k:'chatId',v:chatId}]});
const getAll	        = async (areaId)	=> await Http.get('chatusers', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateEvent',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('chatusers', {data});
const update	        = async (id, data)	=> await Http.put('chatusers', {data,conditions:[{k:'id',v:id}]});

const removeByChatId	= async (chatId)	=> await Http.remove('chatusers', {conditions:[{k:'chatId',v:chatId}]});

export {
	getAll,
	get,
	add,
	update,
    getByUserId,
    getByChatId,
    removeByChatId
}