import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= async ()				=> await Http.get('officecontractordocuments', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (Id)			=> await Http.get('officecontractordocuments', {conditions:[{k:'id',v:Id}]});

const add		= async (data)			=> await Http.post('officecontractordocuments', {data});
const remove	= async (id)			=> await Http.remove('officecontractordocuments', {conditions:[{k:'id',v:id}]});

export {
	getAll,
	getById,
	add,
	remove
}