import {Component} from 'react';
import { Link } from 'react-router-dom';

// plug=ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {OfficeCourtMails,Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {mailType,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class OfficeCourtMailsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const mails = await OfficeCourtMails.getAll();
		this.setState({user,data:mails.data,loading:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewOfficeCourtPage, null, null, null, 2);
	}
	render() {
		return <Template
					page={'departments'}
					title={'Судебные дела'}
					controlPanel={
						<div className="form-control-panel">
							<Link to={'/office-court-mail'} className="btn">Добавить</Link>
						</div>
					}
					backLink={'/office'}>
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-office-mail-item">
					<div className="name">
						<i className={`bi bi-box-arrow-in-${v.type === mailType.INBOX ? 'down' : 'up'}`}></i>
						<div>
							<span>{Moment(v.date*1000).format('DD.MM.y, H:mm')} — {v.number}</span>
							{v.comment}
						</div>
					</div>
					<div className="buttons">
						<Link to={`/office-court-mail/${v.id}`}><i className="bi bi-pencil-square"></i></Link>
					</div>
				</div>)
			}
		</Template>
	}
}

export default OfficeCourtMailsScreen;