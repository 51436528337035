import {Component} from 'react';

// plug=ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DocumentViewer from '../../Components/DocumentViewer';
import Alert from '../../Components/Alert';

// models
import {Documents,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,documentType,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class HouseholdTransportScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			documents:[],
			filedocument:null,
			doctype:null,
			doc:null,
			documentview:false,
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user});
		await this.documentsGet(false);

        // Add events in log
        Events.add(user.id, eventMessageName.viewHouseholdTransportPage, null, null, null, 2);
	}
	documentsGet = async () => {
		const docs = await Documents.getAll();
		const documents = docs.data.filter(f => f.type === documentType.household.TRANSPORT);
		this.setState({documents,adddocumentshow:documents.length?false:true,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const document = (await Documents.getById(id)).data[0];
		const oldData = document;
		Events.add(user.id, eventMessageName.deleteHouseholdTransportDocument + ' ' + document.link, JSON.stringify(oldData), null, null, 7);
		
		await Documents.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true,adddocumentshow:false}, async () => await this.documentsGet(true));
	documentView = (d) => {
		const doc = `${API.documents}/?p=departments/household/transports&ft=${d.fileType}&fn=${d.link}`;
		const doctype = d.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const linkDocument = ' ' + d.link;
		Events.add(this.state.user.id, eventMessageName.viewHouseholdTransportDocument + linkDocument, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = (d) => {
		const uri = `${API.documents}/?p=departments/household/transports&ft=${d.fileType}&fn=${d.link}`;

		// Add events in log
		const linkDocument = ' ' + d.link;
		Events.add(this.state.user.id, eventMessageName.dowloadHouseholdTransportDocument + linkDocument, null, null, null, 6);

		window.location.href = uri;
	}
	documentSave = async (e) => {
		e.preventDefault();
		const {filedocument,user} = this.state;
		const d = {
			type:documentType.household.TRANSPORT,
			fileType:filedocument.type,
			link:filedocument.name,
			userId: user.id,
			userName: user.name
		};
		const res = await Documents.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		Events.add(this.state.user.id, eventMessageName.addHouseholdTransportDocument + ' ' + d.link, null, JSON.stringify(newData), null, 3);

		if (res) {
			Errors.add();
			this.fileSave(`documents/departments/household/transports`, filedocument);
			this.documentsReload();
		}
		return false;
	}
	documentHide = async (e) => {
		let d;
		if (e.status === 0) d = {status: 1}
		else if (e.status === 1) d = {status: 0}

		// Add events in log
		// get old data before update
		const dataModel = await Documents.getById(e.id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
		const oldData = getOldData(d);
		const newData = d;

		await Documents.update(e.id, d).catch(() => this.error());

		// Add events in log
		const linkDocument = ' ' + dataModel.data[0].link;
		Events.add(this.state.user.id, eventMessageName.updateHouseholdTransportStatus + linkDocument, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
		Errors.update();
		this.documentsGet();
	}
	error = () => Errors.error();
	fileSave = (path, file) => Files.upload(0, null, path, file);
	render() {
		return <Template
					page={'departments'}
					title={'Транспорт'}
					controlPanel={
						<div className="form-control-panel">
							<button form={'document-form'} type="submit">Сохранить</button>
						</div>
					}
					backLink={'/household'}>
			{this.state.loading ? <Loader /> :
				<form id={'document-form'} className="form" onSubmit={this.documentSave}>
					<div className="form-row form-row-block">
						<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
						{this.state.adddocumentshow ?
							<div className="form-row-block-inner">
								<div className="form-row">
									<label htmlFor="file">Файл</label>
									<input onChange={this.fileChange} name="file" className="file" required type="file" />
								</div>
							</div> : null}
					</div>
					{this.state.documents ?
						<div className="list-household-documents">
							{this.state.documents.map((v,i) => {
								if (v.status === 0) {
									return <div key={i} className="list-household-documents-item">
										<div className="name">
											<span>Путевой лист — {Moment(v.dateCreate*1000).format('DD.MM.y, H:mm')}</span>
											{v.link}
										</div>
										<div className="buttons">
											<i className="bi bi-dash-circle" onClick={() => this.documentHide(v)}></i>
											<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
											<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
											<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
										</div>
									</div>
								}
								
							})}
							{this.state.documents.map((v,i) => {
								if (v.status === 1) {
									return <div key={i} className="list-household-documents-item household-document-hidden">
										<div className="name">
											<span>Путевой лист — {Moment(v.dateCreate*1000).format('DD.MM.y, H:mm')}</span>
											{v.link}
										</div>
										<div className="buttons">
										<i className="bi bi-plus-circle" onClick={() => this.documentHide(v)}></i>
										</div>
									</div>
								}
							})}
						</div> : null}
				</form>
			}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

export default HouseholdTransportScreen;