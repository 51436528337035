import {Component} from 'react';
import Template from '../../Components/Template';
import Alert, { alertShow } from '../../Components/Alert';
import {Helpers,LS} from '../../Globals/index';
import './styles.scss';
import { Childs, ProgramChildTasks, ProgramChildrens, ProgramTasks, Programs, Teachers } from '../../Models';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { API } from '../../Globals/Constants';
import { debounce } from 'lodash';
import Confirm from '../../Components/Confirm';

class ProgramsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user		: null,
			loading		: true,
			childs		: [],
			teachers	: [],
			programs	: [],
			updatedChilds: [],
			isChooseProgram: null,
			isInfoOpen: null,
			filter: {
				type: null,
				view: 0,
				sort: "byDateAsc",
				filter: "peoples",
				search: ""
			},
			filteredChilds: [],
			isOpenDoneConfirm: false,
			doneChildId: null,
			doneProgram: null,
			isHistoryOpen: null
		};
		this.searchDebounced = debounce(this.performFiltering, 500);
	}

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getAll(areaId);
		const teachers = await Teachers.getAll(areaId, 1);
		const programs = await Programs.getAll();
		const programTasks = await ProgramTasks.getAll();
		const programChildrens = await ProgramChildrens.getAll();
		const programChildTasks = await ProgramChildTasks.getAll();

		try {
			const updatedTasks = programTasks.data.map((task) => { return { ...task, status: -1 }; });

			const updatedChilds = childs.data.map((child) => {
				const childId = child.id;
				const filteredProgramChildTasks = programChildTasks.data.filter((programChildTask) => programChildTask.childId === childId);

				const childTaskIds = filteredProgramChildTasks.map((programChildTask) => programChildTask.programTaskId);

				const programTaskIds = programTasks.data.map((task) => task.id);

				const missingTaskIds = programTaskIds.filter((taskId) => !childTaskIds.includes(taskId));
				const missingTasks = programTasks.data.filter((task) => missingTaskIds.includes(task.id));
				missingTasks.forEach((task) => filteredProgramChildTasks.push({ childId, programTaskId: task.id, status: -1 }));

				const childTasks = filteredProgramChildTasks.map((programChildTask) => {
					const task = updatedTasks.find((task) => task.id === programChildTask.programTaskId);
					return { ...task, status: programChildTask.status };
				});

				const filteredTasks = programChildrens.data.filter((programChild) => programChild.childId === childId).map((programChild) => {
					const programId = programChild.programId;
					const program = programs.data.find((program) => program.id === programId);

					const allPrograms = programChildrens.data.filter((program) => program.childId === child.id).map((item) => {
						return { ...item, program: programs.data.find((programItem) => programItem.id === item.programId) }
					});

					const currentDateTimestamp = Math.floor(Date.now() / 1000);
					const dateStartedTimestamp = Math.floor(programChild.dateStarted);
					const elapsedTimeInSeconds = currentDateTimestamp - dateStartedTimestamp;
					const allChildTasks = childTasks.filter((task) => task.programId === programId);
					const uniqueTasks = {};
					const uniqueChildTasks = allChildTasks.filter((task) => {
						if (!uniqueTasks[task.id]) {
							uniqueTasks[task.id] = true;
							return true;
						}
						return false;
					});
					const allChildTasksDone = allChildTasks.every(task => task.status === 1);

					programChild.remainingDays = allChildTasksDone ? 0 : Math.max(program?.countDays - Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)), 0);
					
					let style = allChildTasksDone
						? 'tasks-done'
						: programChild.remainingDays <= 0 && programChild.dateStarted === 0
						? 'tasks-not-started'
						: programChild.remainingDays === 0 && programChild.dateStarted > 0
						? 'tasks-error'
						: programChild.remainingDays < 10 && programChild.dateStarted > 0
						? 'warning'
						: programChild.dateStarted > 0 && programChild.remainingDays >= 0
						? 'tasks-done'
						: 'default';

					programChild.styleDays = style;
					programChild.allChildTasksDone = allChildTasksDone;

					return {
						program,
						programChild,
						tasks: uniqueChildTasks,
						allPrograms
					};
				});

				const curator = teachers?.data?.find(t => t.id === child.teacherId);

				return {
					...child,
					programChild: filteredTasks[0]?.programChild|| {},
					program: filteredTasks[0]?.program || {},
					tasks: filteredTasks[0]?.tasks || [],
					curator,
					allPrograms: filteredTasks[0]?.allPrograms || []
				};
			});

			console.log(updatedChilds)

			this.setState({
				user,
				areaId,
				loading: false,
				childs: childs.data,
				teachers: teachers.data,
				programs: programs.data,
				tasks: programTasks.data,
				childTasks: programChildrens.data,
				updatedChilds
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			this.setState({ loading: true });
		}
		this.searchDebounced(this.state.filter.search);
	}

	closeProgram = (childId) => {
		ProgramChildrens.updateAll(childId, { status: 0 });
	}

	taskDone = () => {
		const {doneProgram, doneChildId} = this.state;

		ProgramChildrens.update(doneChildId, doneProgram.id, { status: 0 }).then(() => {
			this.setState((prevState) => {
				const updatedChilds = prevState.updatedChilds.map((child) => {
					if (child.id === doneChildId) {
						// eslint-disable-next-line no-unused-vars
						const { program, programChild, ...updatedChild } = child;

						return updatedChild;
					}

					return child;
				});

				return { updatedChilds, isChooseProgram: null, childTasks: this.state.childTasks.filter(item => item.childId !== doneChildId) };
			});

			this.performFiltering('');
		});

		alertShow('Задача завершена');
	}

	startProgram = (childId, program) => {
		const { id: programId, countDays } = program;
		ProgramChildrens.update(childId, programId, { dateStarted: Math.floor(Date.now() / 1000) }).then(() => {
			this.setState((prevState) => {
				const updatedChilds = prevState.updatedChilds.map((child) => {
					if (child.id === childId) {
						const currentDateTimestamp = Math.floor(Date.now() / 1000);
						child.programChild.dateStarted = currentDateTimestamp;
						child.programChild.remainingDays = countDays;
					}
					return child;
				});
				
				return { updatedChilds, isChooseProgram: null };
			});
			alertShow('Программа была начата!');
		});
		this.searchDebounced(this.state.filter.search);
	}

	infoToggle = (childId) => this.setState((prevState) => ({ isInfoOpen: prevState.isInfoOpen === childId ? null : childId, isChooseProgram: null }));
	programToggle = (childId) => this.setState((prevState) => ({ isChooseProgram: prevState.isChooseProgram === childId ? null : childId, isInfoOpen: childId }));

	addChildProgram = (childId, programId) => {
		if (childId > 0 && programId > 0) {
			try {
				ProgramChildrens.update(childId, programId, { status: 0 }).then(() => {
					ProgramChildrens.add({programId, childId, status: 1}).then((res) => {
						const id = res?.data?.id;
						if (id > 0) {
							alertShow('Программа была закреплена за воспитанником');

							const newProgram = this.state.programs.find((program) => program.id === programId);

							this.setState((prevState) => {
								const updatedChilds = prevState.updatedChilds.map((child) => {
									if (child.id === childId) {
										const updatedTasks = this.state.tasks.filter((task) => task.programId === programId);
										const newTasks = [];
										updatedTasks.forEach((task) => newTasks.push({ ...task, status: -1 }));

										return { ...child, program: newProgram, programChild: {...child.programChild, allChildTasksDone: false, styleDays: 'tasks-not-started', programId: newProgram.id, dateStarted: 0, remainingDays: newProgram.remainingDays }, tasks: newTasks };
									}
									return child;
								});
				
								return { updatedChilds };
							});


						} else {
							alertShow('Произошла ошибка при выборе программы', 1);
						}
					})
				})
			} catch (err) {
				alertShow('Произошла ошибка при выборе программы', 1);
			}
		} else {
			alertShow('Произошла ошибка при выборе программы', 1);
		}
		this.searchDebounced(this.state.filter.search);
	}

	toggleProgramTask = (childId, programTaskId, status) => {
		if (status < 0) {
			ProgramChildTasks.add({childId, programTaskId, status: 1}).then((res) => {
				if (res.data?.id && res.data?.id > 0) {
					alertShow('Задача отмечена как выполненная');
					this.setState((prevState) => {
						const updatedChilds = prevState.updatedChilds.map((child) => {
							if (child.id === childId) {
								const updatedTasks = child.tasks.map((task) => { if (task.id === programTaskId) { return { ...task, status: 1 }; } return task; });
								const currentDateTimestamp = Math.floor(Date.now() / 1000);
								const allChildTasksDone = updatedTasks.every(task => task.status === 1);
								child.programChild.dateStarted = currentDateTimestamp;
								const dateStartedTimestamp = Math.floor(child.programChild.dateStarted);
								const elapsedTimeInSeconds = currentDateTimestamp - dateStartedTimestamp;
								child.program.remainingDays = allChildTasksDone ? 0 : Math.max(child.program?.countDays - Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)), 0);
								
								let style = allChildTasksDone
									? 'tasks-done'
									: child.programChild.remainingDays <= 0 && child.programChild.dateStarted === 0
									? 'tasks-not-started'
									: child.programChild.remainingDays === 0 && child.programChild.dateStarted > 0
									? 'tasks-error'
									: child.programChild.remainingDays < 10 && child.programChild.dateStarted > 0
									? 'warning'
									: child.programChild.dateStarted > 0 && child.programChild.remainingDays >= 0
									? 'tasks-done'
									: 'default';
								child.programChild.styleDays = style;
								child.programChild.allChildTasksDone = allChildTasksDone;
								return { ...child, tasks: updatedTasks };
							}
							return child;
						});

						return { updatedChilds };
					});
				}
			});
			this.searchDebounced(this.state.filter.search);
		} else if (status === 1 || status === 0) {
			ProgramChildTasks.update(programTaskId, childId, {status: status === 0 ? 1 : 0});
			alertShow(`Задача отмечена как ${status === 0 ? 'выполненная' : 'не выполненная'}`, status === 1 ? 1 : 0);
			
			this.setState((prevState) => {
				const updatedChilds = prevState.updatedChilds.map((child) => {
					if (child.id === childId) {
						const updatedTasks = child.tasks.map((task) => { if (task.id === programTaskId) { return { ...task, status: status ? 0 : 1 }; } return task; });
						const currentDateTimestamp = Math.floor(Date.now() / 1000);
						child.programChild.dateStarted = currentDateTimestamp;
						const allChildTasksDone = updatedTasks.every(task => task.status === 1);
						const dateStartedTimestamp = Math.floor(child.programChild.dateStarted);
						const elapsedTimeInSeconds = currentDateTimestamp - dateStartedTimestamp;
						child.program.remainingDays = allChildTasksDone ? 0 : Math.max(child.program.countDays - Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)), 0);
						
						let style = allChildTasksDone
							? 'tasks-done'
							: child.programChild.remainingDays <= 0 && child.programChild.dateStarted === 0
							? 'tasks-not-started'
							: child.programChild.remainingDays === 0 && child.programChild.dateStarted > 0
							? 'tasks-error'
							: child.programChild.remainingDays < 10 && child.programChild.dateStarted > 0
							? 'warning'
							: child.programChild.dateStarted > 0 && child.programChild.remainingDays >= 0
							? 'tasks-done'
							: 'default';
						child.programChild.styleDays = style;
						child.programChild.allChildTasksDone = allChildTasksDone;
						return { ...child, tasks: updatedTasks };
					}
					return child;
				});

				return { updatedChilds };
			});
			this.searchDebounced(this.state.filter.search);
		}
	}
	
	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

	setFilter = (type) => {
		this.searchDebounced(this.state.filter.search);
		this.setState((prevState) => ({ filter: { ...prevState.filter, type: type === prevState.filter.type ? null : type } }));
	}

	sortBy = (event) => {
		const sortOption = event.target.value;
		this.setSearchParticipants('');
		this.setState((prevState) => ({ filter: { ...prevState.filter, sort: sortOption } }));
	};

	searchBy = (event) => {
		const filterOption = event.target.value;
		this.setSearchParticipants('');
		this.setState((prevState) => ({ filter: { ...prevState.filter, filter: filterOption } }));
	};

	dateToString = (date) => date.toISOString();

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({ filter: { ...prevState.filter, search: query, } }));
		this.searchDebounced(query);
	};

	performFiltering = (query) => this.setState((prevState) => ({ filteredChilds: this.filterData(prevState.updatedChilds, query), loading: false, }));

	isEmptyObject = (obj) => Object.keys(obj).length === 0;

	filterData = (data, searchQuery) => {
		const { filter } = this.state;

		let filteredChilds = data;
		if (filter.type !== null) {
			filteredChilds = filteredChilds.filter((t) => t.programChild.remainingDays <= filter.type);
		}

		if (filter.filter === "new") {
			filteredChilds = filteredChilds.filter((child) => child.programChild.dateStarted === 0 && child.programChild.remainingDays === 0 && !child.programChild.allChildTasksDone);
		} else if (filter.filter === "active") {
			filteredChilds = filteredChilds.filter((child) => child.programChild.dateStarted > 0 && !child.programChild.allChildTasksDone && !this.isEmptyObject(child.program));
		} else if (filter.filter === "done") {
			filteredChilds = filteredChilds.filter((child) => child.programChild.allChildTasksDone);
		} else if (filter.filter === "without") {
			filteredChilds = filteredChilds.filter((child) => this.isEmptyObject(child.programChild));
		}

		if (filter.filter) {
			if (filter.search) {
				if (filter.filter === "users") {
					filteredChilds = filteredChilds.filter((child) => {
						const name = `${child.lastName} ${child.firstName} ${child.middleName}`;
						return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
				else if (filter.filter === "teachers") {
					filteredChilds = filteredChilds.filter((child) => {
						const name = `${child.curator.lastName} ${child.curator.firstName} ${child.curator.middleName}`;
						return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
			}
		}

		if (filter.sort === "byDateDesc") {
			filteredChilds.sort((a, b) => new Date(a.programChild.dateStarted) - new Date(b.programChild.dateStarted));
		}
		else if (filter.sort === "byDateAsc") {
			filteredChilds.sort((a, b) => new Date(b.programChild.dateStarted) - new Date(a.programChild.dateStarted));
		} else if (filter.sort === "byType") {
			filteredChilds.sort((a, b) => a.lastName.localeCompare(b.lastName));
		} else if (filter.sort === "byDays") {
			filteredChilds.sort((a, b) => a.programChild.remainingDays > b.programChild.remainingDays && a.programChild.remainingDays !== 0 ? -1 : 1)
		}

		return filteredChilds;
	};

	openDoneConfirm = (value, childId, program) => {
		this.setState({ isOpenDoneConfirm: value, doneChildId: childId, doneProgram: program })
	}

	toggleOpenHistory = (value) => {
		this.setState({ isHistoryOpen: this.state.isHistoryOpen === value ? null : value })
	}

	render() {
		const { filteredChilds, programs, tasks, isInfoOpen, isChooseProgram, filter, isHistoryOpen } = this.state;

		return <>
			<Template
				page={'programs'}
				title={'Программы'}
				controlPanel={
					<div className="control-panel">
						<Link to="programs-list"><button>Редактировать программы</button></Link>
					</div>
				}>
				{this.state.isOpenDoneConfirm && (
					<Confirm
						message={'Не все задачи выполнены! Вы уверены, что хотите завершить программу?'}
						action={() => {
							this.taskDone()
						}}
						close={() => { this.openDoneConfirm(false) }}
					/>
				)}
				<div className="programs-filter">
					<div className={filter.type === 0 && "active"} onClick={() => this.setFilter(0)}>0 дней</div>
					<div className={filter.type === 10 && "active"} onClick={() => this.setFilter(10)}>10 дней</div>
					<div>
						<select onChange={this.searchBy}>
							<option value="">Фильтр</option>
							<option disabled>Поиск по:</option>
							<option value="users">Участники</option>
							{/* <option value="teachers">Ответственный</option> */}
							<option disabled></option>
							<option disabled>Фильтр:</option>
							<option value="new">Новые</option>
							<option value="active">Активные</option>
							<option value="done">Завершенные</option>
							<option value="without">Без программы</option>
						</select>
						<input type="text" placeholder="Поиск" value={this.state.filter.search} onChange={(e) => this.setSearchParticipants(e.target.value)} />
					</div>
					<div>
						Сортировать:
						<select onChange={this.sortBy} style={{marginLeft:"5px"}}>
							<option value="byDateAsc">По дате (убыв.)</option>
							<option value="byDateDesc">По дате (возр.)</option>
							<option value="byType">По алфавиту</option>
							<option value="byDays">По дням</option>
						</select>
					</div>
				</div>
				<div className="programs-container">
					<div className="programs-list">

						{filteredChilds?.map((child, i) => (<>
							<div key={i} className="program-item">
								<div className="main-section">
									<div className="section-block">
										<div className="user-avatar">{child.photo ? <img src={`${API.assets}/childs/${child.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}</div>
										<div className="block-info">
											<span className="name1">{`${child.lastName} ${child.firstName} ${child.middleName}`}</span>
											<span className="name2 program-name">{child.program?.name || <b>Программа не выбрана</b>}</span>
										</div>
									</div>
									{child.programChild?.dateStarted > 0 && child.program?.id > 0 ?
										<div className="section-block">
											<div className="block-info block-date">
												<span className="name1">Дата начала</span>
												<span className="name2">{this.dateToLocale(child.programChild?.dateStarted)}</span>
											</div>
										</div>
									: null}
									<div className={`section-block ${child.programChild?.styleDays}`}>
										{
											child.programChild?.allChildTasksDone
											? <div className="block-info" style={{justifyContent: "center"}}><span className="name1">Программа завершена</span></div>
											: child.programChild?.dateStarted > 0 && child.program.id > 0 ? <>
												<div className="days-count">{child.programChild.remainingDays}</div>
												<div className="block-info">
													<span className="name1">дней до завершения</span>
													<span className="name2">программы</span>
												</div>
												<div className="task-done-btn"><i onClick={() => this.openDoneConfirm(true, child.id, child.program)} className="bi bi-x-circle-fill"></i></div>
											</> 
											: <button onClick={() => {
												if (child.programChild?.dateStarted === 0 && child.program.id > 0) {
													this.startProgram(child.id, child.program);
												} else {
													this.infoToggle(child.id);
												}
											}}>
												{child.programChild?.dateStarted === 0 && child.program.id > 0 ? 'Начать программу' : 'Выбрать программу'}
											</button>
										}
									</div>
									<div className={isInfoOpen === child.id ? `block-toggle active` : `block-toggle`} onClick={() => this.infoToggle(child.id)}>
										<i className="bi bi-caret-down-fill"></i>
									</div>
								</div>
								<div className={isInfoOpen === child.id ? `main-section visible` : `main-section invisible`}>
									{child.programChild?.dateStarted > 0 && child.program.id > 0 && isChooseProgram !== child.id  ? <>
										<div className="section-block">
											<div className="block-info">
												<span className="name1">Задания</span>
												<ul className="task-list">
													{child.tasks.sort((a, b) => a.status - b.status).map((task, i) => 
														<li key={i} className={task.status > 0 ? 'task-true' : 'task-false'} onClick={() => this.toggleProgramTask(child.id, task.id, task.status)} >
															<i className={`bi ${task.status > 0 ? 'bi-check-square' : 'bi-square'}`}></i>
															{task.name}
														</li>
													)}
												</ul>
											</div>
										</div>
										<div className="section-blocks">
											<div className="block">
												<div className="block-info">
													{child?.curator?.id > 0 ? <>
														<span className="name1">{`${child?.curator?.lastName} ${child?.curator?.firstName} ${child?.curator?.middleName}`}</span>
														<span className="name2">{child?.curator?.position}</span>
													</> : <><span className="name1">Ответственный</span><span className="name2">-</span></>}
												</div>
											</div>
											{child.programChild.allChildTasksDone ? 
												<div className="block" style={{marginTop: "10px"}}>
													<button onClick={() => {
														this.infoToggle(child.id);
														this.programToggle(child.id);
													}}>Выбрать новую программу</button>
												</div>
											: null}
											{/* <div className="block" style={{marginTop:"10px"}}>
												<div className="block-info">
													<span className="name1">Сидоров Андрей Петрович</span>
													<span className="name2">Ответственный</span>
												</div>
											</div> */}
										</div>
										<div className="section-block">
											<div className="block-info" style={{width: "205px"}}>
												<ul className="links">
													<Link to={`correction-anamnesis/${child.id}`}><li>Анамнез</li></Link>
													<Link to={`correction-dynamic/${child.id}`}><li>Динамика</li></Link>
													<Link to={`correction-call/${child.id}`}><li>Звонки</li></Link>
													<Link to={`correction-effect/${child.id}`}><li>Последствия</li></Link>
													<Link to={`correction-handkerchief/${child.id}`}><li>Платок</li></Link>
													<Link to={`correction-function/${child.id}`}><li>Функция</li></Link>
													<Link to={`correction-photo-dynamic/${child.id}`}><li>Фотодинамика</li></Link>
												</ul>
											</div>
										</div>
									</> :
									<div className="programsList">
										{programs?.length > 0? programs?.map((program, i) => (
											<div key={i} className={child.programChild?.programId === program?.id ? 'current-program' : ''}>
												<span>Программа: <i>{program?.name}</i></span>
												<span>Время выполнения: <i>{program?.countDays} дней</i></span>
												<span>Задачи:</span>
												<ul>{tasks.filter(task => task.programId === program?.id).map((task, i) => <li key={i}>{task.name}</li>)}</ul>
												<button><Link to={`programs-edit/${program.id}`}>Редактировать</Link></button>
												<button
													onClick={() => {
														if (child.programChild?.programId === program?.id) {
															alertShow('Эта программа уже выбрана', 1);
														} else {
															this.addChildProgram(child.id, program?.id);
														}
													}
												}>{child.programChild?.programId === program?.id ? 'Выбрана' : 'Выбрать'}</button>
											</div>
										)): <span>Нет созданных программ</span>}
									</div>}
								</div>
								
								{
									isInfoOpen === child.id ? (
										<div className="section-block-history">
											<button onClick={() => this.toggleOpenHistory(child.id)}>История выполненных программ</button>
											{
												isHistoryOpen === child.id ? (
													<div className="history-container">
														{console.log(child)}
														<span>История программ <b>{`${child?.firstName} ${child?.lastName} ${child.middleName}`}</b>:</span>
														<div>
															<table className="table">
																<tr>
																	<th>
																		Программа
																	</th>
																	<th>
																		Дата Создания
																	</th>
																	<th>
																		Дата начала
																	</th>
																</tr>
																{
																	child?.allPrograms?.map((program, i) => {
																		return (
																			<tr key={i}>
																				<td>
																					{program?.program?.name}
																				</td>
																				<td>
																					{this.dateToLocale(program?.dateCreate)}
																				</td>
																				<td>
																					{this.dateToLocale(program?.dateStarted)}
																				</td>
																			</tr>
																		);
																	})
																}
															</table>
														</div>
													</div>
												) : (
													null
												)
											}
										</div>
									) : null
								}
							</div>
						</>))}
					</div>
				</div>
			<Alert />
		</Template></>
	}
}

export default ProgramsScreen;