import { Component } from 'react';
import { Link } from 'react-router-dom';
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import { ChildParents, Childs, Parents, ProgramChildrens, Programs } from '../../Models';
import { API } from '../../Globals/Constants';
import { Helpers, LS, Utils } from '../../Globals/index';

import moment from 'moment';
import './styles.scss';
import { debounce } from 'lodash';

class ParentalScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user		: null,
			loading 	: true,
			filter: {
				type: null,
				view: 0,
				sort: "byType",
				filter: "peoples",
				search: ""
			}
		};
		this.searchDebounced = debounce(this.performFiltering, 500);

	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		
		const childs = await Childs.getAll(areaId);
		const parents = await Parents.getAll(areaId);
		const childparents = await ChildParents.getAll(areaId);
		const programchildrens = await ProgramChildrens.getAll();
		const programs = await Programs.getAll();
		childs.data.forEach(async (child) => {
			const childId = child.id;
			const childParentRelations = childparents.data.filter((relation) => relation.childId === childId);
			childParentRelations.forEach((relation) => {
				const parentId = relation.parentId;
				const parent = parents.data.find((parent) => parent.id === parentId);
				
				if (parent) {
					if (!parent.childs) {
						parent.childs = [];
					}
					const cp = programchildrens.data.filter((cp) => cp.childId === childId)[0];
					const program = programs.data.filter((p) => p.id === cp?.programId)[0];
					child = { ...child, program };
					parent.childs.push(child);
				}
			});
		});

		this.setState({user,data:parents.data,parents:parents.data,childparents:childparents.data,loading:false});
		this.performFiltering(this.state.filter.search);
	}
	childGet = (id) => this.state.childparents.find(f => f.id === id);
	ageGet = (ts) => {
		const years = moment().diff(ts*1000, 'years');
		return `${years} ${Utils.agesGet(years)}`;
	}

	sortBy = (event) => {
		const sortOption = event.target.value;
		this.setSearchParticipants('');
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				sort: sortOption
			}
		}));
	};

	searchBy = (event) => {
		const filterOption = event.target.value;
		this.setSearchParticipants('');
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				filter: filterOption
			}
		}));
	};

	dateToString = (date) => {
		return date.toISOString();
	};

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				search: query,
			},
		}));
	
		this.searchDebounced(query);
	};
	
	performFiltering = (query) => {
		this.setState((prevState) => ({
			parents: this.filterData(prevState.data, query),
			loading: false,
		}));
	};

	filterData = (data, searchQuery) => {
		const { filter } = this.state;
		let parents = data;
		if (filter.type !== null) {
			parents = parents?.filter((t) => t.programChild.remainingDays <= filter.type);
		}
		if (filter.filter) {
			if (filter.search) {
				if (filter.filter === "users") {
					parents = parents?.filter((child) => {
						const c = child.childs?.filter((c) => {
							const name = `${c.lastName} ${c.firstName} ${c.middleName}`;
							return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
						});
						return c?.length > 0;
					});
				}
				else if (filter.filter === "parents") {
					parents = parents?.filter((parent) => {
						const name = `${parent?.lastName} ${parent?.firstName} ${parent?.middleName}`;
						return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
			}
		}
		return parents;
	};

	isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0;
	};
    render() {
		const { filter, parents } = this.state;

		if (filter.sort === "byType") {
			parents?.sort((a, b) => a.lastName.localeCompare(b.lastName));
		}
		
		return <Template
			page={'parental'}
			title={'Родители'}
			controlPanel={
				<div className="control-panel">
					<Link to="parent"><button>+ Добавить родителя</button></Link>
					{/* <button>Экспорт</button> */}
				</div>
			}>
			{this.state.loading ? <Loader /> : <>
				<div className="filter">
					<div>
						<select onChange={this.searchBy}>
							<option value="">Фильтр</option>
							<option disabled>Поиск по:</option>
							<option value="parents">Родитель</option>
							<option value="users">Воспитанники</option>
						</select>
						<input type="text" placeholder="Поиск" value={filter.search} onChange={(e) => this.setSearchParticipants(e.target.value)} />
					</div>
					<div>
						Сортировать:
						<select onChange={this.sortBy} style={{marginLeft:"5px"}}>
							<option value="byType">По алфавиту</option>
						</select>
					</div>
				</div>
				<div className="parental-container">
					<div className="parental_list">
						{parents.map((v,i) => (
						<Link to={`/parent/${v.id}`} key={i}>
							<div className="parent">
								<div className="parent_block">
									<span>{v.lastName} {v.firstName} {v.middleName}</span>
									<span className="info_title">Родитель/опекун</span>
								</div>
								{v.childs && v.childs?.length > 0 ? v.childs.map((c,i) => (
									<Link to={`pupil/${c.id}`} key={i}>
										<div className="parent_block children">
											<div className="avatar">
												{c.photo || '' ? (
													<img src={`${API.assets}/childs/${c.photo}`} alt="" />
												) : (
													<i className="bi bi-person-fill form-no-photo"></i>
												)}
											</div>
											<span>{`${c.lastName} ${c.firstName} ${c.middleName}`}</span>
											<span className="program_title">{c.program?c.program?.name:<b>Программа не выбрана</b>}</span>
										</div>
									</Link>
								)) : null}
							</div>
						</Link>
						))}
					</div>
				</div></>
			}
		</Template>
    }
}

export default ParentalScreen;