import {Component} from 'react';

// plug-ins
import Modal from 'react-awesome-modal';

// styles
import './styles.css';

import PropTypes from 'prop-types';

class Confirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message:props.message,
			modalshow:true
		};
	}
	modalHide = () => this.setState({modalshow:false});
	close = () => {
		this.modalHide();
		this.props.close();
	}
	action = () => {
		this.modalHide();
		this.props.action();
	}
	render() {
		if (this.state.message === null) return null;
		return <Modal visible={this.state.modalshow} width={'340'} effect={'fadeInDown'} onClickAway={() => this.close()}>
			<div className="confirmcontainer">
				<h4>Подтвердите пожалуйста</h4>
				{this.state.message}
				<div className="buttons">
					<button onClick={this.action.bind(this)}>Да</button>
					<div className="cancel" onClick={this.close.bind(this)}>Отмена</div>
				</div>
			</div>
		</Modal>
	}
}

Confirm.propTypes = {
	message: PropTypes.object.isRequired,
	action: PropTypes.object.isRequired,
	close: PropTypes.object.isRequired,
};

export default Confirm;