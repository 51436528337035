import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Закупки',link:'household-purchases',desc:'Канцелярия, книги, бытовая химия, мебель и др.'},
	{name:'Инвентаризация',link:'household-inventory',desc:'Инвентаризация мебели долгого служения'},
	{name:'Транспорт',link:'household-transport',desc:'Контроль транспорта, путевые листы'},
	{name:'Акты',link:'household-acts',desc:'Акты порчи имущества'}
];

class HouseholdScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewHouseholdPage, null, null, null, 2);
	}
	clickLinkHandler = async () => {
        // Add events in log
        Events.add(this.state.user.id, eventMessageName.viewHouseholdInventory, null, null, null, 2);
	}
	render() {
		return <Template page={'household'} title={'Хозяйственная часть'}>
			{this.state.loading ? <Loader /> :
				<div className="household-list">
					{menu.map((v,i) => {
						if (v.link==='household-inventory') {
							return <a onClick={() => this.clickLinkHandler()} href='https://docs.google.com/spreadsheets/d/1tXUAFoBWKIL1SnpQqCOpJlghwGTwTAkp6mPCVOaFHzA/edit?usp=sharing' target="_blank" rel="noopener noreferrer" className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</a>
						} else {
							return <Link to={`/${v.link}`} className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</Link>
						}
					})}
				</div>
			}
		</Template>
	}
}

export default HouseholdScreen;
