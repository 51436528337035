import { requestOderType } from '../../Globals/Constants';
import Http from '../../Globals/Http';

const get		= async (childId)		=> await Http.get('childcorrectionhandkerchiefs', {conditions:[{k:'childId',v:childId}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('childcorrectionhandkerchiefs', {data});
const update	= async (childId, data)	=> await Http.put('childcorrectionhandkerchiefs', {data,conditions:[{k:'childId',v:childId}]});

export {
	get,
	add,
	update
}