import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		= async (type)			=> await Http.get('documents', {conditions:[{k:'type',v:type}]});
const getAll	= async ()				=> await Http.get('documents', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getInactiveAll	= async ()		=> await Http.get('documents', {conditions:[{k:'status',v:comonStatus.IN_ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (id)			=> await Http.get('documents', {conditions:[{k:'id',v:id}]});

const add		= async (data)			=> await Http.post('documents', {data});
const update	= async (id, data)		=> await Http.put('documents', {data,conditions:[{k:'id',v:id}]});
const remove	= async (id)			=> await Http.remove('documents', {conditions:[{k:'id',v:id}]});

export {
	get,
	getAll,
	getInactiveAll,
	getById,
	add,
	update,
	remove
}