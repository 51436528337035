import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('chats', {conditions:[{k:'id',v:id}]});
const getAll	        = async (areaId)	=> await Http.get('chats', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateEvent',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('chats', {data});
const update	        = async (id, data)	=> await Http.put('chats', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update
}