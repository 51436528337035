import {Component} from 'react';
import { Link } from 'react-router-dom';
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import {ChildParents, Childs, Parents, ProgramChildTasks, ProgramChildrens, ProgramTasks, Programs, Teachers} from '../../Models';
import {API} from '../../Globals/Constants';
import {Helpers, LS, Utils} from '../../Globals/index';
import moment from 'moment';
import { debounce } from 'lodash';

import './styles.scss';

class PupilsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true,
			filter: {
				type: null,
				view: 0,
				sort: "byType",
				filter: "peoples",
				search: ""
			},
			filteredChilds: [],
			updatedChilds: []
		};
		this.searchDebounced = debounce(this.performFiltering, 500);
	}

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getAll(areaId);
		const teachers = await Teachers.getAll(areaId, 1);
		const programs = await Programs.getAll();
		const programTasks = await ProgramTasks.getAll();
		const programChildrens = await ProgramChildrens.getAll();
		const programChildTasks = await ProgramChildTasks.getAll();
		
		try {
			const updatedTasks = programTasks.data.map((task) => { return { ...task, status: -1 }; });

			const updatedChilds = childs.data.map((child) => {
				const childId = child.id;
				const filteredProgramChildTasks = programChildTasks.data.filter((programChildTask) => programChildTask.childId === childId);
				// if (programs.data.length === 0 || filteredProgramChildTasks.length === 0) { return { ...child, programChild: {}, program: {}, tasks: [], }; }

				const childTaskIds = filteredProgramChildTasks.map((programChildTask) => programChildTask.programTaskId);

				const programTaskIds = programTasks.data.map((task) => task.id);

				const missingTaskIds = programTaskIds.filter((taskId) => !childTaskIds.includes(taskId));
				const missingTasks = programTasks.data.filter((task) => missingTaskIds.includes(task.id));
				missingTasks.forEach((task) => filteredProgramChildTasks.push({ childId, programTaskId: task.id, status: -1 }));

				const childTasks = filteredProgramChildTasks.map((programChildTask) => {
					const task = updatedTasks.find((task) => task.id === programChildTask.programTaskId);
					return { ...task, status: programChildTask.status };
				});

				const filteredTasks = programChildrens.data.filter((programChild) => programChild.childId === childId).map((programChild) => {
					const programId = programChild.programId;
					const program = programs.data.find((program) => program.id === programId);

					const currentDateTimestamp = Math.floor(Date.now() / 1000);
					const dateStartedTimestamp = Math.floor(programChild.dateStarted);
					const elapsedTimeInSeconds = currentDateTimestamp - dateStartedTimestamp;
					const allChildTasks = childTasks.filter((task) => task.programId === programId);
					const allChildTasksDone = allChildTasks.every(task => task.status === 1);

					programChild.remainingDays = allChildTasksDone ? 0 : Math.max(program?.countDays - Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)), 0);
					
					let style = allChildTasksDone
						? 'tasks-done'
						: programChild.remainingDays <= 0 && programChild.dateStarted === 0
						? 'tasks-not-started'
						: programChild.remainingDays === 0 && programChild.dateStarted > 0
						? 'tasks-error'
						: programChild.remainingDays < 10 && programChild.dateStarted > 0
						? 'warning'
						: programChild.dateStarted > 0 && programChild.remainingDays >= 0
						? 'tasks-done'
						: 'default';

					programChild.styleDays = style;
					programChild.allChildTasksDone = allChildTasksDone;


					return {
						program,
						programChild,
						tasks: allChildTasks
					};
				});

				const teacher = teachers.data.find(t => t.id === child.teacherId);
				return {
					...child,
					teacher,
					programChild: filteredTasks[0]?.programChild || {},
					program: filteredTasks[0]?.program || {},
					tasks: filteredTasks[0]?.tasks || [],
				};
			});

			const parents = await Parents.getAll(areaId);
			const childparents = await ChildParents.getAll(areaId);
			this.setState({
				user,
				data:childs.data,
				parents:parents.data,
				childparents:childparents.data,
				teachers: teachers.data,
				updatedChilds,
				loading:false
			});
		} catch (error) {
			console.error('Error fetching data:', error);
			this.setState({ loading: false });
		}
		this.performFiltering(this.state.filter.search);
	}

	parentGet = (id) => this.state.parents.find(f => f.id === id);
	ageGet = (ts) => `${moment().diff(ts*1000, 'years')} ${Utils.agesGet(moment().diff(ts*1000, 'years'))}`;
	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

	setFilter = (type) => this.setState((prevState) => ({ filter: { ...prevState.filter, type: type === prevState.filter.type ? null : type } }));

	sortBy = (event) => this.setState((prevState) => ({ filter: { ...prevState.filter, sort: event.target.value } }));

	searchBy = (event) => this.setState((prevState) => ({ filter: { ...prevState.filter, filter: event.target.value } }));

	dateToString = (date) => date.toISOString();

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({
			filter: { ...prevState.filter, search: query }
		}));
		this.searchDebounced(query);
	};

	isEmptyObject = (obj) => Object.keys(obj).length === 0;

	performFiltering = (query) => this.setState((prevState) => ({ filteredChilds: this.filterData(prevState.updatedChilds, query), loading: false }));

	filterData = (data, searchQuery) => {
		const { filter } = this.state;
		let filteredChilds = data;
		if (filter.type !== null) {
			filteredChilds = filteredChilds.filter((t) => t.programChild.remainingDays <= filter.type);
		}
		if (filter.filter) {
			if (filter.search) {
				if (filter.filter === "users") {
					filteredChilds = filteredChilds.filter((child) => {
						const name = `${child.lastName} ${child.firstName} ${child.middleName}`;
						return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
				else if (filter.filter === "program") {
					filteredChilds = filteredChilds.filter((child) => {
						return child.program?.name?.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
				else if (filter.filter === "teachers") {
					filteredChilds = filteredChilds.filter((child) => {
						const name = `${child.teacher?.lastName} ${child.teacher?.firstName} ${child.teacher?.middleName}`;
						return name.toLowerCase().includes(searchQuery.toLowerCase().trim())
					});
				}
				else if (filter.filter === "age") {
					filteredChilds = filteredChilds.filter((child) => {
						return moment().diff(child.birthDate*1000, 'years') === Number(searchQuery.toLowerCase().trim());
					})
				}
			}
		}
		return filteredChilds;
	};

    render() {
		const { filter, filteredChilds } = this.state;

	
		if (filter.sort === "byDateDesc") {
			filteredChilds?.sort((a, b) => new Date(a.programChild.dateStarted) - new Date(b.programChild.dateStarted));
		} else if (filter.sort === "byDateAsc") {
			filteredChilds?.sort((a, b) => new Date(b.programChild.dateStarted) - new Date(a.programChild.dateStarted));
		} else if (filter.sort === "byType") {
			filteredChilds?.sort((a, b) => a.lastName.localeCompare(b.lastName));
		} else if (filter.sort === "byAgeAsc") {
			filteredChilds?.sort((a, b) => a.birthDate - b.birthDate);
		} else if (filter.sort === "byAgeDesc") {
			filteredChilds?.sort((a, b) => b.birthDate - a.birthDate);
		}

		console.log(filteredChilds);

		return <Template 
			page={'pupils'} 
			title={'Воспитанники'} 
			controlPanel={
				<div className="control-panel">
					<Link to="pupil"><button>+ Добавить воспитанника</button></Link>
					{/* <button>Экспорт</button> */}
				</div>}
			>
			{this.state.loading ? <Loader /> : <>
				<div className="filter">
					<div>
						<select onChange={this.searchBy}>
							<option value="">Фильтр</option>
							<option disabled>Поиск по:</option>
							<option value="users">Воспитанники</option>
							<option value="program">Программа</option>
							<option value="teachers">Ответственный</option>
							<option value="age">Возраст</option>
							{/* <option disabled></option>
							<option disabled>Фильтр:</option>
							<option value="new">Новые</option>
							<option value="active">Активные</option>
							<option value="done">Завершенные</option>
							<option value="without">Без программы</option> */}
						</select>
						<input type="text" placeholder="Поиск" value={filter.search} onChange={(e) => this.setSearchParticipants(e.target.value)} />
					</div>
					<div>
						Сортировать:
						<select onChange={this.sortBy} style={{marginLeft:"5px"}}>
							<option value="byDateAsc">По дате поступления (убыв.)</option>
							<option value="byDateDesc">По дате поступления (возр.)</option>
							<option value="byType">По алфавиту</option>
							<option value="byAgeDesc">По возрасту (возр.)</option>
							<option value="byAgeAsc">По возрасту (убыв.)</option>
						</select>
					</div>
				</div>
				<div className="pupils-container">
					{/* <div className="filter">
						Filter
					</div> */}
					<div className="pupils_list">
						{filteredChilds.map((v,i) => (
						<Link to={`/pupil/${v.id}`} key={i}>
							<div className="pupil">
								<div className="pupil_block" style={{width:"30%"}}>
									<div className="avatar">
										{v.photo ? <img src={`${API.assets}/childs/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
									</div>
									<span>{v.lastName} {v.firstName} {v.middleName}</span>
									<span className="program_title">{v.program.name || <b>Программа не выбрана</b>}</span>
								</div>
								<div className="pupil_block">
									<span>Дата поступления</span>
									<span>{this.dateToLocale(v.dateIn)}</span>
								</div>
								<div className="pupil_block">
									<span>{this.ageGet(v.birthDate)}</span>
									<span>{moment(v.birthDate*1000).format('DD.MM.y')}</span>
								</div>
								{v.teacher ? (
									<div className="pupil_block">
										<span>{v.teacher ? `${v.teacher?.lastName} ${v.teacher?.firstName} ${v.teacher?.middleName}` : '-'}</span>
										<span>{v.teacher?.position || 'Ответственный'}</span>
									</div>
								) : v.teacherId > 0 ? (
									<div className="pupil_block">
										<span>{this.state.teachers.find(t => t.id === v.teacherId) ? `${this.state.teachers.find(t => t.id === v.teacherId)?.lastName} ${this.state.teachers.find(t => t.id === v.teacherId)?.firstName} ${this.state.teachers.find(t => t.id === v.teacherId)?.middleName}` : '-'}</span>
										<span>{this.state.teachers.find(t => t.id === v.teacherId)?.position || 'Ответственный'}</span>
									</div>
								) : (
									<div className="pupil_block">
										<span>-</span>
										<span>Ответственный</span>
									</div>
								)}
							</div>
						</Link>
						))}
					</div>
				</div>
			</>}
		</Template>
    }
}

export default PupilsScreen;