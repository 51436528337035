import { Component } from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import { CorrectionFunctions, Teachers, Childs, Events } from '../../Models';

// globals
import { eventMessageName } from '../../Globals/Constants';

// helpers
import { Helpers, Errors, Utils, LS } from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class CorrectionFunctionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id || 0),
      user: null,
      data: {},
      child: {},
      notfound: null,
      loading: true,
    };
  }
  componentDidMount = async () => {
    const user = Helpers.authCheck();
    if (this.state.id) {
      const child = await Childs.get(this.state.id);
      const areaId = parseInt(LS.get('areaId'));
      const teachers = await Teachers.getAllWithoutType(areaId);
      const corr = await CorrectionFunctions.get(this.state.id);
      if (corr.data) {
        corr.data.map((el) => {
          return (el.info = el.info.replace(/&#13;|&#013;/g, '\n'));
        });
      }
      this.setState({
        data: corr.data || [],
        child: child.data[0],
        notfound: false,
        teachers: teachers.data,
        user,
        loading: false,
      });

      // Add events in log
      const fullName =
        ' ' +
        child.data[0].lastName +
        ' ' +
        child.data[0].firstName +
        ' ' +
        child.data[0].middleName;
      Events.add(
        user.id,
        eventMessageName.viewCorrectionChildFunction + fullName,
        null,
        null,
        null,
        2
      );
    } else this.setState({ notfound: false });
    this.setState({ user, loading: false });
  };
  formHandle = (e) => this.setState({ [e.target.name]: e.target.value });
  save = async (e) => {
    e.preventDefault();
    const { id, data, info, teachersId } = this.state;

    const d = {
      info: info,
      teachersId: teachersId,
      dateModify: Utils.now(),
    };
    if (data) {
      d.childId = id;
      const res = await CorrectionFunctions.add(d).catch(() => this.error());

      // Add events in log
      const getDataModel = (await Childs.get(id)).data[0];
      const fullName =
        ' ' +
        getDataModel.lastName +
        ' ' +
        getDataModel.firstName +
        ' ' +
        getDataModel.middleName;
      const newData = d;
      Events.add(
        this.state.user.id,
        eventMessageName.addNewFunctionCorrection + fullName,
        null,
        JSON.stringify(newData),
        null,
        3
      );

      if (res) Errors.add();
      else return false;
    }
    this.props.history.push(`/pupil/${this.props.match.params.id}`);
    return false;
  };
  getTeacher = (id) => {
    if (id) {
      const teacher = this.state.teachers.find((e) => e.id === id);
      if (teacher) {
        const nameTeacher =
          teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
        return nameTeacher;
      } else {
        return null;
      }
    }
  };
  error = () => Errors.error();
  render() {
    return (
      <Template
        page={'departments'}
        title={'Функция'}
        controlPanel={
          <div className="form-control-panel">
            <button form="correction-form" type="submit">
              Сохранить
            </button>
          </div>
        }
        backLink={`/pupil/${this.props.match.params.id}`}
      >
        {this.state.loading ? (
          <Loader />
        ) : this.state.notfound === null ? null : this.state.notfound ? (
          <NotFound back={`/pupil/${this.props.match.params.id}`} />
        ) : (
          <form
            id="correction-form"
            className="form correction-function"
            onSubmit={this.save}
          >
            <div className="form-row">
              <label>имя ребенка</label>
              <h4>
                {this.state.child.lastName} {this.state.child.firstName}{' '}
                {this.state.child.middleName}
              </h4>
            </div>
            <div className="form-row">
              <label htmlFor="info">Описание</label>
              <textarea
                name="info"
                id="info"
                placeholder="Добавьте описание"
                required
                className="large"
                onChange={this.formHandle}
              >
                {this.state.data.info}
              </textarea>
            </div>
            <div className="form-row">
              <label htmlFor="teachersId">Ответственный</label>
              <select
                name="teachersId"
                id="teachersId"
                onChange={this.formHandle}
                required
                defaultValue={this.state.data.teachersId}
              >
                <option></option>
                {this.state.teachers.map((v, i) => (
                  <option key={i} value={v.id}>
                    {v.lastName} {v.firstName} {v.middleName}
                  </option>
                ))}
              </select>
            </div>
            <div className="list-function">
              {this.state.data.map((v, i) => (
                <div key={i} className="list-function-item">
                  <div className="name">
                    <span>{Moment(v.dateCreate * 1000).format('DD.MM.y')}</span>
                  </div>
                  <div className="name">
                    <b>Функция:</b> {v.info}
                  </div>
                  <div className="name">
                    <b>Ответственный:</b> {this.getTeacher(v.teachersId)}
                  </div>
                </div>
              ))}
            </div>
          </form>
        )}
        <Alert />
      </Template>
    );
  }
}

CorrectionFunctionScreen.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default CorrectionFunctionScreen;
