import {Component} from 'react';

// components
import Alert, {alertShow} from '../../Components/Alert';

// models
import {Users,Teachers,TeacherTimetables,Areas} from '../../Models';

// helpers
import {LS,Utils} from '../../Globals/index';

// styles
import './styles.css';

class LoginScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areas:[],
			areaId:null,
			username:'',
			password:'',
			usernameError:false,
			passwordError:false,
			areaError:false
		};
	}
	componentDidMount = async () => {
		const areas = await Areas.getActive();
		this.setState({areas:areas.data});
	}
	login = async () => {
		this.setState({usernameError:false,passwordError:false});
		const {username,password,areaId} = this.state;
		if (Utils.empty(username)) {
			this.setState({usernameError:true});
			return;
		}
		if (Utils.empty(password)) {
			this.setState({passwordError:true});
			return;
		}
		if (Utils.empty(areaId) || areaId === 0) {
			this.setState({areaError:true});
			return;
		}
		let isteacher = false;
		let res = await Teachers.login(areaId, username, password);
		if (res.code !== 0 || res.data === null) {
			res = await Users.login(username, password);
			if (res.code !== 0 || res.data === null) {
				this.setState({usernameError:true,passwordError:true});
				alertShow('<b>Ошибка! Пользователь не найден!</b><br>Проверье правильность ввода имени пользователя и пароля', true);
				return;
			}
		} else {
			const tt = await TeacherTimetables.get(res.data.id);
			const timetable = tt.data && tt.data.length ? tt.data[0] : null;
			if (!timetable) {
				alertShow('<b>Расписание не задано!</b><br>Для пользователя не установлен график работы.<br>Обратитесь к администратору!', true);
				return;
			}
			//const days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
			//const day = new Date().getDay();
			//const start = timetable[`${days[day]}Start`], end = timetable[`${days[day]}End`];
			//if (start === 0 || end === 0) return this.timetableError();
			//const h = new Date().getHours();
			///if (!(h >= start && h <= end)) return this.timetableError()
			isteacher = true;
		}
		const user = res.data;
		user.isteacher = isteacher;
		user.name = user.name || `${user.lastName} ${user.firstName} ${user.middleName}`;
		LS.set('user', user);
		LS.set('areaId', areaId);
		if (isteacher) {
			if (user.roles.find(f => f === 'dashboard')) {
				window.location.href = '/';
				return;
			}
			if (user.roles.find(f => f === 'notifications')) {
				window.location.href = '/notifications';
				return;
			}
			window.location.href = '/departments';
		} else window.location.href = '/';
	}
	timetableError = () => alertShow('<b>Ошибка доступа!</b><br>Вы пытаетесь войти в систему в не рабочее время!', true);
	handleForm = (e) => this.setState({[e.target.name]:e.target.value});
	areaHandle = (e) => this.setState({areaId:parseInt(e.target.value)})
	render() {
		return (
			<>
				<div className="login-container">
					<img src={require('../../Images/vp.logo.svg').default} alt="Время Первых" />
					<div className="form-column">
						<label>
							<span>Имя пользователя</span>
							<input type="text" name="username" placeholder="Имя пользователя" data-iserror={`${this.state.usernameError!==undefined&&this.state.usernameError}`} onChange={this.handleForm} maxLength={20} />
						</label>
					</div>
					<div className="form-column">
						<label>
							<span>Пароль</span>
							<input type="password" name="password" placeholder="Пароль" data-iserror={`${this.state.passwordError!==undefined&&this.state.passwordError}`} onChange={this.handleForm} maxLength={20} />
						</label>
					</div>
					<div className="form-column">
						<label>
							<span>Филиал</span>
							<select onChange={this.areaHandle} required data-iserror={`${this.state.areaError!==undefined&&this.state.areaError}`}>
								<option></option>
								{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
							</select>
						</label>
					</div>
					<button onClick={() => this.login()} className="btn">Войти</button>
				</div>
				<Alert />
			</>
		);
	}
}

export default LoginScreen;