import { Component } from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {
  ParentPsychologyAnamnesis,
  Parents,
  Teachers,
  Events,
} from '../../Models';

// globals
import { eventMessageName } from '../../Globals/Constants';

// helpers
import { Helpers, Errors, Utils, LS } from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ParentPsychologyAnamnesisScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id || 0),
      user: null,
      data: {},
      child: {},
      notfound: null,
      loading: true,
    };
  }
  componentDidMount = async () => {
    const user = Helpers.authCheck();
    if (this.state.id) {
      const child = await Parents.get(this.state.id);
      const areaId = parseInt(LS.get('areaId'));
      const teachers = await Teachers.getAllWithoutType(areaId);
      if (child.data && child.data.length) {
        const psy = await ParentPsychologyAnamnesis.get(this.state.id);
        this.setState({
          data: psy.data[0] || {},
          child: child.data[0],
          notfound: false,
          teachers: teachers.data,
        });

        // Add events in log
        const fullName =
          ' ' +
          child.data[0].lastName +
          ' ' +
          child.data[0].firstName +
          ' ' +
          child.data[0].middleName;
        Events.add(
          user.id,
          eventMessageName.viewPsychologyParentAnamnesisPage + fullName,
          null,
          null,
          null,
          2
        );
      } else this.setState({ notfound: true });
    } else this.setState({ notfound: false });
    this.setState({ user, loading: false });
  };
  formHandle = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  save = async (e) => {
    e.preventDefault();
    const { id, data } = this.state;
    const d = {
      info: data.info,
      task: data.task,
      teachersId: data.teachersId,
      dateModify: Utils.now(),
    };
    if (data.id > 0) {
      // Add events in log
      // get old data before update
      const dataModel = await ParentPsychologyAnamnesis.get(id);
      const getOldData = (data) => {
        const keysData = Object.keys(data);
        let oldData = {};
        keysData.forEach((key) => {
          let item = {};
          item[key] = dataModel.data[0][key];
          Object.assign(oldData, item);
        });
        return oldData;
      };
      const oldData = getOldData(d);
      const newData = d;

      await ParentPsychologyAnamnesis.update(id, d).catch(() => this.error());

      // Add events in log
      const getDataModel = (await Parents.get(id)).data[0];
      const fullName =
        ' ' +
        getDataModel.lastName +
        ' ' +
        getDataModel.firstName +
        ' ' +
        getDataModel.middleName;
      Events.add(
        this.state.user.id,
        eventMessageName.updateDataAnamnesisParent + fullName,
        JSON.stringify(oldData),
        JSON.stringify(newData),
        null,
        5
      );

      Errors.update();
    } else {
      d.parentId = id;
      const res = await ParentPsychologyAnamnesis.add(d).catch(() =>
        this.error()
      );

      // Add events in log
      const getDataModel = (await Parents.get(id)).data[0];
      const fullName =
        ' ' +
        getDataModel.lastName +
        ' ' +
        getDataModel.firstName +
        ' ' +
        getDataModel.middleName;
      const newData = d;
      Events.add(
        this.state.user.id,
        eventMessageName.addNewAnamnesisParent + fullName,
        null,
        JSON.stringify(newData),
        null,
        3
      );

      if (res) Errors.add();
      else return false;
    }
    this.props.history.push(`/parent/${this.props.match.params.id}`);
    return false;
  };
  error = () => Errors.error();
  render() {
    return (
      <Template
        page={'departments'}
        title={'Анамнез родителя'}
        controlPanel={
          <div className="form-control-panel">
            <button form="psychology-form" type="submit">
              Сохранить
            </button>
          </div>
        }
        backLink={`/parent/${this.props.match.params.id}`}
      >
        {this.state.loading ? (
          <Loader />
        ) : this.state.notfound === null ? null : this.state.notfound ? (
          <NotFound back={'/parental'} />
        ) : (
          <form
            id="psychology-form"
            className="form psychology-anamnesis"
            onSubmit={this.save}
          >
            <div className="form-row">
              <label>имя родителя</label>
              <h4>
                {this.state.child.lastName} {this.state.child.firstName}{' '}
                {this.state.child.middleName}
              </h4>
            </div>
            <div className="form-row">
              <label htmlFor="info">Состояние</label>
              <textarea
                name="info"
                id="info"
                placeholder="Опишите состояние ребенка"
                required
                className="large"
                onChange={this.formHandle}
              >
                {this.state.data.info}
              </textarea>
            </div>
            <div className="form-row">
              <label htmlFor="task">Задача</label>
              <textarea
                name="task"
                id="task"
                placeholder="Опишите задачу"
                required
                className="medium"
                onChange={this.formHandle}
              >
                {this.state.data.task}
              </textarea>
            </div>
            <div className="form-row">
              <label htmlFor="teachersId">Ответственный</label>
              <select
                name="teachersId"
                id="teachersId"
                onChange={this.formHandle}
                required
                defaultValue={this.state.data.teachersId}
              >
                <option></option>
                {this.state.teachers.map((v, i) => (
                  <option key={i} value={v.id}>
                    {v.lastName} {v.firstName} {v.middleName}
                  </option>
                ))}
              </select>
            </div>
            {this.state.data.dateModify ? (
              <div className="form-row">
                <label>Последние изменение</label>
                <div className="date">
                  {Moment(this.state.data.dateModify * 1000).format(
                    'DD.MM.y, H:mm'
                  )}
                </div>
              </div>
            ) : null}
          </form>
        )}
        <Alert />
      </Template>
    );
  }
}

ParentPsychologyAnamnesisScreen.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default ParentPsychologyAnamnesisScreen;
