import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import DocumentViewer from '../../Components/DocumentViewer';
import Alert from '../../Components/Alert';

// models
import {SchoolBooks,SchoolBookDocuments,Files} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class EducationBookScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			documents:[],
			filedocument:null,
			doctype:null,
			doc:null,
			documentview:false,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const books = await SchoolBooks.get(this.state.id);
			if (books.data && books.data.length) {
				this.setState({data:books.data[0],notfound:false});
				await this.documentsGet();
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name
		};
		if (id === 0) {
			const res = await SchoolBooks.add(d).catch(() => this.error());
			if (res) {
				Errors.add();
				this.setState({id:parseInt(res.data.id)});
			}
			else return false;
		} else {
			await SchoolBooks.update(id, d).catch(() => this.error());
			Errors.update();
		}
		await this.documentSave();
		this.props.history.push('/education-books');
		return false;
	}
	error = () => Errors.error();
	documentsGet = async () => {
		const docs = await SchoolBookDocuments.getAll();
		const documents = docs.data.filter(f => f.schoolBookId === this.state.id);
		this.setState({documents,loading:false});
	}
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentDelete = async (id) => {
		await SchoolBookDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true}, async () => await this.documentsGet(true));
	documentView = (d) => {
		const doc = `${API.documents}/?p=departments/education/books/${this.state.id}&ft=${d.fileType}&fn=${d.link}`;
		const doctype = d.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = (d) => {
		const uri = `${API.documents}/?p=departments/education/books/${this.state.id}&ft=${d.fileType}&fn=${d.link}`;
		window.location.href = uri;
	}
	documentSave = async () => {
		const {filedocument,id} = this.state;
		const d = {
			schoolBookId:id,
			fileType:filedocument.type,
			link:filedocument.name
		};
		const res = await SchoolBookDocuments.add(d).catch(() => this.error());
		if (res) {
			Errors.add();
			this.fileSave(`documents/departments/education/books/${id}`, filedocument);
			this.documentsReload();
		}
		return false;
	}
	fileSave = (path, file) => Files.upload(0, null, path, file);
	render() {
		return <Template
					page={'departments'}
					title={this.state.id === 0 ? 'Добавление учебника' : 'Редактирование учебника'}
					controlPanel={
						<div className="form-control-panel">
							<button form="education-books-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/education-books'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/users'} /> :
						<form id="education-books-form" className="form" onSubmit={this.save}>
							<div className="form-row-oneline">
								<div className="form-row">
									<label htmlFor="name">Название учебника</label>
									<input type="text" name="name" id="name" placeholder="Название учебника" required className="double" autoFocus={true} onChange={this.formHandle} value={this.state.data.name} />
								</div>
								<div className="form-row">
									<label htmlFor="file">Файл</label>
									<input onChange={this.fileChange} name="file" className="file" type="file" />
								</div>
							</div>
							{this.state.documents ?
								<div className="list-education-book-documents">
									{this.state.documents.map((v,i) => <div key={i} className="list-education-book-documents-item">
										<div className="name">
											<span>Учебник</span>
											{v.link}
										</div>
										<div className="buttons">
											<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
											<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
											<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
										</div>
									</div>)}
								</div> : null}
						</form>
					}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

EducationBookScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default EducationBookScreen;