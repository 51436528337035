// components
import {alertShow} from '../../Components/Alert';

const add		= () =>	alertShow('<b>Новая запись добавлена успешно!</b>');
const update	= () =>	alertShow('<b>Данные успешно обновлены!</b>');
const error		= () =>	alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);

export {
	add,
	update,
	error
}