import {Component} from 'react';
import Template from '../../../Components/Template';
import Alert from '../../../Components/Alert';
import {ChildParents, Childs, Parents} from '../../../Models';
import {Helpers, LS} from '../../../Globals/index';

import ParentalInfo from './ParentalInfo';
import ParentalTasks from './ParentalTasks';
import ParentalPsychology from './ParentalPsychology';

import '../styles.scss';

import PropTypes from 'prop-types';
import { API } from '../../../Globals/Constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ParentScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTab  : null,
			id			: parseInt(this.props.match.params.id||0),
			data		: {},
			parentsAll	: [],
			parentId	: null,
			user		: null,
			tab			: 1,
			is_edit 	: false,
			loading 	: true,
			parent		: {
				firstName	: '',
				lastName	: '',
				middleName	: '',
				childrens 	: [],
				fullName	: '',
			}
		};
		const tabs = this.tabs();
		if (this.state.currentTab == null) {
			this.state.currentTab = tabs[0];
		}
	}

	handleFullNameChange = (event) => {
		//const { parent } = this.state;
		const { value } = event.target;
		const names = value.split(' ');

		const updatedParent = {
			firstName: names[0]  || '',
			lastName: names[1]   || '',
			middleName: names[2] || '',
		};

		this.setState({ parent: updatedParent });
	};

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		if (this.state.id) {
			const parent = await Parents.get(this.state.id);
			if (parent.data && parent.data.length) {
				const data = parent.data[0];
				data.fullName = `${data.lastName} ${data.firstName} ${data.middleName}`;
				this.setState({parent: data});
				
				const childs = await Childs.getAll(areaId);
				const childparents = await ChildParents.getAll(areaId);
				const childParentRelations = childparents.data.filter((relation) => relation.parentId === data.id);

				const children = [];
				childParentRelations.forEach((relation) => {
					const child = childs.data.find((child) => child.id === relation.childId);
					if (child) {
						child.fullName = `${child.lastName} ${child.firstName} ${child.middleName}`;
						children.push(child);
					}
				});

				const updatedParent = { ...data, childs: children };
				this.setState({ parent: updatedParent });
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});

		const tabs = this.tabs();
		if (this.state.currentTab == null) {
			this.setState({ currentTab: tabs[0] });
		}
	}
	getCurrentTab = () => {
		switch (this.state.currentTab) {
			case "Информация": return <ParentalInfo parent={this.state.parent} />;
			case "Психологическая работа": return <ParentalPsychology parent={this.state.parent} />;
			case "Задачи": return <ParentalTasks parent={this.state.parent} />;
		}
	}
	selectTab = (tab) => {
		this.setState({currentTab: tab})
	}
	tabs = () => {
		return ['Информация', 'Психологическая работа'/*, 'Задачи'*/]
	}
	render() {
		const tabs = this.tabs()
		const { parent } = this.state;
		return <>
			<Template
				page={'parental'}
				title={this.state.id === 0 ? 'Добавление родителя' : `${parent.fullName}`}
				controlPanel={
					<div className="control-panel">
						<Link to={`${this.state.id}/edit`}><i className="bi bi-pencil-square"></i></Link>
					</div>
				}
				backLink={'/parental'}>
				<div className="parental_container">
					<div className="parent-person">
						<div className="parent">
							<div className="parent_block">
								<span>{this.state.id > 0 ? parent.fullName : null}</span>
								<span>Родитель/опекун</span>
							</div>
							{parent.childs && parent.childs.length > 0 ? parent.childs.map((c, i) => { return (
								<Link to={`/pupil/${c.id}`} key={i}>
									<div className="parent_block children">
										<div className="avatar">
											<img src={`${API.assets}/childs/${c.photo}`} alt="" />
											{/* <i className="bi bi-person-fill form-no-photo"></i> */}
										</div>
										<span>{c.fullName}</span>
										<span>Название программы</span>
									</div>
								</Link>
							)}) : <div className="parent_block">
								<span>Воспитанник не выбран</span>
							</div>}
							{/* <div className="parent_block">
								<span>-</span>
								<span>Психолог</span>
							</div> */}
						</div>
					</div>
					<div className="parental_tabs">
						<div className="full-width-tabs">
						{tabs.map((tab, index) => (
							<div key={index} onClick={() => this.selectTab(tab)} className={tab == (this.state.currentTab ?? tabs[0]) ? "tab selected" : "tab"}>
								{tab}
							</div>
						))}
						</div>
					</div>
					{this.getCurrentTab()}
				</div>
			<Alert />
		</Template></>
	}
}

ParentScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default ParentScreen;