import Http from '../../Globals/Http';

const get		= async (childId)		=> await Http.get('childschooljournals', {conditions:[{k:'childId',v:childId}]});
const getRate		= async (Id)		=> await Http.get('childschooljournals', {conditions:[{k:'Id',v:Id}]});

const add		= async (data)			=> await Http.post('childschooljournals', {data});
const update	= async (id, data)	=> await Http.put('childschooljournals', {data,conditions:[{k:'id',v:id}]});

const remove = async (id) => await Http.remove('childschooljournals', {conditions:[{ k: 'id', v: id }]});

export {
	get,
	getRate,
	add,
	update,
	remove
}