import { Component } from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {
  Childs,
  ChildCorrectionHandkerchiefs,
  Teachers,
  Events,
} from '../../Models';

// helpers
import { Helpers, Errors, Utils, LS } from '../../Globals/index';

// globals
import { eventMessageName } from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ChildCorrectionHandkerchiefScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id || 0),
      user: null,
      data: {},
      child: {},
      notfound: null,
      loading: true,
    };
  }
  componentDidMount = async () => {
    const user = Helpers.authCheck();
    if (this.state.id) {
      const child = await Childs.get(this.state.id);
      const areaId = parseInt(LS.get('areaId'));
      const teachers = await Teachers.getAllWithoutType(areaId);
      if (child.data && child.data.length) {
        const handkerchief = await ChildCorrectionHandkerchiefs.get(
          this.state.id
        );
        if (handkerchief.data) {
          handkerchief.data.map((el) => {
            return (el.comment = el.comment.replace(/&#13;|&#013;/g, '\n'));
          });
        }
        this.setState({
          data: handkerchief.data || [],
          child: child.data[0],
          notfound: false,
          teachers: teachers.data,
        });

        // Add events in log
        const fullName =
          ' ' +
          child.data[0].lastName +
          ' ' +
          child.data[0].firstName +
          ' ' +
          child.data[0].middleName;
        Events.add(
          user.id,
          eventMessageName.viewCorrectionChildHandkerchiefPage + fullName,
          null,
          null,
          null,
          2
        );
      } else this.setState({ notfound: true });
    } else this.setState({ notfound: false });
    this.setState({ user, loading: false });
  };
  formHandle = (e) => this.setState({ [e.target.name]: e.target.value });
  save = async (e) => {
    e.preventDefault();
    const { id, data, color, comment, teachersId } = this.state;

    const d = {
      color: color,
      comment: comment,
      teachersId: teachersId,
      dateModify: Utils.now(),
    };

    if (data) {
      d.childId = id;
      const res = await ChildCorrectionHandkerchiefs.add(d).catch(() =>
        this.error()
      );

      // Add events in log
      const getDataModel = (await Childs.get(id)).data[0];
      const fullName =
        ' ' +
        getDataModel.lastName +
        ' ' +
        getDataModel.firstName +
        ' ' +
        getDataModel.middleName;
      const newData = d;
      Events.add(
        this.state.user.id,
        eventMessageName.addNewHandkerchiefCorrection + fullName,
        null,
        JSON.stringify(newData),
        null,
        3
      );

      if (res) Errors.add();
      else return false;
    }
    this.props.history.push(`/pupil/${id}`);
    return false;
  };
  getTeacher = (id) => {
    if (id) {
      const teacher = this.state.teachers.find((e) => e.id === id);
      if (teacher) {
        const nameTeacher =
          teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
        return nameTeacher;
      } else {
        return null;
      }
    }
  };
  error = () => Errors.error();
  render() {
    return (
      <Template
        page={'departments'}
        title={'Платок ребенка'}
        controlPanel={
          <div className="form-control-panel">
            <button form="correction-form" type="submit">
              Сохранить
            </button>
          </div>
        }
        backLink={`/pupil/${this.props.match.params.id}`}
      >
        {this.state.loading ? (
          <Loader />
        ) : this.state.notfound === null ? null : this.state.notfound ? (
          <NotFound back={`/pupil/${this.props.match.params.id}`} />
        ) : (
          <form
            id="correction-form"
            className="form correction-handkerchief"
            onSubmit={this.save}
          >
            <div className="form-row">
              <label>имя ребенка</label>
              <h4>
                {this.state.child.lastName} {this.state.child.firstName}{' '}
                {this.state.child.middleName}
              </h4>
            </div>
            <div className="form-row">
              <label htmlFor="color">Цвет платка</label>
              <input
                type="text"
                name="color"
                id="color"
                placeholder="Напишите цвет"
                required
                onChange={this.formHandle}
                value={this.state.data.color}
              />
            </div>
            <div className="form-row">
              <label htmlFor="comment">Комментарий</label>
              <textarea
                name="comment"
                id="comment"
                placeholder="Оставьте комментарий"
                className="large"
                onChange={this.formHandle}
              >
                {this.state.data.comment}
              </textarea>
            </div>
            <div className="form-row">
              <label htmlFor="teachersId">Ответственный</label>
              <select
                name="teachersId"
                id="teachersId"
                onChange={this.formHandle}
                required
                defaultValue={this.state.data.teachersId}
              >
                <option></option>
                {this.state.teachers.map((v, i) => (
                  <option key={i} value={v.id}>
                    {v.lastName} {v.firstName} {v.middleName}
                  </option>
                ))}
              </select>
            </div>
            <div className="list-handkerchief">
              {this.state.data.map((v, i) => (
                <div key={i} className="list-handkerchief-item">
                  <div className="name">
                    <span>{Moment(v.dateCreate * 1000).format('DD.MM.y')}</span>
                    <b>Платок:</b> {v.color}
                  </div>
                  <div className="name">
                    <b>Комментарий:</b> {v.comment}
                  </div>
                  <div className="name">
                    <b>Ответственный:</b> {this.getTeacher(v.teachersId)}
                  </div>
                </div>
              ))}
            </div>
          </form>
        )}
        <Alert />
      </Template>
    );
  }
}

ChildCorrectionHandkerchiefScreen.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default ChildCorrectionHandkerchiefScreen;
