import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
//import Start					from './Screens/Start';
import Login					from './Screens/Login';

import Error					from './Screens/Error';

import Departments				from './Screens/Departments';

import Peoples					from './Screens/Peoples';
import Childs					from './Screens/Childs';
import Child					from './Screens/Child';
import Parents					from './Screens/Parents';
import Parent					from './Screens/Parental/Parent';
import EditParent				from './Screens/Parent';
import Users					from './Screens/Users';
import User						from './Screens/User';
import Teachers					from './Screens/Teachers';
import Teacher					from './Screens/Teacher';
import Employees				from './Screens/Employees';
import Employee					from './Screens/Employee';
import Volunteers				from './Screens/Volunteers';
import Volunteer				from './Screens/Volunteer';
import PeoplesExport			from './Screens/PeoplesExport';
import PsychologyExport			from './Screens/PsychologyExport';
import CorrectionExport			from './Screens/CorrectionExport';
import EducationExport			from './Screens/EducationExport';
import ArchiveExport			from './Screens/ArchiveExport';
import MedicExport				from './Screens/MedicExport';
import SportExport				from './Screens/SportExport';

import Sport					from './Screens/Sport';
import SportChilds				from './Screens/SportChilds';
import SportPlan				from './Screens/SportPlan';
import SportStandarts			from './Screens/SportStandarts';
import ChildSportPhysic			from './Screens/ChildSportPhysic';
import ChildSportSections		from './Screens/ChildSportSections';
import ChildSportAnamnesis		from './Screens/ChildSportAnamnesis';
import ChildSportEffects		from './Screens/ChildSportEffects';

import Methodic					from './Screens/Methodic';
import MethodicLiterature		from './Screens/MethodicLiterature';
import MethodicProgram			from './Screens/MethodicProgram';

import Medic					from './Screens/Medic';
import MedicChilds				from './Screens/MedicChilds';
import ChildMedicAnamnesis		from './Screens/ChildMedicAnamnesis';
import ChildMedicDiseases		from './Screens/ChildMedicDiseases';
import ChildMedicPills			from './Screens/ChildMedicPills';
import ChildMedicTraumas		from './Screens/ChildMedicTraumas';
import ChildMedicInspections	from './Screens/ChildMedicInspections';
import ChildMedicF026			from './Screens/ChildMedicF026';
import ChildMedicCard			from './Screens/ChildMedicCard';
import MedicPurchases			from './Screens/MedicPurchases';

import Archive					from './Screens/Archive';
import ArchiveChilds			from './Screens/ArchiveChilds';
import ArchiveParents			from './Screens/ArchiveParents';
import ArchiveTeachers			from './Screens/ArchiveTeachers';

import EducationPersonal		from './Screens/EducationPersonal';

import Education				from './Screens/Education';
import EducationChilds			from './Screens/EducationChilds';
import EducationAttestat		from './Screens/EducationAttestat';
import EducationJournal			from './Screens/EducationJournal';
// import EducationBooks			from './Screens/EducationBooks';
import EducationBook			from './Screens/EducationBook';
// import EducationNotify			from './Screens/EducationNotify';

import Search						from './Screens/Search';

import HR						from './Screens/HR';
import HRTeachers				from './Screens/HRTeachers';
import HRTeacher				from './Screens/HRTeacher';
import HRDocuments				from './Screens/HRDocuments';
import HRDocumentVacation		from './Screens/HRDocumentVacation';
import HRDocumentMaternityLeave	from './Screens/HRDocumentMaternityLeave';
import HRDocumentFines			from './Screens/HRDocumentFines';
import HRDocumentEncouragement	from './Screens/HRDocumentEncouragement';
import HRDocumentExplanatory	from './Screens/HRDocumentExplanatory';
import HRDocumentContract		from './Screens/HRDocumentContract';
// import HRTimesheet				from './Screens/HRTimesheet';
import HRStafftable				from './Screens/HRStafftable';
import HRDecree					from './Screens/HRDecree';

import Household				from './Screens/Household';
import HouseholdActs			from './Screens/HouseholdActs';
import HouseholdTransport		from './Screens/HouseholdTransport';
// import HouseholdInventory		from './Screens/HouseholdInventory';
import HouseholdPurchases		from './Screens/HouseholdPurchases';

import Office					from './Screens/Office';
import OfficeMails				from './Screens/OfficeMails';
import OfficeMail				from './Screens/OfficeMail';
import OfficeStatistics			from './Screens/OfficeStatistics';
import OfficeCourtMails			from './Screens/OfficeCourtMails';
import OfficeCourtMail			from './Screens/OfficeCourtMail';
import OfficeContractors		from './Screens/OfficeContractors';

import Psychology					from './Screens/Psychology';
import PsychologyChilds				from './Screens/PsychologyChilds';
import PsychologyParents			from './Screens/PsychologyParents';
import PsychologyChildsGroup		from './Screens/PsychologyChildsGroup';
import PsychologyChildsGirlsGroup	from './Screens/PsychologyChildsGirlsGroup';
import ChildPsychologyAnamnesis		from './Screens/ChildPsychologyAnamnesis';
import ChildPsychologyDiagnostic	from './Screens/ChildPsychologyDiagnostic';
import ChildPsychologyIndividual	from './Screens/ChildPsychologyIndividual';
import ChildPsychologyCall			from './Screens/ChildPsychologyCall';
import ChildPsychologyDynamic		from './Screens/ChildPsychologyDynamic';
import ParentPsychologyAnamnesis	from './Screens/ParentPsychologyAnamnesis';
import ParentPsychologySessions		from './Screens/ParentPsychologySessions';
import ParentPsychologyIndividual	from './Screens/ParentPsychologyIndividual';
import ParentPsychologyIntensive	from './Screens/ParentPsychologyIntensive';
import ParentPsychologySchool		from './Screens/ParentPsychologySchool';

import Correction				from './Screens/Correction';
import CorrectionRules			from './Screens/CorrectionRules';
import CorrectionChilds			from './Screens/CorrectionChilds';
import CorrectionChildsGroup	from './Screens/CorrectionChildsGroup';
import CorrectionChildsGirlsGroup	from './Screens/CorrectionChildsGirlsGroup';
import ChildCorrectionAnamnesis	from './Screens/ChildCorrectionAnamnesis';
import ChildCorrectionDynamic	from './Screens/ChildCorrectionDynamic';
import ChildCorrectionPhotoDynamic	from './Screens/ChildCorrectionPhotoDynamic';
import ChildCorrectionCall		from './Screens/ChildCorrectionCall';
import ChildComments			from './Screens/ChildComments';
import ChildCommentsPsy			from './Screens/ChildCommentsPsy';
import ChildCorrectionEffect	from './Screens/ChildCorrectionEffect';
import CorrectionTimetable		from './Screens/CorrectionTimetable';
import CorrectionFunction		from './Screens/CorrectionFunction';
import CorrectionTaskmg			from './Screens/CorrectionTaskmg';
import ChildCorrectionHandkerchief	from './Screens/ChildCorrectionHandkerchief';

import Notifications			from './Screens/Notifications';

import Groups					from './Screens/Groups';
import Group					from './Screens/Group';
import Roles					from './Screens/Roles';
import Role						from './Screens/Role';
import Settings					from './Screens/Settings';
import Setting					from './Screens/Setting';
import Areas					from './Screens/Areas';
import Area						from './Screens/Area';
import Events					from './Screens/Events';
import Event					from './Screens/Event';

import Programs 				from './Screens/Programs';
import Program 					from './Screens/Programs/Program';
import ProgramsList 			from './Screens/Programs/Programs';
import TasksScreen 				from './Screens/Tasks';
import ChatScreen 				from './Screens/Chat';
import PupilsScreen 			from './Screens/Pupils';
import PupilScreen 				from './Screens/Pupils/Pupil';
import ParentalScreen 			from './Screens/Parental';
import NewsScreen 				from './Screens/News';
import TrainingScreen 			from './Screens/Training';
import PsychologyTasks 			from './Screens/Tasks/PsychologyTasks';
import CorrectionTasks 			from './Screens/Tasks/CorrectionTasks';
import OtherTasks 				from './Screens/Tasks/OtherTasks';
import AlertsScreen 			from './Screens/Alerts';


// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={NewsScreen} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/notifications' component={Notifications} exact />

			<Route path='/departments' component={Departments} exact />

			<Route path='/peoples' component={Peoples} exact />
			<Route path='/childs' component={Childs} exact />
			<Route path='/pupil' component={Child} exact />
			<Route path='/pupil/:id/edit' component={Child} exact />
			<Route path='/parents' component={Parents} exact />
			<Route path='/parent' component={EditParent} exact />
			<Route path='/parent/:id/edit' component={EditParent} exact />
			<Route path='/parent/:id' component={Parent} exact />
			<Route path='/users' component={Users} exact />
			<Route path='/user' component={User} exact />
			<Route path='/user/:id' component={User} exact />
			<Route path='/teachers' component={Teachers} exact />
			<Route path='/teacher' component={Teacher} exact />
			<Route path='/teacher/:id' component={Teacher} exact />
			<Route path='/employees' component={Employees} exact />
			<Route path='/employee' component={Employee} exact />
			<Route path='/employee/:id' component={Employee} exact />
			<Route path='/volunteers' component={Volunteers} exact />
			<Route path='/volunteer' component={Volunteer} exact />
			<Route path='/volunteer/:id' component={Volunteer} exact />
			<Route path='/peoplesexport' component={PeoplesExport} exact />
			<Route path='/psychologyexport' component={PsychologyExport} exact />
			<Route path='/correctionexport' component={CorrectionExport} exact />
			<Route path='/educationexport' component={EducationExport} exact />
			<Route path='/archiveexport' component={ArchiveExport} exact />
			<Route path='/medicexport' component={MedicExport} exact />
			<Route path='/sportexport' component={SportExport} exact />

			<Route path='/sport' component={Sport} exact />
			<Route path='/sport-childs' component={SportChilds} exact />
			<Route path='/sport-anamnesis/:id' component={ChildSportAnamnesis} exact />
			<Route path='/sport-plan' component={SportPlan} exact />
			<Route path='/sport-standarts' component={SportStandarts} exact />
			<Route path='/sport-physic/:id' component={ChildSportPhysic} exact />
			<Route path='/sport-sections/:id' component={ChildSportSections} exact />
			<Route path='/sport-effects/:id' component={ChildSportEffects} exact />

			<Route path='/methodic' component={Methodic} exact />
			<Route path='/methodic-lit' component={MethodicLiterature} exact />
			<Route path='/methodic-program' component={MethodicProgram} exact />

			<Route path='/medic' component={Medic} exact />
			<Route path='/medic-childs' component={MedicChilds} exact />
			<Route path='/medic-anamnesis/:id' component={ChildMedicAnamnesis} exact />
			<Route path='/medic-disease/:id' component={ChildMedicDiseases} exact />
			<Route path='/medic-pills/:id' component={ChildMedicPills} exact />
			<Route path='/medic-trauma/:id' component={ChildMedicTraumas} exact />
			<Route path='/medic-inspection/:id' component={ChildMedicInspections} exact />
			<Route path='/medic-f026/:id' component={ChildMedicF026} exact />
			<Route path='/medic-card/:id' component={ChildMedicCard} exact />
			<Route path='/medic-purchases' component={MedicPurchases} exact />
			
			<Route path='/archive' component={Archive} exact />
			<Route path='/archive-childs' component={ArchiveChilds} exact />
			<Route path='/archive-parents' component={ArchiveParents} exact />
			<Route path='/archive-teachers' component={ArchiveTeachers} exact />

			<Route path='/education-personal' component={EducationPersonal} exact />

			<Route path='/education' component={Education} exact />
			<Route path='/education-childs' component={EducationChilds} exact />
			<Route path='/education-attestat/:id' component={EducationAttestat} exact />
			<Route path='/education-journal/:id' component={EducationJournal} exact />
			{/* <Route path='/education-books' component={EducationBooks} exact /> */}
			<Route path='/education-book' component={EducationBook} exact />
			<Route path='/education-book/:id' component={EducationBook} exact />
			{/* <Route path='/education-notify' component={EducationNotify} exact /> */}

			<Route path='/hr' component={HR} exact />
			<Route path='/hr-teachers' component={HRTeachers} exact />
			<Route path='/hr-teacher' component={HRTeacher} exact />
			<Route path='/hr-teacher/:id' component={HRTeacher} exact />
			<Route path='/hr-documents' component={HRDocuments} exact />
			<Route path='/hr-vacation/:id' component={HRDocumentVacation} exact />
			<Route path='/hr-maternityleave/:id' component={HRDocumentMaternityLeave} exact />
			<Route path='/hr-fines/:id' component={HRDocumentFines} exact />
			<Route path='/hr-encouragement/:id' component={HRDocumentEncouragement} exact />
			<Route path='/hr-explanatory/:id' component={HRDocumentExplanatory} exact />
			<Route path='/hr-contract/:id' component={HRDocumentContract} exact />
			{/* <Route path='/hr-timesheet' component={HRTimesheet} exact /> */}
			<Route path='/hr-stafftable' component={HRStafftable} exact />
			<Route path='/hr-decree' component={HRDecree} exact />

			<Route path='/household' component={Household} exact />
			<Route path='/household-acts' component={HouseholdActs} exact />
			<Route path='/household-transport' component={HouseholdTransport} exact />
			{/* <Route path='/household-inventory' component={HouseholdInventory} exact /> */}
			<Route path='/household-purchases' component={HouseholdPurchases} exact />

			<Route path='/office' component={Office} exact />
			<Route path='/office-mails' component={OfficeMails} exact />
			<Route path='/office-mail' component={OfficeMail} exact />
			<Route path='/office-mail/:id' component={OfficeMail} exact />
			<Route path='/office-statistics' component={OfficeStatistics} exact />
			<Route path='/office-court-mails' component={OfficeCourtMails} exact />
			<Route path='/office-court-mail' component={OfficeCourtMail} exact />
			<Route path='/office-court-mail/:id' component={OfficeCourtMail} exact />
			<Route path='/office-contractors' component={OfficeContractors} exact />

			<Route path='/psychology' component={Psychology} exact />
			<Route path='/psychology-childs' component={PsychologyChilds} exact />
			<Route path='/psychology-parents' component={PsychologyParents} exact />
			<Route path='/psychology-group' component={PsychologyChildsGroup} exact />
			<Route path='/psychology-group-girls' component={PsychologyChildsGirlsGroup} exact />
			<Route path='/psychology-parent-anamnesis/:id' component={ParentPsychologyAnamnesis} exact />
			<Route path='/psychology-parent-sessions/:id' component={ParentPsychologySessions} exact />
			<Route path='/psychology-parent-individual/:id' component={ParentPsychologyIndividual} exact />
			<Route path='/psychology-parent-individual/:id' component={ParentPsychologyIndividual} exact />
			<Route path='/psychology-parent-intensive/:id' component={ParentPsychologyIntensive} exact />
			<Route path='/psychology-parent-school/:id' component={ParentPsychologySchool} exact />
			<Route path='/psychology-anamnesis/:id' component={ChildPsychologyAnamnesis} exact />
			<Route path='/psychology-diagnostic/:id' component={ChildPsychologyDiagnostic} exact />
			<Route path='/psychology-individual/:id' component={ChildPsychologyIndividual} exact />
			<Route path='/psychology-call/:id' component={ChildPsychologyCall} exact />
			<Route path='/psychology-dynamic/:id' component={ChildPsychologyDynamic} exact />

			<Route path='/correction' component={Correction} exact />
			<Route path='/correction-rules' component={CorrectionRules} exact />
			<Route path='/correction-childs' component={CorrectionChilds} exact />
			<Route path='/correction-group' component={CorrectionChildsGroup} exact />
			<Route path='/correction-group-girls' component={CorrectionChildsGirlsGroup} exact />
			<Route path='/correction-anamnesis/:id' component={ChildCorrectionAnamnesis} exact />
			<Route path='/correction-dynamic/:id' component={ChildCorrectionDynamic} exact />
			<Route path='/correction-photo-dynamic/:id' component={ChildCorrectionPhotoDynamic} exact />
			<Route path='/correction-call/:id' component={ChildCorrectionCall} exact />
			<Route path='/comments/:id' component={ChildComments} exact />
			<Route path='/commentspsy/:id' component={ChildCommentsPsy} exact />
			<Route path='/correction-effect/:id' component={ChildCorrectionEffect} exact />
			<Route path='/correction-timetable' component={CorrectionTimetable} exact />
			<Route path='/correction-function/:id' component={CorrectionFunction} exact />
			<Route path='/correction-taskmg/:id' component={CorrectionTaskmg} exact />
			<Route path='/correction-handkerchief/:id' component={ChildCorrectionHandkerchief} exact />

			<Route path='/groups' component={Groups} exact />
			<Route path='/group' component={Group} exact />
			<Route path='/group/:id' component={Group} exact />
			<Route path='/roles' component={Roles} exact />
			<Route path='/role' component={Role} exact />
			<Route path='/role/:id' component={Role} exact />
			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/setting/:id' component={Setting} exact />
			<Route path='/areas' component={Areas} exact />
			<Route path='/area' component={Area} exact />
			<Route path='/area/:id' component={Area} exact />
			<Route path='/events' component={Events} exact />
			<Route path='/event/:id' component={Event} exact />
			<Route path='/alerts' component={AlertsScreen} exact />


			<Route path='/programs' component={Programs} exact />
			<Route path='/programs-list' component={ProgramsList} exact />
			<Route path='/programs-edit/:id' component={Program} exact />
			<Route path='/programs-edit' component={Program} exact />
			<Route path='/tasks/:id' component={TasksScreen} exact />
			<Route path='/chat' component={ChatScreen} exact />
			<Route path='/pupils' component={PupilsScreen} exact />
			<Route path='/pupil' component={PupilScreen} exact />
			<Route path='/pupil/:id' component={PupilScreen} exact />
			<Route path='/parental' component={ParentalScreen} exact />
			<Route path='/parent' component={Parent} exact />
			<Route path='/parent/:id' component={Parent} exact />
			<Route path='/news' component={NewsScreen} exact />
			<Route path='/training' component={TrainingScreen} exact />
			<Route path='/psychology-tasks' component={PsychologyTasks} exact />
			<Route path='/correction-tasks' component={CorrectionTasks} exact />
			<Route path='/other-tasks' component={OtherTasks} exact />
			<Route path='/search' component={Search} exact />


			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;