import { useRef, useState } from "react";
import { PropTypes } from 'prop-types';
import { journalLinksPsy, journalType, journalTypeName } from "../../../Globals/Constants";
import Card from "../../Tasks/Card";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Anamnes from "../../Tasks/Anamnes";

const PupilPsychology = ({ pupil }) => {
    const tabs = [journalType.COMMENTSPSY, journalType.DIAGNOSTIC, journalType.CALL, journalType.DYNAMIC];
    const [tab, setTab] = useState(tabs[0]);
    const containerRef = useRef(null);
    const capitalizeCamelCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <div className="pupil_info">
            <div className="left_side">
                <div className="tabs">
                    <ul>
                        {tabs.map((t, i) => t === tab ? <li key={i} className={"active"} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li> : <li key={i} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li>)}
                    </ul>
                </div>
                <div className="form">
                    <Link to={`/${journalLinksPsy[tab - 1]}/${pupil.id}`}><button className="default_button">Добавить запись</button></Link>
                </div>
                <div className="notes">
                    <Card childId={pupil.id} type={tab} iscorrection={false}></Card>
                </div>
            </div>
            <div className='right_side'>
                <div className="form" ref={containerRef}>
                    <div className="left-part" style={{width: "30%"}}>
                        <div className="comment">Анамнез:</div>
                    </div>
                    <div className="right-part" style={{width: "70%"}}>
                        <div className="form-section">
                            <Anamnes childId={pupil.id} type="psychology" />
                            <Link to={`/psychology-anamnesis/${pupil.id}`}><button className="default_button">Изменить</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


PupilPsychology.propTypes = {
    pupil: PropTypes.object.isRequired,
};


export default PupilPsychology;