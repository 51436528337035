import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const login		= async (username, password)	=> await Http.request('users', 'login', {conditions:[{k:'username',v:username},{k:'password',v:password}]});

const get		= async (id)			=> await Http.get('users', {conditions:[{k:'id',v:id}]});
const getAll	= async ()				=> await Http.get('users', {orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('users', {data});
const update	= async (id, data)		=> await Http.put('users', {data,conditions:[{k:'id',v:id}]});

export {
	login,
	getAll,
	get,
	add,
	update
}