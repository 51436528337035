import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Childs,Events} from '../../Models';

// helpers
import {Helpers,LS} from '../../Globals/index';

// globals
import {API,comonStatus,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class ArchiveChildsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:null,
			user:null,
			notfound:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user});
		await this.dataGet();

		// Add events in log
		Events.add(user.id, eventMessageName.viewArchiveChildPage, null, null, null, 2);
	}
	dataGet = async () => {
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getInactiveAll(areaId);
		this.setState({data:childs.data,loading:false});
	}
	active = async (id) => {
		const d = {status:comonStatus.ACTIVE};
		// Add events in log
		// get old data before update
		const dataModel = await Childs.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
		const oldData = getOldData(d);
		const newData = d;

		await Childs.update(id, d);

		// Add events in log
		const fullName = ' ' + dataModel.data[0].lastName + ' ' + dataModel.data[0].firstName + ' ' + dataModel.data[0].middleName;
		Events.add(this.state.user.id, eventMessageName.updateArchiveChild + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
		this.dataGet();
	}
	render() {
		return <Template page={'departments'} title={'Дети'} backLink={'/archive'}>
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-archive-child-item">
					<div className="avatar">
						{v.photo ? <img src={`${API.assets}/childs/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
					</div>
					<div className="name">{v.lastName} {v.firstName} {v.middleName}</div>
					<div className="buttons">
						<i className="bi bi-toggle2-off" onClick={() => this.active(v.id)}></i>
					</div>
				</div>)
			}
		</Template>
	}
}

export default ArchiveChildsScreen;