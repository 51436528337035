import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('trainingusers', {conditions:[{k:'id',v:id}]});
const getAll	        = async (areaId)	=> await Http.get('trainingusers', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateCreate',isd:requestOderType.ASC}]});
const getByUserId 		= async (userId) 	=> await Http.get('trainingusers', {conditions:[{k:'userId',v:userId}]})

const add		        = async (data)		=> await Http.post('trainingusers', {data});
const update	        = async (userId, trainingId, data)	=> await Http.put('trainingusers', {data,conditions:[{k:'userId',v:userId},{k:'trainingId',v:trainingId}]});

export {
	getAll,
	get,
	add,
	update,
	getByUserId
}