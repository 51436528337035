import { Component } from 'react';

// components
import Template from '../../Components/Template';
import Alert from '../../Components/Alert';

// models
import { Events } from '../../Models';

// helpers
import { Helpers } from '../../Globals/index';

// styles
import './styles.css';

// globals
import { API, eventMessageName } from '../../Globals/Constants';

//vars
const listExport = [
  {
    name: 'Экспорт всех оценок',
    idexport: 1,
    typeExportDb: 'childschooljournals',
  },
];

class EducationExportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
      export: false,
      list: [],
      startDate: '',
      endDate: '',
      timePeriod: 'month',
      data: {
        startDate: '',
        endDate: '',
      },
    };
  }

  componentDidMount = async () => {
    const user = Helpers.authCheck();
    this.setState({ user, loading: false });
  };

  formHandle = (e) => {
    const { name, value } = e.target;
    if (name === 'timePeriod') {
      const today = new Date();
      const endDate = today.toISOString().split('T')[0];
      let startDate;
      if (value === 'year') {
        startDate = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        )
          .toISOString()
          .split('T')[0];
      } else {
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
        startDate = firstDayOfMonth.toISOString().split('T')[0];
      }
      this.setState({
        data: { ...this.state.data, startDate, endDate, [name]: value },
      });
    } else {
      this.setState({ data: { ...this.state.data, [name]: value } });
    }
  };

  save = (e) => {
    console.log(this.state.data, listExport);
    e.preventDefault();
    this.setState({ export: true });

    if (!this.state.export) {
      const idexport = listExport[0].idexport;
      const typeExportDb = listExport[0].typeExportDb;
      const { startDate, endDate } = this.state;

      const uri = `${API.documentsExcel}/?idexport=${idexport}&t=${typeExportDb}&asort=fullName&fn=fileout.xlsx&startDate=${startDate}&endDate=${endDate}`;

      console.log(uri);

      // Add events in log
      const nameExport = ' ' + listExport[0].name;
      Events.add(
        this.state.user.id,
        eventMessageName.dowloadCorrectionExport + nameExport,
        null,
        null,
        null,
        6
      );

      window.location.href = uri;
    }
  };

  render() {
    return (
      <Template page={'departments'} title={'Экспорт'} backLink={'/peoples'}>
        <form id="child-form" className="form" onSubmit={this.save}>
          <div className="form-row form-row-block">
            <div className="form-row-block-inner correction-export-row">
              <div className="form-row" style={{ width: '50%' }}>
                <label htmlFor="status">
                  Выберите что нужно экспортировать
                </label>
                <select
                  name="status"
                  id="status"
                  onChange={this.formHandle}
                  required
                >
                  {listExport.map((v, i) => (
                    <option key={i} value={i}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-row" style={{ width: '50%' }}>
                <label htmlFor="timePeriod">Выберите промежуток времени</label>
                <select
                  name="timePeriod"
                  id="timePeriod"
                  onChange={this.formHandle}
                  required
                >
                  <option value="self">Свой период</option>
                  <option value="month">За месяц</option>
                  <option value="year">За год</option>
                </select>
              </div>
              <div className="form-row" style={{ width: '50%' }}>
                <label htmlFor="startDate">Начальная дата:</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={this.state.data.startDate}
                  onChange={this.formHandle}
                  required
                  style={{ width: '100%' }}
                />
              </div>
              <div className="form-row" style={{ width: '50%' }}>
                <label htmlFor="endDate">Конечная дата:</label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={this.state.data.endDate}
                  onChange={this.formHandle}
                  required
                  style={{ width: '100%' }}
                />
              </div>
              {this.state.export ? (
                <button className="btn">Загрузка...</button>
              ) : (
                <button type="submit" className="btn">
                  Экспортировать
                </button>
              )}
            </div>
          </div>
        </form>
        <Alert />
      </Template>
    );
  }
}

export default EducationExportScreen;
