import { TimeSheet } from '../../Models';
import { LS } from '../index'

const authCheck = () => {
	const user = LS.get('user');
	if (user === null) {
		logoff();
		return null;
	}
	return user;
}

const logoff = () => {
	LS.clear();
	window.location.href = '/login';
	return false;
}

const canUseToday = async () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();
	const currentDay = currentDate.getDate();
	const user = authCheck();
	const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

	if (isAdmin(user)) return true;

	let canuse = false;

	if(user) {
		try {
			canuse = await TimeSheet.getUserByFullDate(user.id, currentYear, currentMonth, currentDay).then((res) => {
				const data = res.data[0];
				if (data?.dateFrom <= currentTimestampInSeconds && data?.dateTo >= currentTimestampInSeconds) {
					return true;
				} else {
					return false;
				}
			});
		} catch (err) {
			console.error(err);
			return canuse;
		}
	}
	return canuse;
}

// const hasPermission = (permission) => {
// 	const user = LS.get('user');
// 	if (user === null) return false;
// 	if (isAdmin(user)) return true;
	
// 	return false;
// }

const inRole = (role) => {
	const user = LS.get('user');
	if (user === null) return false;
	if (!isAdmin(user)) {

		console.log(user.roles);

		if (Array.isArray(role)) {
			return user.roles.find(f => role.includes(f));
		}
		return user.roles.find(f => f === role) !== undefined;
	}
	return true;
}

const isAdmin = (user) => {
	user = user || LS.get('user');
	if (user === null) return false;
	return !user.isteacher;
}

export {
	authCheck,
	inRole,
	isAdmin,
	canUseToday
}