import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';

// models
import {Teachers,NotificationMessages,Events} from '../../Models';

// helpers
import {Helpers,LS,Errors} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class NotificationsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:{},
			user:null,
			adddocumentshow:false,
			tab:2,
			teachers:[],
			readStatus:0,
			loading:true,
			loadingMessage:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const message = await NotificationMessages.get(user.id);
		this.setState({user,message:message.data,loadingMessage:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewNotificationPage, null, null, null, 4);
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	tabSet = (tab) => this.setState({tab});
	tabMessage = async () => {
		this.tabSet(2);
		const message = await NotificationMessages.get(this.state.user.id);
		this.setState({message:message.data,loadingMessage:false});
	}
	tabSend = async () => {
		this.tabSet(1);
		const areaId = parseInt(LS.get('areaId'));
		const teachers = await Teachers.getAllWithoutType(areaId);
		this.setState({teachers:teachers.data,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {data,user} = this.state;
		const d = {
			info:data.info,
			teacherId:data.teacherId,
			senderId:user.id,
			readStatus:this.state.readStatus
		};
		const res = await NotificationMessages.add(d).catch(() => this.error());

		// Add events in log
		const getDataModel = (await Teachers.get(d.teacherId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const newData = d;
		Events.add(this.state.user.id, eventMessageName.addNotificationMessage + fullName, null, JSON.stringify(newData), null, 3);

		if (res) Errors.update();
		return false;
	}
	error = () => Errors.error();
	getTeacher = (id) => {
		if (id) {
				const teacher = this.state.teachers.find(e => e.id === id);
				if (teacher) {
				const nameTeacher = teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
				return nameTeacher
			} else {
				return null
			}
		}
	}
	changeStatusMessage = async (curentStatus, id) => {
		const newStatus = curentStatus === 0 ? 1 : 0
		const d = {
			readStatus:newStatus
		};
		// Add events in log
		// get old data before update
		const dataModel = await NotificationMessages.getById(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
		const oldData = getOldData(d);
		const newData = d;

		const res = await NotificationMessages.update(id, d).catch(() => this.error());

		// Add events in log
		const getDataModel = (await Teachers.get(dataModel.data[0].teacherId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(this.state.user.id, eventMessageName.updateNotificationStatus + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
		this.tabMessage();
		if (res) Errors.update();
		return false;
	}
	render() {
		return <Template page={'notifications'} title={'Уведомления'}>
				<div className="notifications-list">
					<ul className="top-tabs">
						<li className={this.state.tab===2?'selected':null} onClick={() => this.tabMessage()}>Сообщения</li>
						<li className={this.state.tab===1?'selected':null} onClick={() => this.tabSend()}>Отправить</li>
					</ul>
			
					{this.state.tab === 1 ?
						<form id="notifications-form" className="form" onSubmit={this.save}>
							{this.state.loading ? <Loader /> :
								<div className="form-row form-row-block">
									<h3 onClick={() => this.adddocumentShow()}>Отправить сообщение</h3>
									{this.state.adddocumentshow ?
										<div className="form-row-block-inner">
											<div className="form-row-oneline">
												<div className="form-row">
													<label htmlFor="teacherId">Кому отправить</label>
													<select name="teacherId" id="teacherId" onChange={this.formHandle} required>
														<option></option>
														{this.state.teachers.map((v,i) => <option key={i} value={v.id}>{v.lastName} {v.firstName} {v.middleName}</option>)}
													</select>
												</div>
											</div>
											<div className="form-row">
												<label htmlFor="info">Сообщение</label>
												<textarea name="info" id="info" placeholder="Напишите сообщение" required onChange={this.formHandle}>{this.state.info}</textarea>
											</div>
											<button type="submit" className="btn">Отправить</button>
										</div> : null}
								</div>
							}
						</form>
					: null}

					{this.state.tab === 2 ?
						<>
							{this.state.loadingMessage ? <Loader /> :
								<div className="list-notifications">
									{this.state.message.length === 0 ? <div className="notfound">Список сообщений пуст</div> :
										this.state.message.map((v,i) => <div key={i} className={"list-notifications-item " +
													(v.readStatus === 0 ? 'list-notifications-item__unread' : null)}
													onClick={() => this.changeStatusMessage(v.readStatus, v.id)}>
												<div className="name">
													<span>{Moment(v.dateCreate*1000).format('DD.MM.y h:m')}</span>
													<b>Сообщение:</b> {v.info}
												</div>
												<div className="name">
													<b>От кого:</b> {this.getTeacher(v.senderId)}
												</div>
												<div className="name">
													<b>Статус сообщения:</b> {v.readStatus === 0 ? 'Непрочитанное' : 'Прочитанное'}
												</div>
											</div>
									)}
								</div>
							}
						</>
					: null}
				</div>
			<Alert />
		</Template>
	}
}

export default NotificationsScreen;