import Http from '../../Globals/Http';

const get		= async (childId)		=> await Http.get('childmedictraumas', {conditions:[{k:'childId',v:childId}]});

const add		= async (data)			=> await Http.post('childmedictraumas', {data});
const update	= async (childId, data)	=> await Http.put('childmedictraumas', {data,conditions:[{k:'childId',v:childId}]});

export {
	get,
	add,
	update
}