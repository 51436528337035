import {Component} from 'react';
import Template from '../../Components/Template';
import Alert from '../../Components/Alert';
import {Helpers,LS} from '../../Globals/index';
import './styles.scss';
import { ProgramTasks, Programs } from '../../Models';

class ProgramsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user		 : null,
			programId    : parseInt(this.props.match.params.id || 0),
			loading		 : true,
			programEditor: false,
			programs	 : [],
			programData  : {
				id		    : null,
                name		: null,
				tasks		: [],
			},
		};
	}

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		try {
			const programsResponse = await Programs.getAll();
			const programsData = programsResponse.data || [];
			const programsWithTasks = await Promise.all(
                programsData.map(async (program) => {
                    const tasksResponse = await ProgramTasks.get(program.id);
                    const tasks = tasksResponse.data?.map(({ id, name }) => ({
                        id,
                        value: name
                    }));
                    return { ...program, tasks };
                })
            );

            this.setState({
                user,
                areaId,
                loading: false,
                programs: programsWithTasks,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
	}

	openProgramEditor = (programId = null) => {
		if (programId && programId > 0) {
			//
		} else {
			this.setState({programEditor: true, programId, programData: {
				id: null,
				name: null,
				tasks: [],
				dateUntil: null,
			}});
		}
	}
	
	render() {
        const { programs } = this.state;
		return <>
			<Template
				page={'programs-list'}
				title={'Программы'}
				controlPanel={
					<div className="control-panel">
						{!this.state.programId || this.state.programId <= 0 ? <Link to="/programs-edit"><button onClick={() => this.openProgramEditor()}>+ Добавить программу</button></Link> : null}
					</div>
				}>
                    <div className="programs-list-container">
                        <div className="programs-list">
                            {programs?.map((item, i) => (<>
                                <Link to={`programs-edit/${item.id}`}>
                                    <div key={i} className="programs-item programs-item-list">
                                        <div className="item-block">
                                            <span>Название программы</span>
                                            <span>{item.name}</span>
                                        </div>
                                        <div className="item-block">
                                            <span>Задачи</span>
                                            <ul>
                                                {item.tasks?.map((task, i) => (
                                                    <li key={i}>{task.value}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="item-block">
                                            <span>Время выполнения задачи</span>
                                            <span>{item.countDays} дней</span>
                                        </div>
                                    </div>
                                </Link>
                            </>))}
                        </div>
                    </div>
			<Alert />
		</Template></>
	}
}

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
ProgramsList.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any,
};


export default ProgramsList;