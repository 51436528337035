import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';


// models
import {Events} from '../../Models';

// globals
import {eventMessageName} from '../../Globals/Constants';

// helpers
import {Helpers} from '../../Globals/index';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Дети',link:'childs',desc:'Список детей, данные и документы'},
	{name:'Родители',link:'parents',desc:'Список родителей, данные и документы'},
	{name:'Сотрудники',link:'employees',desc:'Список сотрудников, данные и документы'},
	{name:'Волонтеры',link:'volunteers',desc:'Список волонтеров, данные и документы'},
	{name:'Экспорт',link:'peoplesexport',desc:'Экспорт по выбранным параметрам'}
];

class PeoplesScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});
		
		// Add events in log
		Events.add(user.id, eventMessageName.viewPeoplePage, null, null, null, 2);
	}
	render() {
		return <Template page={'departments'} title={'Личное дело'}>
			{this.state.loading ? <Loader /> :
				<div className="peoples-list">
					{menu.map((v,i) => <Link to={`/${v.link}`} className="item" key={i}>
						<div className="item-title">{v.name}</div>
						<div className="item-description">{v.desc}</div>
					</Link>)}
				</div>
			}
		</Template>
	}
}

export default PeoplesScreen;