import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Учителя, сотрудники',link:'hr-teachers',desc:'Список учителей и сотрудников, данные и документы'},
	{name:'Документы',link:'hr-documents',desc:'Отпуски, декерты, договора, служебные записки'},
	{name:'Штатное расписание',link:'hr-stafftable',desc:'Cостав, численность и должности сотрудников'},
	{name:'Табель',link:'hr-timesheet',desc:'График работы учителей, сотрудников'},
	{name:'Приказы',link:'hr-decree',desc:'Документы внутреннего управления'}
];

class HRScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewHrPage, null, null, null, 2);
	}
	clickLinkTimesheet = () => {
		// Add events in log
		Events.add(this.state.user.id, eventMessageName.viewHrTimesheetPage, null, null, null, 2);
	}
	render() {
		return <Template page={'departments'} title={'Кадровая работа'}>
			{this.state.loading ? <Loader /> :
				<div className="hr-list">
					{menu.map((v,i) => {
						if (v.link==='hr-timesheet') {
							return <a onClick={() => {this.clickLinkTimesheet()}} href='https://docs.google.com/spreadsheets/d/1RRTDWYjrvWWhRx3c9MUD8n429GQUpGI8WnmV1TRsfl0/edit?usp=sharing' target="_blank" rel="noopener noreferrer" className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</a>
						} else {
							return <Link to={`/${v.link}`} className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</Link>
						}
					})}
				</div>
			}
		</Template>
	}
}

export default HRScreen;