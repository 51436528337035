import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (id)		=> await Http.get('officecourtmails', {conditions:[{k:'id',v:id}]});
const getAll	= async ()			=> await Http.get('officecourtmails', {orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)		=> await Http.post('officecourtmails', {data});
const update	= async (id, data)	=> await Http.put('officecourtmails', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update
}