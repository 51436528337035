import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// globals
import {eventMessageName} from '../../Globals/Constants';

// helpers
import {Helpers} from '../../Globals/index';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Успеваемость',link:'education-childs',desc:'Электронный дневник, справка об аттестации'},
	// {name:'Учебники',link:'education-books',desc:'Список учебников, литературы'},
	// {name:'Уведомление',link:'education-notify',desc:'Прикрепить документ'},
	{name:'Табель учителей',link:'hr-timesheet',desc:'График работы учителей, сотрудников'},
	{name:'Экспорт оценок',link:'educationexport',desc:'Экспорт оценок'}
];

class EducationScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewEducationPage, null, null, null, 2);
	}
	linkHandler = async () => {
        // Add events in log
        Events.add(this.state.user.id, eventMessageName.viewTabelTeacher, null, null, null, 2);
	}
	render() {
		return <Template page={'departments'} title={'Учебная часть'}>
			{this.state.loading ? <Loader /> :
				<div className="education-list">
					{menu.map((v,i) => {
						if (v.link==='hr-timesheet') {
							return <a onClick={() => this.linkHandler()} href='https://docs.google.com/spreadsheets/d/1PQhwCjbDYtdhmwsSZ3IsJbdI5BaGYiRYqq78eEAtjkg/edit?usp=sharing' target="_blank" rel="noopener noreferrer" className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</a>
						} else {
							return <Link to={`/${v.link}`} className="item" key={i}>
								<div className="item-title">{v.name}</div>
								<div className="item-description">{v.desc}</div>
							</Link>
						}
					})}
				</div>
			}
		</Template>
	}
}

export default EducationScreen;