import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const login		= async (areaId, username, password)	=> await Http.request('teachers', 'login', {conditions:[{k:'areaId',v:areaId},{k:'username',v:username},{k:'password',v:password}]});

const get		= async (id)			=> await Http.get('teachers', {conditions:[{k:'id',v:id}]});
const getAll	= async (areaId, typePeople)		=> await Http.get('teachers', {conditions:[{k:'areaId',v:areaId},{k:'typePeople',v:typePeople},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'lastName',isd:requestOderType.ASC}]});
const getAllWithoutType	= async (areaId)		=> await Http.get('teachers', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'lastName',isd:requestOderType.ASC}]});
const getInactiveAll	= async (areaId)	=> await Http.get('teachers', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.IN_ACTIVE}],orders:[{k:'lastName',isd:requestOderType.ASC}]});

const add		= async (data)			=> await Http.post('teachers', {data});
const update	= async (id, data)		=> await Http.put('teachers', {data,conditions:[{k:'id',v:id}]});

export {
	login,
	getAll,
	getAllWithoutType,
	getInactiveAll,
	get,
	add,
	update
}