import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (childId)	=> await Http.get('childparents', {conditions:[{k:'childId',v:childId}]});
const getAll	= async (areaId)	=> await Http.get('childparents', {conditions:[{k:'areaId',v:areaId}],orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)		=> await Http.post('childparents', {data});
const remove	= async (childId)	=> await Http.remove('childparents', {conditions:[{k:'childId',v:childId}]});

export {
	getAll,
	get,
	add,
	remove
}