import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Дети',link:'archive-childs',desc:'Список детей с неактивным статусом'},
	{name:'Родители',link:'archive-parents',desc:'Список родителей с неактивным статусом'},
	{name:'Учителя, сотрудники',link:'archive-teachers',desc:'Список сотрудников с неактивным статусом'},
	{name:'Экспорт',link:'archiveexport',desc:'Экспорт по выбранным параметрам'}
	// {name:'Документы',link:'archive-documents',desc:'Документы в архиве'}
];

class ArchiveScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewArchivePage, null, null, null, 2);
	}
	render() {
		return <Template page={'archive'} title={'Архив'}>
			{this.state.loading ? <Loader /> :
				<div className="archive-list">
					{menu.map((v,i) => <Link to={`/${v.link}`} className="item" key={i}>
						<div className="item-title">{v.name}</div>
						<div className="item-description">{v.desc}</div>
					</Link>)}
				</div>
			}
		</Template>
	}
}

export default ArchiveScreen;