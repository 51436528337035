import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DocumentViewer from '../../Components/DocumentViewer';
import Alert from '../../Components/Alert';

// models
import {OfficeContractorDocuments,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class OfficeContractorsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			documents:[],
			filedocument:null,
			doctype:null,
			doc:null,
			contractor:null,
			type:null,
			documentview:false,
			adddocumentshow:true,
			loading:true
		};
	}
	documentTypes = [
		{id:8,name:'Договор',type:'contract'},
		{id:9,name:'Акт приема передачи',type:'act'},
		{id:10,name:'Договор (расторжение)',type:'contract-fail'}
	];
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user});
		await this.documentsGet(false);

        // Add events in log
        Events.add(user.id, eventMessageName.viewOfficeContractorPage, null, null, null, 2);
	}
	documentsGet = async () => {
		const statuses = [8,9,10];
		const docs = await OfficeContractorDocuments.getAll();
		const documents = docs.data.filter(f => statuses.includes(f.type));
		this.setState({documents,adddocumentshow:documents.length?false:true,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	formHandle = (e) => this.setState({[e.target.name]:e.target.value});
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const document = (await OfficeContractorDocuments.getById(id)).data[0];
		const oldData = document;
		const linkDocument = ' ' + document.link;
		const nameContractor = ' ' + document.contractor;
		Events.add(user.id, eventMessageName.deleteOfficeContractorDocument + nameContractor + linkDocument, JSON.stringify(oldData), null, null, 7);
		
		await OfficeContractorDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true,adddocumentshow:false}, async () => await this.documentsGet(true));
	documentView = (d) => {
		const type = this.documentTypeGet(d.type).type;
		const doc = `${API.documents}/?p=departments/office/contractors/${type}&ft=${d.fileType}&fn=${d.link}`;
		const doctype = d.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const nameContractor = ' ' + d.contractor;
		const fileName = ' ' + d.link;
		Events.add(user.id, eventMessageName.viewOfficeContractorDocument + nameContractor + fileName, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = (d) => {
		const type = this.documentTypeGet(d.type).type;
		const uri = `${API.documents}/?p=departments/office/contractors/${type}&ft=${d.fileType}&fn=${d.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const nameContractor = ' ' + d.contractor;
		const fileName = ' ' + d.link;
		Events.add(user.id, eventMessageName.dowloadOfficeContractorDocument + nameContractor + fileName, null, null, null, 6);

		window.location.href = uri;
	}
	documentSave = async (e) => {
		e.preventDefault();
		const {filedocument,type,contractor} = this.state;
		const code = this.documentTypeGet(type).type;
		const d = {
			contractor,
			type,
			fileType:filedocument.type,
			link:filedocument.name
		};
		const res = await OfficeContractorDocuments.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		const linkDocument = ' ' + d.link;
		const nameContractor = ' ' + contractor;
		Events.add(this.state.user.id, eventMessageName.addOfficeContractor + nameContractor + linkDocument, null, JSON.stringify(newData), null, 3);

		if (res) {
			Errors.add();
			this.fileSave(`documents/departments/office/contractors/${code}`, filedocument);
			this.documentsReload();
		}
		return false;
	}
	error = () => Errors.error();
	fileSave = (path, file) => Files.upload(0, null, path, file);
	documentTypeGet = (type) => this.documentTypes.find(f => f.id === parseInt(type));
	render() {
		return <Template
					page={'departments'}
					title={'Контрагенты'}
					controlPanel={
						<div className="form-control-panel">
							<button form={'contractors-form'} type="submit">Сохранить</button>
						</div>
					}
					backLink={'/office'}>
			{this.state.loading ? <Loader /> :
				<form id={'contractors-form'} className="form" onSubmit={this.documentSave}>
					<div className="form-row form-row-block">
						<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
						{this.state.adddocumentshow ?
							<div className="form-row-block-inner">
								<div className="form-row">
									<label htmlFor="contractor">Контрагент</label>
									<input type="text" name="contractor" id="contractor" className="wide" required onChange={this.formHandle} value={this.state.contractor} />
								</div>
								<div className="form-row-oneline">
									<div className="form-row">
										<label htmlFor="file">Файл</label>
										<input onChange={this.fileChange} name="file" className="file" required type="file" />
									</div>
									<div className="form-row">
										<label htmlFor="type">Тип документа</label>
										<select name="type" id="type" onChange={this.formHandle} required>
											<option></option>
											{this.documentTypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
										</select>
									</div>
								</div>
							</div> : null}
					</div>
					{this.state.documents ?
						<div className="list-contractors-documents">
							{this.state.documents.map((v,i) => <div key={i} className="list-contractors-documents-item">
								<div className="name">
									<span>{Moment(v.dateCreate*1000).format('DD.MM.y, H:mm')} — {v.contractor}</span>
									{this.documentTypeGet(v.type).name}
								</div>
								<div className="buttons">
									<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
									<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
									<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
								</div>
							</div>)}
						</div> : null}
				</form>
			}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

export default OfficeContractorsScreen;