import Http from '../../Globals/Http';
import {requestConditionType,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('tasks', {conditions:[{k:'id',v:id}]});
const getByCategory	    = async (type)		=> await Http.get('tasks', {conditions:[{k:'status',v:0,op:requestConditionType.MORE},{k:'category',v:type}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getAll	        = async ()			=> await Http.get('tasks', {conditions:[{k:'status',v:0,op:requestConditionType.MORE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('tasks', {data});
const update	        = async (id, data)	=> await Http.put('tasks', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update,
	getByCategory
}