import { Component } from 'react';
import { Link } from 'react-router-dom';

// components
import Confirm from '../Confirm';

// helpers
import { Helpers, LS } from '../../Globals';

// styles
import './styles.css';
import PropTypes from 'prop-types';

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      alertShow: false,
      selected: null,
      loading: true,
      isOpenedUserInfo: false,
      isMenuCollapsed: false,
      search: '',
      canuse: false,
    };
  }
  componentDidMount = async () => {
    const user = await Helpers.authCheck();
    const canuse = await Helpers.canUseToday();
    if (user) this.setState({ user, loading: false });
    if (canuse) this.setState({ canuse });
  };
  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuCollapsed: !prevState.isMenuCollapsed,
    }));
  };
  selectCheck = (page) => (page === this.props.page ? 'selected' : null);
  selectInnerCheck = (pages) => {
    const page = window.location.href
      .replace(/^.*\/\/[^/]+/, '')
      .replace('/', '')
      .replace(/\/\d+$/, '');
    return Array.isArray(pages)
      ? pages.includes(page)
        ? 'selectedinner'
        : null
      : page === pages
      ? 'selectedinner'
      : null;
  };
  expand = (e) => {
    e.preventDefault();
    if (e.target.tagName === 'I') e.target = e.target.parentNode;
    const selected = e.target.href.replace(/^.*\/\/[^/]+/, '').replace('/', '');
    this.setState({ selected });
    return false;
  };
  expandCheck = (page) =>
    page === this.state.selected || this.selectCheck(page);
  logoff = (e) => {
    e.preventDefault();
    this.setState({ alertShow: true });
  };
  confim = () => {
    LS.clear();
    this.close();
    window.location.href = '/login';
  };
  toggleUserInfo = () => {
    this.setState({ isOpenedUserInfo: !this.state.isOpenedUserInfo });
  };
  close = () => this.setState({ alertShow: false });
  render() {
    if (this.state.loading) return null;

    const { isMenuCollapsed } = this.state;

    return (
      <>
        {/* <div className="container"> */}
        <div className={`container ${isMenuCollapsed ? 'menu-collapsed' : ''}`}>
          <div className="menu">
            <div
              className={isMenuCollapsed ? 'logo logo-collapsed' : 'logo'}
              onClick={this.toggleMenu}
            >
              {isMenuCollapsed ? (
                <img
                  src={require('../../Images/logo192.png')}
                  alt="Время Первых"
                />
              ) : (
                <img
                  src={require('../../Images/vp.logo.menu.svg').default}
                  alt="Время Первых"
                />
              )}
            </div>
            <ul>
              <li>
                <Link to={'/news'} className={this.selectCheck('news')}>
                  <i className="bi bi-newspaper">
                    <div className="tooltip">Новости</div>
                  </i>
                  {!isMenuCollapsed && 'Новости'}
                </Link>
              </li>
              <li>
                <Link to={'/alerts'} className={this.selectCheck('alerts')}>
                  <i className="bi bi-house-fill">
                    <div className="tooltip">Уведомления</div>
                  </i>
                  {!isMenuCollapsed && 'Уведомления'}
                </Link>
              </li>
              {Helpers.inRole(['programs']) ? (
                <>
                  <li>
                    <Link
                      to={'/programs'}
                      className={this.selectCheck('programs')}
                    >
                      <i className="bi bi-house-fill">
                        <div className="tooltip">Программы</div>
                      </i>
                      {!isMenuCollapsed && 'Программы'}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/psychology-tasks'}
                      className={this.selectCheck('')}
                    >
                      <i className="bi bi-journal-bookmark-fill">
                        <div className="tooltip">Задачи</div>
                      </i>
                      {!isMenuCollapsed && 'Задачи'}
                      {!isMenuCollapsed && (
                        <i
                          className={`bi ${
                            this.selectInnerCheck(['psychology', 'correction'])
                              ? 'bi-chevron-down'
                              : 'bi-chevron-right'
                          }`}
                        ></i>
                      )}
                    </Link>
                    {!isMenuCollapsed && (
                      <ul>
                        {Helpers.inRole(['psychology']) ? (
                          <li>
                            <Link
                              to={'/psychology-tasks'}
                              className={this.selectInnerCheck([
                                'psychology-tasks',
                              ])}
                            >
                              Психологическая работа
                            </Link>
                          </li>
                        ) : null}
                        {Helpers.inRole(['correction']) ? (
                          <li>
                            <Link
                              to={'/correction-tasks'}
                              className={this.selectInnerCheck([
                                'correction-tasks',
                              ])}
                            >
                              Коррекционная работа
                            </Link>
                          </li>
                        ) : null}
                        <li>
                          <Link
                            to={'/other-tasks'}
                            className={this.selectInnerCheck(['other-tasks'])}
                          >
                            Другое
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </>
              ) : null}
              {Helpers.inRole(['chat']) ? (
                <li>
                  <Link to={'/chat'} className={this.selectCheck('chat')}>
                    <i className="bi bi-chat-fill" title="Чат">
                      <div className="tooltip">Чат</div>
                    </i>
                    {!isMenuCollapsed && 'Чат'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['pupils']) ? (
                <li>
                  <Link to={'/pupils'} className={this.selectCheck('pupils')}>
                    <i className="bi bi-people-fill">
                      <div className="tooltip">Воспитанники</div>
                    </i>
                    {!isMenuCollapsed && 'Воспитанники'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['parental']) ? (
                <li>
                  <Link
                    to={'/parental'}
                    className={this.selectCheck('parental')}
                  >
                    <i className="bi bi-people">
                      <div className="tooltip">Родители</div>
                    </i>
                    {!isMenuCollapsed && 'Родители'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['household']) ? (
                <li>
                  <Link
                    to={'/household'}
                    className={this.selectCheck('household')}
                  >
                    <i className="bi bi-house-door-fill">
                      <div className="tooltip">Хозяйственная часть</div>
                    </i>
                    {!isMenuCollapsed && 'Хозяйственная часть'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['trainings']) ? (
                <li>
                  <Link
                    to={'/training'}
                    className={this.selectCheck('training')}
                  >
                    <i className="bi bi-card-checklist">
                      <div className="tooltip">Обучение</div>
                    </i>
                    {!isMenuCollapsed && 'Обучение'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['office']) ? (
                <li>
                  <Link to={'/office'} className={this.selectCheck('office')}>
                    <i className="bi bi-card-heading">
                      <div className="tooltip">Делопроизводство</div>
                    </i>
                    {!isMenuCollapsed && 'Делопроизводство'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['archive']) ? (
                <li>
                  <Link to={'/archive'} className={this.selectCheck('archive')}>
                    <i className="bi bi-archive-fill">
                      <div className="tooltip">Архив</div>
                    </i>
                    {!isMenuCollapsed && 'Архив'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['education']) ? (
                <li>
                  <Link
                    to={'/education'}
                    className={this.selectCheck('education')}
                  >
                    <i className="bi bi-file-earmark-person">
                      <div className="tooltip">Учебная часть</div>
                    </i>
                    {!isMenuCollapsed && 'Учебная часть'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['sport']) ? (
                <li>
                  <Link to={'/sport'} className={this.selectCheck('sport')}>
                    <i className="bi bi-file-earmark-person">
                      <div className="tooltip">Спортивная часть</div>
                    </i>
                    {!isMenuCollapsed && 'Спортивная часть'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['hr']) ? (
                <li>
                  <Link to={'/hr'} className={this.selectCheck('hr')}>
                    <i className="bi bi-file-earmark-person">
                      <div className="tooltip">Кадровая работа</div>
                    </i>
                    {!isMenuCollapsed && 'Кадровая работа'}
                  </Link>
                </li>
              ) : null}
              {Helpers.inRole(['settings']) ? (
                <li>
                  <Link
                    to={'/hr-documents'}
                    style={{ cursor: 'pointer' }}
                    className={this.selectCheck('')}
                  >
                    <i className="bi bi-collection-fill">
                      <div className="tooltip">Настройки</div>
                    </i>
                    {!isMenuCollapsed && 'Настройки'}
                    {!isMenuCollapsed && (
                      <i
                        className={`bi ${
                          this.expandCheck('departments')
                            ? 'bi-chevron-down'
                            : 'bi-chevron-right'
                        }`}
                      ></i>
                    )}
                  </Link>
                  {!isMenuCollapsed && (
                    <ul>
                      {Helpers.inRole(['hr']) ? (
                        <li>
                          <Link
                            to={'/hr-documents'}
                            className={this.selectInnerCheck(['hr-documents'])}
                          >
                            {!isMenuCollapsed && 'Сотрудники'}
                          </Link>
                        </li>
                      ) : null}
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/groups'}
                            className={this.selectInnerCheck([
                              'groups',
                              'group',
                            ])}
                          >
                            {!isMenuCollapsed && 'Роли права'}
                          </Link>
                        </li>
                      ) : null}
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/roles'}
                            className={this.selectInnerCheck(['roles', 'role'])}
                          >
                            {!isMenuCollapsed && 'Список ролей'}
                          </Link>
                        </li>
                      ) : null}
                      {Helpers.inRole(['programs']) ? (
                        <li>
                          <Link
                            to={'/programs-list'}
                            className={this.selectInnerCheck([
                              'programs-list',
                              'programs-edit',
                            ])}
                          >
                            {!isMenuCollapsed && 'Программы'}
                          </Link>
                        </li>
                      ) : null}
                      {/* <li><Link to={'/'} className={this.selectInnerCheck([])}>Смена</Link></li> */}
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/users'}
                            className={this.selectInnerCheck(['users', 'user'])}
                          >
                            {!isMenuCollapsed && 'Администраторы'}
                          </Link>
                        </li>
                      ) : null}
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/areas'}
                            className={this.selectInnerCheck(['areas', 'area'])}
                          >
                            {!isMenuCollapsed && 'Филиалы'}
                          </Link>
                        </li>
                      ) : null}
                      {/* <li><Link to={'/'} className={this.selectInnerCheck([])}>Отчеты</Link></li> */}
                      <li>
                        <Link
                          to={'/departments'}
                          className={this.selectInnerCheck(['departments'])}
                        >
                          <i className="bi bi-collection-fill"></i>
                          {!isMenuCollapsed && 'Подразделения'}
                          {!isMenuCollapsed && (
                            <i
                              className={`bi ${
                                this.expandCheck('departments')
                                  ? 'bi-chevron-down'
                                  : 'bi-chevron-right'
                              }`}
                            ></i>
                          )}
                        </Link>
                      </li>
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/events'}
                            className={this.selectInnerCheck([
                              'events',
                              'event',
                            ])}
                          >
                            {!isMenuCollapsed && 'Просмотр событий'}
                          </Link>
                        </li>
                      ) : null}
                      {Helpers.isAdmin() ? (
                        <li>
                          <Link
                            to={'/settings'}
                            className={this.selectInnerCheck([
                              'settings',
                              'setting',
                            ])}
                          >
                            {!isMenuCollapsed && 'Настройки'}
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  )}
                </li>
              ) : null}
            </ul>
          </div>
          <div className="main">
            <div className="main-container">
              <div className="main-search">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="поиск"
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                  <i
                    className="bi bi-search"
                    onClick={() =>
                      (window.location.href = `/search?search=${this.state.search.replace(
                        ' ',
                        '+'
                      )}`)
                    }
                  >
                    <div
                      style={{ backgroundColor: '#888' }}
                      className="tooltip"
                    >
                      Поиск
                    </div>
                  </i>
                </div>
                <div
                  className="main-user-info"
                  onClick={() => this.toggleUserInfo()}
                >
                  {this.state.user
                    ? this.state.user.name ||
                      `${this.state.user.lastName} ${this.state.user.firstName} ${this.state.user.middleName}`
                    : 'Пользователь'}
                  <svg
                    className={
                      this.state.isOpenedUserInfo ? 'rotate' : 'without-rotate'
                    }
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                      fill="#0F0F0F"
                    />
                  </svg>
                  {this.state.isOpenedUserInfo && (
                    <div className="main-user-info-dropdown">
                      <Link to={'/logoff'} onClick={this.logoff.bind(this)}>
                        <i className="bi bi-door-open-fill"></i>Выход
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {this.props.title ? (
                <div className="title">
                  <div className="title-block">
                    {this.props.backLink ? (
                      <Link
                        to={this.props.backLink}
                        onClick={() => window.history.back()}
                        className="back"
                      >
                        <i className="bi bi-caret-left-fill"></i>
                      </Link>
                    ) : null}
                    <h1>{this.props.title}</h1>
                  </div>
                  {this.props.controlPanel || null}
                </div>
              ) : null}
              <div className="main-scroll">
                {!this.state.canuse && this.props.page !== 'hr-documents' ? (
                  <div className="timesheet-error">
                    Вы зашли в систему в не рабочее время! Смена на это время не
                    установлена
                  </div>
                ) : (
                  this.props.children
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.alertShow && (
          <Confirm
            message={
              'Вы уверены что хотите выйти из системы администрирования?'
            }
            action={this.confim.bind(this)}
            close={this.close.bind(this)}
          />
        )}
      </>
    );
  }
}

Template.propTypes = {
  controlPanel: PropTypes.object,
  history: PropTypes.string,
  backLink: PropTypes.string,
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};
export default Template;
