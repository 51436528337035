import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert, { alertShow } from '../../Components/Alert';
import DocumentViewer from '../../Components/DocumentViewer';

// models
import {Childs,Parents,ChildCorrectionJournals,ChildPsychologyJournals,ChildDocuments,DocumentTypes,Files,ParentPsychologyJournals,Teachers,Events, ChildCorrectionPhotoJournals} from '../../Models';

// globals
import {API,eventMessageName,journalTypeName} from '../../Globals/Constants';

// helpers
import {Helpers,Errors,Utils,LS} from '../../Globals/index';

// styles
import './styles.css';

import PropTypes from 'prop-types';
import Confirm from '../Confirm';

class Journal extends Component {
	constructor(props) {
		const urlParams = new URLSearchParams(window.location.search);
		const nameParam = urlParams.get('link');
		super(props);
		this.state = {
			id:parseInt(this.props.id||0),
			isparent:this.props.isparent||false,
			iscorrection:this.props.iscorrection||false,
			//isphotodynamic:this.props.isphotodynamic||false,
			isphotodynamic:true,
			requiredphoto:this.props.requiredphoto||false,
			type:this.props.type,
			user:null,
			data:[],
			info:null,
			amount:null,
			date:new Date(),
			people:{},
			notfound:null,
			adddocumentshow: nameParam == 'add-document',
			childdocuments:null,
			documenttypes:[],
			doc:null,
			doctype:null,
			file:null,
			documents:[],
			loadingdocuments:false,
			loading:true,
			isOpenDelete:0
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const {id,type,isparent,iscorrection} = this.state;
		if (id) {
			if (isparent) {
				const parent = await Parents.get(id);
				const areaId = parseInt(LS.get('areaId'));
				const teachers = await Teachers.getAllWithoutType(areaId);
				if (parent.data && parent.data.length) {
					const pp = await ParentPsychologyJournals.get(id, type);
					this.setState({data:pp.data||[],people:parent.data[0],notfound:false,teachers:teachers.data});

					// Add events in log
					const fullName = ' ' + parent.data[0].lastName + ' ' + parent.data[0].firstName + ' ' + parent.data[0].middleName;
					Events.add(user.id, eventMessageName.viewPsychologyParent + journalTypeName[this.state.type - 1] + fullName, null, null, null, 2);
				} else this.setState({notfound:true});
			} else {
				const child = await Childs.get(id);
				const areaId = parseInt(LS.get('areaId'));
				const teachers = await Teachers.getAllWithoutType(areaId);
				if (child.data && child.data.length) {
					const cp = iscorrection ? await ChildCorrectionJournals.get(id, type) : await ChildPsychologyJournals.get(id, type);
					const documenttypes = await DocumentTypes.getAll();
					if (this.state.isphotodynamic && cp.data.length) {
						await this.childDocumentsGet();
						cp.data.map(async (v, i) => {
							const res = await ChildCorrectionPhotoJournals.get(v.id, type);
							if (res.data) cp.data[i].photos = res.data.map((p) => p.photo);
							this.setState({data:cp.data});
						});
					}
					
					this.setState({data:cp.data||[],documenttypes,people:child.data[0],notfound:false,teachers:teachers.data});

					// Add events in log
					const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
					const messageName = iscorrection ? eventMessageName.viewCorrectionChild : eventMessageName.viewPsychologyChild;
					Events.add(user.id, messageName + journalTypeName[this.state.type - 1] + fullName, null, null, null, 2);
				} else this.setState({notfound:true});
			}
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	childDocumentsGet = async () => {
		const childdocuments = await ChildDocuments.get(this.state.id);
		this.setState({childdocuments:childdocuments.data,loadingdocuments:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	formHandle = (e) => this.setState({[e.target.name]:e.target.value});
	save = async (e) => {
		e.preventDefault();
		const {id,info,date,user,type,amount,task,teachersId} = this.state;
		const d = {
			type,
			info,
			task:task,
			teachersId:teachersId,
			date:Math.round(new Date(date).getTime() / 1000),
			userId:user.id,
			userName:user.name
		};
		let res = null;
		if (this.state.isparent) {
			d.parentId = id;
			d.amount = amount;
			res = await ParentPsychologyJournals.add(d).catch(() => this.error());

			// Add events in log
			const getDataModel = (await Parents.get(id)).data[0];
			const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
			const newData = d;
			Events.add(this.state.user.id, eventMessageName.addPsychologyParent + journalTypeName[this.state.type - 1] + fullName, null, JSON.stringify(newData), null, 3);
		} else {
			d.childId = id;

			// Add events in log
			const getDataModel = (await Childs.get(id)).data[0];
			const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
			const newData = d;

			if (this.state.iscorrection) {
				res = await ChildCorrectionJournals.add(d).catch(() => this.error());
				if (res.data) {
					this.documentSave(e, res.data.id);
				}

				// Add events in log
				Events.add(this.state.user.id, eventMessageName.addCorrectionChild + journalTypeName[this.state.type - 1] + fullName, null, JSON.stringify(newData), null, 3);
			} else {
				res = await ChildPsychologyJournals.add(d).catch(() => this.error());
				if(this.state.isphotodynamic) {	
					if (res.data) {
						this.documentSave(e, res.data.id);
					}
				}

				// Add events in log
				Events.add(this.state.user.id, eventMessageName.addPsychologyChild + journalTypeName[this.state.type - 1] + fullName, null, JSON.stringify(newData), null, 3);
			}
		}
		if (res) Errors.add();
		else return false;
		this.props.back();
		return false;
	}
	getTeacher = (id) => {
		if (id) {
				const teacher = this.state.teachers.find(e => e.id === id);
				if (teacher) {
				const nameTeacher = teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
				return nameTeacher
			} else {
				return null
			}
		}
	}
	getFileTypeFromString = (fileName) => {
		const parts = fileName.toLowerCase().split('.');
		
		const fileExtension = parts[parts.length - 1];

		return fileExtension;
	};
	filenameGet = (id, filename) => `child_${id}_${filename}`;
	documentSave = async (e, journalId) => {
		e.preventDefault();
		
		const {id,documents,type} = this.state;
		
		documents.map(async (doc) => {
			let filetype = 32;
			if (['png', 'gif', 'jpg', 'jpeg', 'webp'].includes(this.getFileTypeFromString(doc.file.name))) {
				filetype = 33;
			}
			const d = {
				childId:id, type: filetype, fileType:doc.file.type, link:this.filenameGet(id, doc.file.name), status: doc.target.status
			};
			console.log(d);
			const res = await ChildDocuments.add(d).catch(() => this.error());
			if (res.data) {
				await ChildCorrectionPhotoJournals.add({type,journalId,photo:res.data.id})
				this.fileSave(id, `documents/childs/${id}`, doc.file);
			}
			/*const newData = d;
			const getDataModel = (await Childs.get(this.state.id)).data[0];
			const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
			Events.add(this.state.user.id, eventMessageName.addDocumentChild + fullName, null, JSON.stringify(newData), null, 3);*/
		});

		this.documentsReload();
		return false;
	}
	fileSave = (id, path, file) => {
		Files.upload(id, 'child', path, file);
	}
	documentTypeGet = (id) => this.state.documenttypes.find(f => f.id === id);
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const getDocumentModel = (await ChildDocuments.getById(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const oldData = getDocumentModel;
		Events.add(user.id, eventMessageName.deletePeopleChildEditDocument + fullName + ' ' + getDocumentModel.link, JSON.stringify(oldData), null, null, 7);
		
		await ChildDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loadingdocuments:true,adddocumentshow:false}, () => this.childDocumentsGet());
	documentView = async (child) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;
		const doctype = child.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + child.link;
		Events.add(user.id, eventMessageName.viewPeopleChildEditDocument + fullName + fileName, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (child) => {
		const uri = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + child.link;
		Events.add(user.id, eventMessageName.dowloadPeopleChildEditDocument + fullName + fileName, null, null, null, 6);

		window.location.href = uri;
	}

	fileChange = (f) => {
		const reader = new FileReader(), file = f;
		if (file) {
			reader.onloadend = (e) => this.state.documents.push(e.target.result);
			reader.readAsDataURL(file);
		}
	}

	filesChange = (e) => {
		const documents = [];
		for (const file of e.target.files) {
			documents.push({url:URL.createObjectURL(file),target:file,file});
		}
		this.setState({documents});
	}
	
    deleteJournal = (id) => {
        const find = this.state.data.find(item => item.id === id);

		console.log(this.state.data, id)

		if (this.state.iscorrection) {
			if (this.state.isparent) {
				//
			} else {
				ChildCorrectionJournals.remove(find.id);
			}
		} else {
			if (this.state.isparent) {
				ParentPsychologyJournals.remove(find.id);
			} else {
				ChildPsychologyJournals.remove(find.id);
			}
		}


        alertShow('Запись удалена');

		setTimeout(() => {
			window.location.reload();
		}, 500);
    }

	setIsOpenDelete = (id) => {
		this.setState({ isOpenDelete: id });
	};

	error = () => Errors.error();
	render() {
		return <>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={this.props.back} /> :
						<form id="journal-form" className="form journal" onSubmit={this.save}>
							{this.state.isOpenDelete > 0 && (
								<Confirm
									message={'Вы уверены, что хотите удалить запись с фото?'}
									action={() => {
										this.deleteJournal(this.state.isOpenDelete);
										this.setIsOpenDelete(0);
									}}
									close={() => { this.setIsOpenDelete(0) }}
								/>
							)}
							<div className="form-row">
								<label>имя {this.state.isparent? 'родителя' : 'ребенка'}</label>
								<h4>{this.state.people.lastName} {this.state.people.firstName} {this.state.people.middleName}</h4>
							</div>
							<div className="form-row form-row-block">
								<h3 onClick={() => this.adddocumentShow()}>Добавить запись</h3>
								{this.state.adddocumentshow ?
									<div className="form-row-block-inner">
										{this.state.isphotodynamic ? <div className="form-row">
											<label htmlFor="file">Вложение</label>
											<input onChange={this.filesChange} multiple name="file" className="file" required={this.state.requiredphoto} type="file" />
										</div> : null}
										<div className="form-row">
											<label htmlFor="info">Описание</label>
											<textarea name="info" id="info" placeholder="Опишите процедуру" required onChange={this.formHandle}>{this.state.info}</textarea>
										</div>
										<div className="form-row">
											<label htmlFor="date">Дата отчета</label>
											<input type="date" name="date" id="date" className="date" required onChange={this.formHandle} value={this.state.date} />
										</div>
										<div className="form-row">
											<label htmlFor="task">Задача</label>
											<textarea name="task" id="task" placeholder="Опишите задачу" required className="medium" onChange={this.formHandle}></textarea>
										</div>
										<div className="form-row">
											<label htmlFor="teachersId">Ответственный</label>
											<select name="teachersId" id="teachersId" onChange={this.formHandle} required defaultValue={this.state.data.teachersId}>
												<option></option>
												{this.state.teachers.map((v,i) => <option key={i} value={v.id}>{v.lastName} {v.firstName} {v.middleName}</option>)}
											</select>
										</div>
										{this.state.isparent ?
											<div className="form-row">
												<label htmlFor="amount">Сумма, ₽</label>
												<input type="text" name="amount" id="amount" placeholder="Сумма" required onChange={this.formHandle} value={this.state.amount} />
											</div> : null}
									</div> : null}
							</div>
							<div className="list-journal">
								{this.state.data.map((v,i) => <div key={i} className="list-journal-item">
									{this.state.isphotodynamic && v.photos ?
										<div className="name images">
											{console.log(this.state.childdocuments)}
											{this.state.childdocuments.filter((doc) => v.photos.includes(`${doc.id}`)).map((doc, i) => doc.type === 33 ? <img key={i} onClick={()=>this.documentView(doc)} src={`${API.assets}/documents/childs/${this.state.id}/${doc.link}`}></img> : <div className="documents" key={i} onClick={() => this.documentView(doc)} ><i className="bi bi-file-earmark-text"></i><span>{doc.link}</span></div>)}
										</div>
									: null}
									<div className="name">
										<span>{Moment(v.date*1000).format('DD.MM.y, H:mm')}</span>
										{this.state.isparent ? <div>Сумма: <b>{Utils.moneyFormat(v.amount, false)} ₽</b></div> : null}
										<b>Описание:</b> {v.info}
									</div>
									<div className="name">
										<b>Задача:</b> {v.task}
									</div>
									<div className="name">
										<b>Ответственный:</b> {this.getTeacher(v.teachersId)}
									</div>
									<div>
									{ this.state.user.position === 'Суперадмин' ? <span onClick={(e) => {
										this.setIsOpenDelete(v.id);
										e.stopPropagation();

									}} className="button-deletes">Удалить</span> : null }
									</div>
								</div>)}
							</div>
						</form>
				}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</>
	}
}
Journal.propTypes = {
	id: PropTypes.any.isRequired,
	isparent: PropTypes.any.isRequired,
	iscorrection: PropTypes.any.isRequired,
	isphotodynamic: PropTypes.any.isRequired,
	type: PropTypes.any.isRequired,
	back: PropTypes.any.isRequired,
	requiredphoto: PropTypes.any
};

export default Journal;