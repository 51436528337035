import { useState } from "react";
import { PropTypes } from 'prop-types';
import Card from "../../Tasks/Card";
import { journalLinksCor, journalType, journalTypeName } from "../../../Globals/Constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Anamnes from "../../Tasks/Anamnes";

const PupilCorrection = ({ pupil }) => {
    const tabs = [journalType.COMMENTS, journalType.DYNAMIC, journalType.CALL, journalType.EFFECT, journalType.HANDKERCHIEF, journalType.FUNCTION];
    const [tab, setTab] = useState(tabs[0]);

    // const checked = true;

    const capitalizeCamelCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <div className="pupil_info">
            <div className="left_side">
                <div className="tabs">
                    <ul>
                        {tabs.map((t, i) => t === tab ? <li key={i} className={"active"} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li> : <li key={i} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li>)}
                    </ul>
                </div>
                <div className="form">
                    <Link to={`/${journalLinksCor[tab - 1]}/${pupil.id}`}><button className="default_button">Добавить запись</button></Link>
                </div>
                <div className="notes">
                    <Card childId={pupil.id} type={tab} iscorrection={true}></Card>
                </div>
            </div>
            <div className='right_side'>
                <div className="form">
                    <div className="left-part" style={{width: "30%"}}>
                        <div className="comment">Анамнез:</div>
                    </div>
                    <div className="right-part" style={{width: "70%"}}>
                        <div className="form-section">
                            <Anamnes childId={pupil.id} type="correction" />
                            <Link to={`/correction-anamnesis/${pupil.id}`}><button className="default_button">Изменить</button></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="tasks">
                    Задания
                    <ul>
                        <li><i className={checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                        <li><i className={checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                        <li><i className={checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                        <li><i className={!checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                        <li><i className={!checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                        <li><i className={!checked ? "bi bi-check-square" : "bi bi-dash-square"}></i><span>Название задания</span></li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
}

PupilCorrection.propTypes = {
    pupil: PropTypes.object.isRequired,
};

export default PupilCorrection;