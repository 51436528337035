import {Component} from 'react';
import { Link } from 'react-router-dom';

// plug-ins
import Moment from 'moment';
import Modal from 'react-awesome-modal';
import DocumentViewer from '../../Components/DocumentViewer';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert, {alertShow} from '../../Components/Alert';
import ParentAdd from '../../Components/ParentAdd';

// models
import {Childs,Images,Parents,ChildParents,ChildDocuments,DocumentTypes,Files,Events} from '../../Models';

// helpers
import {Utils,Helpers,Errors,LS} from '../../Globals/index';

// globals
import {API,comonStatusName,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class ChildScreen extends Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		const nameParam = urlParams.get('link');
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			childparents:[],
			parents:[],
			parentsAll:[],
			documenttypes:[],
			childdocuments:[],
			parentId:null,
			file:null,
			document:{},
			filedocument:null,
			photo:null,
			user:null,
			notfound:null,
			nophoto:false,
			tab: nameParam === 'add-document' ? 2 : 1,
			customName:null,
			doc:null,
			doctype:null,
			documentview:false,
			adddocumentshow:true,
			additionalshow:true,
			educationshow:true,
			personalshow:true,
			parentsshow:true,
			parentmodalshow:false,
			isparentadd:false,
			loadingdocuments:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const child = await Childs.get(this.state.id);
			if (child.data && child.data.length) {
				const data = child.data[0];
				const cp = await ChildParents.get(data.id);
				const ids = cp.data.map((v) => v.parentId);
				const childparents = ids && ids.length ? await Parents.getByIds(ids) : {data:[]};
				const documenttypes = await DocumentTypes.getAll();
				await this.childDocumentsGet();
				this.setState({data,childparents:childparents.data,documenttypes:documenttypes.data,notfound:false,additionalshow:false,educationshow:false,personalshow:false});

				// Add events in log
				const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
				Events.add(user.id, eventMessageName.viewPeopleChildEdit + fullName, null, null, null, 2);
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		const areaId = parseInt(LS.get('areaId'));
		const parents = await Parents.getAll(areaId);
		this.setState({user,parentsAll:parents.data,loading:false});
	}
	childDocumentsGet = async () => {
		const childdocuments = await ChildDocuments.get(this.state.id);
		this.setState({childdocuments:childdocuments.data,loadingdocuments:false});
	}
	imageDefault = () => this.setState({nophoto:true});
	imageChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), file = e.target.files[0];
		if (file) {
			reader.onloadend = () => this.setState({nophoto:false,file,photo:reader.result});
			reader.readAsDataURL(file);
		}
	}
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.type==='checkbox'?(e.target.checked?1:0):e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data,file} = this.state;

		// Add events in log
		// get old data before update
		const dataModel = await Childs.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}

		const areaId = parseInt(LS.get('areaId'));
		const d = {
			areaId,
			firstName:data.firstName,
			lastName:data.lastName,
			middleName:data.middleName,
			photo:data.photo,
			birthDate:Number.isInteger(data.birthDate) ? Math.round(new Date(data.birthDate).getTime()) : Math.round(new Date(data.birthDate).getTime() / 1000),
			birthPlace:data.birthPlace,
			dateIn:Number.isInteger(data.dateIn) ? Math.round(new Date(data.dateIn).getTime()) : Math.round(new Date(data.dateIn).getTime() / 1000),
			gender:data.gender,
			address:data.address,
			addressReal:data.addressReal,
			school:data.school,
			schoolClass:data.schoolClass,
			isHomeEducation:data.isHomeEducation,
			status:data.status
		};
		if (data.dateOut) d.dateOut = Number.isInteger(data.dateOut) ? Math.round(new Date(data.dateOut).getTime()) : Math.round(new Date(data.dateOut).getTime() / 1000);
		if (id === 0) {
			const res = await Childs.add(d).catch(() => this.error());
			
			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			const newData = d;
			Events.add(this.state.user.id, eventMessageName.addNewChild + fullName, null, JSON.stringify(newData), null, 3);
			if (res) {
				if (file) {
					this.imageSave(res.data.id, file);
					const dataPhoto = {photo:this.filenameGet(res.data.id, file.name)};
					Childs.update(res.data.id, dataPhoto);

					// Add events in log
					const newData = dataPhoto;
					const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
					Events.add(this.state.user.id, eventMessageName.addNewChildPhoto + fullName, null, JSON.stringify(newData), null, 3);
				}
				this.childParentsAdd(res.data.id);
				Errors.add();
			} else return false;
		} else {
			// Add events in log
			// get old data before update
			const oldData = getOldData(d);
			const newData = d;

			await Childs.update(id, d).then(() => {
				if (Number(d.status) === 0) {
					console.log(this.state.childparents)
					this.state.childparents.map(async (p) => {
						await Parents.update(p.id, { status: 0 });
					});
				}
			}).catch(() => this.error());

			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.updateDataChild + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			if (file) {
				this.imageSave(id, file);
				const dataPhoto = {photo:this.filenameGet(id, file.name)};
				Childs.update(id, dataPhoto);

				// Add events in log
				const oldData = getOldData(dataPhoto);
				const newData = dataPhoto;
				const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
				Events.add(this.state.user.id, eventMessageName.updateChildPhoto + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			}
			this.childParentsAdd(id);
			Errors.update();
		}
		this.props.history.push(`/pupil/${this.state.id}`);
		return false;
	}
	findDuplicateParents = (e) => {
		e.preventDefault();
		let numbers = [];
		this.state.childparents.map(v => numbers.push(v.type));
		const duplicates = numbers.filter((item, index) => index !== numbers.indexOf(item));
		if (duplicates.includes(0) || duplicates.includes(1)) {
			alertShow('<b>Ошибка! Количество мам или пап не должны быть больше 2!</b><b>Удалите лишних родителей!</b>', true);
		} else {
			this.save(e);
		}
	}
	imageSave = (id, file) => Images.upload(id, 'child', 0, file);
	filenameGet = (id, filename) => `child_${id}_${filename}`;
	childParentsAdd = async (id) => {
		const areaId = parseInt(LS.get('areaId'));
		await ChildParents.remove(id);
		this.state.childparents.forEach((v) => {
			const dataParents = {areaId,childId:id,parentId:v.id};
			ChildParents.add(dataParents)

			// Add events in log
			const newData = dataParents;
			Events.add(this.state.user.id, eventMessageName.addParentsChild, null, JSON.stringify(newData), null, 3);
		});
	}
	error = () => Errors.error();
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	additionalShow = () => this.setState({additionalshow:!this.state.additionalshow});
	educationShow = () => this.setState({educationshow:!this.state.educationshow});
	personalShow = () => this.setState({personalshow:!this.state.personalshow});
	parentShow = () => this.setState({parentsshow:!this.state.parentsshow});
	dateGet = (d) => {
		if (Number.isInteger(d)) return d === 0 ? null : Moment(d*1000).format('y-MM-DD');
		return d;
	}
	parentModalShow = (visible) => this.setState({parentmodalshow:visible});
	parentModalClose = (e) => {
		e.preventDefault();
		this.parentModalShow(false);
		return false;
	}
	parentModalAdd = () => this.setState({isparentadd:true});
	parentEdit = (parentId, isparentadd) => this.setState({parentId,parents:[],isparentadd}, () => this.parentModalShow(true));
	parentSave = (parent) => {
		this.parentModalShow(false);
		const p = this.state.childparents.find(f => f.id === parent.id);
		if (!p) this.setState({childparents:[...this.state.childparents,parent]});
		if (p){
			let dataParents = [...this.state.childparents];  
			let index = dataParents.findIndex(f => f.id === parent.id);
			dataParents[index] = parent;                  
			this.setState({childparents:[...dataParents ]})
		}
	}
	parentSearch = (e) => {
		const text = e.target.value;
		if (Utils.empty(text) || text.length < 3) {
			this.setState({parents:[]});
			return;
		}
		const parents = this.state.parentsAll.filter(f => this.nameCheck(f.firstName, text) || this.nameCheck(f.lastName, text) || this.nameCheck(f.middleName, text));
		this.setState({parents});
	}
	nameCheck = (text, value) => text.toLowerCase().indexOf(value.toLowerCase()) !== -1;
	parentSelect = (id) => {
		const parent = this.state.parentsAll.find(f => f.id === id)
		if (parent) this.parentSave(parent);
	}
	parentDelete = (id) => {
		const childparents = this.state.childparents.filter(f => f.id !== id)
		this.setState({childparents});
	}
	tabSet = (tab) => this.setState({tab});
	formDocumentHandle = (e) => this.setState({document:{...this.state.document,[e.target.name]:e.target.value}});
	formDocumentHandleName = (e) => this.setState({customName:e.target.value});
	documentSave = async (e) => {
		e.preventDefault();
		const {id,document,filedocument,customName} = this.state;
		const d = {
			childId:id,
			type:document.type,
			fileType:filedocument.type,
			link:this.filenameGet(id, filedocument.name),
			customName:document.type === "0" ? customName : null,
			status:document.status
		};
		const res = await ChildDocuments.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(this.state.user.id, eventMessageName.addDocumentChild + fullName, null, JSON.stringify(newData), null, 3);

		if (res) {
			this.fileSave(id, `documents/childs/${id}`, filedocument);
			Errors.add();
		} else return false;
		this.documentsReload();
		return false;
	}
	fileSave = (id, path, file) => Files.upload(id, 'child', path, file);
	documentTypeGet = (id) => this.state.documenttypes.find(f => f.id === id);
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const getDocumentModel = (await ChildDocuments.getById(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const oldData = getDocumentModel;
		Events.add(user.id, eventMessageName.deletePeopleChildEditDocument + fullName + ' ' + getDocumentModel.link, JSON.stringify(oldData), null, null, 7);
		
		await ChildDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loadingdocuments:true,adddocumentshow:false}, () => this.childDocumentsGet());
	documentView = async (child) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;
		const doctype = child.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + child.link;
		Events.add(user.id, eventMessageName.viewPeopleChildEditDocument + fullName + fileName, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (child) => {
		const uri = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + child.link;
		Events.add(user.id, eventMessageName.dowloadPeopleChildEditDocument + fullName + fileName, null, null, null, 6);

		window.location.href = uri;
	}
	render() {
		return <Template
				page={'departments'}
				title={this.state.id === 0 ? 'Добавление ребенка' : 'Редактирование ребенка'}
				controlPanel={
					<div className="form-control-panel">
						<button form="child-form" type="submit">Сохранить</button>
					</div>
				}
				backLink={this.state.id && this.state.id > 0 ? `/pupil/${this.state.id}` : 'pupils'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/pupils'} /> :
						<>
							{this.state.id !== 0 ?
								<ul className="top-tabs">
									<li className={this.state.tab===1?'selected':null} onClick={() => this.tabSet(1)}>Информация</li>
									{Helpers.inRole(['childs-documents'])?<li className={this.state.tab===2?'selected':null} onClick={() => this.tabSet(2)}>Документы</li>:null}
								</ul> : null}
							{this.state.tab === 1 ?
								<form id="child-form" className="form" onSubmit={this.findDuplicateParents}>
									<div className="form-row-oneline">
										<div className={this.state.nophoto?`form-photo form-photo-error`:"form-photo"}>
											<label>
												{!this.state.nophoto ? <img src={this.state.photo||`${API.assets}/childs/${this.state.data.photo}${Utils.urlUpdater()}`} onError={this.imageDefault} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
												<input onChange={this.imageChange} name="image" className="hide" accept="image/jpeg" type="file" />
											</label>
										</div>
										<div>
											<div className="form-row">
												<label htmlFor="lastName">Фамилия</label>
												<input type="text" name="lastName" id="lastName" placeholder="Фамилия" required onChange={this.formHandle} value={this.state.data.lastName} />
											</div>
											<div className="form-row-oneline">
												<div className="form-row">
													<label htmlFor="firstName">Имя</label>
													<input type="text" name="firstName" id="firstName" placeholder="Имя" required onChange={this.formHandle} value={this.state.data.firstName} />
												</div>
												<div className="form-row">
													<label htmlFor="middleName">Отчество</label>
													<input type="text" name="middleName" id="middleName" placeholder="Отчество" required onChange={this.formHandle} value={this.state.data.middleName} />
												</div>
											</div>
										</div>
									</div>
									<div className="form-row-oneline">
										<div className="form-row">
											<label htmlFor="dateIn">Дата поступления</label>
											<input type="date" name="dateIn" id="dateIn" className="date" required onChange={this.formHandle} defaultValue={this.dateGet(this.state.data.dateIn)} />
										</div>
										<div className="form-row">
											<label htmlFor="dateOut">Дата выписки</label>
											<input type="date" name="dateOut" id="dateOut" className="date" onChange={this.formHandle} defaultValue={this.dateGet(this.state.data.dateOut)} />
										</div>
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.parentShow()}>Родители / Опекуны</h3>
										{this.state.parentsshow ?
											<div className="form-row-block-inner">
												{this.state.childparents.length ?
														<div className="list-parents list-parents-inline">
															{this.state.childparents.map((v,i) => <div key={i} className="list-parents-item">
																<div className="avatar">
																	{v.photo ? <img src={`${API.assets}/parents/${v.photo}${Utils.urlUpdater()}`} alt="" /> : <i className="bi bi-person-fill form-no-photo parent__no-photo"></i>}
																</div>
																<div className="name">
																	<span>{v.lastName}</span>
																	{v.firstName} {v.middleName}
																</div>
																<div className="info">
																	<span>{Utils.phoneFormatter(v.phone)}</span>
																	{v.work}, {v.workPosition}
																</div>
																<div className="buttons">
																	<i className="bi bi-pencil-square" onClick={() => this.parentEdit(v.id, true)}></i>
																	<i className="bi bi-x-circle delete" onClick={() => this.parentDelete(v.id)}></i>
																</div>
															</div>)}
														</div>
													: null}
												<button type="button" className="btn" onClick={() => this.parentEdit()}>Добавить</button>
											</div> : null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.personalShow()}>Персональная информация</h3>
										{this.state.personalshow ?
											<div className="form-row-block-inner">
												<div className="form-row">
													<label htmlFor="gender">Пол</label>
													<select name="gender" id="gender" onChange={this.formHandle} defaultValue={this.state.data.gender} required>
														<option></option>
														<option value="1">Мужской</option>
														<option value="2">Женский</option>
													</select>
												</div>
												<div className="form-row-oneline">
													<div className="form-row">
														<label htmlFor="birthDate">Дата рождения</label>
														<input type="date" name="birthDate" id="birthDate" className="date" required onChange={this.formHandle} value={this.dateGet(this.state.data.birthDate)} />
													</div>
													<div className="form-row">
														<label htmlFor="birthPlace">Место рождения</label>
														<input type="text" name="birthPlace" id="birthPlace" placeholder="Введите страну, город, населенный пункт" className="double" required onChange={this.formHandle} value={this.state.data.birthPlace} />
													</div>
												</div>
												<div className="form-row">
													<label htmlFor="address">Адрес</label>
													<input type="text" name="address" id="address" placeholder="Введите адрес регистрации" className="full" required onChange={this.formHandle} value={this.state.data.address} />
												</div>
												<div className="form-row">
													<label htmlFor="addressReal">Адрес проживания</label>
													<input type="text" name="addressReal" id="addressReal" placeholder="Введите фактический адрес проживания" className="full" required onChange={this.formHandle} value={this.state.data.addressReal} />
												</div>
											</div>: null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.educationShow()}>Обучение</h3>
										{this.state.educationshow ?
											<div className="form-row-block-inner">
												<div className="form-row-oneline">
													<div className="form-row">
														<label htmlFor="school">Номер школы</label>
														<input type="text" name="school" id="school" placeholder="Номер школы" onChange={this.formHandle} value={this.state.data.school} />
													</div>
													<div className="form-row">
														<label htmlFor="schoolClass">Класс</label>
														<input type="text" name="schoolClass" id="schoolClass" placeholder="Класс" onChange={this.formHandle} value={this.state.data.schoolClass} />
													</div>
												</div>
												<div className="form-row-oneline">
													<div className="form-row">
														<label>Домашнее обучение</label>
														<div>
															<input type="checkbox" name="isHomeEducation" id="isHomeEducation" onChange={this.formHandle} defaultChecked={this.state.data.isHomeEducation===1} /> <label htmlFor="isHomeEducation" className="normal">да</label>
														</div>
													</div>
												</div>
											</div>: null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.additionalShow()}>Дополнительно</h3>
										{this.state.additionalshow ?
											<div className="form-row-block-inner">
												<div className="form-row">
													<label htmlFor="status">Статус</label>
													<select name="status" id="status" onChange={this.formHandle} required defaultValue={this.state.data.status}>
														<option></option>
														{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</div>
											</div>: null}
									</div>
								</form> : null}
							{this.state.tab === 2 ?
								<>
									<form id="child-form" className="form" onSubmit={this.documentSave}>
										<div className="form-row form-row-block">
											<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
											{this.state.adddocumentshow ?
												<div className="form-row-block-inner">
													<div className="form-row-oneline">
														<div className="form-row">
															<label htmlFor="file">Файл</label>
															<input onChange={this.fileChange} name="file" className="file" required type="file" />
														</div>
														<div className="form-row">
															<label htmlFor="type">Тип документа</label>
															<select name="type" id="type" onChange={this.formDocumentHandle} required>
																<option></option>
																<option style={{fontWeight: "bold"}} key={-1} value={0}>Ввести собственный</option>
																{this.state.documenttypes.map((v,i) => {
																	if ([1,2,5,3,6,8,32].includes(v.id)) {
																		return <option key={i} value={v.id}>{v.name}</option>
																	} else {
																		return null
																	}
																})}
																{this.state.documenttypes.map((v,i) => {
																	if ([31].includes(v.id)) {
																		return <option key={i} value={v.id}>{v.name}</option>
																	}
																})}
															</select>
														</div>
														{this.state.document.type === '0' ?
														<div className="form-row">
															<label htmlFor="type">Название документа</label>
															<input type="text" id="customName" placeholder="Введите название документа" name="customName" value={this.state.customName} onChange={this.formDocumentHandleName} required />
														</div>
														: null}
													</div>
													<div className="form-row">
														<label htmlFor="status">Статус</label>
														<select name="status" id="status" onChange={this.formDocumentHandle} required>
															<option></option>
															{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
														</select>
													</div>
												</div> : null}
										</div>
										<div className="list-documents">
											{this.state.loadingdocuments ? <Loader /> :
												this.state.childdocuments.map((v,i) => v.type !== 33 ? <div key={i} className="list-documents-item">
													<div className="name">
														{/* <span>{this.documentTypeGet(v.type)?.name} {} — {Moment(v.dateCreate*1000).format('DD.MM.y')}</span> */}
														<span>{this.documentTypeGet(v.type) ? this.documentTypeGet(v.type).name : v.customName} — {Moment(v.dateCreate*1000).format('DD.MM.y')}</span>
													</div>
													<div className="buttons">
														<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
														<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
														<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
													</div>
												</div> : null)}
										</div>
									</form>
									{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
								</> : null}
						</>
			}
			{this.state.parentmodalshow && <Modal visible={this.state.parentmodalshow} width={'1020'} height={'695'} effect={'fadeInUp'}>
				<div className="modal-container">
					{this.state.isparentadd ?
							<>
								<div className="modal-title">
									<h2>{this.state.parentId ? 'Редактирование родителя' : 'Добавление родителя'}</h2>
									<div className="form-control-panel">
										<button form="parent-form" type="submit">Сохранить</button>
										<Link to={'/'} onClick={this.parentModalClose} className="close">закрыть</Link>
									</div>
								</div>
								<div className="content">
									<ParentAdd id={this.state.parentId} onSave={(parent) => this.parentSave(parent)} />
								</div>
							</>
						:
							<>
								<div className="modal-title">
									<h2>Список родителей</h2>
									<div className="form-control-panel">
										<button type="button" onClick={() => this.parentModalAdd()}>Создать</button>
										<Link to={'/'} onClick={this.parentModalClose} className="close">закрыть</Link>
									</div>
								</div>
								<div className="content">
									<input type="search" placeholder="Введите Ф.И.О." onChange={this.parentSearch} autoFocus={true} />
									<div className="list-parents">
										{this.state.parents.map((v,i) => <div key={i} className="list-parents-item">
											<div className="avatar">
												<img src={`${API.assets}/parents/${v.photo}${Utils.urlUpdater()}`} alt="" />
											</div>
											<div className="name">
												<span>{v.lastName}</span>
												{v.firstName} {v.middleName}
											</div>
											<div className="buttons">
												<i className="bi bi-pencil-square" onClick={() => this.parentEdit(v.id, true)}></i>
												<i className="bi bi-person-plus" onClick={() => this.parentSelect(v.id)}></i>
											</div>
										</div>)}
									</div>
								</div>
							</>
						}
				</div>
			</Modal>}
			<Alert />
		</Template>
	}
}

import PropTypes from 'prop-types';
ChildScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default ChildScreen;