import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Settings} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// styles
import './styles.css';

class SettingsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const settings = await Settings.getAll();
		this.setState({user,data:settings.data,loading:false});
	}
	render() {
		return <Template
					page={'settings'}
					title={'Настройки'}
					controlPanel={
						<div className="form-control-panel">
							<Link to={'/setting'} className="btn">Добавить</Link>
						</div>
					}>
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-settings-item">
					<div className="name">
						<span>{v.name}</span>
					</div>
					<div className="buttons">
						<Link to={`/setting/${v.id}`}><i className="bi bi-pencil-square"></i></Link>
					</div>
				</div>)
			}
		</Template>
	}
}

export default SettingsScreen;