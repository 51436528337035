import Http from '../../Globals/Http';
import {requestConditionType,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('taskusers', {conditions:[{k:'id',v:id}]});
const getAll	        = async ()			=> await Http.get('taskusers', {conditions:[{k:'status',v:0,op:requestConditionType.MORE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getIndividualTasks = async (userId, userType)	=> await Http.get('taskusers', {conditions:[{k:'status',v:0,op:requestConditionType.MORE},{k:'userId',v:userId},{k:'userType',v:userType}]});

const add		        = async (data)		=> await Http.post('taskusers', {data});
const update	        = async (id, data)	=> await Http.put('taskusers', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update,
	getIndividualTasks
}