import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Roles} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class RoleScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const roles = await Roles.get(this.state.id);
			if (roles.data && roles.data.length) this.setState({data:roles.data[0],notfound:false});
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name,
			code:data.code
		};
		if (id === 0) {
			const res = await Roles.add(d).catch(() => this.error());
			if (res) Errors.add();
			else return false;
		} else {
			await Roles.update(id, d).catch(() => this.error());
			Errors.update();
		}
		this.props.history.push('/roles');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'settings'}
					title={this.state.id === 0 ? 'Добавление роли' : 'Редактирование роли'}
					controlPanel={
						<div className="form-control-panel">
							<button form="role-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/roles'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/roles'} /> :
						<form id="role-form" className="form" onSubmit={this.save}>
							<div className="form-row">
								<label htmlFor="name">Название роли</label>
								<input type="text" name="name" id="name" placeholder="Название роли" required className="double" autoFocus={true} onChange={this.formHandle} value={this.state.data.name} />
							</div>
							<div className="form-row">
								<label htmlFor="code">Код роли</label>
								<input type="text" name="code" id="code" placeholder="Код роли" required onChange={this.formHandle} value={this.state.data.code} />
							</div>
						</form>
			}
			<Alert />
		</Template>
	}
}

RoleScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default RoleScreen;