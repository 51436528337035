
// plug-ins
import Moment from 'moment';

// styles
import './styles.css';
import PropTypes from 'prop-types';
// funcs
const fieldNameGet = (field) => {
	switch(field) {
		case 'id': return 'ID';
		case 'name': return 'Имя, название';
		case 'phone': return 'Телефон';
		case 'password': return 'Пароль';
		case 'type': return 'Тип';
		case 'status': return 'Статус';
		case 'statusCollect': return 'Статус сборки';
		case 'description': return 'Описание';
		case 'dateCreate': return 'Дата создания';
		case 'dateModify': return 'Дата изменения';
		case 'dateTill': return 'Дата окончания';
		case 'code': return 'Код';
		case 'userId': return 'Пользователь';
		case 'link': return 'Ссылка';
		case 'image': return 'Изображение';
		case 'sortOrder': return 'Сортировка';
		case 'address': return 'Адрес';
		case 'date': return 'Дата';
		case 'userName': return 'Имя пользователя';
		case 'fileType': return 'Тип файла';
		case 'childId': return 'ID ребенка';
		case 'parentId': return 'ID родителя';
		case 'photo': return 'Фото';
		case 'workPosition': return 'Адрес работы';
		case 'work': return 'Кем работает';
		case 'birthDate': return 'Дата рождения';
		case 'middleName': return 'Отчество';
		case 'lastName': return 'Фамилия';
		case 'firstName': return 'Имя';
		case 'position': return 'Позиция';
		case 'typePeople': return 'Тип человека';
		case 'groupId': return 'ID группы';
		case 'username': return 'Имя пользователя';
		case 'info': return 'Информация';
		case 'isAccess': return 'Доступ';
		case 'mondayStart': return 'Понедельник начало';
		case 'mondayEnd': return 'Понедельник конец';
		case 'tuesdayStart': return 'Вторник начало';
		case 'tuesdayEnd': return 'Вторник конец';
		case 'wednesdayStart': return 'Среда начало';
		case 'wednesdayEnd': return 'Среда конец';
		case 'thursdayStart': return 'Четверг начало';
		case 'thursdayEnd': return 'Четверг конец';
		case 'fridayStart': return 'Пятница начало';
		case 'fridayEnd': return 'Пятница конец';
		case 'saturdayStart': return 'Суббота начало';
		case 'saturdayEnd': return 'Суббота конец';
		case 'sundayStart': return 'Воскресенье начало';
		case 'sundayEnd': return 'Воскресенье конец';
		case 'teacherId': return 'ID учителя';
		case 'birthPlace': return 'Место рождения';
		case 'dateIn': return 'Дата поступления';
		case 'gender': return 'Пол';
		case 'addressReal': return 'Фактический адрес';
		case 'teachersId': return 'ID учителя';
		case 'task': return 'Задача';
		case 'amount': return 'Сумма';
		case 'comment': return 'Комментарий';
		case 'color': return 'Цвет';
		case 'schoolSubjectName': return 'Предмет';
		case 'rate': return 'Оценка';
		case 'schoolSubjectId': return 'ID предмета';
		case 'number': return '№ письма';
		case 'readStatus': return 'Статус сообщения';
		case 'senderId': return 'ID сообщения';
		case 'areaId': return 'Филиал';
		case 'school': return 'Школа';
		case 'schoolClass': return 'Школьный класс';
		case 'isHomeEducation': return 'Домашнее обучение';
		case 'chronic': return 'Хронические';
		case 'allergies': return 'Алергия';
		case 'treatment': return 'Лечение';
		default: return field;
	}
}

const valueGet = (field, value) => {
	switch(field) {
		case 'phone': return 'Телефон';
		case 'dateCreate': return Moment(value*1000).format('DD.MM.y, H:mm');
		case 'birthDate': return Moment(value*1000).format('DD.MM.y');
		case 'dateModify': return Moment(value*1000).format('DD.MM.y, H:mm');
		case 'dateTill': return Moment(value*1000).format('DD.MM.y, H:mm');
		case 'date': return Moment(value*1000).format('DD.MM.y, H:mm');
		case 'dateIn': return Moment(value*1000).format('DD.MM.y');
		case 'password': return '******';
		default: return value;
	}
}

const JsonTable = (props) => {
	const data = JSON.parse(props.data);
	if (data === null) return 'нет данных';
	return (
		<table className="table">
			<tbody>
				{data && Object.keys(data).map((v,i) => <tr key={i}><td>{fieldNameGet(v)}</td><td>{valueGet(v,data[v])}</td></tr>)}
			</tbody>
		</table>
	);
}

JsonTable.propTypes = {
	data: PropTypes.object.isRequired,
};

export default JsonTable;