import {API} from '../Constants';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const request = async (controller, method, data) => {
	const url = `${API.url}/${controller}/${method}`;
	
	const options = {
		method:'POST',
		mode:'cors',
		headers:{
			'Authentication':API.key,
			'Content-Type':'application/json'
		},
		body: data == null ? null : JSON.stringify(data)
	};
	const response = await fetch(url, options);
	if (response.status === 200) {
		if (isDevelopmentMode) console.log(response);
		const json = await response.json();
		if (isDevelopmentMode) console.log(json);
		return json;
	}
	console.log('error:', response);
	throw await response.json();
};

const get		= async (controller, data) => await request(controller, 'get', data);
const post		= async (controller, data) => await request(controller, 'add', data);
const put		= async (controller, data) => await request(controller, 'update', data);
const remove	= async (controller, data) => await request(controller, 'delete', data);

const image = (id, type, index, data) => {
	let fd = new FormData();
	fd.append('id', id);
	fd.append('type', type);
	fd.append('index', index);
	fd.append('image', data);
	return new Promise((resolve, reject) => {
		const url = `${API.url}/images/add`;
		const options = {
			method:'POST',
			headers:new Headers({'Authentication':API.key}),
			body: fd
		};
		fetch(url, options)
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				return res.json();
			})
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				if (res.code === 0) resolve(res);
				else reject(res);
			})
			.catch(error => {
				if (isDevelopmentMode) console.log(error);
				reject(error);
		});
	});
}

const file = (id, type, path, data) => {
	let fd = new FormData();
	fd.append('id', id);
	fd.append('type', type);
	fd.append('path', path);
	fd.append('file', data);
	return new Promise((resolve, reject) => {
		const url = `${API.url}/files/add`;
		const options = {
			method:'POST',
			headers:new Headers({'Authentication':API.key}),
			body: fd
		};
		fetch(url, options)
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				return res.json();
			})
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				if (res.code === 0) resolve(res);
				else reject(res);
			})
			.catch(error => {
				if (isDevelopmentMode) console.log(error);
				reject(error);
		});
	});
}

const Http = {
	get,
	post,
	put,
	remove,
	request,
	image,
	file
}

export default Http;