import { useState } from "react";
import { journalParentsPsy, journalType, journalTypeName } from "../../../Globals/Constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../../Tasks/Card";
import { PropTypes } from 'prop-types';
import Anamnes from "../../Tasks/Anamnes";

const ParentalPsychology = ({ parent }) => {
    const tabs = [journalType.SESSIONS, journalType.INDIVIDUAL, journalType.INTENSIVE];
    const capitalizeCamelCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const [tab, setTab] = useState(tabs[0]);

    return (<>
        <div className="parental_info">
            <div className="left_side">
                <div className="tabs">
                    <ul>
                        {tabs.map((t, i) => t === tab ? <li key={i} className={"active"} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li> : <li key={i} onClick={() => setTab(t)}>{capitalizeCamelCase(journalTypeName[t-1])}</li>)}
                    </ul>
                </div>
                <div className="form">
                    <Link to={`/${journalParentsPsy[tab - 1]}/${parent.id}`}><button className="default_button">Добавить запись</button></Link>
                </div>
                <div className="notes">
                    <Card childId={parent.id} type={tab} isparent={true} iscorrection={false}></Card>
                </div>
            </div>
            <div className='right_side'>
                <div className="form">
                    <div className="left-part" style={{width: "30%"}}>
                        <div className="comment">Анамнез:</div>
                    </div>
                    <div className="right-part" style={{width: "70%"}}>
                        <div className="form-section">
                            <Anamnes isparent={true} childId={parent.id} type="psychology" />
                            <Link to={`/psychology-parent-anamnesis/${parent.id}`}><button className="default_button">Изменить</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

ParentalPsychology.propTypes = {
    parent: PropTypes.object.isRequired,
};

export default ParentalPsychology;