import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';

// models
import {CorrectionChildsGroup,Teachers,Events} from '../../Models';

// helpers
import {Helpers,Errors,LS} from '../../Globals/index';

// globals
import {genderType,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class CorrectionChildsGroupScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			info:null,
			data:[],
			date:new Date(),
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const corr = await CorrectionChildsGroup.getAllBoys();
		const areaId = parseInt(LS.get('areaId'));
		const teachers = await Teachers.getAllWithoutType(areaId);
		this.setState({user,data:corr.data||[],adddocumentshow:corr.data&&corr.data.length?false:true,loading:false,teachers:teachers.data});

        // Add events in log
        Events.add(user.id, eventMessageName.viewCorrectionGroupBoysPage, null, null, null, 2);
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	formHandle = (e) => this.setState({[e.target.name]:e.target.value});
	save = async (e) => {
		e.preventDefault();
		const {info,date,task,teachersId} = this.state;
		const d = {
			info,
			task:task,
			teachersId:teachersId,
			gender:genderType.man,
			date:Math.round(new Date(date).getTime() / 1000)
		};
		const res = await CorrectionChildsGroup.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		Events.add(this.state.user.id, eventMessageName.addCorrectionGroupBoys, null, JSON.stringify(newData), null, 3);

		if (res) Errors.add();
		else return false;
		this.props.history.push('/correction');
		return false;
	}
	getTeacher = (id) => {
		if (id) {
				const teacher = this.state.teachers.find(e => e.id === id);
				if (teacher) {
				const nameTeacher = teacher.lastName + ' ' + teacher.firstName + ' ' + teacher.middleName;
				return nameTeacher
			} else {
				return null
			}
		}
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'departments'}
					title={'Групповая работа мальчиков'}
					controlPanel={
						<div className="form-control-panel">
							<button form="correction-group-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/correction'}>
			{this.state.loading ? <Loader /> :
				<form id="correction-group-form" className="form correction-group" onSubmit={this.save}>
					<div className="form-row form-row-block">
						<h3 onClick={() => this.adddocumentShow()}>Добавить запись</h3>
						{this.state.adddocumentshow ?
							<div className="form-row-block-inner">
								<div className="form-row">
									<label htmlFor="info">Описание</label>
									<textarea name="info" id="info" placeholder="Опишите процедуру" required onChange={this.formHandle}>{this.state.info}</textarea>
								</div>
								<div className="form-row">
									<label htmlFor="date">Дата отчета</label>
									<input type="date" name="date" id="date" className="date" required onChange={this.formHandle} value={this.state.date} />
								</div>
								<div className="form-row">
									<label htmlFor="task">Задача</label>
									<textarea name="task" id="task" placeholder="Опишите задачу" required className="medium" onChange={this.formHandle}>{this.state.data.task}</textarea>
								</div>
								<div className="form-row">
									<label htmlFor="teachersId">Ответственный</label>
									<select name="teachersId" id="teachersId" onChange={this.formHandle} required defaultValue={this.state.data.teachersId}>
										<option></option>
										{this.state.teachers.map((v,i) => <option key={i} value={v.id}>{v.lastName} {v.firstName} {v.middleName}</option>)}
									</select>
								</div>
							</div> : null}
					</div>
					<div className="list-correction-group">
						{this.state.data.map((v,i) => <div key={i} className="list-correction-group-item">
							<div className="name">
								<span>{Moment(v.date*1000).format('DD.MM.y')}</span>
								<b>Описание:</b> {v.info}
							</div>
							<div className="name">
								<b>Задача:</b> {v.task}
							</div>
							<div className="name">
								<b>Ответственный:</b> {this.getTeacher(v.teachersId)}
							</div>
						</div>)}
					</div>
				</form>
			}
			<Alert />
		</Template>
	}
}

CorrectionChildsGroupScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default CorrectionChildsGroupScreen;