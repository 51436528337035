import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Teachers,Events} from '../../Models';

// helpers
import {Helpers,LS} from '../../Globals/index';

// globals
import {API,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.scss';
import TimeSheet from './TimeSheet';

class HRDocumentsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:null,
			user:null,
			notfound:false,
			loading:true,
			timesheet:false
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const teachers = await Teachers.getAllWithoutType(areaId);
		this.setState({user,data:teachers.data,loading:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewHrDocumentPage, null, null, null, 2);
	}
	closeTimeSheet = () => this.setState({ timesheet: false });
	openTimeSheet = () => this.setState({ timesheet: true });
	table = () => {
		//const { data: teachers } = this.state;

		return <div className="timesheet">
			<TimeSheet teachers={this.state.data} closeTimeSheet={this.closeTimeSheet} />	
		</div>
	}
	render() {
		return <Template page={'hr-documents'} title={'Список сотрудников'} backLink={'/'} controlPanel={<div className="control-panel"><button onClick={() => this.openTimeSheet()}>Таймшит</button></div>}>
			{this.state.timesheet && this.table()}
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-hr-teachers-item">
					<div className="avatar">
						{v.photo ? <img src={`${API.assets}/teachers/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
					</div>
					<div>
						<div className="name">{v.lastName} {v.firstName} {v.middleName}</div>
						<div className="controls">
							<Link to={`/hr-vacation/${v.id}`}>отпуск</Link>
							<Link to={`/hr-maternityleave/${v.id}`}>декрет</Link>
							<Link to={`/hr-fines/${v.id}`}>штрафы</Link>
							<Link to={`/hr-encouragement/${v.id}`}>поощрения</Link>
							<Link to={`/hr-explanatory/${v.id}`}>объяснительные</Link>
							<Link to={`/hr-contract/${v.id}`}>договор</Link>
						</div>
					</div>
					<div className="buttons">
						<Link to={`/teacher/${v.id}`}><i className="bi bi-arrow-right-circle"></i></Link>
					</div>
				</div>)
			}
		</Template>
	}
}

export default HRDocumentsScreen;