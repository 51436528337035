import { Component } from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
// models
import { ChatMessages, ChatUsers, Chats, Events } from '../../Models';

// globals
import { eventMessageName } from '../../Globals/Constants';

// helpers
import { Helpers } from '../../Globals/index';

// styles
import './styles.scss';

import PropTypes from 'prop-types';
import ChatModal from '../../Components/NoteModal/ChatModal';
import Alert, { alertShow } from '../../Components/Alert';

class ChatScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: true,
            newMessage: '',
            messages: [],
            chatId: 0,
            chatData: null,
			isOpen: false,
			textArea: '',
            sender: null,
            usersList: [],
            chatInterval: null,
            userchats: null
        };
    }

    componentDidMount = async () => {
        const user = Helpers.authCheck();
        this.setState({ user, loading: false });
        this.getChatDialogs(user.id);
        // this.getChatDialogs(user.id);
        // this.getUsersInChat(this.state.chatId);
        // this.getChatInfo(this.state.chatId);
        // this.getChatMessages(this.state.chatId);
        const chatInterval = setInterval(() => {
            this.updateChat();
        }, 10000);
        this.setState({chatInterval});
        Events.add(user.id, eventMessageName.viewPsychologyPage, null, null, null, 2);
    };

    componentWillUnmount() {
        clearInterval(this.chatInterval);
    }

    updateChat = (id = null) => {
        if (this.state.user && !id) {
            if (this.state.chatId != this.state.chatData?.id) {
                this.getUsersInChat(this.state.chatId);
                this.getChatInfo(this.state.chatId);
            }
            this.getChatMessages(this.state.chatId);
        } else if (this.state.user) {
            if (id != this.state.chatData?.id) {
                this.getUsersInChat(id);
                this.getChatInfo(id);
            }
            this.getChatMessages(id);
        }
    }

    getChatMessages = async (chatId) => {
        await ChatMessages.getByChatId(chatId).then((res) => {
            if (res?.data) {
                this.setState({messages: res.data});
            }
        });
    }

    getChatInfo = async (chatId) => {
        try {
            const res = await Chats.get(chatId);
            this.setState({chatData: res.data[0]});
            return res.data[0];
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getUsersInChat = async (chatId) => {
        try {
            const res = await ChatUsers.getByChatId(chatId);
            if (res && res.data) {
                const uniqueUsers = res.data.reduce((acc, user) => {
                    if (!acc.some((item) => item.userId === user.userId)) {
                        acc.push(user);
                    }
                    return acc;
                }, []);
    
                this.setState({ usersList: uniqueUsers });
            }
            return res.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getChatDialogs = async (userId) => {
        if (userId > 0) {
            try {
                const res = await ChatUsers.getByUserId(userId);
                if (res && res.data) {
                    const userchats = [];
                    for (const chat of res.data) {
                        const chatInfo = await this.getChatInfo(chat.chatId);
                        if (chatInfo?.status) {
                            const updatedChat = { ...chat, name: chatInfo?.name || "" };
                            userchats.push(updatedChat);
                        }
                    }
                    this.setState({userchats});
                    if (!this.state.chatId) {
                        //this.setCurrentChatId(userchats[0]?.chatId || 0);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    handleSendMessage = async () => {
        const { newMessage, messages } = this.state;
        if (newMessage) {
            const newmsg = { userId: this.state.user.id, userName: this.state.user.name || `${this.state.user?.lastName} ${this.state.user?.firstName} ${this.state.user?.middleName}`, message: newMessage, status: 1, chatId: this.state.chatId, dateCreate: Math.floor(Date.now() / 1000) };
            try {
                const res = await ChatMessages.add(newmsg);
                if (res?.data.id > 0) {
                    const updatedMessages = [newmsg, ...messages];
                    this.setState({ messages: updatedMessages, newMessage: '' });
                } else {
                    alertShow('Ошибка при отправке сообщения', 1);
                }
            } catch {
                alertShow('Ошибка при отправке сообщения', 1);
            }

        }
    };

    alertMessage = (message, iserror) => {
        alertShow(message, iserror || false);
    }

    setNewMessage = (e) => this.setState({ newMessage: e.target.value });
    setIsOpen = (val) => this.setState({isOpen: val});
	setTextArea = (e) => this.setState({textArea: e.target.value});
	autoResizeTextarea = (e) => {
		e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
	}
    handleChange = (e) => this.setTextArea(e.target.value);
	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
    timeToLocale = (timeStamp) => {
        const date = new Date(timeStamp * 1000);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

	setCurrentChatId = (id) => {
        if (this.state.chatId === id) {
            return;
        }
        try {
            this.setState({chatId: id});
            this.setState({messages: [], usersList: [],});
            this.updateChat(id);
        } catch {
            console.error('error on setCurrent chat id');
        }
	}

    getUserNameFromUsersList(userId) {
        const user = this.state.usersList?.find(user => user.userId === userId);
        return user ? user.name : '';
    }

    declineParticipant(number) {
        if (number === 1) {
            return `${number} участник`;
        } else if (number > 1 && number < 5) {
            return `${number} участника`;
        } else {
            return `${number} участников`;
        }
    }

    render() {
        const { loading, messages, newMessage } = this.state;

        return <Template page={'chat'} title={'Чат'}>
            {loading ? (
            <Loader />
            ) : (
                <div className="chat-container">
                    <ChatModal setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} alertMessage={this.alertMessage} updateChat={this.updateChat} chatId={this.state.chatId} chatData={this.state.chatData} usersList={this.state.usersList}></ChatModal>
                    <div className="chat-header">
                        <div className="left-side">
                            <button onClick={() => {
                                this.setIsOpen(true);
                                this.setCurrentChatId(null);
                            }}>+ Создать чат</button>
                        </div>
                        <div className="right-side">
                        {this.state.userchats?.length > 0 && this.state.chatId > 0 && this.state.chatId === this.state.chatData?.id && this.state.usersList?.length > 0 ? (<>
                            <span>{this.state.chatData?.name}</span>
                            <span>({this.declineParticipant(this.state.usersList?.length)})</span>
                            {this.state.chatData?.userId == this.state.user?.id ? <i className="bi bi-pencil-square" onClick={() => this.setIsOpen(true)}></i> : null}
                        </>) : <div style={{position:"absolute",top:"-10px"}}><Loader /></div>}
                        </div>
                    </div>
                    <div className="chat">
                        <div className="left-side">
                            <ul>
                                {this.state.userchats?.length > 0 ? (
                                    this.state.userchats?.map((item, i) => (
                                        <li key={i} className={this.state.chatId === item.chatId ? 'active' : null} onClick={() => this.setCurrentChatId(item.chatId)}>{item.name}</li>
                                    ))
                                ) : <li>Список чатов пуст</li>}
                            </ul>
                        </div>
                        <div className="right-side">
                            {this.state.userchats?.length > 0 && this.state.chatId === this.state.chatData?.id && this.state.usersList?.length > 0 ? (<>
                            <div className="chat-dialog">
                            {messages?.length > 0 ? (
                                <>
                                    {(() => {
                                        const messagesByDate = messages
                                            .sort((a, b) => b.dateCreate - a.dateCreate)
                                            .reduce((acc, message) => {
                                                const messageDate = new Date(message.dateCreate * 1000);
                                                const today = new Date();
                                                const yesterday = new Date(today);
                                                yesterday.setDate(today.getDate() - 1);

                                                let dateString;
                                                if (messageDate.toDateString() === today.toDateString()) {
                                                    dateString = 'Сегодня';
                                                } else if (messageDate.toDateString() === yesterday.toDateString()) {
                                                    dateString = 'Вчера';
                                                } else {
                                                    dateString = messageDate.toLocaleDateString();
                                                }

                                                if (!acc[dateString]) {
                                                    acc[dateString] = [];
                                                }
                                                acc[dateString].push(message);
                                                return acc;
                                            }, {});

                                        return Object.entries(messagesByDate).map(([date, messageGroup]) => (<>
                                                {messageGroup.map((message, i) => (
                                                    <div className={`message ${message.userId === this.state.user.id ? 'user' : 'sender'}`} key={i}>
                                                        <div className="message-sender">{message.userName || `${this.state.user.lastName} ${this.state.user.firstName} ${this.state.user.middleName}`}</div>
                                                        <div className="message-content">{message.message}</div>
                                                        <div className="message-bottom">{this.dateToLocale(message.dateCreate)} {this.timeToLocale(message.dateCreate)}<i className="bi bi-check-all"></i></div>
                                                    </div>
                                                ))}
                                                <div key={date} className="message-date">{date}</div>
                                        </>));
                                    })()}
                                </>
                            ) : (
                                <div className="select-chat"><span>Здесь пока нет сообщений, но вы можете отправить первое сообщение</span></div>
                            )}
                            </div>
                            <div className="input-container">
                                <textarea
                                    placeholder="Введите сообщение"
                                    value={newMessage}
                                    onChange={this.setNewMessage}
                                />
                                <button onClick={this.handleSendMessage}>Отправить</button>
                            </div>
                            </>) : <div className="select-chat"><span>Выберите любой чат или создайте новый</span><Loader /></div>}
                        </div>
                    </div>
                </div>
            )}
            <Alert />
        </Template>
    }
}

ChatScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default ChatScreen;