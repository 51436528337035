const phoneNormalize = (phone) => {
	if (!empty(phone)) {
		phone = phone.replace(/\s+/gi, '');
		phone = phone.replace(/-/gi, '');
		phone = phone.replace(/\(/gi, '');
		phone = phone.replace(/\)/gi, '');
		phone = phone.replace(/\+/gi, '');
		phone = phone.replace(/[^0-9]+/, '');
		if (!empty(phone)) phone = phone.length < 10 ? '' : phone;
		if (!empty(phone) && phone[0] === '8') phone = phone.substr(1);
		if (!empty(phone)) phone = phone[0] !== '7' ? '7' + phone : phone;
	}
	return phone && phone.length === 11 ? phone : null;
}

const phoneFormatter = (phone) => empty(phone) ? 	phone : phone.replace('+', '').replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
const empty = (text) => text == null || text === '' || text.toString().trim() === '';

const moneyFormat = (amount, nofraction) => amount ? amount.toFixed(nofraction === undefined || nofraction === true ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d)\.?)/g, '$1 ') : 0;

const rn2br = (text) => text.toString().replace(/\r\n/gi, '<br/>').replace(/\r/gi, '<br/>').replace(/\n/gi, '<br/>');

const urlUpdater = () => `?v=${Math.random().toString().replace('0.', '')}`;

const agesGet = (age) => {
	let count = age % 100;
	if (count >= 5 && count <= 20) return 'лет';
	count = count % 10;
	if (count === 1) return 'год';
	if (count >= 2 && count <= 4) return 'года';
	return 'лет';
}

const translit = (text) => {
	const keys = {'а':'a','б':'b','в':'v','г':'g','д':'d','е':'e','ё':'e','ж':'j','з':'z','и':'i','к':'k','л':'l','м':'m','н':'n','о':'o','п':'p','р':'r','с':'s','т':'t','у':'u','ф':'f','х':'h','ц':'c','ч':'ch','ш':'sh','ь':'', 'ы':'y','э':'e','ю':'u','я':'ya','щ':'shch','ъ':''};
    return text ? text.toLowerCase().split('').map(char => typeof keys[char] === 'undefined' ? char : keys[char]).join('') : '';
}

const now = () => Math.ceil(new Date().getTime() / 1000);


const dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

export {
	phoneNormalize,
	phoneFormatter,
	empty,
	moneyFormat,
	rn2br,
	urlUpdater,
	agesGet,
	translit,
	now,
	dateToLocale
}