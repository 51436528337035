import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import DocumentViewer from '../../Components/DocumentViewer';
import Alert from '../../Components/Alert';

// models
import {OfficeCourtMails,OfficeCourtMailDocuments,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,mailTypeName,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class OfficeCourtMailScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			documents:[],
			filedocument:null,
			doctype:null,
			doc:null,
			documentview:false,
			adddocumentshow:true,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const mails = await OfficeCourtMails.get(this.state.id);

			// Add events in log
			Events.add(user.id, eventMessageName.viewOfficeCourtNumber + ' ' + mails.data[0].number, null, null, null, 4);

			if (mails.data && mails.data.length) {
				this.setState({data:mails.data[0],notfound:false});
				await this.documentsGet();
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	save = async (e) => {
		e.preventDefault();
		const {id,data,user} = this.state;
		const d = {
			userId:user.id,
			userName:user.name,
			number:data.number,
			comment:data.comment,
			type:data.type,
			date:Number.isInteger(data.date) ? Math.round(new Date(data.date).getTime()) : Math.round(new Date(data.date).getTime() / 1000)
		};
		if (id === 0) {
			const res = await OfficeCourtMails.add(d).catch(() => this.error());

			// Add events in log
			const newData = d;
			Events.add(this.state.user.id, eventMessageName.addOfficeCourtMail + ' ' + d.number, null, JSON.stringify(newData), null, 3);

			if (res) {
				Errors.add();
				this.setState({id:parseInt(res.data.id)});
			}
			else return false;
		} else {
			// Add events in log
			// get old data before update
			const dataModel = await OfficeCourtMails.get(id);
			const getOldData = (data) => {
				const keysData = Object.keys(data);
				let oldData = {};
				keysData.forEach((key) => {
					let item = {}
					item[key] = dataModel.data[0][key];
					Object.assign(oldData, item);
				});
				return oldData;
			}
			const oldData = getOldData(d);
			const newData = d;

			await OfficeCourtMails.update(id, d).catch(() => this.error());

			// Add events in log
			const numberMail = ' ' + d.number;
			Events.add(this.state.user.id, eventMessageName.updateOfficeCourtMail + numberMail, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			Errors.update();
		}
		await this.documentSave();
		this.props.history.push('/office-court-mails');
		return false;
	}
	error = () => Errors.error();
	documentsGet = async () => {
		const docs = await OfficeCourtMailDocuments.getAll();
		const documents = docs.data.filter(f => f.officeMailId === this.state.id);
		this.setState({documents,loading:false});
	}
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentDelete = async (id) => {
		// Add events in log
		const document = (await OfficeCourtMailDocuments.getById(id)).data[0];
		const mail = (await OfficeCourtMails.get(document.officeMailId)).data[0];
		const oldData = document;
		Events.add(this.state.user.id, eventMessageName.deleteOfficeCourtMailNumber + ' ' + mail.number + ' ' + document.link, JSON.stringify(oldData), null, null, 7);
		
		await OfficeCourtMailDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true}, async () => await this.documentsGet(true));
	documentView = async (d) => {
		const doc = `${API.documents}/?p=departments/office/court/${this.state.id}&ft=${d.fileType}&fn=${d.link}`;
		const doctype = d.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const mails = await OfficeCourtMails.get(this.state.id);
		const numberMail = ' ' + mails.data[0].number;
		const linkMail = ' ' + d.link;
		Events.add(this.state.user.id, eventMessageName.viewOfficeCourtMailNumber + numberMail + ' вложение ' + linkMail, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (d) => {
		const uri = `${API.documents}/?p=departments/office/court/${this.state.id}&ft=${d.fileType}&fn=${d.link}`;

		// Add events in log
		const mails = await OfficeCourtMails.get(this.state.id);
		const numberMail = ' ' + mails.data[0].number;
		const linkMail = ' ' + d.link;
		Events.add(this.state.user.id, eventMessageName.dowloadOfficeCourtMailNumber + numberMail + linkMail, null, null, null, 6);

		window.location.href = uri;
	}
	documentSave = async () => {
		const {id,filedocument,user} = this.state;
		if (!filedocument) return false;
		const d = {
			officeMailId:id,
			fileType:filedocument.type,
			link:filedocument.name,
			userId:user.id,
			userName:user.name
		};
		const res = await OfficeCourtMailDocuments.add(d).catch(() => this.error());
		if (res) {
			Errors.add();
			this.fileSave(`documents/departments/office/court/${id}`, filedocument);
			this.documentsReload();
		}
		return false;
	}
	fileSave = (path, file) => Files.upload(0, null, path, file);
	dateGet = (d) => {
		if (Number.isInteger(d)) return d === 0 ? null : Moment(d*1000).format('y-MM-DD');
		return d;
	}
	render() {
		return <Template
					page={'departments'}
					title={this.state.id === 0 ? 'Добавление письма' : 'Редактирование письма'}
					controlPanel={
						<div className="form-control-panel">
							<button form="office-mails-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/office-court-mails'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/users'} /> :
						<form id="office-mails-form" className="form" onSubmit={this.save}>
							<div className="form-row form-row-block">
								<h3 onClick={() => this.adddocumentShow()}>Добавить запись</h3>
								{this.state.adddocumentshow ?
									<div className="form-row-block-inner">
										<div className="form-row-oneline">
											<div className="form-row">
												<label htmlFor="number">Номер</label>
												<input type="text" name="number" id="number" required onChange={this.formHandle} value={this.state.data.number} />
											</div>
											<div className="form-row">
												<label htmlFor="date">Дата</label>
												<input type="date" name="date" id="date" className="date" required onChange={this.formHandle} value={this.dateGet(this.state.data.date)} />
											</div>
											<div className="form-row">
												<label htmlFor="type">тип</label>
												<select name="type" id="type" className="middle" defaultValue={this.state.data.type} onChange={this.formHandle} required>
													<option></option>
													{mailTypeName.map((v,i) => i > 0 && <option key={i} value={i}>{v}</option>)}
												</select>
											</div>
										</div>
										<div className="form-row">
											<label htmlFor="file">Файл</label>
											{this.state.id ?
													<input onChange={this.fileChange} name="file" className="file" type="file" />
												:
													<input onChange={this.fileChange} name="file" className="file" type="file" required />
											}
										</div>
										<div className="form-row">
											<label htmlFor="comment">Комментарий</label>
											<input type="text" name="comment" id="comment" className="wide" onChange={this.formHandle} value={this.state.data.comment} />
										</div>
									</div> : null}
							</div>
							{this.state.documents ?
								<div className="list-office-mail-documents">
									{this.state.documents.map((v,i) => <div key={i} className="list-office-mail-documents-item">
										<div className="name">
											<span>Вложение</span>
											{v.link}
										</div>
										<div className="buttons">
											<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
											<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
											<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
										</div>
									</div>)}
								</div> : null}
						</form>
					}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

OfficeCourtMailScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default OfficeCourtMailScreen;