import {Component} from 'react';

// plug-ins
import Moment from 'moment';
import InputMask from 'react-input-mask';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';
import DocumentViewer from '../../Components/DocumentViewer';

// models
import {Images,Teachers,UserGroups,Files,DocumentTypes,TeacherDocuments,TeacherTimetables,Events} from '../../Models';

// helpers
import {Utils,Helpers,Errors,LS} from '../../Globals/index';

// globals
import {API,comonStatusName,typePeople,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class EmployeeScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			groups:[],
			file:null,
			photo:null,
			user:null,
			timetable:{},
			documenttypes:[],
			employeedocuments:[],
			document:{},
			customName:null,
			filedocument:null,
			notfound:null,
			nophoto:false,
			doc:null,
			doctype:null,
			tab:1,
			documentview:false,
			adddocumentshow:true,
			additionalshow:true,
			personalshow:true,
			accessshow:true,
			timetableshow:true,
			loadingdocuments:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const groups = await UserGroups.getAll();
		if (this.state.id) {
			const employee = await Teachers.get(this.state.id);
			if (employee.data && employee.data.length) {
				//const groups = await UserGroups.getAll();
				const documenttypes = await DocumentTypes.getAll();
				const tt = await TeacherTimetables.get(this.state.id);
				const timetable = tt.data ? tt.data[0]||{} : {};
				await this.employeeDocumentsGet();
				this.setState({data:employee.data[0],documenttypes:documenttypes.data,timetable,notfound:false,additionalshow:false,accessshow:false,timetableshow:(timetable&&timetable.data)||false});

				// Add events in log
				const fullName = ' ' + employee.data[0].lastName + ' ' + employee.data[0].firstName + ' ' + employee.data[0].middleName;
				Events.add(user.id, eventMessageName.viewPeopleEmployeeEdit + fullName, null, null, null, 2);
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,groups:groups.data,loading:false});
	}
	employeeDocumentsGet = async () => {
		const employeedocuments = await TeacherDocuments.get(this.state.id);
		this.setState({employeedocuments:employeedocuments.data,loadingdocuments:false});
	}
	imageDefault = () => this.setState({nophoto:true});
	imageChange = (e) => {
        e.preventDefault();
		const reader = new FileReader(), file = e.target.files[0];
		if (file) {
			reader.onloadend = () => this.setState({nophoto:false,file,photo:reader.result});
			reader.readAsDataURL(file);
		}
	}
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.type==='checkbox'?(e.target.checked?1:0):e.target.value}});
	formTimetableHandle = (e) => this.setState({timetable:{...this.state.timetable,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data,file} = this.state;

		// Add events in log
		// get old data before update
		const dataModel = await Teachers.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}

		const areaId = parseInt(LS.get('areaId'));
		const d = {
			areaId,
			groupId:data.groupId,
			typePeople:typePeople.TEACHER,
			firstName:data.firstName,
			lastName:data.lastName,
			middleName:data.middleName,
			photo:data.photo,
			phone:Utils.phoneNormalize(data.phone),
			birthDate:Number.isInteger(data.birthDate) ? Math.round(new Date(data.birthDate).getTime()) : Math.round(new Date(data.birthDate).getTime() / 1000),
			position:data.position,
			info:data.info,
			username:data.username,
			isAccess:data.isAccess,
			status:data.status
		};
		if (id === 0) d.password = data.password;
		else {
			if (!Utils.empty(data.password)) d.password = data.password;
		}
		if (id === 0) {
			const res = await Teachers.add(d).catch(() => this.error());

			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			const newData = d;
			Events.add(this.state.user.id, eventMessageName.addNewEmployee + fullName, null, JSON.stringify(newData), null, 3);
			if (res) {
				if (file) {
					this.imageSave(res.data.id, file);
					const dataPhoto = {photo:this.filenameGet(res.data.id, file.name)};
					Teachers.update(res.data.id, {photo:this.filenameGet(res.data.id, file.name)});

					// Add events in log
					const newData = dataPhoto;
					const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
					Events.add(this.state.user.id, eventMessageName.addNewEmployeePhoto + fullName, null, JSON.stringify(newData), null, 3);
				}
				this.timetableSave(res.data.id, d);
				Errors.add();
			} else return false;
		} else {
			// Add events in log
			// get old data before update
			const oldData = getOldData(d);
			const newData = d;

			await Teachers.update(id, d).catch(() => this.error());

			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.updateDataEmployee + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			this.timetableSave(id, d);
			if (file) {
				this.imageSave(id, file);
				const dataPhoto = {photo:this.filenameGet(id, file.name)};
				Teachers.update(id, {photo:this.filenameGet(id, file.name)});

				// Add events in log
				const oldData = getOldData(dataPhoto);
				const newData = dataPhoto;
				const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
				Events.add(this.state.user.id, eventMessageName.updateEmployeePhoto + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			}
			Errors.update();
		}
		this.props.history.push('/employees');
		return false;
	}
	imageSave = (id, file) => Images.upload(id, 'teacher', 0, file);
	filenameGet = (id, filename) => `teacher_${id}_${filename}`;
	error = () => Errors.error();
	timetableSave = async (id, d) => {
		// Add events in log
		// get old data before update
		const dataModel = await TeacherTimetables.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}

		const {timetable} = this.state;
		if (timetable.id) {
			// Add events in log
			// get old data before update
			const oldData = getOldData(timetable);
			const newData = timetable;

			TeacherTimetables.update(timetable.id, timetable)

			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.updateDataEmployeeTime + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
		} else {
			timetable.teacherId = id;
			TeacherTimetables.add(timetable);

			// Add events in log
			const newData = timetable;
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.addNewEmployeeTime + fullName, null, JSON.stringify(newData), null, 3);
		}
	}
	dateGet = (d) => {
		if (Number.isInteger(d)) return d === 0 ? null : Moment(d*1000).format('y-MM-DD');
		return d * 1000;
	}
	additionalShow = () => this.setState({additionalshow:!this.state.additionalshow});
	personalShow = () => this.setState({personalshow:!this.state.personalshow});
	accessShow = () => this.setState({accessshow:!this.state.accessshow});
	timetableShow = () => this.setState({timetableshow:!this.state.timetableshow});
	formNameHandle = (e) => {
		const value = e.target.value, name = e.target.name;
		const fn = Utils.translit(this.state.data.firstName),
			ln = Utils.translit(this.state.data.lastName),
			mn = Utils.translit(this.state.data.middleName);
		const username = Utils.empty(fn) || Utils.empty(ln) || Utils.empty(mn) ? '' : `${fn[0]}.${mn[0]}.${ln}`;
		this.setState({data:{...this.state.data,[name]:value,username}});
	}
	tabSet = (tab) => this.setState({tab});
	formDocumentHandle = (e) => this.setState({document:{...this.state.document,[e.target.name]:e.target.value}});
	formDocumentHandleName = (e) => this.setState({customName:e.target.value});
	documentSave = async (e) => {
		e.preventDefault();
		const {id,document,filedocument,customName} = this.state;
		const d = {
			teacherId:id,
			type:document.type,
			fileType:filedocument.type,
			link:this.filenameGet(id, filedocument.name),
			customName:document.type === "0" ? customName : null,
			status:document.status
		};
		const res = await TeacherDocuments.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		const teacherData = (await Teachers.get(this.state.id)).data[0];
		const fullName = ' ' + teacherData.lastName + ' ' + teacherData.firstName + ' ' + teacherData.middleName;
		Events.add(this.state.user.id, eventMessageName.addDocumentEmployee + fullName, null, JSON.stringify(newData), null, 3);

		if (res) {
			this.fileSave(id, `documents/teachers/${id}`, filedocument);
			Errors.add();
		} else return false;
		this.documentsReload();
		return false;
	}
	fileSave = (id, path, file) => Files.upload(id, 'teacher', path, file);
	documentTypeGet = (id) => this.state.documenttypes.find(f => f.id === id);
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const teacherData = (await Teachers.get(this.state.id)).data[0];
		const teacherDocument = (await TeacherDocuments.getById(id)).data[0];
		const fullName = ' ' + teacherData.lastName + ' ' + teacherData.firstName + ' ' + teacherData.middleName;
		const oldData = teacherDocument;
		Events.add(user.id, eventMessageName.deletePeopleEmployeeEditDocument + fullName + ' ' + teacherDocument.link, JSON.stringify(oldData), null, null, 7);
		
		await TeacherDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loadingdocuments:true,adddocumentshow:false}, () => this.employeeDocumentsGet());
	documentView = async (employee) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=teachers&ft=${employee.fileType}&fn=${employee.link}`;
		const doctype = employee.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const teacherData = (await Teachers.get(this.state.id)).data[0];
		const fullName = ' ' + teacherData.lastName + ' ' + teacherData.firstName + ' ' + teacherData.middleName;
		const fileName = ' ' + employee.link;
		Events.add(user.id, eventMessageName.viewPeopleEmployeeEditDocument + fullName + fileName, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (employee) => {
		const uri = `${API.documents}/?id=${this.state.id}&t=teachers&ft=${employee.fileType}&fn=${employee.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const teacherData = (await Teachers.get(this.state.id)).data[0];
		const fullName = ' ' + teacherData.lastName + ' ' + teacherData.firstName + ' ' + teacherData.middleName;
		const fileName = ' ' + employee.link;
		Events.add(user.id, eventMessageName.dowloadPeopleEmployeeEditDocument + fullName + fileName, null, null, null, 6);

		window.location.href = uri;
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	render() {
		return <Template
					page={'departments'}
					title={this.state.id === 0 ? 'Добавление сотрудника' : 'Редактирование сотрудника'}
					controlPanel={
						<div className="form-control-panel">
							<button form="teacher-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/employees'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/employees'} /> :
						<>
							{this.state.id !== 0 ?
								<ul className="top-tabs">
									<li className={this.state.tab===1?'selected':null} onClick={() => this.tabSet(1)}>Информация</li>
									<li className={this.state.tab===2?'selected':null} onClick={() => this.tabSet(2)}>Документы</li>
								</ul> : null}
							{this.state.tab === 1 ?
								<form id="teacher-form" className="form" onSubmit={this.save}>
									<div className="form-row-oneline">
										<div className={this.state.nophoto?`form-photo form-photo-error`:"form-photo"}>
											<label>
												{!this.state.nophoto ? <img src={this.state.photo||`${API.assets}/teachers/${this.state.data.photo}${Utils.urlUpdater()}`} onError={this.imageDefault} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
												<input onChange={this.imageChange} name="image" className="hide" accept="image/jpeg" type="file" />
											</label>
										</div>
										<div>
											<div className="form-row">
												<label htmlFor="lastName">Фамилия</label>
												<input type="text" name="lastName" id="lastName" placeholder="Фамилия" required onChange={this.formNameHandle} value={this.state.data.lastName} />
											</div>
											<div className="form-row-oneline">
												<div className="form-row">
													<label htmlFor="firstName">Имя</label>
													<input type="text" name="firstName" id="firstName" placeholder="Имя" required onChange={this.formNameHandle} value={this.state.data.firstName} />
												</div>
												<div className="form-row">
													<label htmlFor="middleName">Отчество</label>
													<input type="text" name="middleName" id="middleName" placeholder="Отчество" required onChange={this.formNameHandle} value={this.state.data.middleName} />
												</div>
											</div>
										</div>
									</div>
									<div className="form-row-oneline">
										<div className="form-row">
											<label htmlFor="phone">Телефон</label>
											<InputMask type="text" name="phone" id="phone" mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" value={this.state.data.phone} onChange={this.formHandle} required />
										</div>
										<div className="form-row">
											<label htmlFor="birthDate">Дата рождения</label>
											<input type="date" name="birthDate" id="birthDate" className="date" required onChange={this.formHandle} defaultValue={this.dateGet(this.state.data.birthDate)} />
										</div>
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.personalShow()}>Персональная информация</h3>
										{this.state.personalshow ?
											<div className="form-row-block-inner">
												<div className="form-row">
													<label htmlFor="position">Позиция</label>
													<input type="text" name="position" id="position" placeholder="Позиция" required className="double" onChange={this.formHandle} value={this.state.data.position} />
												</div>
												<div className="form-row">
													<label htmlFor="info">Доп. описание</label>
													<textarea name="info" id="info" placeholder="Доп. описание" onChange={this.formHandle}>{this.state.data.info}</textarea>
												</div>
											</div> : null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.accessShow()}>Доступ</h3>
										{this.state.accessshow ?
											<div className="form-row-block-inner">
												<div className="form-row-oneline">
													<div className="form-row">
														<label htmlFor="username">Имя пользователя</label>
														<input type="text" name="username" id="username" placeholder="Имя пользователя" required onChange={this.formHandle} value={this.state.data.username} />
													</div>
													<div className="form-row">
														<label htmlFor="password">Пароль</label>
														{this.state.id === 0 ? 
																<input type="password" name="password" id="password" placeholder="Пароль" required onChange={this.formHandle} value={this.state.data.password} />
															:
																<input type="password" name="password" id="password" placeholder="Пароль" onChange={this.formHandle} value={this.state.data.password} />
														}
													</div>
												</div>
												<div className="form-row">
													<label htmlFor="groupId">Группа</label>
													<select name="groupId" id="groupId" onChange={this.formHandle} required defaultValue={this.state.data.groupId}>
														<option></option>
														{this.state.groups.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</div>
												<div className="form-row-oneline">
													<div className="form-row">
														<label>Есть доступ к системе</label>
														<div>
															<input type="checkbox" name="isAccess" id="isAccess" onChange={this.formHandle} defaultChecked={this.state.data.isAccess===1} /> <label htmlFor="isAccess" className="normal">да</label>
														</div>
													</div>
												</div>
											</div>: null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.timetableShow()}>График работы</h3>
										{this.state.timetableshow ?
											<div className="form-row-block-inner">
												<div className="form-row form-row-timetable">
													<label htmlFor="mondayStart">Понедельник</label>
													<div className="form-row-oneline">
														<input type="text" name="mondayStart" id="mondayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.mondayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="mondayEnd" id="mondayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.mondayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="tuesdayStart">Вторник</label>
													<div className="form-row-oneline">
														<input type="text" name="tuesdayStart" id="tuesdayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.tuesdayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="tuesdayEnd" id="tuesdayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.tuesdayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="wednesdayStart">Среда</label>
													<div className="form-row-oneline">
														<input type="text" name="wednesdayStart" id="wednesdayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.wednesdayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="wednesdayEnd" id="wednesdayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.wednesdayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="thursdayStart">Четверг</label>
													<div className="form-row-oneline">
														<input type="text" name="thursdayStart" id="thursdayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.thursdayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="thursdayEnd" id="thursdayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.thursdayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="fridayStart">Пятница</label>
													<div className="form-row-oneline">
														<input type="text" name="fridayStart" id="fridayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.fridayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="fridayEnd" id="fridayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.fridayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="saturdayStart">Суббота</label>
													<div className="form-row-oneline">
														<input type="text" name="saturdayStart" id="saturdayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.saturdayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="saturdayEnd" id="saturdayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.saturdayEnd||''}`} />
													</div>
												</div>
												<div className="form-row form-row-timetable">
													<label htmlFor="sundayStart">Воскресенье</label>
													<div className="form-row-oneline">
														<input type="text" name="sundayStart" id="sundayStart" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.sundayStart||''}`} />
														<div className="dash">—</div>
														<input type="text" name="sundayEnd" id="sundayEnd" onChange={this.formTimetableHandle} maxLength={2} value={`${this.state.timetable.sundayEnd||''}`} />
													</div>
												</div>
											</div>: null}
									</div>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.additionalShow()}>Дополнительно</h3>
										{this.state.additionalshow ?
											<div className="form-row-block-inner">
												<div className="form-row">
													<label htmlFor="status">Статус</label>
													<select name="status" id="status" onChange={this.formHandle} required defaultValue={this.state.data.status}>
														<option></option>
														{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</div>
											</div>: null}
									</div>
								</form> : null}
							{this.state.tab === 2 ?
								<>
									<form id="teacher-form" className="form" onSubmit={this.documentSave}>
										<div className="form-row form-row-block">
											<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
											{this.state.adddocumentshow ?
												<div className="form-row-block-inner">
													<div className="form-row-oneline">
														<div className="form-row">
															<label htmlFor="file">Файл</label>
															<input onChange={this.fileChange} name="file" className="file" required type="file" />
														</div>
														<div className="form-row">
															<label htmlFor="type">Тип документа</label>
															<select name="type" id="type" onChange={this.formDocumentHandle} required>
																<option></option>
																<option style={{fontWeight: "bold"}} key={-1} value={0}>Ввести собственный</option>
																{this.state.documenttypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
															</select>
														</div>
														{this.state.document.type === '0' ?
														<div className="form-row">
															<label htmlFor="type">Название документа</label>
															<input type="text" id="customName" placeholder="Введите название документа" name="customName" value={this.state.customName} onChange={this.formDocumentHandleName} required />
														</div>
														: null}
													</div>
													<div className="form-row">
														<label htmlFor="status">Статус</label>
														<select name="status" id="status" onChange={this.formDocumentHandle} required>
															<option></option>
															{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
														</select>
													</div>
												</div>: null}
										</div>
										<div className="list-documents">
											{this.state.loadingdocuments ? <Loader /> :
												this.state.employeedocuments.map((v,i) => <div key={i} className="list-documents-item">
													<div className="name">
														<span>{this.documentTypeGet(v.type) ? this.documentTypeGet(v.type).name : v.customName} — {Moment(v.dateCreate*1000).format('DD.MM.y')}</span>
													</div>
													<div className="buttons">
														<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
														<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
														<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
													</div>
												</div>)}
										</div>
									</form>
									{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
								</> : null}
						</>
			}
			<Alert />
		</Template>
	}
}

EmployeeScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default EmployeeScreen;