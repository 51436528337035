import {Component} from 'react';
import { Link } from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Teachers,Events} from '../../Models';

// helpers
import {Helpers,LS,Utils} from '../../Globals/index';

// globals
import {API,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class EmployeesScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const teachers = await Teachers.getAll(areaId, 1);
		this.setState({user,data:teachers.data,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewPeopleEmpolyeesPage, null, null, null, 2);
	}
	deactivatePeople = async (id) => {
        const data = {status:0};

        // Add events in log
        // get old data before update
		const dataModel = await Teachers.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
        const fullName = ' ' + dataModel.data[0].lastName + ' ' + dataModel.data[0].firstName + ' ' + dataModel.data[0].middleName;
		const oldData = getOldData(data);
        const newData = data;
        Events.add(this.state.user.id, eventMessageName.deactivatePeopleEmpoyee + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
        
        await Teachers.update(id, data);
        this.updatePeople();
    }
    updatePeople = async () => {
        const areaId = parseInt(LS.get('areaId'));
        const teachers = await Teachers.getAll(areaId, 1);
		this.setState({data:teachers.data});
    }
	ageGet = (ts) => {
		const years = Moment().diff(ts*1000, 'years');
		return `${years} ${Utils.agesGet(years)}`;
	}
	render() {
		return <Template
					page={'departments'}
					title={'Список сотрудников'}
					controlPanel={
						<div className="form-control-panel">
							<Link to={'/employee'} className="btn">Добавить</Link>
						</div>
					}
					backLink={'/peoples'}>
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-employee-item">
					<div className="avatar">
						{v.photo ? <img src={`${API.assets}/teachers/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
					</div>
					<div className="name">
						<h3>{v.lastName}</h3>
						<h4>{v.firstName} {v.middleName}</h4>
						<div className="date">
							{this.ageGet(v.birthDate)} ({Moment(v.birthDate*1000).format('DD.MM.y')})
						</div>
					</div>
					<div className="work">
						<span>{Utils.phoneFormatter(v.phone)}</span>
						{v.position}
					</div>
					<div className="buttons">
						<i className="bi bi-eye" onClick={() => this.deactivatePeople(v.id)}></i>
						<Link to={`/employee/${v.id}`}><i className="bi bi-pencil-square"></i></Link>
					</div>
				</div>)
			}
		</Template>
	}
}

export default EmployeesScreen;