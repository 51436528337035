import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// helpers
import {phoneFormatter} from '../../Globals/Utils';

// globals
import {comonStatusName,eventTypeName} from '../../Globals/Constants';

// styles
import './styles.css';

import PropTypes from 'prop-types';
const DataGrid = (props) => {
	const {keys,data,link,tableName} = props;
	const linkShow = (link, item, parent, table) => {
		if ((table === 'events' && item.key === 'message'))
			return <Link to={`${link}/${idGet(parent)}`}>{item.show}</Link>;
		return item.show;
	}
	const idGet = (parent) => {
		for (const item of parent) {
			if (item.key === 'id') return item.value;
		}
		return 0;
	}
	return (
		<div className="dg-container">
			<table>
				<thead>
					<tr>
						<th>№</th>
						{keys.map((v,i) => <th key={i}>{v}</th>)}
					</tr>
				</thead>
				<tbody>
					{data.map((v,i) => <tr key={i}>
						<td>{i+1}</td>
						{v.map((r,j) => <td key={j}>{linkShow(link,r,v,tableName)}</td>)}
					</tr>)}
				</tbody>
			</table>
		</div>
	);
}

export default DataGrid;

export const dgDataPrepare = (data, tableName, keys = [], collection = []) => {
	const out = [];
	data.forEach((v) => {
		const inner = [];
		if (keys.length === 0) {
			for (const k in v) inner.push(valueGet(k, v[k], tableName, collection));
		} else keys.forEach((k) => inner.push(valueGet(k, v[k], tableName, collection)));
		out.push(inner);
	});
	return out;
}
const valueGet = (key, value, tableName, collection) => {
	let show = value;
	switch(key) {
		case 'phone':
			show = phoneFormatter(value);
			break;
		case 'dateCreate':
			show = Moment(value*1000).format('DD.MM.y, H:mm');
			break;
		case 'status':
			show = statusGet(tableName, value);
			break;
		case 'type':
			show = typeGet(tableName, value);
			break;
		case 'userId':
			if (collection) {
				const listUsers = collection.filter(obj => obj.listUsers);
				const user = listUsers[0]['listUsers'].find(f => f.id === value);
				show = user ? <Link to={`/teacher/${user.id}`}>{user.lastName + ' ' + user.firstName + ' ' + user.middleName}</Link> : '—';
			} else show = null;
			break;
		default: break;
	}
	return {key,value,show};
}
const statusGet = (tableName, value) => {
	switch(tableName) {
		default: return comonStatusName[value];
	}
}
const typeGet = (tableName, value) => {
	switch(tableName) {
		case 'events':
			return eventTypeName[value];
		default: return value;
	}
}
DataGrid.propTypes = {
	match: PropTypes.object.isRequired,
	keys: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	link: PropTypes.object.isRequired,
	tableName: PropTypes.object.isRequired,
};