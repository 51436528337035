import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (id)			=> await Http.get('timesheet', {conditions:[{k:'id',v:id}]});
const getAll	= async ()				=> await Http.get('timesheet', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getByUser = async (userId) 		=> await Http.get('timesheet', {conditions:[{k:'userId',v:userId}]});

const getByDate = async (year, month)   => await Http.get('timesheet', {conditions:[{k:'year',v:year},{k:'month',v:month}]});

const getUserByFullDate = async (userId, year, month, day)   => await Http.get('timesheet', {conditions:[{k:'userId',v:userId},{k:'year',v:year},{k:'month',v:month},{k:'day',v:day}]});

const add		= async (data)			=> await Http.post('timesheet', {data});
const update	= async (id, data)		=> await Http.put('timesheet', {data,conditions:[{k:'id',v:id}]});

const remove 	= async (userId, year, month, day) => await Http.remove('timesheet', {conditions:[{k:'userId',v:userId},{k:'year',v:year},{k:'month',v:month},{k:'day',v:day}]});

export {
	getAll,
	get,
	add,
	update,
	getByUser,
	getByDate,
	remove,
	getUserByFullDate
}