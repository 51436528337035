import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('programs', {conditions:[{k:'id',v:id}]});
const getAll	        = async ()	=> await Http.get('programs', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('programs', {data});
const update	        = async (id, data)	=> await Http.put('programs', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update
}