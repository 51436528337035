import { Link } from 'react-router-dom';

// styles
import './styles.css';
import PropTypes from 'prop-types';
const NotFound = (props) => (
	<div className="notfound">
		<h4>Ничего не найдено в выбранной области поиска!</h4>
		<p>
			Воспользуйтесь меню, смягчите условия поиска или фильтра.<br/>
			Задайте запрос по-другому или установите более мягкие ограничения.
		</p>
		{props.nolink ? null : <Link to={props.back||'/'}>{props.backtext||'Назад'}</Link>}
	</div>
);
NotFound.propTypes = {
	nolink: PropTypes.object.isRequired,
	backtext: PropTypes.object.isRequired,
	back: PropTypes.object.isRequired
};
export default NotFound;