import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (parentId)		=> await Http.get('parentdocuments', {conditions:[{k:'parentId',v:parentId}], orders:[{k:'dateCreate', isd:requestOderType.DESC}]});
const getAll	= async ()				=> await Http.get('parentdocuments', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (id)			=> await Http.get('parentdocuments', {conditions:[{k:'id',v:id}]});

const add		= async (data)			=> await Http.post('parentdocuments', {data});
const remove	= async (id)			=> await Http.remove('parentdocuments', {conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	getById,
	add,
	remove
}