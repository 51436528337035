import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// helpers
import {Helpers} from '../../Globals/index';

// styles
import './styles.css';

class DepartmentsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	menu = [];
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (Helpers.inRole(['child-personal-info','parent-personal-info']))
			this.menu.push({name:'Личное дело',link:'peoples',desc:'Личные дела: дети, родители, сотрудники'});
		if (Helpers.inRole(['psychology','psychology-diagnostic','psychology-work-parents','psychology-family-sessions']))
			this.menu.push({name:'Психологическая работа',link:'psychology',desc:'Анамнез, диагностика, динамика'});
		if (Helpers.inRole(['correction','correction-coupones','correction-dynamic']))
			this.menu.push({name:'Коррекционная работа',link:'correction',desc:'Диагностика, динамика'});
		if (Helpers.inRole(['education','education-attestat']))
			this.menu.push({name:'Учебная часть',link:'education',desc:'Электронный дневник'});
		if (Helpers.inRole('household'))
			this.menu.push({name:'Хозяйственная часть',link:'household',desc:'Инвентаризация, закупки, акты'});
		// if (Helpers.inRole('finance'))
		// 	this.menu.push({name:'Финансовая часть',link:'finance',desc:''});
		if (Helpers.inRole('office'))
			this.menu.push({name:'Делопроизводство',link:'office',desc:'Письма, документы, судебные дела'});
		if (Helpers.inRole(['hr','hr-timesheet']))
			this.menu.push({name:'Кадровая работа',link:'hr',desc:'Сотрудники, штатное расписание, табель'});
		if (Helpers.inRole('education-personal'))
			this.menu.push({name:'Обучение персонала',link:'education-personal',desc:'Нормативные документы, литература'});
		if (Helpers.inRole('archive'))
			this.menu.push({name:'Архив',link:'archive',desc:'Архив сотрудников, детей, документов'});
		if (Helpers.inRole(['medic','medic-disease','medic-purchases','medic-dynamic']))
			this.menu.push({name:'Медицинская часть',link:'medic',desc:'Анамнез, амбулаторная карта школьника'});
		if (Helpers.inRole('methodic'))
			this.menu.push({name:'Методическая часть',link:'methodic',desc:'Программа, литература, учебники, книги'});
		if (Helpers.inRole('sport'))
			this.menu.push({name:'Спортивная часть',link:'sport',desc:'Физическое состояние ребенка, нормативы, динамика'});
		this.setState({user,loading:false});
	}
	render() {
		return <Template page={'departments'} title={'Подразделения'}>
			{this.state.loading ? <Loader /> :
				<div className="departments-list">
					{this.menu.map((v,i) => <Link to={`/${v.link}`} className="item" key={i}>
						<div className="item-title">{v.name}</div>
						<div className="item-description">{v.desc}</div>
					</Link>)}
				</div>
			}
		</Template>
	}
}

export default DepartmentsScreen;