import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('chatmessages', {conditions:[{k:'id',v:id}]});
const getByChatId       = async (chatId)    => await Http.get('chatmessages', {conditions:[{k:'chatId',v:chatId}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getAll	        = async (areaId)	=> await Http.get('chatmessages', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateEvent',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('chatmessages', {data});
const update	        = async (id, data)	=> await Http.put('chatmessages', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	add,
	update,
    getByChatId
}