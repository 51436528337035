import Http from '../../Globals/Http';

const get		= async (parentId)		=> await Http.get('parentpsychologyanamnesis', {conditions:[{k:'parentId',v:parentId}]});

const add		= async (data)			=> await Http.post('parentpsychologyanamnesis', {data});
const update	= async (parentId, data)	=> await Http.put('parentpsychologyanamnesis', {data,conditions:[{k:'parentId',v:parentId}]});

export {
	get,
	add,
	update
}