import {Component} from 'react';
import Template from '../../Components/Template';
import Alert, { alertShow } from '../../Components/Alert';
import {Helpers,LS} from '../../Globals/index';
import './styles.scss';
import { ProgramTasks, Programs } from '../../Models';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../Components/Loader';
import Confirm from '../../Components/Confirm';

class Program extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user		: null,
			programId   : parseInt(this.props.match.params.id || 0),
			loading		: true,
            newItem     : "",
            tasks       : [],
            showEdit    : -1,
            updatedText : "",
            programName : "",
            countDays   : 1,
            isOpenDeleteConfirm: false,
		};
	}

	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		try {
            if (this.state.programId > 0) {
                const programsResponse = await Programs.getAll();
                const programsData = programsResponse.data || [];
                const program = programsData.filter(item => item.id === this.state.programId)[0];
                if (program.id > 0 && this.state.programId == program.id) {
                    const id = program.id;
                    const programTasks = await ProgramTasks.get(id);
                    const tasks = programTasks.data?.map(({ id, name }) => ({
                        id,
                        value: name
                    }));
                    this.setState({programName: program.name, countDays: program?.countDays, tasks});
                } else {
                    this.setState({programId: 0});
                }
            }
            this.setState({user, areaId, loading: false});
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
	}

    setOpenDeleteConfirm = (val) => {
        this.setState({isOpenDeleteConfirm: val});
    }

    addItem = () => {
        const { newItem } = this.state;
    
        if (!newItem) {
            alertShow("Введите описание задачи для добавление задачи в список задач", 1);
            return;
        }
    
        const item = {
            id: Math.floor(Math.random() * 1000),
            value: newItem,
        };
    
        this.setState(prevState => ({
            tasks: [...prevState.tasks, item],
            newItem: ""
        }));
    }

    deleteItem = (id) => {
        const { tasks } = this.state;
        const newArray = tasks.filter((item) => item.id !== id);
        this.setState({tasks: newArray});
    }
    
    editItem = (id, newText) => {
        const { tasks } = this.state;
    
        const currentItem = tasks.find((item) => item.id === id);
    
        const newItem = {
            id: currentItem.id,
            value: newText,
        };
    
        this.deleteItem(id);
    
        this.setState(prevState => ({
            tasks: [...prevState.tasks, newItem],
            updatedText: "",
            showEdit: -1
        }));
    }

    delete = async () => {
        const { programId } = this.state;
        Programs.update(programId, {status: 0}).then(() => {
            this.props.history.push('/programs-list');
        });
    }

    save = async () => {
        const { programId, programName, countDays, tasks } = this.state;

        if (programName == '') {
            alertShow('Введите название программы', 1);
            return;
        }

        if (countDays < 1) {
            alertShow('Количество дней не может быть меньше 1', 1);
            return;
        }

        if (!tasks.length) {
            alertShow('Список задач пуст', 1);
            return;
        }

        try {
            if (programId > 0) {
                Programs.update(programId, { name: programName, countDays });
                const existingTasks = await ProgramTasks.get(programId);
                const tasksToUpdate = existingTasks.data.filter((existingTask) => !tasks.some((newTask) => newTask.value === existingTask.name));
                const newTasks = tasks.filter((newTask) => !existingTasks.data.some((existingTask) => newTask.value === existingTask.name));
                tasksToUpdate.forEach((task) => ProgramTasks.update(task.id, { status: 0 }));
                newTasks.forEach((newTask) => ProgramTasks.add({ programId, name: newTask.value, status: 1 }));
                alertShow('Программа сохранена!');
            } else {
                await Programs.add({ name: programName, status: 1, countDays }).then((res) => {
                    const id = res?.data?.id;
                    if (id > 0) {
                        tasks?.reverse();
                        tasks.map((item) => ProgramTasks.add({
                            programId: id,
                            name: item.value,
                            status: 1
                        }));
                        tasks?.reverse();
                        this.setState({programId: id});
                    } else {
                        alertShow('Произошла ошибка при сохранении программы!', 1);
                    }
                });
                alertShow('Программа создана!');
            }
        } catch (err) {
            console.error(err);
            alertShow('Произошла ошибка при сохранении программы!', 1);
        }
    }

	render() {
        const { loading, newItem, tasks, showEdit, updatedText, programName, countDays } = this.state;

		return loading ? <Loader /> : (
			<Template
				page={'programs-edit'}
				title={!this.state.programId || this.state.programId <= 0 ? 'Новая программа' : `Редактирование программы ${programName}`}
				controlPanel={
					<div className="control-panel">
						<Link to="/programs-list"><button>Программы</button></Link>
					</div>
				}>
                <div className="programs-list-container">
                    <div className="programs-list">
                        <div className="programs-item">
                            <div className="item-block">
                                <span>Название программы</span>
                                <input type="text" value={programName} onChange={(e) => this.setState({ programName: e.target.value })} placeholder="Введите название программы" />
                            </div>
                            <div className="item-block">
                                <span>Задачи</span>
                                <div className="tasks">
                                    <input
                                        type="text"
                                        placeholder="Описание задачи..."
                                        value={newItem}
                                        onChange={(e) => this.setState({ newItem: e.target.value })}
                                    />
                                    <button onClick={this.addItem}>Добавить</button>
                                </div>
                                <ul className="tasks-list">
                                    {tasks?.map((item, i) => {
                                        return (
                                            <div key={i}>
                                                <li key={item.id} onClick={() => this.setState({ showEdit: item.id })}>
                                                    {item.value}
                                                    <button className="delete-button" onClick={() => this.deleteItem(item.id)}><i className="bi bi-x"></i></button>
                                                </li>

                                                {showEdit === item.id ? (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={updatedText}
                                                            onChange={(e) => this.setState({ updatedText: e.target.value })}
                                                            placeholder="Изменить задачу"
                                                        />
                                                        <button onClick={() => this.editItem(item.id, updatedText)}><i className="bi bi-check"></i></button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="item-block">
                                <span>Время выполнения программы</span>
                                <input type="number" value={countDays} onChange={(e) => this.setState({ countDays: Number(e.target.value) })} />дней
                            </div>
                        </div>
                        <div className="footer">
                            {this.state.programId && this.state.programId > 0 ? (
                                <button style={{marginRight:"10px",backgroundColor:"#CCC"}} onClick={() => this.setOpenDeleteConfirm(true)}>Удалить</button>
                            ) : null}
                            <button onClick={() => this.save()}>{!this.state.programId || this.state.programId <= 0 ? 'Создать' : 'Сохранить'}</button>
                        </div>
                    </div>
                </div>
                {this.state.isOpenDeleteConfirm && this.state.programId > 0 && (
                    <Confirm
                        message={'Вы уверены, что хотите удалить программу?'}
                        action={() => {
                            this.delete();
                        }}
                        close={() => { this.setOpenDeleteConfirm(false) }}
                    />
                )}
                <Alert />
            </Template>
        );
    }
}
Program.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any,
};


export default Program;