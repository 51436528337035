import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (id)		=> await Http.get('programchildtasks', {conditions:[{k:'id',v:id}]});
const getByChildId		= async (childId)   => await Http.get('programchildtasks', {conditions:[{k:'childId',v:childId}]});
const getAll	        = async ()	        => await Http.get('programchildtasks', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('programchildtasks', {data});
const update	        = async (programTaskId, childId, data)	=> await Http.put('programchildtasks', {data,conditions:[{k:'programTaskId',v:programTaskId},{k:'childId',v:childId}]});

export {
	getAll,
	get,
	add,
	update,
    getByChildId
}