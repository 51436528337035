import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {UserGroups,UserGroupRoles,Roles} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class GroupScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			roles:[],
			grouproles:[],
			selected:[],
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const groups = await UserGroups.get(this.state.id);
			if (groups.data && groups.data.length) {
				const roles = await Roles.getAll();
				const grouproles = await UserGroupRoles.byGroupGet(this.state.id);
				const selected = grouproles.data.map((v) => (v.roleId));
				this.setState({data:groups.data[0],roles:roles.data,grouproles:grouproles.data,selected,notfound:false});
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.type==='checkbox'?(e.target.checked?1:0):e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name
		};
		if (id === 0) {
			const res = await UserGroups.add(d).catch(() => this.error());
			if (res) Errors.add();
			else return false;
		} else {
			await UserGroups.update(id, d).catch(() => this.error());
			await this.roleAdd(id);
			Errors.update();
		}
		this.props.history.push('/groups');
		return false;
	}
	error = () => Errors.error();
	roleAdd = async (id) => {
		await UserGroupRoles.remove(id);
		this.state.selected.forEach((v) => UserGroupRoles.add({userGroupId:id,roleId:v}));
	}
	formCheckHandle = (e, id) => {
		const selected = [];
		if (e.target.checked) selected.push(id);
		this.state.selected.forEach((v) => {
			if (v !== id) selected.push(v);
		});
		this.setState({selected});
	}
	render() {
		return <Template
					page={'settings'}
					title={this.state.id === 0 ? 'Добавление группы' : 'Редактирование группы'}
					controlPanel={
						<div className="form-control-panel">
							<button form="groups-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/groups'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/users'} /> :
						<form id="groups-form" className="form" onSubmit={this.save}>
							<div className="form-row">
								<label htmlFor="name">Название группы</label>
								<input type="text" name="name" id="name" placeholder="Название группы" required className="double" autoFocus={true} onChange={this.formHandle} value={this.state.data.name} />
							</div>
							<h4>Список ролей</h4>
							<ul>
								{this.state.roles.map((v,i) => <li key={i}>
									<label>
										<input type="checkbox" name="selected" defaultChecked={this.state.selected.includes(v.id)} onChange={(e) => this.formCheckHandle(e, v.id)} /> {v.name}
									</label>
								</li>)}
							</ul>
						</form>
			}
			<Alert />
		</Template>
	}
}

GroupScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default GroupScreen;