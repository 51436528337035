import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Journal from '../../Components/Journal';

// globals
import {journalType} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ChildCarrectionDynamicScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0)
		};
	}
	back = () => this.props.history.push('/psychology-childs');
	render() {
		return <Template
					page={'departments'}
					title={'Анализ звонков'}
					controlPanel={
						<div className="form-control-panel">
							<button form="journal-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/psychology-childs'}>
						<Journal id={this.state.id} isparent={false} type={journalType.CALL} iscorrection={false} back={this.back} />
		</Template>
	}
}

ChildCarrectionDynamicScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default ChildCarrectionDynamicScreen;