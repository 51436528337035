import PropTypes from 'prop-types';
import './styles.scss';
import { useEffect, useState } from 'react';
import Confirm from '../Confirm';
import {alertShow} from '../Alert';
import { Training } from '../../Models'
import { LS } from '../../Globals';
import Loader from '../Loader';

// eslint-disable-next-line no-unused-vars
const TrainingModal = ({ isOpen, setIsOpen, updateTraining, trainingId }) => {
    const [ textAreaName, setTextAreaName ] = useState('');
    const [ textAreaContent, setTextAreaContent ] = useState('');
    const autoResizeTextArea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

    const [ isOpenCancelConfirm, setOpenCancelConfirm ] = useState(false);
    const [ isOpenSaveConfirm, setOpenSaveConfirm ] = useState(false);
    const [ trainingStatus, setTrainingStatus ] = useState(false);
    const [ wasEdit, setWasEdit ] = useState(false);
    const areaId = parseInt(LS.get('areaId'));
    const [ isLoading, setIsLoading ] = useState(true);

    const handleChange = (e, textareaType) => {
        if (!wasEdit) setWasEdit(true);
        if (textareaType === 'desc') {
            setTextAreaName(e.target.value);
        } else if (textareaType === 'task') {
            setTextAreaContent(e.target.value);
        }
    }

    const forceClose = () => {
        setOpenCancelConfirm(false);
        setOpenSaveConfirm(false);
        setWasEdit(false);
        setIsOpen(false);
    }

    const cancel = (force) => {
        if (!force && wasEdit) return setOpenCancelConfirm(true);
        forceClose();
    };

    const save = (force) => {
        if (force) {
            return forceClose();
        }
        setOpenSaveConfirm(true);
    };

    useEffect(() => {
        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                if (isOpen && wasEdit) setOpenCancelConfirm(true);
                else forceClose();
            }
        }
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [isOpen, wasEdit, cancel]);
    
    useEffect(() => {
        const getTrainingById = async (id) => {
            setIsLoading(true);
            const currentTraining = await Training.get(id);
            if (currentTraining?.data && currentTraining?.data?.length > 0) {
                setTextAreaContent(currentTraining.data[0].content);
                setTextAreaName(currentTraining.data[0].description);
                setTrainingStatus(currentTraining.data[0].status);
            } else {
                setTextAreaName('');
                setTextAreaContent('');
                setTrainingStatus(false);
            }
            setIsLoading(false);
        }
        getTrainingById(trainingId);
        updateTraining();
    }, [trainingId, setIsOpen])

    if (!isOpen) return null;

    return (
        <div className="note-modal-container" onClick={() => cancel(false)}>
            <div className="note-modal-content" onClick={(e) => e.stopPropagation()}>
            {isLoading ? <Loader /> : <>
                <div className="note-modal-header">
                    {trainingId <= 0 ? <h3>Добавить курс</h3> : <h3>Курс {textAreaName}</h3>}
                    <i className="bi bi-x-square" onClick={() => cancel(false)}></i>
                </div>
                <div className="note-modal-body">
                    <div>
                        <span>Название курса:</span>
                        <textarea value={textAreaName} onChange={(e) => handleChange(e, 'desc')} onInput={(e) => autoResizeTextArea(e, 'desc')}></textarea>
                    </div>
                    <div>
                        <span>Описание:</span>
                        <textarea value={textAreaContent} onChange={(e) => handleChange(e, 'task')} onInput={(e) => autoResizeTextArea(e, 'task')}></textarea>
                    </div>
                </div>
                <div className="note-modal-footer">
                    <div className="buttons">
                        <button className="save" onClick={() => save(false)}>Сохранить</button>
                        <button className="cancel" onClick={() => cancel(false)}>Отменить</button>
                    </div>
                </div>
                <div>
                    <span>Курс активен</span>
                    <input
                        type="checkbox"
                        checked={trainingStatus}
                        onChange={() => {
                            setTrainingStatus(!trainingStatus);
                            setWasEdit(true);
                        }}
                    />
                </div>
            </>}
            </div>
            {isOpenCancelConfirm && (
                <Confirm
                    message={'Вы уверены, что хотите закрыть без сохранения?'}
                    action={() => {
                        forceClose();
                        alertShow('Закрыто без сохранения', true);
                    }}
                    close={() => { setOpenCancelConfirm(false) }}
                />
            )}
            {isOpenSaveConfirm && (
                <Confirm
                    message={wasEdit ? 'Вы уверены, что хотите сохранить курс?' : 'Вы ничего не заполнини, закрыть без изменений?'}
                    action={() => {
                        if (wasEdit) {
                            forceClose();
                            alertShow('Успешно сохранено!');
                            if (trainingId && trainingId > 0) {
                                Training.update(trainingId, {
                                    description: textAreaName,
                                    content: textAreaContent,
                                    status: trainingStatus
                                }).then(() => {
                                    updateTraining();
                                });
                            } else {
                                Training.add({
                                    areaId: areaId,
                                    description: textAreaName,
                                    content: textAreaContent,
                                    status: 1,
                                }).then(() => {
                                    updateTraining();
                                });
                            }
                        } else {
                            forceClose();
                            alertShow('Ничего не изменено!');
                        }
                    }}
                    close={() => { setOpenSaveConfirm(false); }}
                />
            )}
        </div>
    );
};

TrainingModal.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateTraining: PropTypes.func,
    trainingId: PropTypes.number
}

export default TrainingModal;