import Http from '../../Globals/Http';
import {requestOderType} from "../../Globals/Constants";

const get		= async (childId, type)	=> await Http.get('childcorrectionjournals', {conditions:[{k:'childId',v:childId},{k:'type',v:type}], orders:[{k:'date', isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('childcorrectionjournals', {data});

const remove 	= async (id) 			=> await Http.remove('childcorrectionjournals', {conditions:[{k:'id',v:id}]});

export {
	get,
	add,
	remove
}