import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (teacherId)		=> await Http.get('teacherdocuments', {conditions:[{k:'teacherId',v:teacherId}],orders:[{k:'id',isd:requestOderType.DESC}]});
const getAll	= async ()				=> await Http.get('teacherdocuments', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (id)			=> await Http.get('teacherdocuments', {conditions:[{k:'id',v:id}]});

const add		= async (data)			=> await Http.post('teacherdocuments', {data});
const remove	= async (id)			=> await Http.remove('teacherdocuments', {conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	getById,
	add,
	remove
}