const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? '//localhost' : '//api.vremya-pervyh-crm.ru';

const API = {
	url			: `${url}/api`,
	assets		: `${url}/assets`,
	documents	: `${url}/documents`,
	documentsExcel	: `${url}/exportexcel`,
	key			: '2dc72c48-b48d-4e73-af45-13b19dc2620b',
	version		: '1.0.1'
};

const taskTypes = {
	PUPILS: 0,
	PARENTS: 1,
	INTENSIVE: 2,
	GROUP: 3,
	TRAINING: 4,
	OTHER: 5
};

const taskTypeNames = [
	'Воспитанники',
	'Родители',
	'Интенсив',
	'Групповая работа',
	'Тренинги',
	'Другое',
];

const taskChildTypes = {
	USTS: 0,
	GROUP: 1,
	RESULT: 2,
	OTHER: 3,
	DYNAMIC: 4,
	INDIVIDUAL: 5,
	SESSIONS: 6,
	INTENSIVE: 7,
	INTENSIVE2: 8,
	MEETS: 9,
	LITTLEGROUP: 10
};

const taskCategories = {
	PSYCHOLOGY: 0,
	CORRECTION: 1,
	OTHER: 2,
	HISTORY: 3
};

const taskCatNames = [
	'Психологическая работа',
	'Коррекционная работа',
	'Другое',
	'История выполнения задач'
];

const taskChildTypeNames = [
	'УСТС',
	'Групповая работа',
	'Итоги',
	'Другое',
	'Психодинамическая группа',
	'Индивидуальная работа',
	'Сессии',
	'Интенсив',
	'4-х дневный интенсив',
	'Встречи с детьми',
	'Малая группа'
];

const newsTypes = {
	INFO 	: 0,
	WARNING	: 1,
	PRIMARY	: 2,
	DANGER	: 3
};

const newsTypesName = [
	'info',
	'warning',
	'primary',
	'danger'
];

const newsTypesViewNames = {
	0: 'Информационное',
	1: 'Внимание',
	2: 'Основное',
	3: 'Критическое'
};

const requestConditionType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1,
	LESS		: 2,
	MORE		: 3,
	IS_NULL		: 4,
	NOT_NULL	: 5,
	LIKE		: 6
};

const requestConcatinationType = {
	AND			: 0,
	OR			: 1
};

const requestOderType = {
	ASC			: 0,
	DESC		: 1
};

const comonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const comonStatusName = ['не активен','активен'];

const programStatus = {
	READY: 0,
	START: 1,
	SUCCESS: 2,
	DELETED: 3,
	ARCHIVE: 4,
};

const familyRelationType = {
	MOM			: 0,
	DAD			: 1,
	CURATOR		: 2
};
const familyRelationTypeName = ['мать','отец','опекун'];

const genderType = {
	man			: 1,
	woman		: 2
};

const documentType = {
	sport: {
		PLAN		: 1,
		STANDARTS	: 2
	},
	methodic: {
		ARTICLE		: 3,
		BOOK		: 4,
		LECTURE		: 5,
		EXERCISE	: 6,
		PLAN		: 7
	},
	medic: {
		PURCHASES	: 8,
		F026		: 9
	},
	educationPersonal: {
		INFO		: 10,
		RULES		: 11,
		DRESS_CODE	: 12,
		ARTICLE		: 13,
		PRESENTATION: 14,
		SCHOOL		: 15,
		REGULAR_MAIN: 33,
		CENTER_RULE: 34,
		CRITERION_HANDKERCHIEF: 35,
		PROGRAM_STAGE_TASK: 36,
		FINE: 37,
		STIMULATION: 38,
		SYSTEM_CONSEQUENCE: 39,
		FUNCTION_PUPIL: 40,
		SYSTEM_COUPON: 41
	},
	hr: {
		STAFFTABLE	: 16,
		TIMESHEET	: 17,
		DECREE		: 18
	},
	household: {
		CHANCELLERY	: 19,
		CHEMICALS	: 20,
		COUPONS		: 21,
		FURNITURE	: 22,
		HOLIDAY		: 23,
		TECHNICS	: 24,
		SOFT		: 25,
		INVENTORY	: 26,
		ACTS		: 27,
		TRANSPORT	: 28,
	},
	education: {
		NOTIFY		: 29
	},
	office: {
		STATISTIC	: 30
	},
	correction: {
		RULES		: 31,
		DOCUMENT	: 32
	}
};

const mailType = {
	INBOX		: 1,
	OUTBOX		: 2
};
const mailTypeName = ['','входящее','исходящее'];

const journalType = {
	DIAGNOSTIC	: 1,
	INDIVIDUAL	: 2,
	CALL		: 3,
	SESSIONS	: 4,
	INTENSIVE	: 5,
	EDUCATION	: 6,
	DYNAMIC		: 7,
	EFFECT		: 8,
	PHOTODYNAMIC: 9,
	HANDKERCHIEF: 10,
	FUNCTION	: 11,
	MOUNTHDYNAMIC: 12,
	ANAMNESIS	: 13,
	TASKMG		: 14,
	MEDOSMOTR	: 15,
	SPORT       : 16,
	MEDCHRONIC	: 17,
	MEDTRAUMA   : 18,
	MEDPILLS    : 19,
	SPORTSECTIONS: 20,
	SPORTEFFECTS: 21,
	SPORTPHYSICS: 22,
	OTHERPARENT: 23,
	COMMENTS: 24,
	COMMENTSPSY: 25
};

const journalLinksCor = [
	'correction-diagnostic',
	'correction-individual',
	'correction-call',
	'correction-sessions',
	'correction-intensive',
	'correction-education',
	'correction-dynamic',
	'correction-effect',
	'correction-photo-dynamic',
	'correction-handkerchief',
	'correction-function',
	'correction-dynamic',
	'correction-anamnesis',
	'correction-taskmg',
	'medic-inspection',
	'sport',
	'medic-disease',
	'medic-trauma',
	'medic-pills',
	'sport-sections',
	'sport-effects',
	'sport-physic',
	'notes-parent',
	'comments',
	'commentspsy',
];

const journalLinksPsy = [
	'psychology-diagnostic',
	'psychology-individual',
	'psychology-call',
	'psychology-sessions',
	'psychology-intensive',
	'psychology-education',
	'psychology-dynamic',
	'psychology-effect',
	'psychology-photo-dynamic',
	'psychology-handkerchief',
	'psychology-function',
	'psychology-dynamic',
	'psychology-anamnesis',
	'psychology-taskmg',
	'medic-inspection',
	'sport',
	'medic-disease',
	'medic-trauma',
	'medic-pills',
	'sport-sections',
	'sport-effects',
	'sport-physic',
	'notes-parent',
	'comments',
	'commentspsy',
];


const journalParentsPsy = [
	'psychology-parent-diagnostic',
	'psychology-parent-individual',
	'psychology-parent-call',
	'psychology-parent-sessions',
	'psychology-parent-intensive',
	'psychology-parent-education',
	'psychology-parent-dynamic',
	'psychology-parent-effect',
	'psychology-parent-photo-dynamic',
	'psychology-parent-handkerchief',
	'psychology-parent-function',
	'psychology-parent-dynamic',
	'psychology-parent-anamnesis',
	'psychology-parent-taskmg',
	'medic-parent-inspection',
	'parent-sport',
	'medic-parent-disease',
	'medic-parent-trauma',
	'medic-parent-pills',
	'sport-parent-sections',
	'sport-parent-effects',
	'sport-parent-physic',
	'notes-parent',
	'comments',
	'commentspsy',
];

const journalTypeName = [
	'диагностика',
	'индивидуальная работа',
	'звонки',
	'сессии',
	'интенсивы',
	'школа',
	'динамика',
	'последствия',
	'фотодинамика',
	'платок',
	'функция',
	'динамика за месяц',
	'анамнез',
	'задания МГ',
	'еженедельный осмотр',
	'спорт',
	'хронические заболевания',
	'травмы',
	'прием таблеток',
	'спорт. секции',
	'последствия',
	'физ. состояние',
	'записи',
	'комментарии',
	'комментарии'
];

const eventType = {
	LOGIN			: 0,
	USER			: 1,
	TEACHER			: 2,
};
const eventTypeName = [
	'Вход в систему',
	'Пользователь',
	'Просмотр страницы',
	'Добавление данных',
	'Просмотр данных',
	'Изменение данных',
	'Выгрузка данных',
	'Удаление данных'
];

const typeUsers = {
	PARENT: 1,
	PUPIL: 2,
	TEACHER: 3,
};

const typeUserNames = [
	'',
	'Родители',
	'Воспитанники',
	'Сотрудники'
];

const typePeople = {
	TEACHER		: 1,
	EMPLOYEE	: 2,
	VOLUNTEER	: 3,
	HR			: 4
};

const userGroupsName = {
	HR			: 6
};

const eventMessageName = {
	viewDataCalendar				: 'Просмотр - /главной страницы/ даты в календаре',
	viewMainPage					: 'Просмотр - /главной страницы/',
	viewPeoplePage					: 'Просмотр страницы - /личное дело/',
	viewPsychologyPage				: 'Просмотр страницы - /психологическая работа/',
	viewCorrectionPage				: 'Просмотр страницы - /коррекционная работа/',
	viewPsychologyChildPage			: 'Просмотр страницы - /психологическая работа/дети/',
	viewCorrectionChildPage			: 'Просмотр страницы - /коррекционная работа/дети/',
	viewCorrectionTimetablePage		: 'Просмотр страницы - /коррекционная работа/расписание/',
	viewPsychologyParentPage		: 'Просмотр страницы - /психологическая работа/родители/',
	viewEducationPage				: 'Просмотр страницы - /учебная часть/',
	viewTabelTeacher				: 'Просмотр страницы - /учебная часть/табель учителей/',
	viewEducationChildPage			: 'Просмотр страницы - /учебная часть/успеваемость/',
	viewOfficePage					: 'Просмотр страницы - /делопроизводство/',
	viewOfficePageNumber			: 'Просмотр - /делопроизводство/письма/редактирование письма №',
	viewOfficeCourtNumber			: 'Просмотр - /делопроизводство/судебные дела/редактирование письма №',
	viewOfficeCourtPage				: 'Просмотр страницы - /делопроизводство/судебные дела/',
	viewHrPage						: 'Просмотр страницы - /кадровая работа/',
	viewHrTimesheetPage				: 'Просмотр страницы - /кадровая работа/табель/',
	viewHrTeachersPage				: 'Просмотр страницы - /кадровая работа/учителя, сотрудники/',
	viewHrDocumentPage				: 'Просмотр страницы - /кадровая работа/документы/',
	viewOfficeMailPage				: 'Просмотр страницы - /делопроизводство/письма/',
	viewOfficeMailNumber			: 'Просмотр - /делопроизводство/письма/',
	viewOfficeCourtMailNumber		: 'Просмотр - /делопроизводство/судебные дела/редактирование письма №',
	viewHouseholdPage				: 'Просмотр страницы - /хозяйственная часть/',
	viewHouseholdInventory			: 'Просмотр страницы - /хозяйственная часть/инвенторизация/',
	viewHouseholdPurcasePage		: 'Просмотр страницы - /хозяйственная часть/закупки/',
	viewHouseholdTransportPage		: 'Просмотр страницы - /хозяйственная часть/транспорт/',
	viewHouseholdActPage			: 'Просмотр страницы - /хозяйственная часть/акты/',
	viewOfficeContractorPage		: 'Просмотр страницы - /делопроизводство/контрагенты/',
	viewOfficeStatisticPage			: 'Просмотр страницы - /делопроизводство/статистика/',
	viewPsychologyGroupBoysPage		: 'Просмотр страницы - /психологическая работа/групповая работа мальчиков/',
	viewPsychologyGroupGirlsPage	: 'Просмотр страницы - /психологическая работа/групповая работа девочек/',
	viewCorrectionGroupBoysPage		: 'Просмотр страницы - /коррекционная работа/групповая работа мальчиков/',
	viewCorrectionRulesPage			: 'Просмотр страницы - /коррекционная работа/правила центра/',
	viewEducationAttestaPage		: 'Просмотр страницы - /учебная часть/успеваемость/справка об аттестации/',
	viewHrDocumentVacationPage		: 'Просмотр страницы - /кадровая работа/документы/отпуск/',
	viewHrDocumentContractsPage		: 'Просмотр страницы - /кадровая работа/документы/договор/',
	viewMedicPillPage				: 'Просмотр данных - /медицинская часть/дети/прием таблеток/',
	viewSportChildEffectPage		: 'Просмотр данных - /спортивная часть/дети/последствия/',
	viewMedicInspectionPage			: 'Просмотр данных - /медицинская часть/дети/осмотр/',
	viewMedicTaumaPage				: 'Просмотр данных - /медицинская часть/дети/травмы/',
	viewMedicCardPage				: 'Просмотр страницы - /медицинская часть/дети/амбулаторная карта/',
	viewMedicF026Page				: 'Просмотр страницы - /медицинская часть/дети/форма №026/',
	viewEducationPersonalPage		: 'Просмотр страницы - /обучение персонала/',
	viewMethodicLiteraturePage		: 'Просмотр данных - /методическая часть/литература/',
	viewSportPlanPage				: 'Просмотр данных - /спортивная часть/план/',
	viewSportStandartsPage			: 'Просмотр данных - /спортивная часть/нормативы/',
	viewMethodicProgramPage			: 'Просмотр данных - /методическая часть/программа/',
	viewSportPage					: 'Просмотр страницы - /спортивная часть/',
	viewNotificationPage			: 'Просмотр данных - /уведомления/сообщения/',
	viewSportChildsPage				: 'Просмотр страницы - /спортивная часть/дети/',
	viewMethodicPage				: 'Просмотр страницы - /методическая часть/',
	viewMedicPurchasesPage			: 'Просмотр данных - /медицинская часть/закупка медикаментов/',
	viewArchiveChildPage			: 'Просмотр страницы - /архив/дети/',
	viewMedicPage					: 'Просмотр страницы - /медицинская часть/',
	viewMedicChildsPage				: 'Просмотр страницы - /медицинская часть/дети/',
	viewArchiveParentPage			: 'Просмотр страницы - /архив/родители/',
	viewArchivePage					: 'Просмотр страницы - /архив/',
	viewArchiveTeacherPage			: 'Просмотр страницы - /архив/учителя, сотрудники/',
	viewHrDocumentStafftablePage	: 'Просмотр страницы - /кадровая работа/штатное расписание/',
	viewHrDocumentExplanatoryPage	: 'Просмотр страницы - /кадровая работа/документы/объяснительная/',
	viewHrDocumentEncouragementPage	: 'Просмотр страницы - /кадровая работа/документы/поощрения/',
	viewHrFinesDocument				: 'Просмотр документа - /кадровая работа/документы/штрафы/',
	viewHrDocumentFinesPage			: 'Просмотр страницы - /кадровая работа/документы/штрафы/',
	viewHrMaternityleaveDocument	: 'Просмотр - /кадровая работа/документы/декрет/документ',
	viewHrDocumentMaternityleavePage: 'Просмотр страницы - /кадровая работа/документы/декрет/',
	viewCorrectionGroupGirlsPage	: 'Просмотр страницы - /коррекционная работа/групповая работа девочек/',
	viewCorrectionChildHandkerchiefPage: 'Просмотр страницы - /коррекционная работа/дети/платок ребенка/',
	viewCorrectionChildTaskmgPage	: 'Просмотр страницы - /коррекционная работа/дети/задания МГ ребенка/',
	viewCorrectionChildFunction		: 'Просмотр страницы - /коррекционная работа/дети/функции ребенка/',
	viewPsychologyChildAnamnesisPage: 'Просмотр страницы - /психологическая работа/дети/анамнез/',
	viewPsychologyParentAnamnesisPage: 'Просмотр страницы - /психологическая работа/родители/анамнез/',
	viewEducationJournalPage		: 'Просмотр страницы - /учебная часть/успеваемость/электронный дневник ребенка/',
	viewEducationJournalRatePage	: 'Просмотр - /учебная часть/успеваемость/электронный дневник ребенка/дата',
	viewCorrectionChildAnamnesisPage: 'Просмотр страницы - /коррекционная работа/дети/анамнез/',
	viewMedicChildAnamnesis			: 'Просмотр данных - /медицинская часть/дети/анамнез/',
	viewSportChildSection			: 'Просмотр данных - /спортивная часть/дети/спортивные секции/',
	viewSportChildPhisic			: 'Просмотр данных - /спортивная часть/дети/физическое состояние/',
	viewMedicChildDisease			: 'Просмотр данных - /медицинская часть/дети/заболевания/',
	viewPsychologyChild				: 'Просмотр страницы - /психологическая работа/дети/',
	viewCorrectionChild				: 'Просмотр страницы - /коррекционная работа/дети/',
	viewPsychologyParent			: 'Просмотр страницы - /психологическая работа/родители/',
	viewPeopleChildsPage			: 'Просмотр страницы - /личное дело/дети/',
	viewPeopleChildEdit				: 'Просмотр страницы - /личное дело/дети/редактирование/',
	viewPeopleTeacherEdit			: 'Просмотр страницы - /личное дело/учителя/редактирование/',
	viewHrTeacherEdit				: 'Просмотр страницы - /кадровая работа/учителя, сотрудники/редактирование/',
	viewPeopleEmployeeEdit			: 'Просмотр страницы - /личное дело/сотрудники/редактирование/',
	viewPeopleVolunteerEdit			: 'Просмотр страницы - /личное дело/волонтеры/редактирование/',
	viewPeopleChildEditDocument		: 'Просмотр - /личное дело/дети/редактирование ребенка/документы',
	viewPeopleParentsPage			: 'Просмотр страницы - /личное дело/родители/',
	viewPeopleTeacherPage			: 'Просмотр страницы - /личное дело/учителя/',
	viewPeopleEmpolyeesPage			: 'Просмотр страницы - /личное дело/сотрудники/',
	viewPeopleVolunteerPage			: 'Просмотр страницы - /личное дело/волонтеры/',
	viewPeopleParentEdit			: 'Просмотр страницы - /личное дело/родители/редактирование родителя/',
	viewPeopleParentEditDocument	: 'Просмотр - /личное дело/родители/редактирование родителя/документы',
	viewPeopleTeacherEditDocument	: 'Просмотр - /личное дело/учителя/редактирование учителя/документы',
	viewHrTeacherEditDocument		: 'Просмотр - /кадровая работа/учителя, сотрудники/редактирование учителя/документы',
	viewCorrectionRulesDocument		: 'Просмотр - /коррекционная работа/правила/документы',
	viewOfficeContractorDocument	: 'Просмотр - /делопроизводство/контрагенты/документы',
	viewOfficeStatisticDocument		: 'Просмотр - /делопроизводство/статистика/документы',
	viewPeopleEmployeeEditDocument	: 'Просмотр - /личное дело/сотрудники/редактирование сотрудника/документы',
	viewPeopleVolunreerEditDocument	: 'Просмотр - /личное дело/волонтеры/редактирование волонтера/документы',
	viewDocumentEducationAttestat	: 'Просмотр - /учебная часть/успеваемость/справка об аттестации ребенка',
	viewHrDocumentVacation			: 'Просмотр - /кадровая работа/документы/ребенок/отпуск/документы',
	viewHrDocumentContract			: 'Просмотр - /кадровая работа/документы/ребенок/договор/документы',
	viewHrDocumentStafftable		: 'Просмотр - /кадровая работа/штатное расписание/документы',
	viewHrDocumentDecree			: 'Просмотр - /кадровая работа/приказы/документы',
	viewHrDocumentDecreePage		: 'Просмотр страницы - /кадровая работа/приказы/',
	viewDocumentMedicCard			: 'Просмотр - /медицинская часть/дети/амбулаторная карта/документы',
	viewDocumentMedicF026			: 'Просмотр - /медицинская часть/дети/форма №026/документы',
	viewEducationPersonalDocument	: 'Просмотр - /обучение персонала/документы',
	viewMethodicLiteratureDocument	: 'Просмотр - /методическая часть/литература/документы',
	viewSportPlanDocument			: 'Просмотр - /спортивная часть/план/документы',
	viewSportStandartDocument		: 'Просмотр - /спортивная часть/нормативы/документы',
	viewMethodicProgramDocument		: 'Просмотр - /методическая часть/программа/документы',
	viewMedicPurchasesDocument		: 'Просмотр - /медицинская часть/закупки медикаментов/документы',
	viewHrDocumentExplanatory		: 'Просмотр - /кадровая работа/документы/объяснительная/документы',
	viewHrDocumentEncouragement		: 'Просмотр - /кадровая работа/поощрения/документы',
	viewHouseholdPurchaseDocument	: 'Просмотр - /хозяйственная часть/закупки/документы',
	viewHouseholdTransportDocument	: 'Просмотр - /хозяйственная часть/траспорт/документы',
	viewHouseholdActDocument		: 'Просмотр - /хозяйственная часть/акты/документы',
	dowloadPeopleChildEditDocument	: 'Скачивание - /личное дело/дети/редактирование ребенка/документы',
	dowloadPeopleParentEditDocument	: 'Скачивание - /личное дело/родители/редактирование родителя/документы',
	dowloadPeopleTeacherEditDocument: 'Скачивание - /личное дело/учителя/редактирование учителя/документы',
	dowloadHrTeacherEditDocument	: 'Скачивание - /кадровая работа/учителя/документы',
	dowloadCorrectionRulesDocument	: 'Скачивание - /коррекционная работа/правила центра/документы',
	dowloadOfficeContractorDocument	: 'Скачивание - /делопроизводство/контрагенты/документы',
	dowloadOfficeStatisticDocument	: 'Скачивание - /делопроизводство/статистика/документы',
	dowloadPeopleEmployeeEditDocument: 'Скачивание - /личное дело/сотрудники/редактирование сотрудника/документы',
	dowloadPeopleVolunteerEditDocument: 'Скачивание - /личное дело/волонтеры/редактирование волонтера/документы',
	dowloadPsychologyExport			: 'Экспорт - /психологическая работа/excel файла:',
	dowloadPeopleExport				: 'Экспорт - /личное дело/excel файла:',
	dowloadCorrectionExport			: 'Экспорт - /коррекционная работа/excel файла:',
	dowloadArchiveExport			: 'Экспорт - /архив/excel файла:',
	dowloadMedicExport				: 'Экспорт - /медицинская часть/excel файла:',
	dowloadSportExport				: 'Экспорт - /спортивная часть/excel файла:',
	dowloadDocumentEducationAttestat: 'Скачивание - /учебная часть/успеваемость/дети/справка об аттестации/',
	dowloadHrDocumentVacation		: 'Скачивание - /кадровая работа/документы/отпуск/документы',
	dowloadHrDocumentContract		: 'Скачивание - /кадровая работа/договор/документы',
	dowloadHrDocumentStafftable		: 'Скачивание - /кадровая работа/штатное расписание/документы',
	dowloadHrDocumentDecree			: 'Скачивание - /кадровая работа/приказы/документы',
	dowloadDocumentMedicCard		: 'Скачивание - /медицинская часть/дети/амбулаторная карта/документы',
	dowloadDocumentMedicF026		: 'Скачивание - /медицинская часть/дети/форма №026/документы',
	dowloadEducationPersonalDocument: 'Скачивание - /обучение персонала/документы',
	dowloadMethodicLiteratureDocument: 'Скачивание - /методическая часть/литература/документы',
	dowloadSportStandartDocument	: 'Скачивание - /спортивная часть/нормативы/документы',
	dowloadSportPlanDocument		: 'Скачивание - /спортивная часть/план/документы',
	dowloadMethodicProgramDocument	: 'Скачивание - /методическая часть/программа/документы',
	dowloadMedicPurchaseslDocument	: 'Скачивание - /медицинская часть/закупка медикаментов/документы',
	dowloadHrDocumentExplanatory	: 'Скачивание - /кадровая работа/документы/объяснительная/документы',
	dowloadHrDocumentEncouregement: 'Скачивание из кадровой работы в поощрения документа',
	dowloadHrDocumentFines			: 'Скачивание - /кадровая работа/документы/штрафы/документы',
	dowloadHrDocumentMaternityleave	: 'Скачивание - /кадровая работа/документы/декрет/документы',
	dowloadHouseholdPurchaseDocument: 'Скачивание - /хозяйственная часть/закупки/документы',
	dowloadHouseholdTransportDocument: 'Скачивание - /хозяйственная часть/транспорт/документы',
	dowloadHouseholdActDocument		: 'Скачивание - /хозяйственная часть/акты/документы',
	dowloadOfficeMailNumber			: 'Скачивание - /делопроизводство/письма/редактирование письма/письма',
	dowloadOfficeCourtMailNumber	: 'Скачивание - /делопроизводство/судебные дела/редактирование письма/письма',
	deletePeopleChildEditDocument	: 'Удаление - /личное дело/дети/редактирование ребенка/документы',
	deletePeopleParentEditDocument	: 'Удаление - /личное дело/родители/редактирование родителя/документы',
	deletePeopleTeacherEditDocument	: 'Удаление - /личное дело/учителя/редактирование учителя/документы',
	deleteHrTeacherEditDocument		: 'Удаление - /кадровая работа/учителя, сотрудники/редактирование учителя/документы',
	deleteCorrectionRulesDocument	: 'Удаление - /коррекционная работа/правила/документы',
	deleteOfficeContractorDocument	: 'Удаление - /делопроизводство/контрагенты/документы',
	deleteOfficeStatisticDocument	: 'Удаление - /делопроизводство/статистика/документы',
	deleteDocumentEducationAttestat	: 'Удаление - /учебная часть/успеваемость/справка об аттестации ребенка/документы',
	deleteHrDocumentVacation		: 'Удаление - /кадровая работа/документы/отпуск/документы',
	deleteHrDocumentContract		: 'Удаление - /кадровая работа/документы/договор/документы',
	deleteHrDocumentStafftable		: 'Удаление - /кадровая работа/штатное расписание/документы',
	deleteHrDocumentDecree			: 'Удаление - /кадровая работа/приказы/документы',
	deleteDocumentMedicCard			: 'Удаление - /медицинская часть/дети/амбулаторная карта/документы',
	deleteDocumentMedicF026			: 'Удаление - /медицинская часть/дети/форма №026/документы',
	deleteEducationPersonalDocument	: 'Удаление - /обучение персонала/документы',
	deleteMethodicLiteratureDocument: 'Удаление - /методическая часть/литература/документы',
	deleteSportPlanDocument			: 'Удаление - /спортивная часть/план/документы',
	deleteSportStandartDocument		: 'Удаление - /спортивная часть/нормативы/документы',
	deleteMethodicProgramDocument	: 'Удаление - /методическая часть/программа/документы',
	deleteMedicPurchasesDocument	: 'Удаление - /медицинская часть/закупки медикаментов/документы',
	deleteHrDocumentExplanatory		: 'Удаление - /кадровая работа/документы/объяснительная/документ',
	deleteHrDocumentEncouragement	: 'Удаление - /кадровая работа/поощрения/документы',
	deleteHrDocumentFines			: 'Удаление - /кадровая работа/документы/штрафы/документы',
	deleteHrDocumentMaternityleave	: 'Удаление - /кадровая работа/документы/декрет/документы',
	deletePeopleEmployeeEditDocument: 'Удаление - /личное дело/сотрудники/редактирование сотрудника/документы',
	deletePeopleVolunteerEditDocument: 'Удаление - /личное дело/волонтеры/редактирование волонтера/документы',
	deleteHouseholdPurchaseDocument	: 'Удаление - /хозяйственная часть/закупки/документы',
	deleteHouseholdTransportDocument: 'Удаление - /хозяйственная часть/транспорт/документы',
	deleteHouseholdActDocument		: 'Удаление - /хозяйственная часть/акты/документы',
	deleteOfficeMailNumber			: 'Удаление - /делопроизводство/письма/редактирование письма/письма',
	deleteOfficeCourtMailNumber		: 'Удаление - /делопроизводство/судебные дела/редактирование письма/письма',
	changeBranch					: 'Смена филиала',
	addDataCalandar					: 'Добавление данных - /главная страница/календарь/',
	addNewChild						: 'Добавление - /личное дело/дети/добавление ребенка',
	addDocumentChild				: 'Добавление - /личное дело/дети/добавление ребенка/документы',
	addNewChildPhoto				: 'Добавление - /личное дело/дети/добавление ребенка/фото',
	addParentsChild					: 'Добавление - /личное дело/дети/добавление ребенка/родителя для ребенка',
	addNewParent					: 'Добавление - /личное дело/родители/добавление родителя',
	addNewTeacher					: 'Добавление - /личное дело/учителя/добавление учителя',
	addNewHrTeacher					: 'Добавление - /кадровая работа/учителя, сотрудники/добавление учителя',
	addNewEmployee					: 'Добавление - /личное дело/сотрудники/добавление сотрудника',
	addNewVolunteer					: 'Добавление - /личное дело/волонтеры/добавление волонтера',
	addNewAnamnesis					: 'Добавление - /психологическая работа/дети/анамнез',
	addNewAnamnesisParent			: 'Добавление - /психологическая работа/родители/анамнез',
	addNewRateEducationJiurnal		: 'Добавление - /учебная часть/успеваемость/электронный дневник/оценка',
	addNewAnamnesisCorrection		: 'Добавление - /коррекционная работа/дети/анамнез',
	addNewAnamnesisMedic			: 'Добавление - /медицинская часть/дети/анамнез',
	addSportChildPhisic				: 'Добавление - /спортивная часть/дети/физическое состояние',
	addSportChildSection			: 'Добавление - /спортивная часть/дети/спортивная секция',
	addNewDiseaseMedic				: 'Добавление - /медицинская часть/дети/заболевания',
	addNewHandkerchiefCorrection	: 'Добавление - /коррекционная работа/дети/платок',
	addNewTaskmgCorrection			: 'Добавление - /коррекционная работа/дети/задания МГ',
	addNewFunctionCorrection		: 'Добавление - /коррекционная работа/дети/функция',
	addPsychologyChild				: 'Добавление - /психологическая работа/дети/',
	addCorrectionChild				: 'Добавление - /коррекционная работа/дети/',
	addPsychologyParent				: 'Добавление - /психологическая работа/родители/',
	addPsychologyGroupBoys			: 'Добавление - /психологическая работа/групповая работа мальчиков',
	addPsychologyGroupGirls			: 'Добавление - /психологическая работа/групповая работа девочек',
	addCorrectionGroupBoys			: 'Добавление - /коррекционная работа/групповая работа мальчиков',
	addCorrectionTimetable			: 'Добавление - /коррекционная работа/расписание',
	addCorrectionRules				: 'Добавление - /коррекционная работа/правила',
	addOfficeContractor				: 'Добавление - /делопроизводство/контрагенты/документы',
	addOfficeStatistic				: 'Добавление - /делопроизводство/статистика/документы',
	addHouseholdPurchaseDocument	: 'Добавление - /хозайственная часть/закупки/документы',
	addHouseholdTransportDocument	: 'Добавление - /хозайственная часть/транспорт/документы',
	addOfficeMail					: 'Добавление - /делопроизводство/письма/добавление письма',
	addOfficeCourtMail				: 'Добавление - /делопроизводство/судебные дела/добавления письма',
	addHouseholdActDocument			: 'Добавление - /хозайственная часть/акты/документа',
	addHrDocumentVacation			: 'Добавление - /кадровая работа/документы/отпуск/документы',
	addHrDocumentContract			: 'Добавление - /кадровая работа/документы/договор/документы',
	addHrDocumentStafftable			: 'Добавление - /кадровая работа/штатное расписание/документы',
	addHrDocumentDecree				: 'Добавление - /кадровая работа/приказы/документы',
	addEntryPillMedic				: 'Добавление - /медицинская часть/дети/прием таблеток/запись',
	addSportChildEffect				: 'Добавление - /спортивная часть/дети/последствия/запись',
	addMedicInspection				: 'Добавление - /медицинская часть/дети/осмотр/запись',
	addEntryTraumaMedic				: 'Добавление - /медицинская часть/дети/травмы/запись',
	addDocumentMedicCard			: 'Добавление - /медицинская часть/дети/амбулаторная карта/документы',
	addDocumentMedicF026			: 'Добавление - /медицинская часть/дети/форма №026/документы',
	addEducationPersonalDocument	: 'Добавление - /обучение персонала/',
	addMethodicLiteratureDocument	: 'Добавление - /методическая часть/литература/',
	addSportPlanDocument			: 'Добавление - /спортивная часть/план/',
	addNotificationMessage			: 'Добавление - /уведомления/отправить/',
	addSportStandartDocument		: 'Добавление - /спортивная часть/нормативы/',
	addMethodicProgramDocument		: 'Добавление - /методическая часть/программа/',
	addMedicPurchasesDocument		: 'Добавление - /медицинская часть/закупки медикаментов/документы',
	addHrDocumentExplanatory		: 'Добавление - /кадровая работа/документы/объяснительная/документы',
	addHrDocumentEncouragement		: 'Добавление - /кадровая работа/документы/поощрения/документы',
	addHrDocumentFines				: 'Добавление - /кадровая работа/документы/штрафы/документы',
	addHrDocumentMaternityleave		: 'Добавление - /кадровая работа/документы/декрет/документы',
	addCorrectionGroupGirls			: 'Добавление - /коррекционная работа/групповая работа для девочек/данных',
	addNewTeacherTime				: 'Добавление - /личное дело/учителя/добавления учителя/график работы',
	addNewEmployeeTime				: 'Добавление - /личное дело/сотрудники/добавления сотрудника/график работы',
	addNewVolunteerTime				: 'Добавление - /личное дело/волонтеры/добавления волонтера/график работы',
	addNewParentPhoto				: 'Добавление - /личное дело/родители/добавления родителя/фото',
	addNewTeacherPhoto				: 'Добавление - /личное дело/учителя/добавления учителя/фото',
	addNewHrTeacherPhoto			: 'Добавление - /кадровая работа/учителя, сотрудники/добавления учителя/фото',
	addNewEmployeePhoto				: 'Добавление - /личное дело/сотрудники/добавления сотрудника/фото',
	addNewVolunteerPhoto			: 'Добавление - /личное дело/волонтеры/добавления волонтера/фото',
	addDocumentParent				: 'Добавление - /личное дело/родители/редактирование родителя/документы',
	addDocumentTeacher				: 'Добавление - /личное дело/учителя/редактирование учителя/документы',
	addDocumentHrTeacher			: 'Добавление - /кадровая работа/учителя, сотрудники/редактирование учителя/документы',
	addDocumentEmployee				: 'Добавление - /личное дело/сотрудники/редактирование сотрудника/документы',
	addDocumentVolunteer			: 'Добавление - /личное дело/волонтеры/редактирование волонтера/документы',
	addDocumentEducationAttestat	: 'Добавление - /учебная часть/успеваемость/справка об атестации/документы',
	updateDataCalendar				: 'Изменения данных - /главная страница/календарь/дата',
	updateDataChild					: 'Изменение данных - /личное дело/дети/редактирование ребенка/',
	updateChildPhoto				: 'Изменение - /личное дело/дети/редактирование ребенка/фото',
	updateDataParent				: 'Изменение данных - /личное дело/родители/редактирование родителя/',
	updateDataTeacher				: 'Изменение данных - /личное дело/учителя/редактирование учителя/',
	updateDataHrTeacher				: 'Изменение данных - /кадровая работа/учителя, сотрудники/редактирование учителя',
	updateArchiveChild				: 'Изменение (активация) - /архив/дети/',
	updateArchiveParent				: 'Изменение (активация) - /архив/родители',
	updateArchiveTeacher			: 'Изменение (активация) - /архив/учителя, сотрудника',
	updateDataHrStafftable			: 'Изменение - /кадровая работа/штатное расписание/документы',
	updateDataEmployee				: 'Изменение - /личное дело/сотрудники/редактирование сотрудника/',
	updateDataVolunteer				: 'Изменение - /личное дело/волонтеры/редактирование волонтера/',
	updateDataAnamnesis				: 'Изменение данных - /психологическая работа/дети/анамнез/',
	updateDataAnamnesisParent		: 'Изменение данных - /психологическая работа/родители/анамнез/',
	updateDocumentEducationAttestat	: 'Изменение - /учебная часть/успеваемость/справка об аттестации/документы',
	updateDataRateEducationJournal	: 'Изменение - /учебная часть/успеваемость/электронный дневник/оценка',
	updateHouseholdPurchaseStatus	: 'Изменение статуса (скрыть/показать) - /хозяйственная часть/закупки/документы',
	updateHouseholdTransportStatus	: 'Изменение статуса (скрыть/показать) - /хозяйственная часть/транспорт/документы',
	updateHouseholdActStatus		: 'Изменение статуса (скрыть/показать) - /хозяйственная часть/акты/документы',
	updateDataAnamnesisCorrection	: 'Изменение данных - /коррекционная работа/дети/анамнез/',
	updateDataAnamnesisMedic		: 'Изменение данных - /медицинская часть/дети/анамнез',
	updateSportChildPhisic			: 'Изменение данных - /спортивная часть/дети/физическое состояние',
	updateSportPlan					: 'Изменение данных - /спортивная часть/план/документы',
	updateSportStandart				: 'Изменение данных - /спортивная часть/нормативы/документы',
	updateSportChildSection			: 'Изменение данных - /спортивная часть/дети/спортивной секции',
	updateDataDiseaseMedic			: 'Изменение данных - /медицинская часть/дети/заболевания',
	updateNotificationStatus		: 'Изменение данных статуса (прочитанно/непрочитанно) - /уведомления/сообщения',
	updateDocumentMedicCard			: 'Изменение документа - /медицинская часть/дети/амбулаторная карта/документы',
	updateDocumentMedicPurchases	: 'Изменение документа - /медицинская часть/закупки медикаментов',
	updateDocumentMedicF026			: 'Изменение документа - /медицинская часть/дети/форма №026',
	updateDataHandkerchiefCorrection: 'Изменение данных - /коррекционная работа/дети/платок',
	updateDataTaskmgCorrection		: 'Изменение данных - /коррекционная работа/дети/задания МГ',
	updateDataFunctionCorrection	: 'Изменение данных - /коррекционная работа/дети/функции',
	updateDataTeacherTime			: 'Изменение - /личное дело/учителя/редактирование учителя/график работы',
	updateDataEmployeeTime			: 'Изменение - /личное дело/сотрудники/редактирование сотрудника/график работы',
	updateDataVolunteerTime			: 'Изменение - /личное дело/волонтеры/редактирование волонтера/график работы',
	updateParentPhoto				: 'Изменение - /личное дело/родители/редактирование родителя/фото',
	updateTeacherPhoto				: 'Изменение - /личное дело/учителя/редактирование учителя/фото',
	updateHrTeacherPhoto			: 'Изменение - /кадровая работа/учителя, сотрудники/редактирование учителя/фото',
	updateEmployeePhoto				: 'Изменение - /личное дело/сотрудники/редактирование сотрудника/фото',
	updateVolunteerPhoto			: 'Изменение - /личное дело/волонтеры/редактирование волонтера/фото',
	updateOfficeMail				: 'Изменение - /делопроизводство/письма/редактирование письма',
	updateOfficeCourtMail			: 'Изменение - /делопроизводство/судебные дела/редактирование письма',
	updateOfficeStatistic			: 'Изменение - /делопроизводство/статистика/документы',
	deactivatePeopleChild			: 'Смена статуса (активирование/деактивирование) - /личное дело/дети/',
	deactivatePeopleParent			: 'Смена статуса (активирование/деактивирование) - /личное дело/родители/',
	deactivatePeopleTeacher			: 'Смена статуса (активирование/деактивирование) - /личное дело/учителя/',
	deactivatePeopleEmpoyee			: 'Смена статуса (активирование/деактивирование) - /личное дело/сотрудники/',
	deactivatePeopleVolunteer		: 'Смена статуса (активирование/деактивирование) - /личное дело/волонтеры/',
}

export {
	API,
	url,
	newsTypes,
	newsTypesViewNames,
	newsTypesName,
	requestConditionType,
	requestConcatinationType,
	requestOderType,
	comonStatus,
	comonStatusName,
	familyRelationType,
	familyRelationTypeName,
	documentType,
	mailType,
	mailTypeName,
	journalType,
	journalTypeName,
	eventType,
	eventTypeName,
	eventMessageName,
	typePeople,
	genderType,
	userGroupsName,
	programStatus,
	journalLinksCor,
	journalLinksPsy,
	journalParentsPsy,
	taskTypes, taskTypeNames, taskChildTypes, taskChildTypeNames, taskCategories, taskCatNames,
	typeUserNames, typeUsers
};