import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		= async (id)			=> await Http.get('childs', {conditions:[{k:'id',v:id}]});
const getAll	= async (areaId)		=> await Http.get('childs', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'lastName',isd:requestOderType.ASC}]});
const getInactiveAll	= async (areaId)	=> await Http.get('childs', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.IN_ACTIVE}],orders:[{k:'lastName',isd:requestOderType.ASC}]});

const add		= async (data)			=> await Http.post('childs', {data});
const update	= async (id, data)		=> await Http.put('childs', {data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	getInactiveAll,
	get,
	add,
	update
}