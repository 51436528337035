import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= async ()				=> await Http.get('correctiontimetables', {orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('correctiontimetables', {data});

export {
	getAll,
	add
}