import {Component} from 'react';

// components
import Template from '../../Components/Template';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';

// models
import {Events,Teachers} from '../../Models';

// helpers
import {Helpers,LS} from '../../Globals';

class EventsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataShow:[],
			type:null,
			user:null,
			progress:10,
			startpage:0,
			loading:true,
			listUsers:[]
		};
	}
	pagesize = 300;
	componentDidMount = async () => {
		const areaId = parseInt(LS.get('areaId'));
		const user = Helpers.authCheck();
		const userAll = await Teachers.getAllWithoutType(areaId);
		this.setState({listUsers:userAll.data});
		Events.getPage([this.state.startpage,this.pagesize], (data => this.setState({dataShow:this.dataSet(data),data,user,loading:false})));
		document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			Events.getPage([startpage,this.pagesize], (d) => {
				if (d.length < this.pagesize) this.dataStop();
				const data = [...this.state.data,...d];
				this.setState({dataShow:this.dataSet(data),data,startpage});
			});
		}
	}
	dataSet = (data) => {
		let collection = [];
		let listUsers = {listUsers:[]};
		// Add collection users use in table
		data.forEach(el => {
			const user = this.state.listUsers.find(f => f.id === el.userId);
			if (user) {
				if (!listUsers['listUsers'].find(f => f.id === user.id)) {
					listUsers['listUsers'].push(user);
				}
			}
		});
		collection.push(listUsers);

		return dgDataPrepare(data, 'events', ['id','type','message','userId','dateCreate'], collection)
	};
	dataSearch = (data, search) => data.filter(f => f.message.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	dataType = (data, type) => data.filter(f => f.type === type);
	render() {
		return <Template page={'settings'} title={'События'}>
			{this.state.loading ? <Loader /> :
				this.state.data.length === 0 ? <NotFound nolink={true} /> :
					<DataGrid
						title={'События'}
						tableName={'events'}
						keys={['ID','Тип','Сообщение','Инициатор','Дата']}
						link={'event'}
						data={this.state.dataShow} />
			}
		</Template>
	}
}

export default EventsScreen;