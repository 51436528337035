import Http from '../../Globals/Http';

const get		= async (teacherId)	=> await Http.get('teachertimetables', {conditions:[{k:'teacherId',v:teacherId}]});

const add		= async (data)		=> await Http.post('teachertimetables', {data});
const update	= async (id, data)	=> await Http.put('teachertimetables', {data,conditions:[{k:'id',v:id}]});

export {
	get,
	add,
	update
}