import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Childs,ChildMedicTraumas,Events} from '../../Models';

// globals
import {eventMessageName} from '../../Globals/Constants';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ChildMedicTraumasScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			user:null,
			data:[],
			info:null,
			date:new Date(),
			child:{},
			notfound:null,
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const child = await Childs.get(this.state.id);
			if (child.data && child.data.length) {
				const medic = await ChildMedicTraumas.get(this.state.id);
				this.setState({data:medic.data||[],child:child.data[0],notfound:false,adddocumentshow:medic.data&&medic.data.length?false:true});

				// Add events in log
				const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
				Events.add(user.id, eventMessageName.viewMedicTaumaPage + fullName, null, null, null, 4);
			} else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	formHandle = (e) => this.setState({[e.target.name]:e.target.value});
	save = async (e) => {
		e.preventDefault();
		const {id,info,date} = this.state;
		const d = {
			childId:id,
			info,
			date:Math.round(new Date(date).getTime() / 1000)
		};
		const res = await ChildMedicTraumas.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		const getDataModel = (await Childs.get(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(this.state.user.id, eventMessageName.addEntryTraumaMedic + fullName, null, JSON.stringify(newData), null, 3);

		if (res) Errors.add();
		else return false;
		this.props.history.push('/medic-childs');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'departments'}
					title={'Травмы'}
					controlPanel={
						<div className="form-control-panel">
							<button form="medic-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/medic-childs'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/medic-childs'} /> :
						<form id="medic-form" className="form medic-traumas" onSubmit={this.save}>
							<div className="form-row">
								<label>имя ребенка</label>
								<h4>{this.state.child.lastName} {this.state.child.firstName} {this.state.child.middleName}</h4>
							</div>
							<div className="form-row form-row-block">
								<h3 onClick={() => this.adddocumentShow()}>Добавить запись</h3>
								{this.state.adddocumentshow ?
									<div className="form-row-block-inner">
										<div className="form-row">
											<label htmlFor="info">Описание</label>
											<textarea name="info" id="info" placeholder="Опишите проишествие" required onChange={this.formHandle}>{this.state.info}</textarea>
										</div>
										<div className="form-row">
											<label htmlFor="date">Дата отчета</label>
											<input type="datetime-local" name="date" id="date" className="date" required onChange={this.formHandle} value={this.state.date} />
										</div>
									</div> : null}
							</div>
							<div className="list-effects">
								{this.state.data.map((v,i) => <div key={i} className="list-effects-item">
									<div className="name">
										<span>{Moment(v.date*1000).format('DD.MM.y, H:mm')}</span>
										{v.info}
									</div>
								</div>)}
							</div>
						</form>
			}
			<Alert />
		</Template>
	}
}

ChildMedicTraumasScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default ChildMedicTraumasScreen;