import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DocumentViewer from '../../Components/DocumentViewer';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Childs,ChildDocuments,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,comonStatus,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

import PropTypes from 'prop-types';
class ChildMedicCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			user:null,
			document:null,
			filedocument:null,
			doctype:null,
			doc:null,
			type:28,
			notfound:null,
			documentview:false,
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const child = await Childs.get(this.state.id);
			if (child.data && child.data.length) {
				const data = child.data[0];
				this.setState({user,data,notfound:false});
				await this.documentsGet();

				// Add events in log
				Events.add(user.id, eventMessageName.viewMedicCardPage, null, null, null, 2);

				return;
			}
		}
		this.setState({notfound:true,loading:false});
	}
	documentsGet = async () => {
		const documents = await ChildDocuments.get(this.state.id);
		const document = documents.data.find(f => f.type === this.state.type);
		this.setState({document,adddocumentshow:document?false:true,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const document = (await ChildDocuments.getById(id)).data[0];
		const oldData = document;
		const dateDocument =  ' ' + Moment(document.dateCreate*1000).format('DD.MM.y');
		Events.add(user.id, eventMessageName.deleteDocumentMedicCard + ' ' + document.link + dateDocument, JSON.stringify(oldData), null, null, 7);
		
		await ChildDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true,adddocumentshow:false}, async () => await this.documentsGet(true));
	documentView = (child) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;
		const doctype = child.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const dateDocument =  ' ' + Moment(child.dateCreate*1000).format('DD.MM.y');
		Events.add(user.id, eventMessageName.viewDocumentMedicCard + ' ' + child.link + dateDocument, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = (child) => {
		const uri = `${API.documents}/?id=${this.state.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const dateDocument =  ' ' + Moment(child.dateCreate*1000).format('DD.MM.y');
		Events.add(user.id, eventMessageName.dowloadDocumentMedicCard + ' ' + child.link + dateDocument, null, null, null, 6);

		window.location.href = uri;
	}
	documentSave = async (e) => {
		e.preventDefault();
		const {id,filedocument,document,type} = this.state;
		const d = {
			childId:id,
			type,
			fileType:filedocument.type,
			link:this.filenameGet(id, filedocument.name),
			status:comonStatus.ACTIVE
		};
		if (document) {
			// Add events in log
			// get old data before update
			const dataModel = await ChildDocuments.getById(document.id);
			const getOldData = (data) => {
				const keysData = Object.keys(data);
				let oldData = {};
				keysData.forEach((key) => {
					let item = {}
					item[key] = dataModel.data[0][key];
					Object.assign(oldData, item);
				});
				return oldData;
			}
			const oldData = getOldData(d);
			const newData = d;

			await ChildDocuments.update(document.id, d).catch(() => this.error());

			// Add events in log
			const linkDocument = ' ' + dataModel.data[0].link;
			const dateDocument =  ' ' + Moment(dataModel.data[0].dateCreate*1000).format('DD.MM.y');
			Events.add(this.state.user.id, eventMessageName.updateDocumentMedicCard + linkDocument + dateDocument, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			Errors.update();
		} else {
			const res = await ChildDocuments.add(d).catch(() => this.error());

			// Add events in log
			const newData = d;
			const linkDocument = ' ' + d.link;
			Events.add(this.state.user.id, eventMessageName.addDocumentMedicCard + linkDocument, null, JSON.stringify(newData), null, 3);

			if (res) Errors.add();
			else return false;
		}
		this.fileSave(id, `documents/childs/${id}`, filedocument);
		this.documentsReload();
		return false;
	}
	error = () => Errors.error();
	fileSave = (id, path, file) => Files.upload(id, 'child', path, file);
	filenameGet = (id, filename) => `child_${id}_${filename}`;
	render() {
		return <Template
					page={'departments'}
					title={'Амбулаторная карта'}
					controlPanel={
						<div className="form-control-panel">
							<button form={'document-form'} type="submit">Сохранить</button>
						</div>
					}
					backLink={'/medic-childs'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/medic-childs'} /> :
						<form id={'document-form'} className="form" onSubmit={this.documentSave}>
							<div className="form-row form-row-block">
								<h3 onClick={() => this.adddocumentShow()}>{this.state.document ? 'Изменить документ' : 'Добавить документ'}</h3>
								{this.state.adddocumentshow ?
									<div className="form-row-block-inner">
										<div className="form-row">
											<label htmlFor="file">Файл</label>
											<input onChange={this.fileChange} name="file" className="file" required type="file" />
										</div>
									</div> : null}
							</div>
							{this.state.document ?
								<div className="list-documents">
									<div className="list-card-documents-item">
										<div className="name">
											<span>Форма №026 — {Moment(this.state.document.dateCreate*1000).format('DD.MM.y')}</span>
										</div>
										<div className="buttons">
											<i className="bi bi-eye" onClick={() => this.documentView(this.state.document)}></i>
											<i className="bi bi-cloud-download" onClick={() => this.documentDownload(this.state.document)}></i>
											<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(this.state.document.id)}></i>
										</div>
									</div>
								</div> : null}
						</form>
			}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

ChildMedicCard.propTypes = {
	match: PropTypes.object.isRequired,
};
export default ChildMedicCard;