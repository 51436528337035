import {Component} from 'react';
import { Link } from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Childs,ChildParents,Parents,Events} from '../../Models';

// helpers
import {Helpers,LS,Utils} from '../../Globals/index';

// globals
import {API,familyRelationTypeName,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class ChildsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			childparents:null,
			parents:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getAll(areaId);
		const parents = await Parents.getAll(areaId);
		const childparents = await ChildParents.getAll(areaId);
		this.setState({user,data:childs.data,parents:parents.data,childparents:childparents.data,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewPeopleChildsPage, null, null, null, 2);
	}
    deactivatePeople = async (id) => {
        const data = {status:0};

        // Add events in log
        // get old data before update
		const dataModel = await Childs.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
        const fullName = ' ' + dataModel.data[0].lastName + ' ' + dataModel.data[0].firstName + ' ' + dataModel.data[0].middleName;
		const oldData = getOldData(data);
        const newData = data;
        Events.add(this.state.user.id, eventMessageName.deactivatePeopleChild + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
        
        await Childs.update(id, data).then(() => {
            this.state.childparents.map(async (p) => {
                if (p.childId === id) {
                    const parent = this.parentGet(p.parentId);
                    if (parent) {
                        await Parents.update(parent.id,data);
                    }
                }
            });
        });
        this.updatePeople();
    }
    updatePeople = async () => {
        const areaId = parseInt(LS.get('areaId'));
        const childs = await Childs.getAll(areaId);
		this.setState({data:childs.data});
    }
	parentGet = (id) => this.state.parents.find(f => f.id === id);
	ageGet = (ts) => {
		const years = Moment().diff(ts*1000, 'years');
		return `${years} ${Utils.agesGet(years)}`;
	}
	render() {
		return <Template
					page={'departments'}
					title={'Список детей'}
                    controlPanel={
                        <div className="form-control-panel">
                            <Link to={'/child'} className="btn">Добавить</Link>
                        </div>
                    }
                    backLink={'/peoples'}>
                {this.state.loading ? <Loader /> :
                    this.state.data.map((v,i) => <div key={i} className="list-child-item">
                        <div className="avatar">
                            {v.photo ? <img src={`${API.assets}/childs/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
                        </div>
                        <div className="name">
                            <h3>{v.lastName}</h3>
                            <h4>{v.firstName} {v.middleName}</h4>
                            <div className="date">
                            {this.ageGet(v.birthDate)} ({Moment(v.birthDate*1000).format('DD.MM.y')}), {v.birthPlace}
                            </div>
                        </div>
                        <div className="parents">
                        {this.state.childparents.map((p,i) => {
                                if (p.childId === v.id) {
                                    const parent = this.parentGet(p.parentId);
                                    if (parent) {
                                        return <div key={i} className="parent">
                                        <span>{familyRelationTypeName[parent.type]}</span>
                                        {parent.lastName} {parent.firstName} {parent.middleName}
                                    </div>;
                                    }
                                }
                                return null;
                            })}
                        </div>
                        <div className="buttons">
                            <i className="bi bi-eye" onClick={() => this.deactivatePeople(v.id)}></i>
                            <Link to={`/pupil/${v.id}`}><i className="bi bi-pencil-square"></i></Link>
                        </div>
                        </div>)
			}
            </Template>
	}
}

export default ChildsScreen;