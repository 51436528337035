import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (mailId)		=> await Http.get('officecourtmaildocuments', {conditions:[{k:'officeMailId',v:mailId}]});
const getAll	= async ()				=> await Http.get('officecourtmaildocuments', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (id)			=> await Http.get('officecourtmaildocuments', {conditions:[{k:'id',v:id}]});

const add		= async (data)			=> await Http.post('officecourtmaildocuments', {data});
const remove	= async (id)			=> await Http.remove('officecourtmaildocuments', {conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	getById,
	add,
	remove
}