import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
// models
import {Events, Training, TrainingUsers} from '../../Models';

// globals
import {eventMessageName} from '../../Globals/Constants';

// helpers
import {Helpers, LS} from '../../Globals/index';

// styles
import './styles.scss';
import Alert, { alertShow } from '../../Components/Alert';
import TrainingModal from '../../Components/NoteModal/TrainingModal';

class TrainingScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			areaId:null,
			loading:true,
			trainingList:null,
			trainingUserList:null,
			isOpen: false,
			currentTrainingId: null,
			textArea: ''
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'))

		await this.updateData();
		this.setState({user,loading:false,areaId});

		// Add events in log
		Events.add(user.id, eventMessageName.viewPsychologyPage, null, null, null, 2);
	}

	updateData = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'))
		const trainingList = await Training.getAll(areaId);
		const trainingUserList = await TrainingUsers.getByUserId(user.id)
		const updatedTrainingList = trainingList.data.map(training => {
			const exists = trainingUserList.data.find(item => item.trainingId === training.id);
			return { ...training, trainingStatus: exists ? exists.status : false };
		});
		this.setState({trainingList: updatedTrainingList, trainingUserList: trainingUserList.data})
	}

	toggleTraining = (trainingId) => {
		const { user, trainingUserList } = this.state;
		const userId = user.id;
		const exists = trainingUserList.filter((item) => item.trainingId === trainingId);
		let newStatus = true;
		if (exists && exists.length > 0) {
			newStatus = !exists[0].status;
			TrainingUsers.update(userId, trainingId, {status: newStatus})
		} else {
			TrainingUsers.add({
				trainingId,
				userId,
				status: newStatus
			})
		}
		alertShow(newStatus ? 'Курс обучения отмечен как пройденный' : 'Курс обучения отмечен как не пройденный', newStatus ? 0 : 1);
		this.updateData();
	}

	setIsOpen = (val) => {
		this.setState({isOpen: val});
	}

	setTextArea = (e) => {
		this.setState({textArea: e.target.value})
	}
	
	autoResizeTextarea = (e) => {
		e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
	}

    handleChange = (e) => {
        this.setTextArea(e.target.value);
    }

	dateToLocale = (timeStamp) => {
		return new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
	}

	setCurrentTrainingId = (id) => {
		this.setState({currentTrainingId: id});
	}

    render() {
		const { trainingList } = this.state;
		return <Template page={'training'} title={'Обучение'}
			controlPanel={
				<div className="control-panel"><button 
				onClick={() => {
					this.setIsOpen(true);
					this.setCurrentTrainingId(0);
				}}>+ Добавить курс</button></div>
			}
		>
			{this.state.loading ? <Loader /> :
				<div className="training-container">
					<TrainingModal setIsOpen={this.setIsOpen} isOpen={this.state.isOpen} updateTraining={this.updateData} trainingId={this.state.currentTrainingId}></TrainingModal>
					<div className="courses">
						{trainingList.map((item, i) => (
							<div key={i} className="course" onClick={() => {
								this.setIsOpen(true);
								this.setCurrentTrainingId(item?.id);
							}}>
								<label>{item.description}</label>
								<div className="description">{item.content}</div>
								<div className="footer">
									<i className={item.trainingStatus ? `bi bi-check-square selected` : `bi bi-square`} onClick={(e) => {
										this.toggleTraining(item.id);
										e.stopPropagation();
									}}></i>
								</div>
							</div>
						))}
					</div>
				</div>
			}
			<Alert />
		</Template>
    }
}

export default TrainingScreen;