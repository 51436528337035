import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
// models
import {Events, News} from '../../Models';

// globals
import {eventMessageName, newsTypesName} from '../../Globals/Constants';

// helpers
import {Helpers, LS} from '../../Globals/index';

// styles
import './styles.scss';
import NewsModal from '../../Components/NoteModal/NewsModal';
import Alert from '../../Components/Alert';

class NewsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			currentDate: new Date(),
			currentYear: new Date().getFullYear(),
			currentFixedYear: new Date().getFullYear(),
			loading: true,
			currentMonth: new Date().getMonth(),
			currentDay: new Date().getDate(),
			yearRange: [],
			isOpen: false,
			currentNewsId: null,
			textArea: '',
			categoryVisibility: {
				"Новее": false,
				"В этом месяце": true,
				"В следующем месяце": false,
				"В прошлом месяце": false,
				"Остальные": false
			},
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

		Events.add(user.id, eventMessageName.viewPsychologyPage, null, null, null, 2);

		const currentYear = new Date().getFullYear();
		const yearRange = Array.from({ length: 6 }, (_, index) => currentYear + 1 - index);
		this.setState({ yearRange });

		this.updateNews();
	}

	updateNews = async () => {
		const areaId = parseInt(LS.get('areaId'));
		const newsList = await News.getAll(areaId);
		this.setState({ newsList: newsList.data, areaId });
	}

	goToPreviousMonth = () => {
		const { currentDate } = this.state;
		const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
		this.setState({ currentDate: previousMonthDate });
	};
	
	goToNextMonth = () => {
		const { currentDate } = this.state;
		const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
		this.setState({ currentDate: nextMonthDate });
	};

	handleYearChange = (event) => {
		const selectedYear = parseInt(event.target.value);
		const { currentDate } = this.state;
		const updatedDate = new Date(currentDate);
		updatedDate.setFullYear(selectedYear);
		this.setState({ currentDate: updatedDate, currentYear: selectedYear });
	};

	setIsOpen = (val) => this.setState({isOpen: val});
	setTextArea = (e) => this.setState({textArea: e.target.value});
    handleChange = (e) => this.setTextArea(e.target.value);
	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
	setCurrentNewsId = (id) => this.setState({currentNewsId: id});
	
	autoResizeTextarea = (e) => {
		e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
	}

	getNewsTimeCategory = (newsDate) => {
		const currentDate = this.state.currentDate;
		const currentYear = this.state.currentYear;
		const currentMonth = currentDate.getMonth();
		const currentYearFixed = this.state.currentFixedYear;
		const nextMonth = (currentMonth + 1) % 12;
	
		if (newsDate.getFullYear() === currentYear && newsDate.getMonth() === currentMonth) {
			return 'В этом месяце';
		} else if (newsDate.getFullYear() === currentYear && newsDate.getMonth() === currentMonth - 1) {
			return 'В прошлом месяце';
		} else if (newsDate.getFullYear() === currentYearFixed - 1 && newsDate.getMonth() === 11 && currentMonth === 0) {
			return 'В прошлом месяце';
		} else if (newsDate.getFullYear() === currentYear && newsDate.getMonth() === nextMonth) {
			return 'В следующем месяце';
		} else if (newsDate.getFullYear() === currentYear && newsDate.getMonth() > nextMonth) {
			return 'Новее';
		} else {
			return 'Остальные';
		}
	};
	
	toggleCategoryVisibility = (category) => {
		this.setState((prevState) => ({
			categoryVisibility: {
				...prevState.categoryVisibility,
				[category]: !prevState.categoryVisibility[category]
			}
		}));
	};

    render() {
		const { currentDate, loading, currentFixedYear, newsList, isOpen, currentYear, yearRange } = this.state;
		const month = currentDate.toLocaleString('default', { month: 'long' });
		const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
		const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
		const weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
		const monthDates = [...Array(daysInMonth)].map((_, index) => index + 1);

		const visibleNewsList = newsList?.filter((item) => {
			const newsDate = new Date(item.dateEvent * 1000);
			return (
				newsDate.getMonth() === currentDate.getMonth() && 
				newsDate.getFullYear() === currentDate.getFullYear()
			);
		}).map((item, index, array) => {
			const newsDate = new Date(item.dateEvent * 1000);
			const duplicateIndex = array.findIndex((otherItem, otherIndex) => index !== otherIndex && new Date(otherItem.dateEvent * 1000).toDateString() === newsDate.toDateString());
			if (duplicateIndex !== -1) { return { ...item, isDublicate: duplicateIndex, }; }
			return item;
		});

		// eslint-disable-next-line no-unused-vars
		const getPosition2 = (date) => {
			const dayIndex = monthDates.indexOf(date);
			const plus = new Date(currentDate).getFullYear() === currentYear && new Date(currentDate).getMonth() === 9;
			console.log(plus)
			const rowIndex = Math.floor((firstDayOfMonth + dayIndex + (plus ? 5 : 0)) / 7) + 2;
			const columnIndex = (firstDayOfMonth + dayIndex) % 7 === 0 ? 7 : (firstDayOfMonth + dayIndex) % 7;
			return { row: columnIndex === 7 ? rowIndex - 1 : rowIndex, column: columnIndex };
		};

		const categorizedNews = {
			"Новее": [],
			"В этом месяце": [],
			"В следующем месяце": [],
			"В прошлом месяце": [],
			"Остальные": []
		};

		
		if (newsList) {
			newsList.forEach((item) => {
				const newsDate = new Date(item.dateEvent * 1000);
				const newsTimeCategory = this.getNewsTimeCategory(newsDate);

				if (categorizedNews[newsTimeCategory]) {
					categorizedNews[newsTimeCategory].push(item);
				} else {
					categorizedNews["Остальные"].push(item);
				}
			});
		}

		return <Template page={'news'} title={'Новости'} controlPanel={<></>}>
			{loading ? (
				<Loader />
			) : (<>
				<NewsModal setIsOpen={this.setIsOpen} isOpen={isOpen} updateNews={this.updateNews} newsId={this.state.currentNewsId}></NewsModal>
				<div className="calendar-container">
					<div className="calendar-header">
						<button className="arrow-button" onClick={this.goToPreviousMonth}>◄</button>
						<h1>{month}</h1>
						<div className="year-dropdown">
							<select value={currentYear} onChange={this.handleYearChange}>
								{yearRange.map((year) => (
									<option key={year} value={year}>{year}</option>
								))}
							</select>
						</div>
						<button className="arrow-button" onClick={this.goToNextMonth}>►</button>
					</div>
					<div className="calendar">
						{weekDays.map((day) => (
							<span key={day} className="day-name">{day}</span>
						))}
						{[...Array(firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1)].map((_, index) => (
							<div key={`empty-${index}`} className="day day--disabled" />
						))}
						{[...Array(daysInMonth)].map((_, index) => (
							<div key={index + 1} className={`day ` + (currentYear === currentFixedYear && (index + 1) === this.state.currentDay && currentDate.getMonth() === this.state.currentMonth && `day--current`)}>{index + 1}</div>
						))}
						{[...Array(42 - (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1) - daysInMonth)].map((_, index) => (
							<div key={`empty-${index}`} className="day day--disabled" />
						))}
						{visibleNewsList &&
							visibleNewsList.map((item, i) => {
								const date = new Date(item?.dateEvent * 1000).getDate();
								const { row, column } = getPosition2(date);

								return (
									<section
										key={i}
										className={`task task--${newsTypesName[item?.type]}`}
										onClick={() => {
											this.setIsOpen(true);
											this.setCurrentNewsId(item?.id);
										}}
										style={{
											gridColumn: `${column} / span 1`,
											backgroundColor: item.customColor,
											gridRow: `${row}`,
											...{ marginLeft: item.isDublicate <= i ? `10px` : `50%` },
											width: `30%`,
											...(row > 1 ? { marginTop: '1rem' } : {}),
										}}
									>
										<div className="task__detail" style={{marginLeft: "-40px"}}>
											<h2>{item?.name}</h2>
											<p>{this.dateToLocale(item?.dateEvent)}</p>
										</div>
									</section>
								);
							})
						}
					</div>
				</div>
				<div className="news-container">
					<button onClick={() => {
						this.setIsOpen(true);
						this.setCurrentNewsId(0);
					}}>Добавить новость</button>
					{Object.entries(categorizedNews).map(([category, newsItems], index) => (<>
						<div key={index}><h4 onClick={() => this.toggleCategoryVisibility(category)}>{category} {`(${newsItems?.length})`}</h4></div>
						{this.state.categoryVisibility[category] && newsItems.map((item, i) => (
							<div
								key={i}
								className="news-item"
								onClick={() => {
									this.setIsOpen(true);
									this.setCurrentNewsId(item?.id);
								}}
							>
								<div className="news-header">
									<section className={`task task--${newsTypesName[item?.type]}`} style={{ backgroundColor: item.customColor, }}></section>
									<span>{this.dateToLocale(item?.dateEvent)}</span>
								</div>
								<div className="news-name">{item?.name}</div>
								<div className="news-content">{item?.description}</div>
								<div className="news-author">{item?.userName}</div>
							</div>
						))}</>
					))}
					{/* {this.state.newsList && this.state.newsList.map((item, i) => (
						<div key={i} className="news-item" 
							onClick={() => {
								this.setIsOpen(true);
								this.setCurrentNewsId(item?.id);
							}}
						>
							<div className="news-header">
								<section className={`task task--${newsTypesName[item?.type]}`}></section>
								<span>{this.dateToLocale(item?.dateEvent)}</span>
							</div>
							<div className="news-name">{item?.name}</div>
							<div className="news-content">{item?.description}</div>
							<div className="news-author">{item?.userName}</div>
						</div>
					))} */}
				</div>
			</>)}
			<Alert />
		</Template>
    }
}

export default NewsScreen;