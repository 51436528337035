import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get		        = async (programId)		=> await Http.get('programtasks', {conditions:[{k:'programId',v:programId},{k:'status',v:comonStatus.ACTIVE}]});
const getAll	        = async ()	=> await Http.get('programtasks', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('programtasks', {data});
const update	        = async (id, data)	=> await Http.put('programtasks', {data,conditions:[{k:'id',v:id}]});
const updateByProgramId = async (programId, data)	=> await Http.put('programtasks', {data,conditions:[{k:'programId',v:programId}]});

export {
	getAll,
	get,
	add,
	update,
	updateByProgramId
}