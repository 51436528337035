import { PropTypes } from 'prop-types';

import { ChildCorrectionAnamnesis, ChildMedicAnamnesis, ChildPsychologyAnamnesis, ChildSportAnamnesis, ParentPsychologyAnamnesis } from '../../Models';
import { useEffect, useState } from 'react';
import Loader from '../../Components/Loader';
import "./styles.scss";

const Anamnes = ({ childId, type, isparent }) => {
    const [anamnesis, setAnamnes] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getJournal = async () => {
            setIsLoading(true);
            let cp = [];
            try {
                switch (type) {
                    case 'correction': cp = await ChildCorrectionAnamnesis.get(childId); break;
                    case 'psychology': cp = isparent ? await ParentPsychologyAnamnesis.get(childId) : await ChildPsychologyAnamnesis.get(childId); break;
                    case 'medic': cp = await ChildMedicAnamnesis.get(childId); break;
                    case 'sport': cp = await ChildSportAnamnesis.get(childId); break;
                }
                if (cp?.data) {
                    setAnamnes(cp.data[0] || {});
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
            }
        }
        getJournal();
    }, [type]);

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="anamnes">
            <span>Описание</span>
            <p>{anamnesis.info || '-'}</p>
            <span>Задача</span>
            <p>{anamnesis.task || '-'}</p>
            {/* <span>Ответственный</span>
            <p>{anamnesis.userName || '-'}</p> */}
        </div>
    );
};

Anamnes.propTypes = {
    childId: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    isparent: PropTypes.boolean,
    iscorrection: PropTypes.boolean
};

export default Anamnes;