import Http from '../../Globals/Http';
import {comonStatus,requestConcatinationType,requestOderType} from '../../Globals/Constants';

const get		        = async (childId)		=> await Http.get('programchildrens', {conditions:[{k:'childId',v:childId},{k:'status',v:comonStatus.ACTIVE}]});
const getAll	        = async ()	=> await Http.get('programchildrens', {conditions:[{k:'status',v:comonStatus.ACTIVE},{k:'status',v:2,con:requestConcatinationType.OR}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});

const add		        = async (data)		=> await Http.post('programchildrens', {data});
const update	        = async (childId, programId, data)	=> await Http.put('programchildrens', {data,conditions:[{k:'childId',v:childId},{k:'programId',v:programId}]});
const updateAll	        = async (childId, data)	=> await Http.put('programchildrens', {data,conditions:[{k:'childId',v:childId}]});

export {
	getAll,
	get,
	add,
	update,
	updateAll
}