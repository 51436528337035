import { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { TaskUsers, Tasks } from "../../../Models";
import { taskCatNames, taskCategories, taskChildTypeNames, taskChildTypes, taskTypeNames, taskTypes, /*typeUserNames,*/ typeUsers } from "../../../Globals/Constants";
import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// eslint-disable-next-line no-unused-vars
import Alert, { alertShow } from "../../../Components/Alert";
import Modal from 'react-awesome-modal';
import React from "react";

const PupilTasks = ({ pupil, teachers }) => {
    const [tab, setTab] = useState(taskCategories.CORRECTION);
    const tabs = [taskCategories.CORRECTION, taskCategories.PSYCHOLOGY, taskCategories.OTHER, taskCategories.HISTORY];

    const [tasks, setTasks] = useState([]);
    const [userTasks, setUserTasks] = useState([]);
    const [updatedTasks, setUpdatedTasks] = useState([]);

    const [loading, setLoading] = useState(false);

    const [isOpenConfirm, setIsOpenConfirm] = useState(0);

    const [selectedTeacher, setSelectedTeacher] = useState(0);

    const [openedtasks, setopenedtasks] = useState([]);

    useEffect(async () => {
        setLoading(true);
        const loadTasks = () => {
            if (tab === taskCategories.HISTORY) {
                Tasks.getAll().then((res) => {
                    setTasks(res?.data);
                });
            } else {
                Tasks.getByCategory(tab).then((res) => {
                    setTasks(res?.data);
                });
            }
        };
        loadTasks();
    }, [tab, pupil]);

    useEffect(async () => {
        setLoading(true);
        const loadPupilTasks = () => {
            TaskUsers.getIndividualTasks(pupil.id, typeUsers.PUPIL).then((res) => setUserTasks(res?.data));
        };
        loadPupilTasks();
    }, [tasks]);

	const checkDate = (start, end) => {
		const differenceInSeconds = end - start;
		const remainingDays = Math.ceil(differenceInSeconds / 86400);
		return remainingDays;
	};

	const toggleTask = (taskId) => {
		let newTasks = [...openedtasks];
		if (isOpenedTask(taskId)) {
			newTasks = newTasks.filter((t) => t !== taskId);
		} else {
			newTasks.push(taskId);
		}

		setopenedtasks(newTasks);
	}

	const isOpenedTask = (taskId) => openedtasks.includes(taskId);

    const getCurrentDateTime = () => Math.floor(new Date().getTime() / 1000);

    const capitalizeCamelCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

	const getDayWord = (number) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return ['день', 'дня', 'дней'][(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
	};

    useEffect(() => {
        const taskIdsInUserTasks = userTasks.map(userTask => userTask.taskId) || [];
        const newTasks = tasks.filter(task => taskIdsInUserTasks.includes(task.id) && task.status !== 4);
        
        const updatedTasks = newTasks.map((task) => {
            const curator = teachers.find((t) => t.id === task.curatorId);
            return { ...task, curator, daysout: checkDate(getCurrentDateTime(), task.dateEnd) };
        });

        setUpdatedTasks(updatedTasks || []);
        console.log(updatedTasks)
    }, [userTasks]);

    useEffect(() => {
        setLoading(false);
    }, [updatedTasks]);

    const dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

    const createTask = async (type, teacherId) => {
        const dateStart = getCurrentDateTime();
        const dateEnd = dateStart + 86400 * (type === 1 ? 3 : 5);

        try {
            const newData = {
                type: taskTypes.OTHER,
                category: 2,
                view: taskChildTypes.OTHER,
                name: type === 1 ? 'Создание личного дела' : 'Загрузка документов',
                info: type === 1 ? 'Необходимо заполнить основные данные' : 'Необходимо загрузить документы',
                curatorId: teacherId,
                dateEnd,
                dateStart,
                status: 1
            };
            await Tasks.add(newData).then((res) => {
                if (res && res.data?.id > 0) {
                    const taskId = res.data.id;
                    TaskUsers.add({
                        taskId,
                        userId: pupil.id,
                        userType: typeUsers.PUPIL,
                        userName: `${pupil.lastName} ${pupil.firstName} ${pupil.middleName}`,
                        status: 1
                    });
                    alertShow('Задача успешно создана');

                    const curator = teachers.find((t) => t.id === teacherId);
                    const updatedNewData = { ...newData, id: taskId, curator, daysout: checkDate(dateStart, dateEnd) };
                    setUpdatedTasks((prev) => ({ ...prev, updatedNewData}))
                } else {
                    alertShow('Произошла ошибка при создании задачи', 1);
                    return false;
                }
            }).catch((res) => {
                console.error(res);
                alertShow('Произошла ошибка при создании задачи', 1);
                return false;
            });
        } catch (error) {
            console.error(error);
        }

        setSelectedTeacher(0);
        setIsOpenConfirm(0);
        Tasks.getByCategory(tab).then((res) => {
            setTasks(res?.data);
        });
    }
    
    const [expandedRowId, setExpandedRowId] = useState(null);

    // const taskChildTypess = {
    //     [taskTypes.OTHER]: [taskChildTypes.OTHER],
    // };

    const handleRowClick = (id) => {
        setExpandedRowId(id === expandedRowId ? null : id);
    };
    // eslint-disable-next-line no-unused-vars
    const getSelect = () => <select value={Number(selectedTeacher)} onChange={(e) => {
        setSelectedTeacher(Number(e.target.value));
    }}>
        <option value={0}>Выберите ответственного</option>
        {teachers?.map((teacher) => (<option key={teacher.id} value={teacher.id}>{`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`}</option>))}
    </select>;

    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter] = useState({
        filter: '',
        search: '',
        query: '',
        secondtype: '',
        type: ''
    });

	const setSearchParticipants = (query) => {
        setFilter((prev) => ({ ...prev, search: query }));
	};

	const searchBy = (event) => {
		const filterOption = event.target.value;
		
        setFilter((prev) => ({ ...prev, filter: filterOption }));
	};

	const sortBy = (event) => {
		const sortOption = event.target.value;
		
        setFilter((prev) => ({ ...prev, sort: sortOption }));
	};

    let filteredTasks = updatedTasks;

    if (filter.search) {
        const searchQuery = filter.search.toLowerCase().trim();
        if (filter.filter === "users") {
            filteredTasks = filteredTasks.filter((task) =>
                task.users?.some((user) => user.userName?.toLowerCase().includes(searchQuery))
            );
        }
        else if (filter.filter === "teachers") {
            console.log(filter.search)
            filteredTasks = filteredTasks.filter((task) => {
                const name = `${task?.curator?.lastName} ${task?.curator?.firstName} ${task?.curator?.middleName}`;

                return name.toLowerCase().includes(searchQuery)
            });

            console.log(filteredTasks)
        }
    }
    if (filter.filter === "new") {
        filteredTasks = filteredTasks.filter((task) => task.status === 1);
    } else if (filter.filter === "active") {
        filteredTasks = filteredTasks.filter((task) => task.status === 2);
    } else if (filter.filter === "done") {
        filteredTasks = filteredTasks.filter((task) => task.status === 3);
    } else if (filter.filter === "archive") {
        filteredTasks = filteredTasks.filter((task) => task.status === 4);
    }

    if (filter.sort === "byDateDesc") {
        filteredTasks.sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart));
    }
    else if (filter.sort === "byDateAsc") {
        filteredTasks.sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart));
    }
    else if (filter.sort === "byDateCreateDesc") {
        filteredTasks.sort((a, b) => new Date(a.dateCreate) - new Date(b.dateCreate));
    }
    else if (filter.sort === "byDateCreateAsc") {
        filteredTasks.sort((a, b) => new Date(b.dateCreate) - new Date(a.dateCreate));
    }

    return (
        <div className="pupil_info" style={{display: "block"}}>
            {isOpenConfirm === 1 && <Modal visible={isOpenConfirm === 1} width={'400'} height={'205'} effect={'fadeInUp'}>
                <div className="modal_task">
                    <div className="content">
                        <span>Выберите ответственного по заполнению личного дела и заполнению основных данных (срок 3 дня) для создания задачи</span>
                        {getSelect()}
                    </div>
                    <div className="buttons">
                        <button onClick={() => createTask(isOpenConfirm, selectedTeacher)}>Создать задачу</button>
                        <button onClick={() => {
                            setSelectedTeacher(0);
                            setIsOpenConfirm(0);
                        }}>Отменить</button>
                    </div>
                </div>
            </Modal>}
            {isOpenConfirm === 2 && <Modal visible={isOpenConfirm === 2} width={'400'} height={'205'} effect={'fadeInUp'}>
                <div className="modal_task">
                    <div className="content">
                        <span>Выберите ответственного для задачи по загрузке документов (срок 5 дней) для создания задачи</span>
                        {getSelect()}
                    </div>
                    <div className="buttons">
                        <button onClick={() => createTask(isOpenConfirm, selectedTeacher)}>Создать задачу</button>
                        <button onClick={() => {
                            setSelectedTeacher(0);
                            setIsOpenConfirm(0);
                        }}>Отменить</button>
                    </div>
                </div>
            </Modal>}
            <div className="tabs">
                <ul>
                    {tabs.map((t, i) => t === tab ? <li key={i} className={"active"} onClick={() => setTab(t)}>{capitalizeCamelCase(taskCatNames[t])}</li> : <li key={i} onClick={() => setTab(t)}>{capitalizeCamelCase(taskCatNames[t])}</li>)}
                </ul>
            </div>
            <div className="tasks_container">
                <Link to={`/${tab === taskCategories.CORRECTION ? 'correction-tasks' : tab === taskCategories.PSYCHOLOGY ? 'psychology-tasks' : 'other-tasks'}?link=add-task`}><button className="default_button">+ Добавить задачу</button></Link>
                <button className="default_button" style={{marginLeft: '10px'}} onClick={() => setIsOpenConfirm(1)}>+ Добавить задачу по созданию личного дела</button>
                <button className="default_button" style={{marginLeft: '10px'}} onClick={() => setIsOpenConfirm(2)}>+ Добавить задачу по загрузке документов</button>
                <div className="task-filter">
                    <div>
                        <select onChange={searchBy}>
                            <option value="">Фильтр</option>
                            <option disabled>Поиск по:</option>
                            <option value="teachers">Ответственный</option>
                            <option disabled>-</option>
                            <option disabled>Фильтр:</option>
                            <option value="new">Новые</option>
                            <option value="active">Активные</option>
                            <option value="done">Завершенные</option>
                            <option value="archive">Архив</option>
                        </select>
                        <input type="text" placeholder="Поиск" value={filter.search} onChange={(e) => setSearchParticipants(e.target.value)} />
                    </div>
                    <div>
                        Сортировать:
                        <select onChange={sortBy} style={{marginLeft:"5px"}}>
                            <option value="byDateAsc">По дате начала (убыв.)</option>
                            <option value="byDateDesc">По дате начала (возр.)</option>
                            <option value="byDateCreateAsc">По дате создания (убыв.)</option>
                            <option value="byDateCreateDesc">По дате создания (возр.)</option>
                        </select>
                    </div>
                </div>
                {loading ? <Loader /> : 
                    <div className="tasks-container">
						<div className="tasks-list">
                            {
                                filteredTasks?.length && tab === taskCategories.HISTORY ? (
                                    <table className={'table'}>
                                        <thead>
                                            <tr>
                                                <th>Дата начала</th>
                                                <th>Категория</th>
                                                <th>Тип</th>
                                                <th>Задача</th>
                                                <th>Ответственный</th>
                                                <th>Статус</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredTasks.sort((a, b) => b.dateStart - a.dateStart).map((task) => (
                                                <React.Fragment key={task.id}>
                                                    {console.log(task)}
                                                    <tr onClick={() => handleRowClick(task.id)}>
                                                        <td>{dateToLocale(task.dateStart)}</td>
                                                        <td>{taskTypeNames[task.type]}</td>
                                                        <td>{taskChildTypeNames[task.view]}</td>
                                                        <td>{task.name}</td>
                                                        <td><span>{task.curator?.lastName} {task.curator?.firstName} {task.curator?.middleName}</span></td>
                                                        <td>{task.status === 1 ? (<span>Не начата</span>) : task.status === 2 ? (<span style={{ color: "#f39c12" }}>В работе</span>) : task.status === 3 ? (<span style={{ color: "#2ecc71" }}>Задача завершена</span>) : <span style={{ color: "#95a5a6" }}>В архиве</span>}</td>
                                                    </tr>
                                                    {expandedRowId === task.id && (<>
                                                        <tr className="table-expand">
                                                            <th colSpan="2">
                                                                Описание
                                                            </th>
                                                            <th colSpan="1">
                                                                Дней осталось
                                                            </th>
                                                            <th colSpan="1">
                                                                Дата завершения
                                                            </th>
                                                            <th colSpan="1">
                                                                Дата изменения
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">{task.info}</td>
                                                            <td colSpan="1">{task.daysout}</td>
                                                            <td colSpan="1">{dateToLocale(task.dateEnd)}</td>
                                                            <td colSpan="1">{dateToLocale(task.dateModify)}</td>
                                                        </tr>
                                                    </>)}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    filteredTasks?.length ? filteredTasks.map((task, i) => (
                                    <div key={i}>
                                        <div className="tasks-item">
                                            <div>
                                                <div className="tasks-block">
                                                    <span>{taskTypeNames[task.type]}</span>
                                                    <span>{taskChildTypeNames[task.view]}</span>
                                                </div>
                                            </div>
                                            {!isOpenedTask(task.id) ? <div>
                                                <div className="tasks-block" style={{ height: '60px' }}>
                                                    <textarea disabled style={{ height: "20px", minHeight: '40px'  }} value={task.name}>{task.name}</textarea>
                                                </div>
                                            </div> : null}
                                            <div>
                                                <div className="tasks-block">
                                                    <span>Дата начала</span>
                                                    <span>{dateToLocale(task.dateStart)}</span>
                                                </div>
                                            </div>
                                            {!isOpenedTask(task.id) ? <div>
                                                <div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || task.status === 4 || task.status === 3 || task.status === 1 ? 'days-good' : task.daysout <= 3 ? 'days-warning' : task.daysout <= 1 ? 'days-error' : 'days-normal'}`}>
                                                    <span>Крайний срок ({task.daysout < 0 ? 0 : task.daysout} {getDayWord(task.daysout) || 'дней'})</span>
                                                    <span>{dateToLocale(task.dateEnd)}</span>
                                                </div>
                                            </div> : null}
                                            <div>
                                                <div className="tasks-block">
                                                    <span>{task.curator?.lastName} {task.curator?.firstName} {task.curator?.middleName}</span>
                                                    <span>{task.curator?.position}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <Link to={`/tasks/${task.id}`}>
                                                    <div className="tasks-block tasks-block-button">
                                                        <div className="task_link">Управление задачей</div>
                                                        {task.status === 1 ? (<span>Не начата</span>) : task.status === 2 ? (<span>В работе</span>) : task.status === 3 ? (<span>Задача завершена</span>) : <span>В архиве</span>}
                                                    </div>
                                                </Link>
                                            </div>
                                            <div onClick={() => toggleTask(task.id)} className={`toggle-button${isOpenedTask(task.id) ? ' active' : ''}`} style={{ width: "60px", flexBasis: "60px" }}>
                                                <i className="bi bi-caret-down-fill"></i>
                                            </div>
                                        </div>
                                        {isOpenedTask(task.id) ? <div className="tasks-item">
                                            <div>
                                                <div className="tasks-block">
                                                    <label>Задача</label>
                                                    <textarea disabled value={task.name}>{task.name}</textarea>
                                                </div>
                                                <div className="tasks-block">
                                                    <label>Описание</label>
                                                    <textarea disabled value={task.info}>{task.info}</textarea>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || task.status === 4 || task.status === 3 || task.status === 1 ? 'days-good' : task.daysout <= 3 ? 'days-warning' : task.daysout <= 1 ? 'days-error' : 'days-normal'}`}>
                                                    <span>Крайний срок ({task.daysout < 0 ? 0 : task.daysout} {getDayWord(task.daysout) || 'дней'})</span>
                                                    <span>{dateToLocale(task.dateEnd)}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="tasks-block">
                                                    <label>Результат</label>
                                                    <textarea disabled value={task.comment} className="tasks-result">{task.comment}</textarea>
                                                </div>
                                            </div>
                                        </div> : <div className="tasks-item" style={{ height: "0px", padding: "0px" }}></div>}
                                    </div>
                                    )) : <div style={{paddingBottom:"20px",marginTop:"20px"}}>Нет задач</div>
                                )
                            }
						</div>
					</div>
                }
            </div>
            <Alert />
        </div>
    );
}

PupilTasks.propTypes = {
    pupil: PropTypes.object.isRequired,
    teachers: PropTypes.array
};

export default PupilTasks;