// import { useState } from 'react';
import PropTypes from 'prop-types';
import { phoneFormatter } from '../../../Globals/Utils';
import { useHistory } from 'react-router-dom';
// import Card from '../../Tasks/Card';
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { journalLinksCor } from '../../../Globals/Constants';

const ParentalInfo = ({ parent }) => {
    const history = useHistory();
    // const [textArea, setTextArea] = useState('');
	// const autoResizeTextarea = (e) => {
	// 	e.target.style.height = 'auto';
    //     e.target.style.height = e.target.scrollHeight + 'px';
	// }
    // const handleChange = (e) => {
    //     setTextArea(e.target.value);
    // }

    const locate = (url) => {
        history.push(url);
    }
    const dateToFormat = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <div className="parental_info">
            <div className="left_side">
                <div className="form">
                    <div className="form-section">
                        <label htmlFor="lastName">Фамилия:</label>
                        <input type="text" id="lastName" name="lastName" disabled value={parent.lastName} placeholder="Фамилия" />
                    </div>
                    <div className="form-section">
                        <label htmlFor="firstName">Имя:</label>
                        <input type="text" id="firstName" name="firstName" disabled value={parent.firstName} placeholder="Имя" />
                    </div>
                    <div className="form-section">
                        <label htmlFor="middleName">Отчество:</label>
                        <input type="text" id="middleName" name="middleName" disabled value={parent.middleName} placeholder="Отчество" />
                    </div>
                    <div className="form-section">
                        <label htmlFor="phone">Телефон:</label>
                        <input type="text" id="phone" name="phone" disabled value={phoneFormatter(parent.phone)} placeholder="Телефон" />
                    </div>
                    <div className="form-section">
                        <label htmlFor="birthDate">Дата рождения:</label>
                        <input type="date" id="birthDate" value={dateToFormat(parent.birthDate)} name="birthDate" />
                    </div>
                    {parent.childs && parent.childs.length > 0 ? parent.childs.map((c, i) => { return (<>
                        <div key={`1`+i} className="form-section" onClick={() => locate(`/pupil/${c.id}`)}>
                            <label htmlFor="parentFullName">Воспитанник:</label>
                            <input type="text" style={{cursor: "pointer"}} id="parentFullName" name="parentFullName" disabled value={c.fullName} />
                        </div>
                        <div key={`2`+i} className="form-section wider">
                            <label htmlFor="admissionDate">Дата постановки:</label>
                            <input type="date" id="admissionDate" name="admissionDate" value={dateToFormat(c.dateIn)} disabled />
                        </div>
                        <div key={`3`+i} className="form-section wider">
                            <label htmlFor="dischargeDate">Дата выписки:</label>
                            <input type="date" id="dischargeDate" name="dischargeDate" value={dateToFormat(c.dateOut)} disabled />
                        </div>
                    </>)}) : <div className="parent_block">
                        <span>Выберите воспитанника</span>
                    </div>}
                    {/* <div className="form-section">
                        <label htmlFor="parentComment"></label>
                        <div className="textarea-container">
                            <span>Комментарий</span>
                            <textarea value={textArea} onChange={(e) => handleChange(e)} id="parentComment" placeholder="Введите комментарий" onInput={(e) => autoResizeTextarea(e)} className="custom-textarea" rows="3"></textarea>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='right_side'>
                {/* <div className="form">
                    <Link to={`/${journalLinksCor[0]}/${parent.id}`}><button className="default_button">Добавить запись</button></Link>
                </div>
                <div className="notes">
                    <Card childId={parent.id} type={0} isparrent={true} iscorrection={true}></Card>
                </div> */}
            </div>
        </div>
    );
}

ParentalInfo.propTypes = {
    parent: PropTypes.object.isRequired,
};

export default ParentalInfo;