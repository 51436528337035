import { PropTypes } from 'prop-types';

import { API, journalLinksCor, journalLinksPsy, journalParentsPsy, journalType } from '../../Globals/Constants';
import {
    ChildCorrectionHandkerchiefs,
    ChildCorrectionJournals,
    ChildCorrectionPhotoJournals,
    ChildDocuments,
    ChildMedicDiseases,
    ChildMedicInspections,
    ChildMedicPills,
    ChildMedicTraumas,
    ChildPsychologyJournals,
    ChildSportEffects,
    ChildSportPhysic,
    ChildSportSections,
    CorrectionFunctions,
    DocumentTypes,
    ParentPsychologyJournals,
    Teachers
} from '../../Models';
import { useEffect, useState } from 'react';
import './styles.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { LS } from '../../Globals';
import Loader from '../../Components/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import moment from 'moment/moment';
import DocumentViewer from '../../Components/DocumentViewer';
import { alertShow } from '../../Components/Alert';
import Confirm from '../../Components/Confirm';

const dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
// eslint-disable-next-line no-unused-vars
const Card = ({ childId, type, isparent, iscorrection }) => {
    const [journal, setJournal] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = LS.get('user');
    const areaId = parseInt(LS.get('areaId'));
	const childDocumentsGet = async () => {
		const childdocuments = await ChildDocuments.get(childId);
		setDocuments(childdocuments.data || []);
	}
    useEffect(() => {
        const getJournal = async () => {
            setIsLoading(true);
            let cp = [];
            try {
                switch (type) {
                    case journalType.HANDKERCHIEF: cp = await ChildCorrectionHandkerchiefs.get(childId); break;
                    case journalType.FUNCTION: cp = await CorrectionFunctions.get(childId); break;
                    case journalType.MEDCHRONIC: cp = await ChildMedicDiseases.get(childId); break;
                    case journalType.MEDOSMOTR: cp = await ChildMedicInspections.get(childId); break
                    case journalType.MEDTRAUMA: cp = await ChildMedicTraumas.get(childId); break;
                    case journalType.MEDPILLS: cp = await ChildMedicPills.get(childId); break;
                    case journalType.SPORTEFFECTS: cp = await ChildSportEffects.get(childId); break;
                    case journalType.SPORTPHYSICS: cp = await ChildSportPhysic.get(childId); break;
                    case journalType.SPORTSECTIONS: cp = await ChildSportSections.get(childId); break;
                    default: cp = isparent ? await ParentPsychologyJournals.get(childId, type) : iscorrection ? await ChildCorrectionJournals.get(childId, type) : await ChildPsychologyJournals.get(childId, type); break;
                }
                if (cp?.data) {              
                    await childDocumentsGet();
                    const newCpData = await Promise.all(cp.data.map(async (v, i) => {
                        const res = await ChildCorrectionPhotoJournals.get(v.id, type);
                        if (res.data) {
                          cp.data[i].photos = res.data.map((p) => p.photo);
                        }
                        return v; // Возвращаем элемент после обновления
                      }));
                    setJournal(newCpData);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
            }
        }
        getJournal();
    }, [type]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    useEffect(() => {
        console.log(moment(startDate))
    }, [startDate])

    useEffect(() => {
        const getTeachers = async () => {
            const teachers = await Teachers.getAll(areaId, 1);
            if (teachers?.data) {
                setTeachers(teachers.data);
            }
        }
        getTeachers();
    }, [type]);

    const [isDocumentView, setDocumentView] = useState(false);
    const [doc, setDoc] = useState(null);
    const [docType, setDocType] = useState(null);
    const [documents, setDocuments] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [documentTypes, setDocumentTypes] = useState([]);

    useEffect(async () => {
        const documenttypes = await DocumentTypes.getAll();

        setDocumentTypes(documenttypes.data);
    }, []);

    const documentView = async (document) => {
		const doctype = document.link.replace(/.*\./gi, '');
		const doc = `${API.documents}/?id=${childId}&t=childs&ft=${document.fileType}&fn=${document.link}`;

        setDoc(doc);
        setDocType(doctype);
        setDocumentView(true);
	}
	const documentViewClose = () => setDocumentView(false);


    useEffect(async () => {
        childDocumentsGet();
    }, []);
    
    const getTeacherNameById = (id) => {
        const teacher = teachers.filter((teacher) => teacher.id === id);
        if (teacher.length > 0) {
            return `${teacher[0].lastName} ${teacher[0].firstName} ${teacher[0].middleName}`;
        }
        return '-';
    }

    const deleteJournal = (id) => {
        const find = journal.find(item => item.id === id);

		console.log(journal, id)

		if (iscorrection) {
			if (isparent) {
				//
			} else {
				ChildCorrectionJournals.remove(find.id);
			}
		} else {
			if (isparent) {
				ParentPsychologyJournals.remove(find.id);
			} else {
				ChildPsychologyJournals.remove(find.id);
			}
		}

        alertShow('Запись удалена');

		setTimeout(() => {
			window.location.reload();
		}, 500);
    }

    const [isOpenDelete, setIsOpenDelete] = useState(0);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {journal?.length > 0 ? (
                <>
                    {isOpenDelete > 0 && (
                        <Confirm
                            message={'Вы уверены, что хотите удалить запись с фото?'}
                            action={() => {
                                deleteJournal(isOpenDelete);
                                setIsOpenDelete(0);
                            }}
                            close={() => { setIsOpenDelete(0) }}
                        />
                    )}
                    <div className="filter_data">
                        <span>Сортировать</span>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            placeholderText="Дата с:"
                            dateFormat="dd.MM.yyyy"
                            locale={ru}
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            placeholderText="Дата по:"
                            dateFormat="dd.MM.yyyy"
                            locale={ru}
                        />
                    </div>
                    <div className="filter_data">
                        Записей: {journal.filter((item) => {
                        if(!startDate || !endDate) {
                            return true;
                        }

                        if(!item.date && !item.dateCreate) {
                            return true;
                        }

                        const itemDate = moment(new Date((item.date || item.dateCreate) * 1000));

                        return itemDate >= moment(startDate) && itemDate <= moment(endDate);
                    }).length}
                    </div>
                    {journal.filter((item) => {
                        if(!startDate || !endDate) {
                            return true;
                        }

                        if(!item.date && !item.dateCreate) {
                            return true;
                        }

                        const itemDate = moment(new Date((item.date || item.dateCreate) * 1000));

                        return itemDate >= moment(startDate) && itemDate <= moment(endDate);
                    }).map((item, i) => (
                        <div key={i} className="note">
                            {type === journalType.HANDKERCHIEF ?
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    <div className="note_name">Цвет: <i>{item.color || '-'}</i></div>
                                    <div className="note_description">Комментарий: {`${item.comment || '-'}`}</div>
                                    <div className="note_owner">Ответственный: {`${getTeacherNameById(item.teachersId)}`}</div>
                                </>
                            : type === journalType.FUNCTION ?
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    <div className="note_description">Комментарий: {`${item.info || '-'}`}</div>
                                    <div className="note_owner">Ответственный: {`${getTeacherNameById(item.teachersId)}`}</div>
                                </>
                            : type === journalType.MEDOSMOTR || type === journalType.MEDPILLS || type === journalType.MEDTRAUMA ?
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    <div className="note_description">Комментарий: {`${item.info || '-'}`}</div>
                                </>
                            : type === journalType.MEDCHRONIC ?
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    <div className="note_description">Хронические: {`${item.chronic || '-'}`}</div>
                                    <div className="note_description">Аллергия: {`${item.allergies || '-'}`}</div>
                                    <div className="note_description">Лечение: {`${item.treatment || '-'}`}</div>
                                </>
                            : type === journalType.SPORTEFFECTS || type === journalType.SPORTPHYSICS || type === journalType.SPORTSECTIONS ?
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    <div className="note_description">Комментарий: {`${item.info || '-'}`}</div>
                                </>
                            :
                                <>
                                    <div className="note_date">{dateToLocale(item.date || item.dateCreate)}<Link to={`/${isparent ? journalParentsPsy[type - 1] : iscorrection ? journalLinksCor[type - 1] : journalLinksPsy[type - 1]}/${childId}`}><button>Перейти</button></Link>{ user.position === 'Суперадмин' ?  <button onClick={() => setIsOpenDelete(item.id)} >Удалить</button> : null }</div>
                                    {item.amount && <div className="note_price">Сумма: <b>{item.amount || 0} ₽</b></div>}
                                    
                                    {item ? (
                                        <div className="note_images">
                                            {documents.filter((doc) => item.photos?.includes(`${doc.id}`)).map((doc, i) => doc.type === 33 ? <img key={i} onClick={()=>documentView(doc)} src={`${API.assets}/documents/childs/${childId}/${doc.link}`}></img> : <div className="documents" key={i} onClick={() => documentView(doc)} ><i className="bi bi-file-earmark-text"></i><span>{doc.link}</span></div>)}
                                        </div>
                                    ) : 'kek'}
                                    <div className="note_name" onClick={() => console.log(item)}>Задача: <i>{item.task || '-'}</i></div>
                                    <div className="note_description">Описание: {`${item.info}`}</div>
                                    <div className="note_owner">Ответственный: {`${item.userName}`}</div>
                                </>
                            }
                        </div>
                    ))}
                    {isDocumentView && <DocumentViewer doc={doc} doctype={docType} close={documentViewClose} />}
                </>
            ) : (
                <div className="note">Нет записей</div>
            )}
        </>
    );
};

Card.propTypes = {
    childId: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    isparent: PropTypes.boolean,
    iscorrection: PropTypes.boolean
};

export default Card;