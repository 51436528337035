import { Component } from 'react';

// components
import Template from '../../Components/Template';
import Journal from '../../Components/Journal';

// globals
import { journalType } from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ChildCommentsPsyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id || 0),
    };
  }
  back = () => this.props.history.push(`/pupil/${this.props.match.params.id}`);
  render() {
    return (
      <Template
        page={'comments'}
        title={'Комментарии'}
        controlPanel={
          <div className="form-control-panel">
            <button form="journal-form" type="submit">
              Сохранить
            </button>
          </div>
        }
        backLink={`/pupil/${this.props.match.params.id}`}
      >
        <Journal
          id={this.state.id}
          isphotodynamic={true}
          isparent={false}
          type={journalType.COMMENTSPSY}
          iscorrection={false}
          back={this.back}
        />
      </Template>
    );
  }
}

ChildCommentsPsyScreen.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default ChildCommentsPsyScreen;
