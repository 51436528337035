import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Childs,Events} from '../../Models';

// helpers
import {Helpers,LS} from '../../Globals/index';

// globals
import {API,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class EducationChildsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:null,
			user:null,
			notfound:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		const childs = await Childs.getAll(areaId);
		this.setState({user,data:childs.data,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewEducationChildPage, null, null, null, 2);
	}
	render() {
		return <Template page={'departments'} title={'Дети'} backLink={'/education'}>
			{this.state.loading ? <Loader /> :
				this.state.data.map((v,i) => <div key={i} className="list-education-child-item">
					<div className="avatar">
						{v.photo ? <img src={`${API.assets}/childs/${v.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
					</div>
					<div>
						<div className="name">{v.lastName} {v.firstName} {v.middleName}</div>
						<div className="controls">
							<Link to={`/education-journal/${v.id}`}>электронный дневник</Link>
							<Link to={`/education-attestat/${v.id}`}>справка об аттестации</Link>
						</div>
					</div>
					<div className="buttons">
						<Link to={`/pupil/${v.id}`}><i className="bi bi-arrow-right-circle"></i></Link>
					</div>
				</div>)
			}
		</Template>
	}
}

export default EducationChildsScreen;