import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';
import ParentAdd from '../../Components/ParentAdd';

// models
import {Parents,Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class ParentScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const parent = await Parents.get(this.state.id);
			this.setState({notfound:!(parent.data && parent.data.length)});

			// Add events in log
			const fullName = ' ' + parent.data[0].lastName + ' ' + parent.data[0].firstName + ' ' + parent.data[0].middleName;
			Events.add(user.id, eventMessageName.viewPeopleParentEdit + fullName, null, null, null, 2);
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	save = () => this.props.history.push(`/parent/${this.state.id}`);
	render() {
		return <Template
					page={'departments'}
					title={this.state.id === 0 ? 'Добавление родителя' : 'Редактирование родителя'}
					controlPanel={
						<div className="form-control-panel">
							<button form="parent-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={this.state.id && this.state.id > 0 ? `/parent/${this.state.id}` : '/parental'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/parental'} /> :
					<ParentAdd id={this.state.id} onSave={() => this.save()} />
			}
			<Alert />
		</Template>
	}
}

import PropTypes from 'prop-types';
ParentScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default ParentScreen;