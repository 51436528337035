import Http from '../../Globals/Http';
import {requestOderType} from "../../Globals/Constants";

const get		= async (journalId, type)	=> await Http.get('childcorrectionphotojournals', {conditions:[{k:'journalId',v:journalId},{k:'type',v:type}], orders:[{k:'date', isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('childcorrectionphotojournals', {data});

const remove 	= async (id) 			=> await Http.remove('childcorrectionphotojournals', {conditions:[{k:'id',v:id}]});

const getTimeStamp = () => {
	var currentDate = new Date();

	currentDate.setMonth(currentDate.getMonth() - 3);

	return Math.floor(currentDate.getTime() / 1000);
};

const getNeedLoadPhoto = async () => await Http.get('childcorrectionjournals', { conditions:[{k:'dateCreate', v: getTimeStamp(), op: 3}] })

export {
	get,
	add,
	remove,
	getNeedLoadPhoto
}