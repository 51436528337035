import Http from '../../Globals/Http';

import {requestOderType} from '../../Globals/Constants';

const get		= async (teacherId)		=> await Http.get('notificationmessages', {conditions:[{k:'teacherId',v:teacherId}],orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getById		= async (messageId)		=> await Http.get('notificationmessages', {conditions:[{k:'id',v:messageId}]});

const add		= async (data)			=> await Http.post('notificationmessages', {data});
const update	= async (messageId, data)	=> await Http.put('notificationmessages', {data,conditions:[{k:'id',v:messageId}]});

export {
	get,
	getById,
	add,
	update
}