import PropTypes from 'prop-types';
import './styles.scss';
import { useEffect, useState } from 'react';
import Confirm from '../Confirm';
import { ChatUsers, Chats, Teachers } from '../../Models'
import { Helpers, LS } from '../../Globals';
import Loader from '../Loader';

// eslint-disable-next-line no-unused-vars
const ChatModal = ({ isOpen, setIsOpen, updateChat, chatId, usersList, chatData, alertMessage }) => {
    const [ chatName, setChatName ] = useState(chatId > 0 ? chatData?.name || 0 : 0);
    const autoResizeTextArea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

    const [ isOpenCancelConfirm, setOpenCancelConfirm ] = useState(false);
    const [ isOpenSaveConfirm, setOpenSaveConfirm ] = useState(false);
    const [ chatStatus, setChatStatus ] = useState(false);
    const [ wasEdit, setWasEdit ] = useState(false);
    const areaId = parseInt(LS.get('areaId'));
    const [ isLoading, setIsLoading ] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [ isOpenDeleteConfirm, setOpenDeleteConfirm ] = useState(false);
    const [userList, setUserList] = useState([]);

    const user = Helpers.authCheck();

    useEffect(() => {
        setChatName(chatData?.name);
    }, [usersList, chatData, chatId])

    useEffect(() => {
        const initialSelectedUsers = Array.from(new Set(usersList?.map(item => item.userId)));
        setSelectedUsers(initialSelectedUsers);
    }, [isOpen, usersList, chatId]);

    const handleUserSelection = (userId) => {
        const isSelected = selectedUsers.includes(userId);
        setSelectedUsers(isSelected ? selectedUsers.filter(id => id !== userId) : [...selectedUsers, userId]);
        setWasEdit(true);
    };

    const handleChange = (e) => {
        if (!wasEdit) setWasEdit(true);
        setChatName(e.target.value);
    }

    const forceClose = () => {
        setOpenCancelConfirm(false);
        setOpenSaveConfirm(false);
        setWasEdit(false);
        setIsOpen(false);
        // setTextAreaContent('');
        // setTextAreaName('');
        // setchatStatus(false);
    }

    const cancel = (force) => {
        if (!force && wasEdit) return setOpenCancelConfirm(true);
        forceClose();
    };

    const save = (force) => {
        if (chatName == '') {
            alertMessage('Введите название чата', 1);
            return;
        }
        if (force) {
            return forceClose();
        }
        setOpenSaveConfirm(true);
    };

    useEffect(() => {
        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                if (isOpen && wasEdit) setOpenCancelConfirm(true);
                else forceClose();
            }
        }
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [isOpen]);
    
    useEffect(() => {
        const getChatById = async (id) => {
            setIsLoading(true);
            const currentChat = await Chats.get(id);
            const teachers = await Teachers.getAllWithoutType(areaId);
            if (teachers?.data) setUserList(teachers?.data);
            if (currentChat?.data && currentChat?.data?.length > 0) {
                setChatName(currentChat.data[0].name);
                setChatStatus(currentChat.data[0].status);
            } else {
                setChatName('');
                setChatStatus(false);
            }
            setIsLoading(false);
        }
        getChatById(chatId);
        updateChat();
    }, [chatId, setIsOpen]);

    if (!isOpen) return null;

    return (
        <div className="note-modal-container" onClick={() => cancel(false)}>
            <div className="note-modal-content" onClick={(e) => e.stopPropagation()}>
            {isLoading ? <Loader /> : <>
                <div className="note-modal-header">
                    {chatId <= 0 ? <h3>Создать чат</h3> : <h3>Чат {chatName}</h3>}
                    <i className="bi bi-x-square" onClick={() => cancel(false)}></i>
                </div>
                <div className="note-modal-body">
                    <div>
                        <span>Название чата:</span>
                        <input value={chatName} onChange={(e) => handleChange(e, 'desc')} onInput={(e) => autoResizeTextArea(e, 'desc')} />
                    </div>
                    <div>
                        <span>Выберите участников:</span>
                        <form>
                            {userList.map((user) => (
                                <div className="select-users" key={user.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.includes(user.id)}
                                            onChange={() => handleUserSelection(user.id)}
                                        />
                                        {`${user.lastName} ${user.firstName} ${user.middleName}`}
                                    </label>
                                </div>
                            ))}
                        </form>
                    </div>
                </div>
                <div className="note-modal-footer">
                    {chatId ? <button className="delete" onClick={() => setOpenDeleteConfirm(true)}>Удалить чат</button> : null}
                    <div className="buttons">
                        <button className="save" onClick={() => save(false)}>{chatId <= 0 ? 'Создать чат' : 'Сохранить'}</button>
                        <button className="cancel" onClick={() => cancel(false)}>Отменить</button>
                    </div>
                </div>
            </>}
            </div>
            {isOpenCancelConfirm && (
                <Confirm
                    message={'Вы уверены, что хотите закрыть без сохранения?'}
                    action={() => {
                        forceClose();
                        alertMessage('Закрыто без сохранения', true);
                    }}
                    close={() => { setOpenCancelConfirm(false) }}
                />
            )}
            {isOpenDeleteConfirm && chatId > 0 && (
                <Confirm
                    message={'Вы уверены, что хотите удалить чат?'}
                    action={() => {
                        updateChat();
                        alertMessage('Чат удален', false);
                        Chats.update(chatId, {status: 0});
                        setOpenDeleteConfirm(false);
                        forceClose();
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }}
                    close={() => { setOpenDeleteConfirm(false) }}
                />
            )}
            {isOpenSaveConfirm && (
                <Confirm
                    message={wasEdit ? 'Вы уверены, что хотите создать чат?' : 'Вы ничего не заполнили, закрыть без изменений?'}
                    action={() => {
                        if (wasEdit) {
                            if (chatId && chatId > 0) {
                                ChatUsers.removeByChatId(chatId).then(() => {
                                    Chats.update(chatId, {
                                        name: chatName,
                                        status: chatStatus
                                    }).then(() => {
                                        const uniqueSelectedUsers = Array.from(new Set(selectedUsers ? [user.id, ...selectedUsers] : [user.id]));
                                        uniqueSelectedUsers.map((item) => {
                                            ChatUsers.add({ chatId, userId: item });
                                        });
                                        alertMessage('Чат обновлен');
                                        updateChat();
                                        forceClose();
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 2000);
                                    });
                                });
                            } else {
                                Chats.add({
                                    areaId: areaId,
                                    name: chatName,
                                    userId: user.id,
                                    status: 1,
                                }).then((res) => {
                                    const resChatId = res.data.id;
                                    if (resChatId && resChatId > 0) {
                                        const uniqueSelectedUsers = Array.from(new Set(selectedUsers ? [user.id, ...selectedUsers] : [user.id]));
                                        uniqueSelectedUsers.map((item) => {
                                            ChatUsers.add({ chatId: resChatId, userId: item });
                                        });
                                    }
                                    alertMessage('Чат создан');
                                    updateChat();
                                    forceClose();
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 2000);
                                });
                            }
                        } else {
                            alertMessage('Ничего не изменено!');
                            forceClose();
                        }
                    }}
                    close={() => { setOpenSaveConfirm(false); }}
                />
            )}
        </div>
    );
};

ChatModal.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateChat: PropTypes.func,
    chatId: PropTypes.number,
    chatData: PropTypes.any,
    usersList: PropTypes.any,
    alertMessage: PropTypes.any
}

export default ChatModal;