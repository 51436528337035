import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Дети',link:'sport-childs',desc:'Физическое состояние, секции, последствия'},
	{name:'План',link:'sport-plan',desc:'Еженедельный план работы по спорту'},
	{name:'Нормативы ',link:'sport-standarts',desc:'Спортивные нормативы'},
	{name:'Экспорт',link:'sportexport',desc:'Экспорт по выбранным параметрам'}
];

class SportScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

		// Add events in log
		Events.add(user.id, eventMessageName.viewSportPage, null, null, null, 2);
	}
	render() {
		return <Template page={'departments'} title={'Спортивная часть'}>
			{this.state.loading ? <Loader /> :
				<div className="sport-list">
					{menu.map((v,i) => <Link to={`/${v.link}`} className="item" key={i}>
						<div className="item-title">{v.name}</div>
						<div className="item-description">{v.desc}</div>
					</Link>)}
				</div>
			}
		</Template>
	}
}

export default SportScreen;