import {Component} from 'react';
import Template from '../../../Components/Template';
import Alert from '../../../Components/Alert';
import {Childs,Parents,Events, ChildParents, Programs, ProgramTasks, ProgramChildrens, ProgramChildTasks, Teachers, ChildDocuments} from '../../../Models';
import {Helpers,LS} from '../../../Globals/index';
import {API, eventMessageName} from '../../../Globals/Constants';

import PupilInfo from './PupilInfo';
import PupilCorrection from './PupilCorrection';
import PupilPsychology from './PupilPsychology';
import PupilTasks from './PupilTasks';
import PupilMed from './PupilMed';
import PupilSport from './PupilSport';
import PupilPhoto from './PupilPhoto';

import '../styles.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../../Components/Loader';

class PupilScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTab:null,
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			updatedChild: {
				firstName	: '',
				lastName	: '',
				middleName	: '',
				parent 		: null,
				photo 		: null,
				parents		: [],
				programChild: {
					remainingDays: 0,
				},
				program		: {},
				tasks		: []
			},
			pupil: {
				firstName	: '',
				lastName	: '',
				middleName	: '',
				parent 		: null,
				photo 		: null,
				parents		: [],
			},
			loading:true,
			parents:[],
			hasDocs: null
		};
	}
	setHasDocs = (val) => {
		this.setState({ hasDocs: val });
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
	
		if (this.state.id) {
			try {
				// Fetch data for one child
				const child = await Childs.get(this.state.id);

				if (child.data && child.data.length) {
					const data = child.data[0];
	
					const cp = await ChildParents.get(data.id);
					const ids = cp.data.map((v) => v.parentId);
					const childparents = ids && ids.length ? await Parents.getByIds(ids) : { data: [] };
	
					const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
					Events.add(user.id, eventMessageName.viewPeopleChildEdit + fullName, null, null, null, 2);
	
					const programs = await Programs.getAll();
					const programTasks = await ProgramTasks.getAll();
					const programChildrens = await ProgramChildrens.getAll();
					const programChildTasks = await ProgramChildTasks.getAll();

					await Teachers.getAll(areaId, 1).then((res) => {
						if (res.data) {
							this.setState({teachers: res.data});
						}
					})
	
					const updatedTasks = programTasks.data.map((task) => ({ ...task, status: -1 }));
	
					const filteredProgramChildTasks = programChildTasks.data.filter((programChildTask) => programChildTask.childId === this.state.id);
	
					const childTaskIds = filteredProgramChildTasks.map((programChildTask) => programChildTask.programTaskId);
	
					const programTaskIds = programTasks.data.map((task) => task.id);
	
					const missingTaskIds = programTaskIds.filter((taskId) => !childTaskIds.includes(taskId));
					const missingTasks = programTasks.data.filter((task) => missingTaskIds.includes(task.id));
					missingTasks.forEach((task) => filteredProgramChildTasks.push({ childId: this.state.id, programTaskId: task.id, status: -1 }));
	
					const childTasks = filteredProgramChildTasks.map((programChildTask) => {
						const task = updatedTasks.find((task) => task.id === programChildTask.programTaskId);
						return { ...task, status: programChildTask.status };
					});
	
					const updatedChild = programChildrens.data.filter((programChild) => programChild.childId === this.state.id).map((programChild) => {
						const programId = programChild.programId;
						const program = programs.data.find((program) => program.id === programId);
	
						const currentDateTimestamp = Math.floor(Date.now() / 1000);
						const dateStartedTimestamp = Math.floor(programChild.dateStarted);
						const elapsedTimeInSeconds = currentDateTimestamp - dateStartedTimestamp;
						const allChildTasks = childTasks.filter((task) => task.programId === programId);
						const allChildTasksDone = allChildTasks.every((task) => task.status === 1);
	
						programChild.remainingDays = allChildTasksDone ? 0 : Math.max(program?.countDays - Math.floor(elapsedTimeInSeconds / (24 * 60 * 60)), 0);
	
						let style = allChildTasksDone
							? 'tasks-done'
							: programChild.remainingDays <= 0 && programChild.dateStarted === 0
							? 'tasks-not-started'
							: programChild.remainingDays === 0 && programChild.dateStarted > 0
							? 'tasks-error'
							: programChild.remainingDays < 10 && programChild.dateStarted > 0
							? 'warning'
							: programChild.dateStarted > 0 && programChild.remainingDays >= 0
							? 'tasks-done'
							: 'default';
	
						programChild.styleDays = style;
						programChild.allChildTasksDone = allChildTasksDone;
	
						return {
							program,
							programChild: programChild,
							tasks: allChildTasks,
						};
					});
	
					const pupilWithParents = {
						...data,
						...updatedChild[0],
						parents: childparents.data,
					};

					this.setState({ data, pupil: pupilWithParents });
	
					this.setState({
						user,
						areaId,
						loading: false,
						programs: programs.data,
						tasks: programTasks.data,
						childTasks: programChildrens.data,
						updatedChild: updatedChild[0],
					});
				} else {
					this.setState({ notfound: true });
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				this.setState({ loading: false });
			}
		} else {
			this.setState({ notfound: false });
		}
		const parents = await Parents.getAll(areaId);
		this.check();
		this.setState({ user, parentsAll: parents.data, loading: false });
	}
	getCurrentTab = () => {
		switch (this.state.currentTab) {
			case "Информация": return <PupilInfo pupil={this.state.pupil} />;
			case "Коррекционная работа": return Helpers.inRole(['correction'])?<PupilCorrection pupil={this.state.pupil} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
			case "Психологическая работа": return Helpers.inRole(['psychology'])?<PupilPsychology pupil={this.state.pupil} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
			case "Задачи": return Helpers.inRole(['tasks'])?<PupilTasks pupil={this.state.pupil} teachers={this.state.teachers} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
			case "Мед. часть": return Helpers.inRole(['medic'])?<PupilMed pupil={this.state.pupil} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
			case "Спорт": return Helpers.inRole(['sport'])?<PupilSport pupil={this.state.pupil} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
			case "Фото": return Helpers.inRole(['correction'])?<PupilPhoto pupil={this.state.pupil} />:<div style={{padding:"10px 15px"}}>У вас нет прав для просмотра этой страницы</div>;
		}
	}
	selectTab = (tab) => {
		this.setState({currentTab: tab})
	}
	tabs = () => {
		return ['Информация', 'Коррекционная работа', 'Психологическая работа', 'Задачи', 'Мед. часть', 'Спорт', 'Фото']
	}
	
	handleTeacherChange = (event, childId) => {
		const selectedTeacherId = parseInt(event.target.value);
		this.setState((prevState) => ({
			pupil: {
				...prevState.pupil,
				teacherId: selectedTeacherId,
			},
		}));
		Childs.update(childId, { teacherId: selectedTeacherId });
	};

	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
	
	check = async () => {
		await ChildDocuments.get(this.state.pupil.id).then((res) => {
			this.setHasDocs(Boolean(res.data.find(item => item.type === 1) || res.data.find(item => item.type === 2)));
		});
	}

	render() {
		const tabs = this.tabs();
		const child = this.state.pupil;
		if (this.state.currentTab == null) {
			this.setState({currentTab:tabs[0]})
		}
		if (this.state.hasDocs === null) {
			this.check();
		}
		return (<>
			<Template
				page={'pupils'}
				title={this.state.id === 0 ? 'Добавление ребенка' : child && child.id > 0 ? `${child?.lastName} ${child?.firstName} ${child?.middleName}` : <Loader />}
				controlPanel={
					<div className="control-panel">
						<Link to={`${this.state.id}/edit`}><i className="bi bi-pencil-square"></i></Link>
					</div>
				}
				backLink={'/pupils'}>
				{child?.id <= 0 || this.state.loading ? <Loader /> : <>
					<div className="pupil_container">
						{
							this.state.hasDocs !== null && !this.state.hasDocs ? (
								<div className={'need-docs'}><span>Внимание! Необходимо Паспорт или Свидетельство о рождении!</span></div>
							) : null
						}
						<div className="pipil-person">
							<div className="pupil">
								<div className="pupil_block" style={{width:"30%"}}>
									<div className="avatar">
										{child?.photo ? <img src={`${API.assets}/childs/${child?.photo}`} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
									</div>
									<span className="middle">{child?.lastName} {child?.firstName} {child?.middleName}</span>
								</div>
								<div className="pupil_block" style={{width:"35%"}}>
									{Helpers.inRole(['correction'])?
									<select value={child.teacherId} onChange={(e) => this.handleTeacherChange(e, child.id)}>
										<option value="">Выберите ответственного</option>
										{this.state.teachers?.map((teacher) => (<option key={teacher.id} value={teacher.id}>{`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`}</option>))}
									</select>:<span>{this.state.teachers.map((teacher) => { if(teacher.id === child.teacherId) return `${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`;})}</span>}
									<span>Ответственный</span>
								</div>
								{/* <div className="pupil_block" style={{width:"25%"}}>
									<span>-</span>
									<span>Психолог</span>
								</div> */}
								<div className="pupil_block" style={{width:"30%"}}>
									<span>Программа</span>
									<span className="middle">{child?.program?.name || 'Программа не выбрана'}</span>
								</div>
								<div className="pupil_block">
									<span>Дата начала</span>
									<span>{child?.programChild?.dateStarted > 0 ? this.dateToLocale(child?.programChild?.dateStarted) : '-'}</span>
								</div>
								{child?.programChild?.dateStarted > 0 ? <div className={`pupil_block ${child?.programChild?.styleDays}`}>
									{child?.programChild.allChildTasksDone ? <><i>Программа завершена</i></> : child?.programChild?.dateStarted > 0 ? <>
										<div>{child?.programChild?.remainingDays || 0}</div>
										<span>дней до завершения</span>
										<span>программы</span></>
									:
										null
									}
								</div>
								: null }
							</div>
						</div>
						<div className="pupil_tabs">
							<div className="full-width-tabs">
							{tabs.map((tab, index) => (
								<div key={index} onClick={() => this.selectTab(tab)} className={tab == (this.state.currentTab ?? tabs[0]) ? "tab selected" : "tab"}>
									{tab}
								</div>
							))}
							</div>
						</div>
						{this.getCurrentTab()}
					</div>
					<Alert />
				</>}
			</Template>
		</>);
	}
}

PupilScreen.propTypes = {
    match: PropTypes.object.isRequired,
};
export default PupilScreen;