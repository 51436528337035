const get = (name) => JSON.parse(window.localStorage.getItem(name) || null);
const set = (name, value) => window.localStorage.setItem(name, JSON.stringify(value));
const remove = (name) => window.localStorage.removeItem(name);
const clear = () => window.localStorage.clear();

export {
	get,
	set,
	remove,
	clear
}