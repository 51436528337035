import {Component} from 'react';
import { Link } from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

//vars
const menu = [
	{name:'Письма',link:'office-mails',desc:'Регистрация входящих и исходящих песем'},
	{name:'Контрагенты',link:'office-contractors',desc:'Длоговора, расторжения, акты передачи'},
	{name:'Статистика',link:'office-statistics',desc:'Прикрепить документы'},
	{name:'Судебные дела',link:'office-court-mails',desc:'Входящие и исходящие письма, протоколы решений'}
];

class OfficeScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});

        // Add events in log
        Events.add(user.id, eventMessageName.viewOfficePage, null, null, null, 2);
	}
	render() {
		return <Template page={'office'} title={'Делопроизводство'}>
			{this.state.loading ? <Loader /> :
				<div className="office-list">
					{menu.map((v,i) => <Link to={`/${v.link}`} className="item" key={i}>
						<div className="item-title">{v.name}</div>
						<div className="item-description">{v.desc}</div>
					</Link>)}
				</div>
			}
		</Template>
	}
}

export default OfficeScreen;