import Http from '../../Globals/Http';

// globals
import { comonStatus } from '../../Globals/Constants';

const get		= async (id)		=> await Http.get('areas', {conditions:[{k:'id',v:id}]});
const getAll	= async ()			=> await Http.get('areas');
const getActive	= async ()			=> await Http.get('areas', {conditions:[{k:'status',v:comonStatus.ACTIVE}]});

const add		= async (data)		=> await Http.post('areas', {data});
const update	= async (id, data)	=> await Http.put('areas', {data,conditions:[{k:'id',v:id}]});


export {
	get,
	getAll,
	getActive,
	add,
	update
}