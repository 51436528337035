import {Component} from 'react';
import Template from '../../Components/Template';
import Alert, { alertShow } from '../../Components/Alert';
import {Helpers,LS} from '../../Globals/index';
import './styles.scss';
import { taskChildTypeNames, taskChildTypes, taskTypeNames, taskTypes, typeUserNames, typeUsers } from '../../Globals/Constants';
import { Childs, Parents, TaskUsers, Tasks, Teachers } from '../../Models';
import MultiSelect from './MultiSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../Components/Loader';

class CorrectionTasks extends Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		const nameParam = urlParams.get('link');
		this.state = {
			user:null,
			loading:true,
			newtaskeditor: nameParam === 'add-task' ? true : false,
			categoryId: 1,
			taskTypes: [
				taskTypes.GROUP, taskTypes.TRAINING, taskTypes.OTHER
			],
			taskChildTypes: {
				[taskTypes.GROUP]: [taskChildTypes.USTS, taskChildTypes.LITTLEGROUP, taskChildTypes.RESULT, taskChildTypes.OTHER],
				[taskTypes.TRAINING]: [taskChildTypes.OTHER],
				[taskTypes.OTHER]: [taskChildTypes.OTHER]
			},
			newtask: {
				type: taskTypes.GROUP,
				category: 1,
				view: taskChildTypes.USTS,
				name: null,
				info: null,
				comment: null,
				curatorId: null,
				dateEnd: this.getCurrentDateTime(),
				dateStart: this.getCurrentDateTime(),
				dateShowStart: null,
				date: null,
				time: null,
				status: null,
				wasedit: false
			},
			selectedParents: [],
			selectedPupils: [],
			timeWasEdit: true,
			teachers: [],
			pupils: [],
			parents: [],
			updatedTasks: [],
			taskusers: [],
			tasks: [],
			filter: {
				type: taskTypes.GROUP,
				view: 0,
				sort: "byDateAsc",
				filter: "peoples",
				search: "",
				secondtype: null
			},
			openedtasks: []
		};
	}
	
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		const areaId = parseInt(LS.get('areaId'));
		await Teachers.getAll(areaId, 1).then((res) => this.setState({teachers: res.data}));
		await Childs.getAll(areaId).then((res) => this.setState({pupils: res.data}));
		await Parents.getAll(areaId).then((res) => this.setState({parents: res.data}));
		await Tasks.getAll().then(async (tasks) => {
			if (tasks && tasks.data) {
				await TaskUsers.getAll().then((users) => {
					if (users && users.data && users.data.length) {
						const updatedTasks = tasks.data.sort((a, b) => new Date(b.dateCreate) - new Date(a.dateCreate)).filter(task => task.category === this.state.categoryId).map((task) => {
							const curator = this.state.teachers.filter((t) => t.id === task.curatorId)[0];
							return { ...task, users: users.data.filter((user) => user.taskId === task.id), curator, daysout: this.checkDate(this.getCurrentDateTime(), task.dateEnd) };
						})
						this.setState({taskusers: users.data, updatedTasks});
					}
				});
				this.setState({tasks: tasks.data});
			}
		});
		this.setState({user,areaId,loading:false});
	}
	
    autoResizeTextArea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

	handleMultiSelectChangePupils = (selectedItems) => this.setSelectedPupils(selectedItems);

	handleMultiSelectChangeParents = (selectedItems) => this.setSelectedParents(selectedItems);

	setSelectedPupils = (items) => this.setState({ selectedPupils: items.map(id => { return this.state.pupils.filter(p => p.id === id)[0] }) });

	setSelectedParents = (items) => this.setState({ selectedParents: items.map(id => { return this.state.parents.filter(p => p.id === id)[0] }) });

	handleTaskTypeChange = (event) => {
		const selectedTaskType = parseInt(event.target.value);
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				type: selectedTaskType,
				view: this.state.taskChildTypes[selectedTaskType],
			},
		}));
	};

	handleTaskChildTypeChange = (event) => {
		const selectedTaskChildType = parseInt(event.target.value);
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				view: selectedTaskChildType,
			},
		}));
	};

	getCurrentDateTime = () => Math.floor(new Date().getTime() / 1000);

	handleDateStartChange = (event) => {
		const selectedDate = event.target.value;
		const dateStart = Math.floor(new Date(`${selectedDate}`).getTime() / 1000);
		if (dateStart + 86400 < this.getCurrentDateTime()) {
			alertShow('Выберите корректную дату и время не раньше текущего времени', 1);
			return;
		}
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				dateStart,
				dateShowStart: selectedDate
			},
			timeWasEdit: true
		}));
	};

	handleDateEndChange = (event) => {
		const selectedDate = event.target.value;
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				date: selectedDate,
			},
			timeWasEdit: true
		}));
	};

	handleTimeEndChange = (event) => {
		const selectedTime = event.target.value;
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				time: selectedTime,
			},
			timeWasEdit: true
		}));
	};

	handleSubmit = () => {
		const { date, time } = this.state.newtask;
		if (!date || !time) {
			alertShow('Выберите корректную дату и время', 1);
			return;
		}
		const dateEnd = this.combineDateTime(date, time);
		
		if (dateEnd === null || dateEnd <= this.getCurrentDateTime()) {
			alertShow('Выберите корректную дату крайнего срока и время не раньше текущего времени', 1);
			return false;
		}

		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				dateEnd,
			},
			timeWasEdit: false
		}));
	};

	combineDateTime = (date, time) => date && time ? Math.floor(new Date(`${date} ${time}`).getTime() / 1000) : null;

	toggleTaskEditor = () => this.setState({ newtaskeditor: !this.state.newtaskeditor });

	handleTeacherChange = (event) => {
		const selectedTeacherId = parseInt(event.target.value);
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				curatorId: selectedTeacherId,
			},
		}));
	};

	handleTeacherTaskChange = (event, task) => {
		const selectedTeacherId = parseInt(event.target.value);
		const updatedTasks = this.state.updatedTasks.map((t) => {
			if (t.id === task.id) {
				const curator = this.state.teachers.filter((t) => t.id === selectedTeacherId)[0];
				Tasks.update(task.id, { curatorId: selectedTeacherId });
				return { ...t, curatorId: selectedTeacherId, curator }
			}
			return t;
		});

		this.setState({ updatedTasks });
	}

	createTask = async () => {
		const { newtask: nt, timeWasEdit, selectedParents: spr, selectedPupils: spl } = this.state;

		if (!nt.info) {
			alertShow('Описание не может быть пустым', 1);
			return false;
		}
		if (!nt.name) {
			alertShow('Задача не может быть пустой', 1);
			return false;
		}
		if (!nt.curatorId) {
			alertShow('Выберите ответственного', 1);
			return false;
		}
		if (timeWasEdit) {
			alertShow('Вы не применили изменение даты и времени', 1);
			return false;
		}
		if (nt.dateEnd === null || nt.dateEnd <= this.getCurrentDateTime()) {
			alertShow('Выберите корректную дату крайнего срока и время не раньше текущего времени', 1);
			return false;
		}
		if (nt.dateStart === null || nt.dateStart + 86400 <= this.getCurrentDateTime()) {
			alertShow('Выберите корректную дату начала и время не раньше текущего времени', 1);
			return false;
		}
		if (nt.dateStart > nt.dateEnd) {
			alertShow('Дата начала не может быть больше даты крайнего срока', 1);
			return false;
		}
		if (!spl.length && !spr.length) {
			alertShow('Вы не выбрали участников', 1);
			return false;
		}

		try {
			const newData = {
				type: nt.type,
				category: this.state.categoryId,
				view: nt.view,
				name: nt.name,
				info: nt.info,
				curatorId: nt.curatorId,
				dateEnd: nt.dateEnd,
				dateStart: nt.dateStart,
				status: 1
			};
			await Tasks.add(newData).then((res) => {
				
				if (res && res.data?.id > 0) {
					const taskId = res.data.id;
					spl.map((u) => TaskUsers.add({
						taskId,
						userId: u.id,
						userType: typeUsers.PUPIL,
						userName: `${u.lastName} ${u.firstName} ${u.middleName}`,
						status: 1
					}));
					spr.map((u) => TaskUsers.add({
						taskId,
						userId: u.id,
						userType: typeUsers.PARENT,
						userName: `${u.lastName} ${u.firstName} ${u.middleName}`,
						status: 1
					}));
					alertShow('Задача успешно создана');

					const updatedTasks = this.state.updatedTasks;
					const curator = this.state.teachers.filter((t) => t.id === nt.curatorId)[0];
					const updatedNewData = { ...newData, id: taskId, curator };
					updatedTasks.push(updatedNewData);

					this.setState({			
						newtask: {
							type: taskTypes.GROUP,
							category: 1,
							view: taskChildTypes.USTS,
							name: null,
							info: null,
							comment: null,
							curatorId: null,
							dateEnd: this.getCurrentDateTime(),
							dateStart: this.getCurrentDateTime(),
							dateShowStart: null,
							date: null,
							time: null,
							status: null,
							wasedit: false
						},
						updatedTasks
					});

					window.location.reload();
				} else {
					alertShow('Произошла ошибка при создании задачи');
					return false;
				}
			}).catch((res) => {
				console.error(res);
				alertShow('Произошла ошибка при создании задачи');
				return false;
			});
		} catch (error) {
			console.error(error);
		}

		return true;
	};

	handleInfo = (e) => {
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				info: e.target.value
			}
		}));
	};

	handleName = (e) => {
		this.setState((prevState) => ({
			newtask: {
				...prevState.newtask,
				name: e.target.value
			}
		}));
	};

	getDayWord = (number) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return ['день', 'дня', 'дней'][(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
	};

	dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

	checkDate = (start, end) => {
		const differenceInSeconds = end - start;
		const remainingDays = Math.ceil(differenceInSeconds / 86400);
		return remainingDays;
	};

	startTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 1) {
			alertShow('Невозможно запустить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 2, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 2 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача запущена');
		return true;
	}

	stopTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.comment === '' || !task.comment) {
			alertShow('Чтобы завершить задачу, необходимо заполнить результат', 1);
			return false;
		}

		if (task.status !== 2) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 3, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 3 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача завершена');
		return true;
	}

	hideTask = (taskId) => {
		const { updatedTasks: ts } = this.state;
		const filteredTask = ts.filter(task => task.id === taskId);

		if (filteredTask.length === 0) {
			alertShow('Невозможно скрыть эту задачу', 1);
			return false;
		}

		const task = filteredTask[0];

		if (task.status !== 3) {
			alertShow('Невозможно завершить эту задачу', 1);
			return false;
		}

		const updatedTasks = ts.map(taskItem => {
			if (taskItem.id === taskId) {
				Tasks.update(taskId, { status: 4, dateModify: Math.floor(Date.now() / 1000) });
				return { ...taskItem, status: 4 };
			}
			return taskItem;
		});

		this.setState({ updatedTasks });

		alertShow('Задача скрыта');
		return true;
	}

	isWasEdit = (task) => {
		return task.id;
	}

	handleTaskName = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, name: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleTaskInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, info: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	handleResultInfo = (e, task) => {
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, comment: e.target.value, wasedit: true };
			}
			return t;
		});
		this.setState({ updatedTasks });
	}

	updateTask = async (task) => {
		const { comment, name, info } = task;
		Tasks.update(task.id, {
			comment, name, info, dateModify: Math.floor(Date.now() / 1000)
		});
		const updatedTasks = this.state.updatedTasks.map(t => {
			if (t.id === task.id) {
				return { ...t, wasedit: false };
			}
			return t;
		});
		this.setState({ updatedTasks });
		alertShow('Задача была обновлена');
	}

	setFilter = (type) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				type
			}
		}));
	}
	
	setSeconFilter = (secondtype) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				secondtype: prevState.filter.secondtype === secondtype ? null : secondtype
			}
		}));
	}

	sortBy = (event) => {
		const sortOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				sort: sortOption
			}
		}));
	};

	searchBy = (event) => {
		const filterOption = event.target.value;
		
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				filter: filterOption
			}
		}));
	};

	dateToString = (date) => {
		return date.toISOString();
	};

	setSearchParticipants = (query) => {
		this.setState((prevState) => ({
			filter: {
				...prevState.filter,
				search: query
			}
		}));
	};

	toggleTask = (taskId) => {
		let newTasks = [...this.state.openedtasks];
		if (this.isOpenedTask(taskId)) {
			newTasks = newTasks.filter((t) => t !== taskId);
		} else {
			newTasks.push(taskId);
		}

		this.setState({ openedtasks: newTasks });
	}

	isOpenedTask = (taskId) => this.state.openedtasks.includes(taskId);

	render() {
		const { newtask, filter } = this.state;

		let filteredTasks = this.state.updatedTasks;
		if (filter.type !== null) {
			filteredTasks = this.state.updatedTasks.filter((t) => t.type === filter.type);
		}

		if (filter.filter) {
			if (filter.search) {
				const searchQuery = filter.search.toLowerCase().trim();
				if (filter.filter === "users") {
					filteredTasks = filteredTasks.filter((task) =>
						task.users.some((user) => user.userName?.toLowerCase().includes(searchQuery))
					);
				}
				else if (filter.filter === "teachers") {
					filteredTasks = filteredTasks.filter((task) => {
						const name = `${task?.curator?.lastName} ${task?.curator?.firstName} ${task?.curator?.middleName}`;
						return name.toLowerCase().includes(searchQuery)
					});
				}
			}
			if (filter.filter === "new") {
				filteredTasks = filteredTasks.filter((task) => task.status === 1);
			} else if (filter.filter === "active") {
				filteredTasks = filteredTasks.filter((task) => task.status === 2);
			} else if (filter.filter === "done") {
				filteredTasks = filteredTasks.filter((task) => task.status === 3);
			} else if (filter.filter === "archive") {
				filteredTasks = filteredTasks.filter((task) => task.status === 4);
			}
		}

		if (filter.sort === "byDateDesc") {
			filteredTasks.sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart));
		}
		else if (filter.sort === "byDateAsc") {
			filteredTasks.sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart));
		} else if (filter.sort === "byType") {
			filteredTasks.sort((a, b) => a.type?.localeCompare(b.type));
		}

		let genderFilter = null;

		if (this.state.filter.secondtype === "male") {
			genderFilter = 1;
		} else if (this.state.filter.secondtype === "female") {
			genderFilter = 2;
		}

		if (genderFilter > 0) {
			filteredTasks = filteredTasks?.filter(task => {
				const pupilIdsWithGender = this.state.pupils?.filter(pupil => pupil.gender === genderFilter).map(pupil => pupil.id);
				console.log(task)
				
				console.log(pupilIdsWithGender.sort((a, b) => a - b));
				return task.users?.filter(user => user.userType === 2).every(user => pupilIdsWithGender.includes(user.userId));
			});
		}

		return <Template
			page={'correction-tasks'}
			title={'Коррекционная работа'}
			controlPanel={<div className="control-panel"><button onClick={() => this.toggleTaskEditor()}>{this.state.newtaskeditor ? 'Задачи' : 'Добавить задачу'}</button></div>}
			backLink={'/'}
		>
			{this.state.loading ? <Loader /> : <>
				{this.state.newtaskeditor ? <>
					<div className="tasks-container">
						<h4 style={{ width: '100%', display: 'block', margin: '10px 0 10px 0' }}>Создание новой задачи</h4>
						<div className="tasks-list">
							<div className="tasks-item">
								<div>
									<div className="tasks-block">
										<span>Вид коррекционной работы</span>
										<div>
											<select value={newtask.type} onChange={this.handleTaskTypeChange}>
												{this.state.taskTypes?.map((taskType, index) => (<option key={index} value={taskType}>{taskTypeNames[taskType]}</option>))}
											</select>
										</div>
									</div>
								</div>
								<div>
									<div className="tasks-block">
										<span>Тип работы</span>
										{newtask.type !== null ? (
											<select value={newtask.view} onChange={this.handleTaskChildTypeChange}>
												{this.state.taskChildTypes[newtask.type]?.map((taskChildType, index) => (
													<option key={index} value={taskChildType}>
														{taskChildTypeNames[taskChildType]}
													</option>
												))}
											</select>
										) : (<select><option>Выберите вид работы</option></select>)}
									</div>
								</div>
								<div>
									<div className="tasks-block">
										<span>Ответственный</span>
										<select value={newtask.curatorId} onChange={this.handleTeacherChange}>
											<option value="">Выберите ответственного</option>
											{this.state.teachers.map((teacher) => (
												<option key={teacher.id} value={teacher.id}>{`${teacher?.lastName} ${teacher?.firstName} ${teacher?.middleName}`}</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="tasks-item">
								<div>
									<div className="tasks-block">
										<label>Задача</label>
										<textarea onInput={this.autoResizeTextArea} onChange={this.handleName}>{this.state.newtask.name}</textarea>
									</div>
									<div className="tasks-block">
										<label>Описание</label>
										<textarea onInput={this.autoResizeTextArea} onChange={this.handleInfo}>{this.state.newtask.info}</textarea>
									</div>
								</div>
								<div>
									<div className="tasks-block">
										<span>Дата начала</span>
										<input type="date" value={newtask.dateShowStart} onChange={this.handleDateStartChange} />
									</div>
									<div className="tasks-block">
										<span>Крайний срок (дата и время)</span>
										<input type="date" value={newtask.date} onChange={this.handleDateEndChange} />
										<input type="time" style={{ marginTop: "10px" }} value={newtask.time} onChange={this.handleTimeEndChange} />
										{this.state.timeWasEdit && <button className="submit-time" onClick={this.handleSubmit}>Применить</button>}
									</div>
								</div>
								<div>
									<div className="tasks-block">
										<span>Участники</span>
										<span>Воспитанники:</span>
										<MultiSelect onChange={this.handleMultiSelectChangePupils} options={this.state.pupils.map((p) => { return { value: p.id, label: `${p?.lastName} ${p?.firstName} ${p?.middleName}`, type: p.gender }})} />
										<ul className="selected-items">
											{this.state.selectedPupils.length ? <>
												<li style={{fontWeight:"bold"}}>Выбранные воспитанники:</li>
												{this.state.selectedPupils ? this.state.selectedPupils.map((c, i) => <li key={i}>{c?.lastName} {c?.firstName} {c?.middleName}</li>) : <li>-</li>}
											</> : <li>Вы не выбрали ни одного участника</li>}
										</ul>
									</div>
									<div className="tasks-block">
										<button className="create-task all-fields" onClick={() => { this.createTask(); }}>Создать задачу</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</> : <>
					<div className="task-filter">
						{this.state.taskTypes.map((t, i) => (
							<div key={i} className={this.state.filter.type === t && "active"} onClick={() => this.setFilter(t)}>{taskTypeNames[t]}</div>
						))}
						<div className={this.state.filter.secondtype === 'male' ? "tab active" : "tab"} onClick={() => this.setSeconFilter('male')}><span className="center-text">Мальчики</span></div>
						<div className={this.state.filter.secondtype === 'female' ? "tab active" : "tab"} onClick={() => this.setSeconFilter('female')}><span className="center-text">Девочки</span></div>
						<div>
							<select onChange={this.searchBy}>
								<option value="">Фильтр</option>
								<option disabled>Поиск по:</option>
								<option value="users">Участники</option>
								<option value="teachers">Ответственный</option>
								<option disabled></option>
								<option disabled>Фильтр:</option>
								<option value="new">Новые</option>
								<option value="active">Активные</option>
								<option value="done">Завершенные</option>
								<option value="archive">Архив</option>
							</select>
							<input type="text" placeholder="Поиск" value={this.state.filter.search} onChange={(e) => this.setSearchParticipants(e.target.value)} />
						</div>
						<div>
							Сортировать:
							<select onChange={this.sortBy} style={{marginLeft:"5px"}}>
								<option value="byDateAsc">По дате (убыв.)</option>
								<option value="byDateDesc">По дате (возр.)</option>
								<option value="byType">По алфавиту</option>
							</select>
						</div>
					</div>
					<div className="tasks-container">
						<div className="tasks-list">
							{filteredTasks?.length ? filteredTasks.map((task, i) => (<div key={i}>
								<div className="tasks-item">
									<div>
										<div className="tasks-block">
											<span>{taskTypeNames[task.type]}</span>
											<span>{taskChildTypeNames[task.view]}</span>
										</div>
									</div>
									{!this.isOpenedTask(task.id) ? <div>
										<div className="tasks-block" style={{ height: '60px' }}>
											<textarea disabled onInput={this.autoResizeTextArea} style={{ height: "20px", minHeight: '40px'  }} onChange={(e) => this.handleTaskName(e, task)} value={task.name}>{task.name}</textarea>
										</div>
									</div> : null}
									<div>
										<div className="tasks-block">
											<span>Дата начала</span>
											<span>{this.dateToLocale(task.dateStart)}</span>
										</div>
									</div>
									{!this.isOpenedTask(task.id) ? <div>
										<div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || task.status === 4 || task.status === 3 || task.status === 1 ? 'days-good' : task.daysout <= 3 ? 'days-warning' : task.daysout <= 1 ? 'days-error' : 'days-normal'}`}>
											<span>Крайний срок ({task.daysout < 0 ? 0 : task.daysout} {this.getDayWord(task.daysout) || 'дней'})</span>
											<span>{this.dateToLocale(task.dateEnd)}</span>
										</div>
									</div> : null}
									<div>
										<div className="tasks-block">
											<span>{task?.curator?.lastName} {task?.curator?.firstName} {task?.curator?.middleName}</span>
											<span>{task?.curator?.position}</span>
											{this.isOpenedTask(task.id) ? <select className="little-select" value={task?.curator?.id} onChange={(e) => this.handleTeacherTaskChange(e, task)}>
												<option value="">Изменить ответственного</option>
												{this.state.teachers.map((teacher) => (
													<option key={teacher.id} value={teacher.id}>{`${teacher.lastName} ${teacher.firstName} ${teacher.middleName}`}</option>
												))}
											</select> : null}
										</div>
									</div>
									<div>
										{task.status === 1 ? (
											<div className="tasks-block task-button-start">
												<div className="btn-start" onClick={() => this.startTask(task.id)}><i className="bi bi-play"></i></div>
												<span>Начать</span>
											</div>
										) : task.status === 2 ? (
											<div className="tasks-block task-button-end">
												<div className="btn-end" onClick={() => this.stopTask(task.id)}><i className="bi bi-stop"></i></div>
												<span>Завершить</span>
											</div>
										) : task.status === 3 ? (
											<div className="tasks-block task-button-hide">
												<div className="btn-hide" onClick={() => this.hideTask(task.id)}><i className="bi bi-x"></i></div>
												<span>Задача завершена</span>
											</div>
										) : <div className="tasks-block">В архиве</div>}
										{task.wasedit && <div className="tasks-block button-save"><span onClick={() => this.updateTask(task)}>Применить</span></div>}
									</div>
									<div onClick={() => this.toggleTask(task.id)} className={`toggle-button${this.isOpenedTask(task.id) ? ' active' : ''}`} style={{ width: "60px", flexBasis: "60px" }}>
										<i className="bi bi-caret-down-fill"></i>
									</div>
								</div>
								{this.isOpenedTask(task.id) ? <div className="tasks-item">
									<div>
										<div className="tasks-block">
											<label>Задача</label>
											<textarea onInput={this.autoResizeTextArea} onChange={(e) => this.handleTaskName(e, task)} value={task.name}>{task.name}</textarea>
										</div>
										<div className="tasks-block">
											<label>Описание</label>
											<textarea onInput={this.autoResizeTextArea} onChange={(e) => this.handleTaskInfo(e, task)} value={task.info}>{task.info}</textarea>
										</div>
									</div>
									<div>
										<div className={`tasks-block ${task.status === 4 ? 'days-hide' : task.daysout > 10 || task.status === 4 || task.status === 3 || task.status === 1 ? 'days-good' : task.daysout <= 3 ? 'days-warning' : task.daysout <= 1 ? 'days-error' : 'days-normal'}`}>
											<span>Крайний срок ({task.daysout < 0 ? 0 : task.daysout} {this.getDayWord(task.daysout) || 'дней'})</span>
											<span>{this.dateToLocale(task.dateEnd)}</span>
										</div>
										<div className="tasks-block" style={{ maxHeight: "400px", overflow: "auto" }}>
											<span>Участники</span>
											<span style={{ marginTop:"10px" }}>{typeUserNames[typeUsers.PUPIL]}:</span>
											<ul className="tasks-peoples">
												{task.users?.filter(user => user.userType === typeUsers.PUPIL).map((u, i) => <li key={i}><Link to={`/pupil/${u.userId}`}><i className="bi bi-person"></i>{u.userName}</Link></li>)}
											</ul>
										</div>
									</div>
									<div>
										<div className="tasks-block">
											<label>Результат</label>
											<textarea disabled={Number(task.status) < 2} onInput={this.autoResizeTextArea} onChange={(e) => this.handleResultInfo(e, task)} value={task.comment} className="tasks-result">{task.comment}</textarea>
										</div>
									</div>
								</div> : <div className="tasks-item" style={{ height: "0px", padding: "0px" }}></div>}
							</div>)) : <div>Нет задач</div>}
						</div>
					</div>
				</>}
				<Alert />
			</>
		}
		</Template>
	}
}

export default CorrectionTasks;