import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Settings} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

import './styles.css';

import PropTypes from 'prop-types';
class SettingScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:{},
			user:null,
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const settings = await Settings.get(this.state.id);
			if (settings.data && settings.data.length) this.setState({data:settings.data[0],notfound:false});
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			name:data.name,
			code:data.code,
			value:data.value
		};
		if (id === 0) {
			const res = await Settings.add(d).catch(() => this.error());
			if (res) Errors.add();
			else return false;
		} else {
			await Settings.update(id, d).catch(() => this.error());
			Errors.update();
		}
		this.props.history.push('/settings');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'settings'}
					title={this.state.id === 0 ? 'Добавление настроек' : 'Редактирование настроек'}
					controlPanel={
						<div className="form-control-panel">
							<button form="setting-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/settings'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/settings'} /> :
						<form id="setting-form" className="form" onSubmit={this.save}>
							<div className="form-row">
								<label htmlFor="name">Название</label>
								<input type="text" name="name" id="name" placeholder="Название" required className="double" autoFocus={this.state.id===0} onChange={this.formHandle} value={this.state.data.name} />
							</div>
							<div className="form-row">
								<label htmlFor="code">Код настройки</label>
								<input type="text" name="code" id="code" placeholder="Код настройки" required onChange={this.formHandle} value={this.state.data.code} />
							</div>
							<div className="form-row">
								<label htmlFor="value">Значение</label>
								<input type="text" name="value" id="value" placeholder="Значение" required className="double" autoFocus={this.state.id!==0} onChange={this.formHandle} value={this.state.data.value} />
							</div>
						</form>
			}
			<Alert />
		</Template>
	}
}

SettingScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default SettingScreen;