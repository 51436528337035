import Http from '../../Globals/Http';
import {requestOderType,genderType} from '../../Globals/Constants';

const getAll	= async ()				=> await Http.get('psychologychildsgroup', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getAllBoys	= async ()			=> await Http.get('psychologychildsgroup', {conditions:[{k:'gender',v:genderType.man}],orders:[{k:'id',isd:requestOderType.DESC}]});
const getAllGirls	= async ()			=> await Http.get('psychologychildsgroup', {conditions:[{k:'gender',v:genderType.woman}],orders:[{k:'id',isd:requestOderType.DESC}]});

const add		= async (data)			=> await Http.post('psychologychildsgroup', {data});

export {
	getAll,
	getAllBoys,
	getAllGirls,
	add
}