import { useEffect, useState } from 'react';
import './styles.scss'
import { PropTypes } from 'prop-types';

const MultiSelect = ({ options, onChange, selectedPupils }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filterValue, setFilterValue] = useState('');

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const handleItemClick = (value) => {
        if (selectedItems.includes(value)) {
            setSelectedItems((prev) => prev.filter((item) => item !== value));
        } else {
            setSelectedItems((prev) => [...prev, value]);
        }
    };

    const handleToggleAll = () => {
        setSelectedItems((prev) => prev.length === 0 ? options.map((option) => option.value) : []);
    };

    const handleToggleFemale = () => {
        setSelectedItems(options.filter((option) => option.type === 2).map(option => option.value));
    };

    const handleToggleMale = () => {
        setSelectedItems(options.filter((option) => option.type === 1).map(option => option.value));
    };

    const handleToggleParents = () => {
        setSelectedItems(options.filter((option) => {
            const pupil = selectedPupils?.find((pupil) => pupil.parentIds.includes(option.value));
            if (pupil) {
                return option;
            }
            return null;
        }).map(item => item.value));
    };

    useEffect(() => {
        onChange(selectedItems);
    }, [selectedItems, onChange]);

    const allChecked = selectedItems.length === options.length;

    const allMale = options.every(option => option.type !== 0) && selectedItems.length === options.filter((option) => option.type === 1).length && selectedItems.every(selectedItemId => {
        const correspondingOption = options.find(option => option.value === selectedItemId);
        return correspondingOption && correspondingOption.type === 1;
    });

    const allFemale = options.every(option => option.type !== 0) && selectedItems.length === options.filter((option) => option.type === 2).length && selectedItems.every(selectedItemId => {
        const correspondingOption = options.find(option => option.value === selectedItemId);
        return correspondingOption && correspondingOption.type === 2;
    });
    
    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(filterValue.toLowerCase())
    );

    const allParents = false;

    const label =
        selectedItems.length === 0
            ? 'Выберите участников'
            : selectedItems.length === options.length
            ? '(Все)'
            : `Выбрано ${selectedItems.length} участников`;

  return (
        <div className={`multi-select ${isOpen ? 'in' : ''}`}>
            <span className="toggle" onClick={toggleOpen}>
                <label>{label}</label>
                <span className="chevron">&lt;</span>
            </span>
            {isOpen && <div className="search-container">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Поиск..."
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                />
            </div>}
            <ul>
                <li>
                <label>
                    <input
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleToggleAll}
                    />
                    (Все)
                </label>
                {options.every(option => option.type === 0) ? 
                    <label>
                        <input
                            type="checkbox"
                            checked={allParents}
                            onChange={handleToggleParents}
                        />
                        (Родители выбранных воспитанников)
                    </label>
                : <>
                    <label>
                        <input
                            type="checkbox"
                            checked={allFemale}
                            onChange={handleToggleFemale}
                        />
                        (Все девочки)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={allMale}
                            onChange={handleToggleMale}
                        />
                        (Все мальчики)
                    </label>
                </>}
                </li>
                {filteredOptions.map((option) => (
                    <li key={option.value}>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedItems.includes(option.value)}
                                onChange={() => handleItemClick(option.value)}
                            />
                            {option.label}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

MultiSelect.propTypes = {
	onChange: PropTypes.any,
	options: PropTypes.any,
    selectedPupils: PropTypes.any
};

export default MultiSelect;