import {Component} from 'react';

// components
import Template from '../../Components/Template';
import Alert from '../../Components/Alert';

// models
import {Events} from '../../Models';

// helpers
import {Helpers} from '../../Globals/index';

// styles
import './styles.css';

// globals
import {API,eventMessageName} from '../../Globals/Constants';

//vars
const listExport = [
	{name:'Список детей с данными о заболевании', idexport: 1, typeExportDb: 'childmedicdiseases'},
	{name:'Список детей с данными о приеме таблеток', idexport: 2, typeExportDb: 'childmedicpills'},
	{name:'Список детей с данными о травмах', idexport: 3, typeExportDb: 'childmedictraumas'},
	{name:'Список детей с данными об осмотре с выбором даты отчета', idexport: 4, typeExportDb: 'childmedicinspections'}
];

class MedicExportScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true,
            list: []
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user,loading:false});
	}
    formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
    save = (e) => {
        const idexport = listExport[this.state.data.status].idexport;
        const typeExportDb = listExport[this.state.data.status].typeExportDb;
        e.preventDefault();
        const uri = `${API.documentsExcel}/?idexport=${idexport}&t=${typeExportDb}&asort=lastName&fn=fileout.xlsx`;

		// Add events in log
		const nameExport = ' ' + listExport[this.state.data.status].name;
		Events.add(this.state.user.id, eventMessageName.dowloadMedicExport + nameExport, null, null, null, 6);
		
        window.location.href = uri;
    }
	render() {
		return <Template page={'departments'} title={'Экспорт'} backLink={'/medic'}>
            <form id="child-form" className="form" onSubmit={this.save}>
                <div className="form-row form-row-block">
                    <div className="form-row-block-inner archive-export-row">
                        <div className="form-row">
                            <label htmlFor="status">Выберите что нужно экспортировать</label>
                            <select name="status" id="status" onChange={this.formHandle} required>
                                <option></option>
                                {listExport.map((v,i) => <option key={i} value={i}>{v.name}</option>)}
                            </select>
                        </div>
                        <button type="submit" className="btn" onClick={this.exportExcel}>Экспортировать</button>
                    </div>
                </div>
            </form>
            <Alert />
		</Template>
	}
}

export default MedicExportScreen;