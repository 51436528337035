import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Childs,ChildMedicAnamnesis,Events} from '../../Models';

// helpers
import {Helpers,Errors,Utils} from '../../Globals/index';

// globals
import {eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ChildMedicAnamnesisScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			user:null,
			data:{},
			child:{},
			notfound:null,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const child = await Childs.get(this.state.id);
			if (child.data && child.data.length) {
				const medic = await ChildMedicAnamnesis.get(this.state.id);
				this.setState({data:medic.data[0]||{},child:child.data[0],notfound:false});

				// Add events in log
				const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
				Events.add(user.id, eventMessageName.viewMedicChildAnamnesis + fullName, null, null, null, 4);
			} else this.setState({notfound:true});
		} else this.setState({notfound:false});
		this.setState({user,loading:false});
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	save = async (e) => {
		e.preventDefault();
		const {id,data} = this.state;
		const d = {
			info:data.info,
			dateModify:Utils.now()
		};
		if (data.id > 0) {
			// Add events in log
			// get old data before update
			const dataModel = await ChildMedicAnamnesis.get(id);
			const getOldData = (data) => {
				const keysData = Object.keys(data);
				let oldData = {};
				keysData.forEach((key) => {
					let item = {}
					item[key] = dataModel.data[0][key];
					Object.assign(oldData, item);
				});
				return oldData;
			}
			const oldData = getOldData(d);
			const newData = d;

			await ChildMedicAnamnesis.update(id, d).catch(() => this.error());

			// Add events in log
			const getDataModel = (await Childs.get(id)).data[0];
			const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
			Events.add(this.state.user.id, eventMessageName.updateDataAnamnesisMedic + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			Errors.update();
		} else {
			d.childId = id;
			const res = await ChildMedicAnamnesis.add(d).catch(() => this.error());

			// Add events in log
			const getDataModel = (await Childs.get(id)).data[0];
			const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
			const newData = d;
			Events.add(this.state.user.id, eventMessageName.addNewAnamnesisMedic + fullName, null, JSON.stringify(newData), null, 3);
			
			if (res) Errors.add();
			else return false;
		}
		this.props.history.push('/medic-childs');
		return false;
	}
	error = () => Errors.error();
	render() {
		return <Template
					page={'departments'}
					title={'Анамнез ребенка'}
					controlPanel={
						<div className="form-control-panel">
							<button form="medic-form" type="submit">Сохранить</button>
						</div>
					}
					backLink={'/medic-childs'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/medic-childs'} /> :
						<form id="medic-form" className="form medic-anamnesis" onSubmit={this.save}>
							<div className="form-row">
								<label>имя ребенка</label>
								<h4>{this.state.child.lastName} {this.state.child.firstName} {this.state.child.middleName}</h4>
							</div>
							<div className="form-row">
								<label htmlFor="info">Состояние</label>
								<textarea name="info" id="info" placeholder="Опишите состояние ребенка" required className="large" onChange={this.formHandle}>{this.state.data.info}</textarea>
							</div>
							{this.state.data.dateModify ?
								<div className="form-row">
									<label>Последние изменение</label>
									<div className="date">{Moment(this.state.data.dateModify*1000).format('DD.MM.y, H:mm')}</div>
								</div> : null}
						</form>
			}
			<Alert />
		</Template>
	}
}

ChildMedicAnamnesisScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default ChildMedicAnamnesisScreen;