import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (childId)		=> await Http.get('childdocuments', {conditions:[{k:'childId',v:childId}], orders:[{k:'dateCreate',isd:requestOderType.DESC}]});
const getAll	= async ()				=> await Http.get('childdocuments', {orders:[{k:'id',isd:requestOderType.DESC}]});
const getById	= async (id)			=> await Http.get('childdocuments', {conditions:[{k:'id',v:id}]});

const add		= async (data)			=> await Http.post('childdocuments', {data});
const update	= async (id, data)			=> await Http.put('childdocuments', {data,conditions:[{k:'id',v:id}]});
const remove	= async (id)			=> await Http.remove('childdocuments', {conditions:[{k:'id',v:id}]});

export {
	getAll,
	get,
	getById,
	add,
	update,
	remove
}