import {Component} from 'react';

// plug-ins
import Moment from 'moment';
import InputMask from 'react-input-mask';

// components
import Loader from '../../Components/Loader';
import Alert from '../../Components/Alert';
import DocumentViewer from '../../Components/DocumentViewer';

// models
import {DocumentTypes,Images,ParentDocuments,Parents,Files,Events} from '../../Models';

// helpers
import {Utils,Helpers,Errors,LS} from '../../Globals/index';

// globals
import {API,comonStatusName,familyRelationTypeName,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class ParentAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.id||0),
			areaId:0,
			data:{},
			file:null,
			photo:null,
			user:null,
			documenttypes:[],
			parentdocuments:[],
			document:{},
			filedocument:null,
			notfound:null,
			nophoto:false,
			tab:1,
			doc:null,
			doctype:null,
			documentview:false,
			adddocumentshow:true,
			loadingdocuments:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const parent = await Parents.get(this.state.id);
			if (parent.data && parent.data.length) {
				const documenttypes = await DocumentTypes.getAll();
				await this.parentDocumentsGet();
				this.setState({data:parent.data[0],documenttypes:documenttypes.data,notfound:false});
			}
			else this.setState({notfound:true});
		} else this.setState({notfound:false});
		const areaId = parseInt(LS.get('areaId'));
		this.setState({user,areaId,loading:false});
	}
	parentDocumentsGet = async () => {
		const parentdocuments = await ParentDocuments.get(this.state.id);
		this.setState({parentdocuments:parentdocuments.data,loadingdocuments:false});
	}
	imageDefault = () => this.setState({nophoto:true});
	imageChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), file = e.target.files[0];
		if (file) {
			reader.onloadend = () => this.setState({nophoto:false,file,photo:reader.result});
			reader.readAsDataURL(file);
		}
	}
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	formHandle = (e) => this.setState({data:{...this.state.data,[e.target.name]:e.target.value}});
	dateGet = (d) => {
		if (Number.isInteger(d)) return Moment(d*1000).format('y-MM-DD');
		return d;
	}
	save = async (e) => {
		e.preventDefault();
		const {id,data,file} = this.state;

		// Add events in log
		// get old data before update
		const dataModel = await Parents.get(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}

		const areaId = parseInt(LS.get('areaId'));
		const d = {
			areaId,
			firstName:data.firstName,
			lastName:data.lastName,
			middleName:data.middleName,
			photo:data.photo,
			phone:Utils.phoneNormalize(data.phone),
			birthDate:Number.isInteger(data.birthDate) ? Math.round(new Date(data.birthDate).getTime()) : Math.round(new Date(data.birthDate).getTime() / 1000),
			work:data.work,
			workPosition:data.workPosition,
			type:data.type,
			status:data.status
		};
		if (id === 0) {
			const res = await Parents.add(d).catch(() => this.error());

			// Add events in log
			const newData = d;
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.addNewParent + fullName, null, JSON.stringify(newData), null, 3);
			if (res) {
				d.id = res.data.id;
				if (file) {
					d.photo = this.filenameGet(d.id, file.name);
					await this.imageSave(d.id, file);
					const dataPhoto = {photo:d.photo};
					Parents.update(res.data.id, dataPhoto);

					// Add events in log
					const newData = dataPhoto;
					const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
					Events.add(this.state.user.id, eventMessageName.addNewParentPhoto + fullName, null, JSON.stringify(newData), null, 3);

					Errors.add();
				}
			} else return false;
		} else {
			// Add events in log
			// get old data before update
			const oldData = getOldData(d);
			const newData = d;

			await Parents.update(id, d).catch(() => this.error());

			// Add events in log
			const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
			Events.add(this.state.user.id, eventMessageName.updateDataParent + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			if (file) {
				await this.imageSave(id, file);
				d.photo = this.filenameGet(id, file.name);
				const dataPhoto = {photo:d.photo};
				Parents.update(id, {photo:d.photo});

				// Add events in log
				const oldData = getOldData(dataPhoto);
				const newData = dataPhoto;
				const fullName = ' ' + d.lastName + ' ' + d.firstName + ' ' + d.middleName;
				Events.add(this.state.user.id, eventMessageName.updateParentPhoto + fullName, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			}
			d.id = id;
			Errors.update();
		}
		if (this.props.onSave) this.props.onSave(d);
		return false;
	}
	imageSave = (id, file) => Images.upload(id, 'parent', 0, file);
	filenameGet = (id, filename) => `parent_${id}_${filename}`;
	error = () => Errors.error();
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	tabSet = (tab) => this.setState({tab});
	formDocumentHandle = (e) => this.setState({document:{...this.state.document,[e.target.name]:e.target.value}});
	documentSave = async (e) => {
		e.preventDefault();
		const {id,document,filedocument} = this.state;
		const d = {
			parentId:id,
			type:document.type,
			fileType:filedocument.type,
			link:this.filenameGet(id, filedocument.name),
			status:document.status
		};
		const res = await ParentDocuments.add(d).catch(() => this.error());

		// Add events in log
		const getDataModel = (await Parents.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const newData = d;
		Events.add(this.state.user.id, eventMessageName.addDocumentParent + fullName, null, JSON.stringify(newData), null, 3);

		if (res) {
			this.fileSave(id, `documents/parents/${id}`, filedocument);
			Errors.add();
		} else return false;
		this.documentsReload();
		return false;
	}
	fileSave = (id, path, file) => Files.upload(id, 'parent', path, file);
	documentTypeGet = (id) => this.state.documenttypes.find(f => f.id === id);
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Parents.get(this.state.id)).data[0];
		const getDocumentModel = (await ParentDocuments.getById(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const oldData = getDocumentModel;
		Events.add(user.id, eventMessageName.deletePeopleParentEditDocument + fullName + ' ' + getDocumentModel.link, JSON.stringify(oldData), null, null, 7);

		await ParentDocuments.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loadingdocuments:true,adddocumentshow:false}, () => this.parentDocumentsGet());
	documentView = async (parent) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=parents&ft=${parent.fileType}&fn=${parent.link}`;
		const doctype = parent.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Parents.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + parent.link;
		Events.add(user.id, eventMessageName.viewPeopleParentEditDocument + fullName + fileName, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (parent) => {
		const uri = `${API.documents}/?id=${this.state.id}&t=parents&ft=${parent.fileType}&fn=${parent.link}`;

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Parents.get(this.state.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + parent.link;
		Events.add(user.id, eventMessageName.dowloadPeopleParentEditDocument + fullName + fileName, null, null, null, 6);

		window.location.href = uri;
	}
	render() {
		return <>
			<>
				{this.state.loading ? <Loader /> :
					<>
						{this.state.id !== 0 ?
							<ul className="top-tabs">
								<li className={this.state.tab===1?'selected':null} onClick={() => this.tabSet(1)}>Информация</li>
								<li className={this.state.tab===2?'selected':null} onClick={() => this.tabSet(2)}>Документы</li>
							</ul> : null}
						{this.state.tab === 1 ?
							<form id="parent-form" className="form" onSubmit={this.save}>
								<div className="form-row-oneline">
									<div className={this.state.nophoto?`form-photo form-photo-error`:"form-photo"}>
										<label>
											{!this.state.nophoto ? <img src={this.state.photo||`${API.assets}/parents/${this.state.data.photo}${Utils.urlUpdater()}`} onError={this.imageDefault} alt="" /> : <i className="bi bi-person-fill form-no-photo"></i>}
											<input onChange={this.imageChange} name="image" className="hide" accept="image/jpeg" type="file" />
										</label>
									</div>
									<div>
										<div className="form-row">
											<label htmlFor="lastName">Фамилия</label>
											<input type="text" name="lastName" id="lastName" placeholder="Фамилия" required onChange={this.formHandle} value={this.state.data.lastName} />
										</div>
										<div className="form-row-oneline">
											<div className="form-row">
												<label htmlFor="firstName">Имя</label>
												<input type="text" name="firstName" id="firstName" placeholder="Имя" required onChange={this.formHandle} value={this.state.data.firstName} />
											</div>
											<div className="form-row">
												<label htmlFor="middleName">Отчество</label>
												<input type="text" name="middleName" id="middleName" placeholder="Отчество" required onChange={this.formHandle} value={this.state.data.middleName} />
											</div>
										</div>
									</div>
								</div>
								<div className="form-row-oneline">
									<div className="form-row">
										<label htmlFor="phone">Телефон</label>
										<InputMask type="text" name="phone" id="phone" mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" value={this.state.data.phone} onChange={this.formHandle} required />
									</div>
									<div className="form-row">
										<label htmlFor="birthDate">Дата рождения</label>
										<input type="date" name="birthDate" id="birthDate" className="date" required onChange={this.formHandle} defaultValue={this.dateGet(this.state.data.birthDate)} />
									</div>
								</div>
								<div className="form-row-oneline">
									<div className="form-row">
										<label htmlFor="work">Место работы</label>
										<input type="text" name="work" id="work" placeholder="Место работы" className="double" required onChange={this.formHandle} value={this.state.data.work} />
									</div>
									<div className="form-row">
										<label htmlFor="workPosition">Должность</label>
										<input type="text" name="workPosition" id="workPosition" placeholder="Должность" className="double" required onChange={this.formHandle} value={this.state.data.workPosition} />
									</div>
								</div>
								<div className="form-row">
									<label htmlFor="type">Тип родства</label>
									<select name="type" id="type" onChange={this.formHandle} required defaultValue={this.state.data.type}>
										<option></option>
										{familyRelationTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
								<div className="form-row">
									<label htmlFor="status">Статус</label>
									<select name="status" id="status" onChange={this.formHandle} required defaultValue={this.state.data.status}>
										<option></option>
										{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</form> : null}
						{this.state.tab === 2 ?
							<>
								<form id="parent-form" className="form" onSubmit={this.documentSave}>
									<div className="form-row form-row-block">
										<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
										{this.state.adddocumentshow ?
											<div className="form-row-block-inner">
												<div className="form-row-oneline">
													<div className="form-row">
														<label htmlFor="file">Файл</label>
														<input onChange={this.fileChange} name="file" className="file" required type="file" />
													</div>
													<div className="form-row">
														<label htmlFor="type">Тип документа</label>
														<select name="type" id="type" onChange={this.formDocumentHandle} required>
															<option></option>
															{this.state.documenttypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
														</select>
													</div>
												</div>
												<div className="form-row">
													<label htmlFor="status">Статус</label>
													<select name="status" id="status" onChange={this.formDocumentHandle} required>
														<option></option>
														{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</div>
											</div> : null}
									</div>
									<div className="list-documents">
										{this.state.loadingdocuments ? <Loader /> :
											this.state.parentdocuments.map((v,i) => <div key={i} className="list-documents-item">
												<div className="name">
													<span>{this.documentTypeGet(v.type).name} — {Moment(v.dateCreate*1000).format('DD.MM.y')}</span>
												</div>
												<div className="buttons">
													<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
													<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
													<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
												</div>
											</div>)}
									</div>
								</form>
									{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
							</> : null}
					</>
				}
			</>
			<Alert />
		</>
	}
}
ParentAdd.propTypes = {
	id: PropTypes.object.isRequired,
	onSave: PropTypes.object.isRequired
};

export default ParentAdd;