import PropTypes from 'prop-types';
import './styles.scss';
import { useEffect, useState } from 'react';
import Confirm from '../Confirm';
import {alertShow} from '../Alert';
import { newsTypes, newsTypesViewNames } from '../../Globals/Constants';
import { News } from '../../Models'
import { Helpers, LS } from '../../Globals';
import Loader from '../Loader';

// eslint-disable-next-line no-unused-vars
const NewsModal = ({ isOpen, setIsOpen, updateNews, newsId }) => {
    const [ textAreaName, setTextAreaName ] = useState('');
    const [ textAreaContent, setTextAreaContent ] = useState('');
    const autoResizeTextArea = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

    const [ isOpenCancelConfirm, setOpenCancelConfirm ] = useState(false);
    const [ isOpenSaveConfirm, setOpenSaveConfirm ] = useState(false);
    const [ isOpenDeleteConfirm, setOpenDeleteConfirm ] = useState(false);
    const [ wasEdit, setWasEdit ] = useState(false);
    const [ newsType, setNewsType ] = useState(newsTypes.INFO);
    const [ selectedDate, setSelectedDate ] = useState(Math.floor(Date.now() / 1000));
    const areaId = parseInt(LS.get('areaId'));
    const user = Helpers.authCheck();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ news, setNews ] = useState({})
    const [ selectedColor, setSelectedColor ] = useState('#000000');

    const handleChange = (e, textareaType) => {
        if (!wasEdit) setWasEdit(true);
        if (textareaType === 'desc') {
            setTextAreaName(e.target.value);
        } else if (textareaType === 'task') {
            setTextAreaContent(e.target.value);
        }
    }

    useEffect(() => {
        if (!wasEdit) setWasEdit(true);
    }, [setNewsType, setSelectedDate])

    const forceClose = () => {
        setOpenCancelConfirm(false);
        setOpenSaveConfirm(false);
        setWasEdit(false);
        setIsOpen(false);
    }

    const cancel = (force) => {
        if (!force && wasEdit) return setOpenCancelConfirm(true);
        forceClose();
    };

    const save = (force) => {
        if (force) {
            return forceClose();
        }
        setOpenSaveConfirm(true);
    };

    useEffect(() => {
        const handleEscapeKey = (e) => {
            if (e.key === 'Escape') {
                if (isOpen && wasEdit) setOpenCancelConfirm(true);
                else forceClose();
            }
        }
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [isOpen, wasEdit, cancel]);
    
    useEffect(() => {
        setOpenCancelConfirm(false);
        setOpenSaveConfirm(false);
        const getNewsById = async (id) => {
            setIsLoading(true);
            const currentNews = await News.get(id);
            if (currentNews?.data && currentNews?.data?.length > 0) {
                setSelectedDate(currentNews.data[0].dateEvent);
                setWasEdit(false);
                setNewsType(currentNews.data[0].type);
                setTextAreaContent(currentNews.data[0].description);
                setTextAreaName(currentNews.data[0].name);
                setNews(currentNews.data[0]);
            } else {
                setTextAreaName('');
                setTextAreaContent('');
                setSelectedDate(Math.floor(Date.now() / 1000));
                setNewsType(0);
                setWasEdit(false);
                setNews({});
            }
            setIsLoading(false);
        }
        getNewsById(newsId);
    }, [newsId, setIsOpen])

    if (!isOpen) return null;

    return (
        <div className="note-modal-container" onClick={() => cancel(false)}>
            <div className="note-modal-content" onClick={(e) => e.stopPropagation()}>
            {isLoading ? <Loader /> : <>
                <div className="note-modal-header">
                    {newsId <= 0 ? <h3>Добавить новость</h3> : <h3>Новость {textAreaName}</h3>}
                    <i className="bi bi-x-square" onClick={() => cancel(false)}></i>
                </div>
                <div className="note-modal-body">
                    <div className="news-type">
                        <span>Приоритет:</span>
                        <select disabled={news.userId !== user.id && newsId !== 0} value={newsType} onChange={(e) => setNewsType(parseInt(e.target.value))}>
                            {Object.keys(newsTypes).map((key) => (
                                <option key={key} value={newsTypes[key]}>
                                    {newsTypesViewNames[newsTypes[key]]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="news-type">
                        <span>Цвет:</span>
                        <input
                            type="color"
                            value={selectedColor}
                            style={{ backgroundColor: selectedColor }}
                            onChange={(e) => {
                                setSelectedColor(e.target.value)
                                console.log(e.target.value)
                            }}
                        />
                    </div>
                    <div>
                        <span>Заголовок новости:</span>
                        <textarea value={textAreaName} disabled={news.userId !== user.id && newsId !== 0} onChange={(e) => handleChange(e, 'desc')} onInput={(e) => autoResizeTextArea(e, 'desc')}></textarea>
                    </div>
                    <div>
                        <span>Описание:</span>
                        <textarea value={textAreaContent} disabled={news.userId !== user.id && newsId !== 0} onChange={(e) => handleChange(e, 'task')} onInput={(e) => autoResizeTextArea(e, 'task')}></textarea>
                    </div>
                    {news.userId === user.id || newsId === 0 ? (<div>
                        <span>Выберите дату:</span>
                        <input
                            type="date"
                            value={new Date(selectedDate * 1000).toISOString().split('T')[0]}
                            onChange={(e) => {
                                setSelectedDate(Math.floor(new Date(e.target.value).getTime() / 1000));
                                setWasEdit(true);
                            }}
                        />
                    </div>) : null}
                </div>
                {news.userId === user.id || newsId === 0 ? (<div className="note-modal-footer">
                    {newsId > 0 ? <button className="delete" onClick={() => setOpenDeleteConfirm(true)}>Удалить новость</button> : null}
                    <div className="buttons">
                        <button className="save" onClick={() => save(false)}>Сохранить</button>
                        <button className="cancel" onClick={() => cancel(false)}>Отменить</button>
                    </div>
                </div>) : null}
            </>}
            </div>
            {isOpenDeleteConfirm && newsId > 0 && (
                <Confirm
                    message={'Вы уверены, что хотите удалить новость?'}
                    action={() => {
                        updateNews();
                        alertShow('Новость удалена', false);
                        News.update(newsId, {status: 0});
                        setOpenDeleteConfirm(false);
                        forceClose();
                    }}
                    close={() => { setOpenDeleteConfirm(false) }}
                />
            )}
            {isOpenCancelConfirm && (
                <Confirm
                    message={'Вы уверены, что хотите закрыть без сохранения?'}
                    action={() => {
                        forceClose();
                        alertShow('Закрыто без сохранения', true);
                        updateNews();
                    }}
                    close={() => { setOpenCancelConfirm(false) }}
                />
            )}
            {isOpenSaveConfirm && (
                <Confirm
                    message={wasEdit ? 'Вы уверены, что хотите сохранить новость?' : 'Вы ничего не заполнили, закрыть без изменений?'}
                    action={() => {
                        if (wasEdit) {
                            forceClose();
                            alertShow('Успешно сохранено!');
                            if (newsId && newsId > 0) {
                                News.update(newsId, {
                                    name: textAreaName,
                                    description: textAreaContent,
                                    type: newsType,
                                    dateEvent: selectedDate,
                                    customColor: selectedColor
                                }).then(() => {
                                    updateNews();
                                });
                            } else {
                                News.add({
                                    areaId: areaId,
                                    name: textAreaName,
                                    description: textAreaContent,
                                    userId: user.id,
                                    userName: user.name,
                                    type: newsType,
                                    status: 1,
                                    dateEvent: selectedDate,
                                    customColor: selectedColor
                                }).then(() => {
                                    updateNews();
                                });
                            }
                            forceClose();
                        } else {
                            forceClose();
                            alertShow('Ничего не изменено!', true);
                        }
                    }}
                    close={() => { setOpenSaveConfirm(false) }}
                />
            )}
        </div>
    );
};

NewsModal.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    updateNews: PropTypes.func,
    newsId: PropTypes.number
}

export default NewsModal;