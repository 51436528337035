import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DocumentViewer from '../../Components/DocumentViewer';
import Alert from '../../Components/Alert';

// models
import {Documents,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,documentType,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';

class HRStafftableScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			document:null,
			filedocument:null,
			doctype:null,
			doc:null,
			documentview:false,
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		this.setState({user});
		await this.documentsGet(false);

		// Add events in log
		Events.add(user.id, eventMessageName.viewHrDocumentStafftablePage, null, null, null, 2);
	}
	documentsGet = async () => {
		const documents = await Documents.getAll();
		const document = documents.data.find(f => f.type === documentType.hr.STAFFTABLE);
		this.setState({document,adddocumentshow:document?false:true,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const document = (await Documents.getById(id)).data[0];
		const oldData = document;
		Events.add(user.id, eventMessageName.deleteHrDocumentStafftable + ' ' + document.link, JSON.stringify(oldData), null, null, 7);
		
		await Documents.remove(id);
		this.documentsReload();
	}
	documentsReload = () => this.setState({loading:true,adddocumentshow:false}, async () => await this.documentsGet(true));
	documentView = async (d) => {
		const doc = `${API.documents}/?p=departments/hr/stafftable&ft=${d.fileType}&fn=${d.link}`;
		const doctype = d.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		Events.add(user.id, eventMessageName.viewHrDocumentStafftable + ' ' + d.link, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (d) => {
		// Add events in log
		const user = Helpers.authCheck();
		Events.add(user.id, eventMessageName.dowloadHrDocumentStafftable + ' ' + d.link, null, null, null, 6);

		const uri = `${API.documents}/?p=departments/hr/stafftable&ft=${d.fileType}&fn=${d.link}`;
		window.location.href = uri;
	}
	documentSave = async (e) => {
		e.preventDefault();
		const {filedocument,document,user} = this.state;
		const d = {
			type:documentType.hr.STAFFTABLE,
			fileType:filedocument.type,
			link:filedocument.name,
			userId: user.id,
			userName: user.name
		};
		if (document) {
			// Add events in log
			// get old data before update
			const dataModel = await Documents.getById(document.id);
			const getOldData = (data) => {
				const keysData = Object.keys(data);
				let oldData = {};
				keysData.forEach((key) => {
					let item = {}
					item[key] = dataModel.data[0][key];
					Object.assign(oldData, item);
				});
				return oldData;
			}
			const oldData = getOldData(d);
			const newData = d;

			await Documents.update(document.id, d).catch(() => this.error());

			// Add events in log
			const linkDocument = ' ' + d.link;
			Events.add(this.state.user.id, eventMessageName.updateDataHrStafftable + linkDocument, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
			Errors.update();
		} else {
			const res = await Documents.add(d).catch(() => this.error());

			// Add events in log
			const newData = d;
			const linkDocument = ' ' + d.link;
			Events.add(this.state.user.id, eventMessageName.addHrDocumentStafftable + linkDocument, null, JSON.stringify(newData), null, 3);

			if (res) Errors.add();
			else return false;
		}
		this.fileSave('documents/departments/hr/stafftable', filedocument);
		this.documentsReload();
		return false;
	}
	error = () => Errors.error();
	fileSave = (path, file) => Files.upload(0, null, path, file);
	render() {
		return <Template
					page={'departments'}
					title={'Штатное расписание'}
					controlPanel={
						<div className="form-control-panel">
							<button form={'document-form'} type="submit">Сохранить</button>
						</div>
					}
					backLink={'/hr'}>
			{this.state.loading ? <Loader /> :
				<form id={'document-form'} className="form" onSubmit={this.documentSave}>
					<div className="form-row form-row-block">
						<h3 onClick={() => this.adddocumentShow()}>{this.state.document ? 'Изменить документ' : 'Добавить документ'}</h3>
						{this.state.adddocumentshow ?
							<div className="form-row-block-inner">
								<div className="form-row">
									<label htmlFor="file">Файл</label>
									<input onChange={this.fileChange} name="file" className="file" required type="file" />
								</div>
							</div> : null}
					</div>
					{this.state.document ?
						<div className="list-documents">
							<div className="list-hr-documents-item">
								<div className="name">
									<span>Штатное расписание — {Moment(this.state.document.dateCreate*1000).format('DD.MM.y')}</span>
									{this.state.document.link}
								</div>
								<div className="buttons">
									<i className="bi bi-eye" onClick={() => this.documentView(this.state.document)}></i>
									<i className="bi bi-cloud-download" onClick={() => this.documentDownload(this.state.document)}></i>
									<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(this.state.document.id)}></i>
								</div>
							</div>
						</div> : null}
				</form>
			}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

export default HRStafftableScreen;