import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DocumentViewer from '../../Components/DocumentViewer';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Teachers,TeacherDocuments,Files,Events} from '../../Models';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// globals
import {API,comonStatus,eventMessageName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class HRDocumentContractScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			user:null,
			documents:null,
			filedocument:null,
			doctype:null,
			doc:null,
			type:25,
			notfound:null,
			documentview:false,
			adddocumentshow:true,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const teacher = await Teachers.get(this.state.id);
			if (teacher.data && teacher.data.length) {
				const data = teacher.data[0];
				this.setState({user,data,notfound:false});
				await this.documentsGet();

				// Add events in log
				const fullName = ' ' + teacher.data[0].lastName + ' ' + teacher.data[0].firstName + ' ' + teacher.data[0].middleName;
				Events.add(user.id, eventMessageName.viewHrDocumentContractsPage + fullName, null, null, null, 2);

				return;
			}
		}
		this.setState({notfound:true,loading:false});
	}
	documentsGet = async () => {
		const docs = await TeacherDocuments.get(this.state.id);
		const documents = docs.data.filter(f => f.type === this.state.type);
		this.setState({documents,adddocumentshow:documents.length?false:true,loading:false});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	fileChange = (e) => {
		e.preventDefault();
		const reader = new FileReader(), filedocument = e.target.files[0];
		if (filedocument) {
			reader.onloadend = () => this.setState({filedocument});
			reader.readAsDataURL(filedocument);
		}
	}
	documentsReload = () => this.setState({loading:true,adddocumentshow:false}, async () => await this.documentsGet(true));
	documentDelete = async (id) => {
		// Add events in log
		const user = Helpers.authCheck();
		const document = (await TeacherDocuments.getById(id)).data[0];
		const oldData = document;
		const getDataModel = (await Teachers.get(document.teacherId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(user.id, eventMessageName.deleteHrDocumentContract + fullName + ' ' + document.link, JSON.stringify(oldData), null, null, 7);
		
		await TeacherDocuments.remove(id);
		this.documentsReload();
	}
	documentView = async (teacher) => {
		const doc = `${API.documents}/?id=${this.state.id}&t=teachers&ft=${teacher.fileType}&fn=${teacher.link}`;
		const doctype = teacher.link.replace(/.*\./gi, '');
		this.setState({documentview:true,doc,doctype});

		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Teachers.get(teacher.teacherId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(user.id, eventMessageName.viewHrDocumentContract + fullName + ' ' + teacher.link, null, null, null, 4);
	}
	documentViewClose = () => this.setState({documentview:false});
	documentDownload = async (teacher) => {
		// Add events in log
		const user = Helpers.authCheck();
		const getDataModel = (await Teachers.get(teacher.teacherId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		Events.add(user.id, eventMessageName.dowloadHrDocumentContract + fullName + ' ' + teacher.link, null, null, null, 6);

		const uri = `${API.documents}/?id=${this.state.id}&t=teachers&ft=${teacher.fileType}&fn=${teacher.link}`;
		window.location.href = uri;
	}
	documentSave = async (e) => {
		e.preventDefault();
		const {id,filedocument,type} = this.state;
		const d = {
			teacherId:id,
			type,
			fileType:filedocument.type,
			link:this.filenameGet(id, filedocument.name),
			status:comonStatus.ACTIVE
		};
		const res = await TeacherDocuments.add(d).catch(() => this.error());

		// Add events in log
		const newData = d;
		const getDataModel = (await Teachers.get(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const linkDocument = ' ' + d.link;
		Events.add(this.state.user.id, eventMessageName.addHrDocumentContract + fullName + linkDocument, null, JSON.stringify(newData), null, 3);

		if (res) Errors.add();
		else return false;
		this.fileSave(id, `documents/teachers/${id}`, filedocument);
		this.documentsReload();
		return false;
	}
	error = () => Errors.error();
	fileSave = (id, path, file) => Files.upload(id, 'teacher', path, file);
	filenameGet = (id, filename) => `teacher_${id}_${filename}`;
	render() {
		return <Template
					page={'departments'}
					title={'Договор о коммерческой тайне'}
					controlPanel={
						<div className="form-control-panel">
							<button form={'document-form'} type="submit">Сохранить</button>
						</div>
					}
					backLink={'/hr-documents'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/hr-documents'} /> :
						<form id={'document-form'} className="form" onSubmit={this.documentSave}>
							<div className="form-row form-row-block">
								<h3 onClick={() => this.adddocumentShow()}>Добавить документ</h3>
								{this.state.adddocumentshow ?
									<div className="form-row-block-inner">
										<div className="form-row">
											<label htmlFor="file">Файл</label>
											<input onChange={this.fileChange} name="file" className="file" required type="file" />
										</div>
									</div> : null}
							</div>
							{this.state.documents.length ?
								this.state.documents.map((v,i) => <div key={i} className="list-hr-documents">
									<div className="list-hr-documents-item">
										<div className="name">
											<span>Договор — {Moment(v.dateCreate*1000).format('DD.MM.y')}</span>
											{v.link}
										</div>
										<div className="buttons">
											<i className="bi bi-eye" onClick={() => this.documentView(v)}></i>
											<i className="bi bi-cloud-download" onClick={() => this.documentDownload(v)}></i>
											<i className="bi bi-x-circle delete" onClick={() => this.documentDelete(v.id)}></i>
										</div>
									</div>
								</div>)
							: null}
						</form>
			}
			{this.state.documentview && <DocumentViewer doc={this.state.doc} doctype={this.state.doctype} close={this.documentViewClose} />}
			<Alert />
		</Template>
	}
}

HRDocumentContractScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default HRDocumentContractScreen;