import {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import JsonTable from '../../Components/JsonTable';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert from '../../Components/Alert';

// models
import {Events,Teachers} from '../../Models';

// helpers
import {Helpers,Utils} from '../../Globals';

// globals
import {eventTypeName} from '../../Globals/Constants';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class EventScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			user:null,
			initiator:null,
			notfound:false,
			progress:10,
			loading:true,
			loadUser:false
		};
	}
	componentDidMount = () => {
		const user = Helpers.authCheck();
		this.setState({user}, () => this.dataGet());
	}
	componentDidUpdate = (prevProps, prevState) => {
		if (this.state.data !== prevState.data && !this.state.loadUser) {
			this.getUserData(this.state.data.userId);
			this.setState({loadUser:true});
		}
	}
	dataGet = () => {
		Events.get(this.state.id, (data) => {
			this.setState({data,notfound:Utils.empty(data),loading:false});
		});
	}
	getUserData = async (id) => {
		const userLog = await Teachers.get(id);
		this.setState({initiator:userLog.data[0]});
	}
	render() {
		return <Template page={'settings'} title={'Просмотр события'} backLink={'/events'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/events'} /> :
					<div className="formcontainer">
						<form>
							<div className="onelinefull">
								<div className="cell">
									<label>
										<span>ID</span>
										<div>{this.state.data.id}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Дата</span>
										<div>{Moment(this.state.data.dateCreate*1000).format('DD.MM.y, H:mm')}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Инициатор</span>
										<div>{this.state.initiator ? <Link to={`/teacher/${this.state.initiator.id}`}>{this.state.initiator.lastName + ' ' + this.state.initiator.firstName + ' ' + this.state.initiator.middleName}</Link> : '—'}</div>
									</label>
								</div>
							</div>
							<div className="cell">
								<label>
									<span>Событие</span>
									<div>{this.state.data.message}</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Тип события</span>
									<div>{eventTypeName[this.state.data.type]}</div>
								</label>
							</div>
							<h4 className="title">Изменения данных</h4>
							<div className="onelinefulldata">
								<div className="cell">
									<label>
										<span>Старые данные</span>
										<div>{this.state.data.dataOld ? <JsonTable data={this.state.data.dataOld} /> : 'нет данных'}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Новые данные</span>
										<div>{this.state.data.dataNew ? <JsonTable data={this.state.data.dataNew} /> : 'нет данных'}</div>
									</label>
								</div>
							</div>
						</form>
					</div>
			}
			<Alert />
		</Template>
	}
}

EventScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
export default withRouter(EventScreen);