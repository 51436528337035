import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DocumentViewer from '../../../Components/DocumentViewer';
import { ChildDocuments, Childs, DocumentTypes, Events } from '../../../Models';
import { API, eventMessageName } from '../../../Globals/Constants';
import { Helpers, Utils } from '../../../Globals';
import Alert, { alertShow } from '../../../Components/Alert';
import Modal from 'react-awesome-modal';

const PupilInfo = ({ pupil }) => {
    // const [textArea, setTextArea] = useState('');

    const [isDocumentView, setDocumentView] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [doc, setDoc] = useState(null);
    const [docType, setDocType] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [documents, setDocuments] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [documentTypes, setDocumentTypes] = useState([]);

    useEffect(async () => {
        const childdocuments = await ChildDocuments.get(pupil.id);
        setDocuments(childdocuments.data);
        const documenttypes = await DocumentTypes.getAll();
        setDocumentTypes(documenttypes.data);
    }, [pupil.id]);

    const documentView = async (documentType, id = 0) => {
        let child = documents.find(item => item.type === documentType);
        if (id > 0) {
            child = documents.find(item => item.id === id);
        }
        if (!child) return alertShow('Документ не загружен', 1);
		const doc = `${API.documents}/?id=${pupil.id}&t=childs&ft=${child.fileType}&fn=${child.link}`;
		const doctype = child.link.replace(/.*\./gi, '');

        setDoc(doc);
        setDocType(doctype);
        setDocumentView(true);
        closeModalDocuments();
        
		const user = Helpers.authCheck();
		const getDataModel = (await Childs.get(pupil.id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const fileName = ' ' + child.link;
		Events.add(user.id, eventMessageName.viewPeopleChildEditDocument + fullName + fileName, null, null, null, 4);
	}
	const documentViewClose = () => setDocumentView(false);
	//const dateToLocale = (timeStamp) => new Date(timeStamp * 1000).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
    const dateToFormat = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [ isModalDocs, setIsModalDocs ] = useState(false);
    const openModalDocuments = () => setIsModalDocs(true);
    const closeModalDocuments = () => setIsModalDocs(false);

	const documentTypeGet = (id) => documentTypes.find(f => f.id === id);

    return (<div className="pupil_info">
        {isModalDocs ? <Modal visible={isModalDocs} width={'400'} height={'auto'} effect={'fadeInUp'}>
            <div className="modal_task">
                <div className="content">
                    <span>Список документов</span>
                    <ul>
                        <li>
                            {documents.filter(d => d.type !== 33).map((item, i) => {
                                return (
                                    <div key={i} className="li_item">
                                        <div>{documentTypeGet(item.type) ? documentTypeGet(item.type).name : item.customName || 'Без названия'}</div><div><button className={`button`} onClick={() => documentView(item.type, item.id)}><i className="bi bi-eye"></i></button></div>
                                    </div>
                                )
                            })}

                        </li>
                    </ul>
                </div>
                <div className="buttons">
                    <Link to={`${pupil.id}/edit?link=add-document`}><button>Все документы</button></Link>
                    <button onClick={() => { closeModalDocuments(); }}>Закрыть</button>
                </div>
            </div>
        </Modal> : null}
        <div className="left_side">
            <div className="form">
                <div className="form-section">
                    <label htmlFor="fullName">Фамилия:</label>
                    <input type="text" id="fullName" value={pupil.lastName} disabled name="fullName" placeholder="Фамилия" />
                </div>
                <div className="form-section">
                    <label htmlFor="fullName">Имя:</label>
                    <input type="text" id="fullName" value={pupil.firstName} disabled name="fullName" placeholder="Имя" />
                </div>
                <div className="form-section">
                    <label htmlFor="fullName">Отчество:</label>
                    <input type="text" id="fullName" value={pupil.middleName} disabled name="fullName" placeholder="Отчество" />
                </div>
                <div className="form-section">
                    <label htmlFor="birthDate">Дата рождения:</label>
                    <input type="date" id="birthDate" name="birthDate" disabled value={dateToFormat(pupil.birthDate)} />
                </div>
                <div className="form-section">
                    <label htmlFor="admissionDate">Дата постановки:</label>
                    <input type="date" id="admissionDate" name="admissionDate" disabled value={dateToFormat(pupil.dateIn)} />
                </div>
                <div className="form-section">
                    <label htmlFor="dischargeDate">Дата выписки:</label>
                    <input type="date" id="dischargeDate" disabled name="dischargeDate" value={dateToFormat(pupil.dateIn)} />
                </div>
                {pupil.parents.map((item, i) => (
                    <div key={i} className="form-section text-top">
                        <Link to={`/parent/${item.id}`} key={i}><label>Родители/опекуны:</label>
                            <div className="parent-block">
                                <span>{item.lastName} {item.firstName} {item.middleName}</span>
                                <span>{Utils.phoneFormatter(item.phone)}</span>
                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#0F0F0F" /></svg>
                            </div>
                        </Link>
                    </div>
                ))}
                {/* <div className="form-section text-top">
                    <label htmlFor="parentComment">Комментарий</label>
                    <div className="textarea-container">
                        <textarea value={textArea} onChange={(e) => handleChange(e)} id="parentComment" placeholder="Введите комментарий" onInput={(e) => autoResizeTextarea(e)} className="custom-textarea" rows="3"></textarea>
                    </div>
                </div> */}
                <div className="form-section">
                    <label htmlFor="school">Школа:</label>
                    <input type="text" id="school" name="school" disabled placeholder="Школа" value={pupil.school} />
                </div>
                <div className="form-section">
                    <label htmlFor="class">Класс:</label>
                    <input type="text" id="class" name="class" disabled placeholder="Класс" value={pupil.schoolClass} />
                </div>
                <div className="form-section">
                    <label htmlFor="registrationAddress">Адрес прописки:</label>
                    <input type="text" id="registrationAddress" disabled name="registrationAddress" value={pupil.address} placeholder="Адрес прописки" />
                </div>
                <div className="form-section">
                    <label htmlFor="residentialAddress">Адрес проживания:</label>
                    <input type="text" id="residentialAddress" disabled name="residentialAddress" value={pupil.addressReal} placeholder="Адрес проживания" />
                </div>
            </div>
        </div>
        <div className='right_side'>
            {Helpers.inRole(['childs-documents'])?<div className="form">
                <div className="left-part">
                    <div className="comment"></div>
                    <div className="comment">Паспорт:</div>
                    <div className="comment">Снилс:</div>
                    <div className="comment">Мед. полис:</div>
                    <div className="comment">Нотариальное согласие:</div>
                    <div className="comment">Договор:</div>
                    {/* <div className="comment">Личное дело:</div>
                    <div className="comment">Мед.карта</div>
                    <div className="comment">Сертификат о прививках:</div>
                    <div className="comment">Банковская карта:</div> */}
                    <Link to={`${pupil.id}/edit?link=add-document`}><button  style={{ marginRight: "10px" }}className="default_button">Добавить документ</button></Link>
                    <button onClick={() => openModalDocuments()} className="default_button">Другие документы</button>
                </div>
                <div className="right-part">
                    <div><span>Документы</span></div>
                    <div><button className={`button ${!documents.find(item => item.type === 1) && !documents.find(item => item.type === 2) && 'disabled'}`} onClick={() => documentView(documents.find(item => item.type === 1) ? 1 : 2)}>Скан паспорта</button></div>
                    <div><button className={`button ${!documents.find(item => item.type === 5) && 'disabled'}`} onClick={() => documentView(5)}>Снилс</button></div>
                    <div><button className={`button ${!documents.find(item => item.type === 3) && 'disabled'}`} onClick={() => documentView(3)}>Мед. полис</button></div>
                    <div><button className={`button ${!documents.find(item => item.type === 6) && 'disabled'}`} onClick={() => documentView(6)}>Согласие</button></div>
                    <div><button className={`button ${!documents.find(item => item.type === 8) && 'disabled'}`} onClick={() => documentView(8)}>Договор</button></div>
                    {/* <div><i className="bi bi-check-square"></i></div>
                    <div><i className="bi bi-check-square"></i></div>
                    <div><i className="bi bi-check-square"></i></div>
                    <div><i className="bi bi-check-square"></i></div> */}
                </div>
            </div>:null}
        </div>
        <Alert />
        {isDocumentView && <DocumentViewer doc={doc} doctype={docType} close={documentViewClose} />}
    </div>
    );
}

PupilInfo.propTypes = {
    pupil: PropTypes.object.isRequired,
};

export default PupilInfo;