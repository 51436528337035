import {Component} from 'react';

// plug-ins
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import NotFound from '../../Components/NotFound';
import Alert, { alertShow } from '../../Components/Alert';

// models
import {Childs,ChildSchoolJournals,SchoolSubjects,Events} from '../../Models';

// globals
import {eventMessageName} from '../../Globals/Constants';

// helpers
import {Helpers,Errors} from '../../Globals/index';

// styles
import './styles.css';
import PropTypes from 'prop-types';
class EducationJournalScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			user:null,
			data:[],
			schoolsubjects:[],
			schoolSubjectId:0,
			comment:'',
			date:new Date(),
			rate:0,
			child:{},
			notfound:null,
			adddocumentshow:true,
			loading:true,
			journalRate:[],
			dataRate:null,
			popupReview: false,
			popupEdit: false,
			popupAdd: false,
			dataPopupReview: null,
			accessUser: false,
			textCommentRate: '',
			textRateSelect: 0,
			textCommentRateReview: '',
			textRateSelectReview: 0,
			dateAdd: null,
			subjectAdd: '',
			startAcademicYears: null,
		};
	}
	componentDidMount = async () => {
		const user = Helpers.authCheck();
		if (this.state.id) {
			const child = await Childs.get(this.state.id);
			if (child.data && child.data.length) {
				await this.dataGet(false);
				this.setState({child:child.data[0],notfound:false});

				// Add events in log
				const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
				Events.add(user.id, eventMessageName.viewEducationJournalPage + fullName, null, null, null, 2);
			} else this.setState({notfound:true});
		} else this.setState({notfound:false});
		const subjs = await SchoolSubjects.getAll();
		const rate = await ChildSchoolJournals.get(this.state.id);
		this.setState({user,schoolsubjects:subjs.data,loading:false,dataRate:rate});
		this.createSpaceJournal(subjs);
	}
	dataGet = async (isstop) => {
		const journals = await ChildSchoolJournals.get(this.state.id);
		const data = journals.data && journals.data.length ? this.dataPrepare(journals.data) : [];
		this.setState({data,loading:isstop?false:true});
		if (!isstop) this.setState({adddocumentshow:journals.data&&journals.data.length?false:true});
	}
	dataPrepare = (data) => {
		return data.reduce((r, a) => {
			r[a.date] = [...r[a.date] || [], a];
			return r;
		}, {});
	}
	createSpaceJournal = (subjs) => {
		const months = ['сентябрь', 'октябрь', 'ноябрь', 'декабрь', 'январь', 'февраль', 'март', 'апрель', 'май'];
		let startAcademicYears;
		if (new Date().getMonth()+1 < 6) {
			startAcademicYears = new Date().getFullYear() - 1;
		} else {
			startAcademicYears = new Date().getFullYear();
		}
		const subjects = [];
		subjs.data.forEach(subject => subjects.push(subject.name));
		const days = [];
		for (let i = 1; i <= 31; i++) days.push(i)
		const journals = [];
		months.forEach(month => journals.push({months: month}));
		journals.map(month => {
			let years;
			let numberMonthDate;
			if (month.months === 'сентябрь') {numberMonthDate=8; years=startAcademicYears;}
			else if (month.months === 'октябрь') {numberMonthDate=9; years=startAcademicYears;}
			else if (month.months === 'ноябрь') {numberMonthDate=10; years=startAcademicYears;}
			else if (month.months === 'декабрь') {numberMonthDate=11; years=startAcademicYears;}
			else if (month.months === 'январь') {numberMonthDate=0; years=startAcademicYears+1;}
			else if (month.months === 'февраль') {numberMonthDate=1; years=startAcademicYears+1;}
			else if (month.months === 'март') {numberMonthDate=2; years=startAcademicYears+1;}
			else if (month.months === 'апрель') {numberMonthDate=3; years=startAcademicYears+1;}
			else if (month.months === 'май') {numberMonthDate=4; years=startAcademicYears+1;}
			let subjectArray = [];
			subjects.forEach(itemSubject => subjectArray.push({subject: itemSubject}));
			subjectArray.map(itemSubject => {
				let dayArray = [];
				days.forEach(itemDay => dayArray.push({day: itemDay}));
				dayArray.map(itemDay => {
					const dateDay = new Date(years, numberMonthDate, itemDay.day);
					Object.assign(itemDay, {rate: null, daterate: null, idrate: null, subjectToDate:itemSubject.subject, monthToDate:month.months})
					Object.assign(itemDay, {rate: null, daterate: null, idrate: null, subjectToDate:itemSubject.subject, date: dateDay})
				});
				Object.assign(itemSubject, {days: dayArray})
			});
			Object.assign(month, {subjects: subjectArray});
		});
		this.setState({journalRate:journals,startAcademicYears});
		this.uploadRateJoarnal();
	}
	uploadRateJoarnal = () => {
		const rate = this.state.dataRate.data;
		const journal = this.state.journalRate;
		journal.map(itemJournal => {
				rate.forEach(itemRate => {
					const date = new Date(itemRate.date * 1000);
					const month = date.toLocaleString('default', {month: 'long'});
					const sudjectJournal = itemJournal.subjects;
					const sudject = itemRate.schoolSubjectName;
					if ((date.getFullYear() === this.state.startAcademicYears) || (date.getFullYear() === this.state.startAcademicYears+1)) {
						if (month === itemJournal.months) {
							sudjectJournal.forEach(itemSubject => {
								if (itemSubject.subject === sudject) {
									const dayJournal = itemSubject.days;
									dayJournal.map(itemDay => {
										if (itemDay.day === date.getUTCDate()) {
											itemDay.rate = itemRate.rate;
											itemDay.daterate = itemRate.date;
											itemDay.idrate = itemRate.id;
										}
									});
								}
							});
						}
					}
				});
			});
		this.setState({journalRate:journal});
	}
	adddocumentShow = () => this.setState({adddocumentshow:!this.state.adddocumentshow});
	formHandle = (e) => this.setState({[e.target.name]:e.target.value});
	save = async (e) => {
		e.preventDefault();
		const {id,comment,date,rate,schoolsubjects,user} = this.state;
		if (comment) this.setState({comment:''});
		const d = {
			childId:id,
			comment,
			rate,
			schoolSubjectId: schoolsubjects.find(f => f.name === this.state.subjectAdd)?.id,
			schoolSubjectName:this.state.subjectAdd,
			userId:user.id,
			userName:user.name,
			date:Math.round(new Date(date).getTime() / 1000)
		};
		const res = await ChildSchoolJournals.add(d).catch(() => this.error());

		// Add events in log
		const getDataModel = (await Childs.get(id)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const dateAdd = ' ' + Moment(d.date*1000).format('DD.MM.y');
		const newData = d;
		Events.add(this.state.user.id, eventMessageName.addNewRateEducationJiurnal + fullName + dateAdd, null, JSON.stringify(newData), null, 3);
			
		this.tooglePopupAdd();
		this.addUpdateRateJournal();
		if (res) Errors.add();
		else return false;
		this.setState({loading:true}, () => this.dataGet(true));
		return false;
	}
	error = () => Errors.error();
	dateHandle = (e, id) => {
		if (id) {
			this.getDataRate(id);
		} else {
			this.tooglePopupAdd();
			let date = new Date(e.target.getAttribute('data-date')).toLocaleDateString().split(".").reverse().join("-");
			this.setState({date: date, subjectAdd:e.target.getAttribute('data-subject')});
		}
	}
	deleteRate = async (id) => {
		const newRate = this.state.journalRate?.map((item) => {
			return (
				{
					...item,
					subjects: (
						item?.subjects?.map((sub) => {
							return (
								{
									...sub,
									days: (
										sub?.days?.filter((day) => day?.idrate !== id)
									)
								}
							)
						})
					)
				}
			)
		})
		ChildSchoolJournals.remove(id);
		alertShow('Оценка удалена');
		this.addUpdateRateJournal();
		this.tooglePopupReview();
		this.setState({ journalRate: newRate });
		// setTimeout(() => this.setState({loading:true}, () => this.dataGet(true)), 1000);
	}
	getDataRate = async (id) => {
		if (id) {
			const rateData = await ChildSchoolJournals.getRate(id);
			if (rateData) {
				this.setState({dataPopupReview:rateData});
				this.setState({dataPopupReview:rateData});
				this.setState({
                    textCommentRateReview: rateData.data[0].comment.replace(/&#13;|&#013;/g, '\n'),
                    textRateSelectReview: rateData.data[0].rate,
                });
				let currentUser = JSON.parse(localStorage.getItem("user"))["name"];
				if (currentUser === "Администратор") {
					this.setState({
						accessUser: true,
					});
				}

				// Add events in log
				const child = await Childs.get(this.state.id);
				const fullName = ' ' + child.data[0].lastName + ' ' + child.data[0].firstName + ' ' + child.data[0].middleName;
				const rate = ' ' + Moment(rateData.data[0].date*1000).format('DD.MM.y');
				Events.add(this.state.user.id, eventMessageName.viewEducationJournalRatePage + fullName + rate, null, null, null, 4);
			}
			this.tooglePopupReview();
		}
	}
	convertTimestampToDate = (timestamp) => {
		const date = new Date(timestamp * 1000).toLocaleDateString();
		return date
	}
	tooglePopupReview = () => this.setState({popupReview: !this.state.popupReview})
	tooglePopupEdit = () => this.setState({popupEdit: !this.state.popupEdit})
	tooglePopupAdd = () => this.setState({popupAdd: !this.state.popupAdd})
	editRateJournal = (e) => {
		e.preventDefault();
		this.tooglePopupReview();
		this.tooglePopupEdit();
	}
	getTextArea = (text) => {
        this.setState({ textCommentRate: text.target.value });
    };
	getRateSelect = (rate) => {
        this.setState({ textRateSelect: rate.target.value });
    };
	saveRateJournal = (e) => {
		e.preventDefault();
		if (e.target.rate.value && e.target.commentRate.value) {
			this.updateRateJournal(this.state.dataPopupReview.data[0].id, {rate: e.target.rate.value, comment: e.target.commentRate.value});
			this.tooglePopupEdit();
		}
	}
	updateRateJournal = async (id, d) => {
		// Add events in log
		// get old data before update
		const dataModel = await ChildSchoolJournals.getRate(id);
		const getOldData = (data) => {
			const keysData = Object.keys(data);
			let oldData = {};
			keysData.forEach((key) => {
				let item = {}
				item[key] = dataModel.data[0][key];
				Object.assign(oldData, item);
			});
			return oldData;
		}
		const oldData = getOldData(d);
		const newData = d;

		const res = await ChildSchoolJournals.update(id, d).catch(() => this.error());
		const rate = await ChildSchoolJournals.get(this.state.id);

		// Add events in log
		const getDataModel = (await Childs.get(rate.data[0].childId)).data[0];
		const fullName = ' ' + getDataModel.lastName + ' ' + getDataModel.firstName + ' ' + getDataModel.middleName;
		const dateChange = ' ' + Moment(dataModel.data[0].date*1000).format('DD.MM.y');
		Events.add(this.state.user.id, eventMessageName.updateDataRateEducationJournal + fullName + dateChange, JSON.stringify(oldData), JSON.stringify(newData), null, 5);
			
		this.setState({dataRate:rate});
		this.uploadRateJoarnal();
		if (res) Errors.add();
		else return false;
	}
	addUpdateRateJournal = async () => {
		const rate = await ChildSchoolJournals.get(this.state.id);
		this.setState({dataRate:rate});
		this.uploadRateJoarnal();
	}
	handleRateSelection = (value) => {
		this.setState({rate:value});
	}
	render() {
		return <Template
					page={'education'}
					title={'Электронный дневник'}
					backLink={'/education-childs'}>
			{this.state.loading ? <Loader /> :
				this.state.notfound === null ? null :
					this.state.notfound ? <NotFound back={'/education-childs'} /> :
					<div>
						<form id="education-journal-form" className="form education-journal" onSubmit={this.save}>
							<div className="form-row">
								<label>имя ребенка</label>
								<h4>{this.state.child.lastName} {this.state.child.firstName} {this.state.child.middleName}</h4>
							</div>
							{this.state.popupAdd && (
								<div>
									<div
										className="journal-popup__backgroud"
										onClick={this.tooglePopupAdd}
									></div>
									<div className="journal-popup__inner journal-add">
										<div className="form-row form-row-block">
												<div className="form-row-block-inner">
													{/* <div className="form-row">
														<label htmlFor="date">Дата оценки</label>
														<input type="date" name="date" id="date" className="date" required
															onChange={this.formHandle}
															defaultValue={this.state.date}
														/>
													</div> */}
													<div className="form-row-oneline">
														{/* <div className="form-row">
															<label htmlFor="schoolSubjectId">Предмет</label>
															<select name="schoolSubjectId" id="schoolSubjectId" onChange={this.formHandle} required
															defaultValue={this.state.subjectAdd}
															>
																<option></option>
																	{this.state.schoolsubjects.map((v,i) => <option key={i} defaultValue={v.id}
																>{v.name}</option>)}
															</select>
														</div> */}
														<div className="form-row">
															<label htmlFor="rate">Оценка</label>
															{/* <select name="rate" id="rate" className="lite" onChange={this.formHandle} required>
																<option></option>
																{[1,2,3,4,5].map((v,i) => <option key={i} value={v}>{v}</option>)}
															</select> */}
															<div className="buttons_rate">
																{[1, 2, 3, 4, 5].map((value, index) => (
																	<button
																		key={index}
																		className={`rate-button ${this.state.rate === value ? 'selected' : ''}`}
																		onClick={() => 
																			this.handleRateSelection(value)
																		}
																	>
																		{value}
																	</button>
																))}
															</div>
														</div>
													</div>
													<div className="form-row">
														<label htmlFor="comment">Комментарий</label>
														<input type="text" name="comment" id="comment" placeholder='необязательно' className="wide" onChange={this.formHandle} value={this.state.comment} />
													</div>
												</div>
										</div>
										<div className="form-control-panel journal-save">
											<button form="education-journal-form" type="submit">Сохранить</button>
										</div>
									</div>
								</div>
							)}
						</form>
						<div className="dairy-education-journal">
							<div className="box-education-journal2">
								<table className="table-education-journal">
									{Object.entries(this.state.journalRate).map((month,idMonth) =>
										<tbody key={idMonth}>
											<tr className="table-education-journal__month">
												<th></th><th colSpan="31">{month['1'].months}</th>
											</tr>
											<tr className="table-education-journal__description">
												<th>Предмет</th>
												<th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th><th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th><th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>28</th><th>30</th><th>31</th>
											</tr>
											{Object.entries(month['1'].subjects).map((subject,idSubject) =>
												<tr className="table-education-journal__description" key={idSubject}>
													<th>{subject['1'].subject}</th>
													{Object.entries(subject['1'].days).map((day,idDay) =>
														<td key={idDay} data-date-rate={day['1'].daterate} data-rate={day['1'].rate} data-id-rate={day['1'].idrate}
															onClick={(e) => this.dateHandle(e, day['1'].idrate)}
															data-date={day['1'].date}
															data-subject={day['1'].subjectToDate}
														>{day['1'].rate}</td>
													)}
												</tr>
											)}
										</tbody>
									)}
									
								</table>
								{this.state.popupReview && (
									<div>
										<div
											className="journal-popup__backgroud"
											onClick={this.tooglePopupReview}
										></div>
										<div className="journal-popup__inner">
											<h1 className="journal-popup__title">
												Комментарий оценки для даты: {' '}
												{this.convertTimestampToDate(this.state.dataPopupReview.data[0].date)}
											</h1>
											<textarea
												className="journal-edit__content" disabled
												defaultValue={this.state.dataPopupReview.data[0].comment}
											></textarea>
											<button className="buttondelete" onClick={() => this.deleteRate(this.state.dataPopupReview.data[0].id)}>Удалить</button>
											{this.state.accessUser && (
												<div className="journal-popup__button">
													<div className="form-control-panel">
														<button
															className="journal-edit__save"
															onClick={(e) => {
																this.editRateJournal(e)
															}}
														>
															Редактировать
														</button>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
								{this.state.popupEdit && (
									<div className="journal-edit form">
										<div
											className="journal-popup__backgroud"
											onClick={this.tooglePopupEdit}
										></div>
										<form id='edit-rate-form' className="journal-popup__inner" onSubmit={this.saveRateJournal}>
											<h1 className="journal-popup__title">
												Редактирование оценки для даты: {' '}
												{this.convertTimestampToDate(this.state.dataPopupReview.data[0].date)}
											</h1>
											<div className="form-row journal-rate">
												<label htmlFor="rate">Оценка</label>
												<select name="rate" id="rate" className="lite"
													onChange={this.getRateSelect} defaultValue={this.state.dataPopupReview.data[0].rate}
													required
												>
													<option></option>
													{[1,2,3,4,5].map((v,i) => <option key={i} value={v}>{v}</option>)}
												</select>
											</div>
											<textarea
												name="commentRate"
												className="journal-edit__content"
												defaultValue={this.state.dataPopupReview.data[0].comment}
												onChange={(text) => this.getTextArea(text)}
											></textarea>
											<div className="journal-popup__button">
												<div className="form-control-panel">
													<button
														form="edit-rate-form"
														className="journal-edit__save"
														disabled={
															(this.state.textCommentRate === "" && this.state.textRateSelect == 0) ||
															(this.state.textRateSelect == 0 && this.state.textCommentRate === this.state.textCommentRateReview) ||
															(this.state.textCommentRate === this.state.textCommentRateReview &&
															this.state.textRateSelect == this.state.textRateSelectReview)
														}
													>
														Сохранить
													</button>
												</div>
											</div>
										</form>
									</div>
								)}
							</div>
						</div>
					</div>
			}
			<Alert />
		</Template>
	}
}

EducationJournalScreen.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
export default EducationJournalScreen;