import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= async (id)			=> await Http.get('usergrouproles', {conditions:[{k:'id',v:id}]});
const getAll	= async ()				=> await Http.get('usergrouproles', {orders:[{k:'id',isd:requestOderType.DESC}]});

const byGroupGet	= async (groupId)	=> await Http.get('usergrouproles', {conditions:[{k:'userGroupId',v:groupId}]});

const add		= async (data)			=> await Http.post('usergrouproles', {data});
const remove	= async (groupId)		=> await Http.remove('usergrouproles', {conditions:[{k:'userGroupId',v:groupId}]});

export {
	getAll,
	get,
	byGroupGet,
	add,
	remove
}